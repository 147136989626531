import { useState, useRef, useEffect } from "react";
import classes from "./Screen.module.scss";
//import thumbnailService from "./ThumbnailService";
import WaitRipple from "src/components/WaitRipple";
import lecturePlayerService from "../Player/LecturePlayerService";
import { EditScreenDto, LectureState, ScreenType } from "../domain/LectureState";
import ImageSoundPlayer from "./ImageSoundPlayer";
import VideoPlayer from "./VideoPlayer";


const Screen = (props: { currentScreen?: EditScreenDto, lectureData: LectureState, children?: any, className: string }) => {

    const { currentScreen, lectureData } = props;

    let [changeScreen, setChangeScreen] = useState(false);

    useEffect(()=>{
        setChangeScreen(true)
    }, [currentScreen?.ScreenId])

    const clickScreen = () => {
        lecturePlayerService.togglePlayingState();
    }

    return (<>
        {(changeScreen) && <WaitRipple />}
        <div onClick={clickScreen} className={[classes.screen, props.className].join(" ")} >
            {currentScreen!.ScreenType === ScreenType.ImageSound &&
                <div className="w-100 h-100">
                    <ImageSoundPlayer
                        className=""
                        lecturedata={props.lectureData}
                        currentScreen={currentScreen!}
                        onReady={() => setChangeScreen(false)} />
                </div>
            }

            {currentScreen!.ScreenType === ScreenType.Video &&
                <VideoPlayer currentScreen={currentScreen!}
                    lecturedata={props.lectureData} onReady={() => setChangeScreen(false)} className="" />

            }

        </div>
    </>
    )
}


export default Screen;