import React, { useEffect } from "react";
import { Progress } from "reactstrap";

import fileUploadservice, { fileUploadData } from "./FileUploadService";
import { currentUser } from "../../services/user/userService";
import {  useAtom } from "jotai";
import { isFunction } from "../../utils/underscore";
import { FileUploadProps } from "../../coursepart/domain/FileDialogsettings";
import FilePicker from "./FilePicker";
import imageDownScaler from "./ImageDownScaler";



const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {

    const { backGroundMessage, onSubmit, onClose, fileOptions, languageService, previewReady, children,
        postchildren, imagePreviewOptions, className, showUploadedFilename } = props;

    const _className = className || 'border rounded-lg p-3';

    const [serviceState] = useAtom(fileUploadData);
    const [user] = useAtom(currentUser);


    const filePicked = (userId: string, fi: File) => {
        const response = fileUploadservice.uploadFile(backGroundMessage, userId, fi);

        if (response.uploadQueued === false) {
            onSubmit(response.promise);
        }
        else {
            response.promise
                .then((file) => onSubmit(file as any))
                .catch(() => onClose());
        }
    }

    useEffect(() => {
        fileUploadservice.setFile(undefined);
    }, []);

    const onFilePicked = (fi: File) => {

        if (imagePreviewOptions) {
            imageDownScaler(fi, imagePreviewOptions)
                .then((data) => {
                    if (data) {
                        fileUploadservice.setImagePreviews(data)
                        if (previewReady && isFunction(previewReady)) {
                            previewReady(() => filePicked(user!.Id, fi), data);
                        }
                    }
                });


            if (!previewReady) {
                filePicked(user!.Id, fi);
            }
        }
        else{
            filePicked(user!.Id, fi);
        }
    }

    return <>
        <div className={_className}>
            {children}
            {showUploadedFilename && serviceState.uploadedFile && <div className="alert alert-primary">{ serviceState.uploadedFile.name }</div>}
            <FilePicker className="mt-2" onFilePicked={onFilePicked} fileOptions={fileOptions} languageService={languageService} />

            <div className={"mt-3 " + (serviceState.isUploading ? "" : "d-none")}>
                <Progress
                    color="success"
                    value={serviceState.percentUploaded}
                />{" "}
                &nbsp;
            </div>

            {postchildren}
        </div>

    </>


}

export default FileUpload;