import FIFO from './Fifo';

/**
 * @classdesc This VUMeter class implements a volume level meter UI.
 * To initialize it, developers need to provide the ID of the canvas
 * element where the VUMeter will be rendered, the minimum decibel
 * value for the VUMeter, an AnalyserNode, fftSize of the
 * analyserNode, and fifoSize to control the VMMeter update speed. The
 * minimum decibel value defines the range of the VUMeter that will be
 * considered silent. When the VUMeter is created, it requires an
 * AnalyserNode for real-time audio input data. This class requires a
 * canvas element and FIFO class. The FIFO class is used to determine
 * how fast the VUMeter gets updated. The height of the VUMeter is
 * determined by the minimum number in the FIFO class.
 */
class VUCalculator {

  minDisplayDecibel_: number;
  fifo_: FIFO;
  analyser_: AnalyserNode;
  dataArray_: Float32Array;

  /**
   * @constructor
 * @param {Number} minDecibel The minimum lower bound decibel of the
   * visualization. If the measured volume is lower than this value,
   * it is considered silent.
   * @param {AnalyserNode} analyserNode The AnalyserNode used for the
   * visualization.
   * @param {Number} fftSize The window size in samples used for
   * performing a Fast Fourier Transform (FFT) to obtain frequency
   * domain data in the AnalyserNode.
   * @param {Number} fifoSize The size of the FIFO queue which
   * determines how fast the VUMeter gets updated.
   */
  constructor(minDecibel: number, analyserNode: AnalyserNode, fftSize: number, fifoSize: number) {
    /** @private @const {!minDisplayDecibel} Minimum decibel level for
     * the VU meter. Any decibel lower than this value is considered
     * silent and converted to a positive value for calculation and
     * graph convenience. */
    this.minDisplayDecibel_ = Math.abs(minDecibel);
    /** @private @const {!fifo} An instance of FIFO class for caching
     * RMS values and extracting a minimum value over time. */
    this.fifo_ = new FIFO(fifoSize);
    /** @private @const {!analyser} AnalyserNode that will be used for
     * visualization */
    this.analyser_ = analyserNode;
    this.analyser_.fftSize = fftSize;
    /** @private @const {!dataArray} Array to store the frequency data
     * obtained from the AnalyserNode. */
    this.dataArray_ = new Float32Array(this.analyser_.frequencyBinCount);
  }

  /**
   * Render the VU meter on the canvas. This function is called
   * periodically to update the meter display. This class doesn't have
   * a timer or a render loop; it requires an external driver for the
   * actual rendering.
   */
  GetVolume() {
    this.analyser_.getFloatTimeDomainData(this.dataArray_);

    const rootMeanSquare = this.calculateRMS(this.dataArray_);
    const decibel = 20 * Math.log10(rootMeanSquare);
    let absDecibel = Math.abs(decibel);
    this.fifo_.push(absDecibel);
    let minDecibel = this.fifo_.getMinValue();
    return minDecibel > this.minDisplayDecibel_ ? 1 : minDecibel / this.minDisplayDecibel_;

  }

  /**
   * Calculate the root mean square (RMS) value from the inputArray.
   * @param {Array} inputArray The array from which to calculate the
   * RMS value.
   * @returns {Number} The root mean square of the array.
   */
  calculateRMS(inputArray: Float32Array) {
    let sumOfSquares = 0;
    for (let i = 0; i < inputArray.length; i++) {
      sumOfSquares += inputArray[i] * inputArray[i];
    }
    let meanSquare = sumOfSquares / inputArray.length;
    return Math.sqrt(meanSquare);
  }
}

export default VUCalculator;