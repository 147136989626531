import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import { onePartatom } from "./CoursePartService";
import { useMemo } from "react";

export default function CoursePartNoRead() {

  const { languageService: t } = useI18n();
  const { partId } = useParams();
  const [partData] = useAtom(
    useMemo(() => onePartatom(partId || ""), [partId])); 
    
  return <>

  <div className={"scrollContent"}>
    <div className="card-box big-card-box">
      <div className="card-box-title card-title ">
        <div className='max-col-width w-100'>
          <h3>{partData.Data?.Name}</h3>
        </div>
      </div>
      <div className="card-box-content card-body">
        {t.getText("courepart.no.read.permission")}
      </div>
    </div>
  </div>
</>



}