import * as React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useI18n } from '../../utils/lni18n';
import { useState } from 'react';
import AddTestForm, { CreateTestData } from "./AddTestForm";


const AddTestDialog: React.FC<{ onSubmit: (data: CreateTestData) => void, onCancel: () => void }> = ({ onSubmit, onCancel }) => {

    const { languageService: t } = useI18n();
    const [createData, setData] = useState<CreateTestData>({ name: undefined, type: undefined, lastInList: false });

    const handleSubmit = React.useCallback(() => {
        onSubmit(createData)
    }, [createData])

    const onChange = (data: CreateTestData) => {
        setData(data);
    }

    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("add.test")}</ModalHeader>
        </span>

        <ModalBody>
            <AddTestForm onChange={onChange} />

        </ModalBody>
        <ModalFooter>

            <Button disabled={!(createData.name && createData.type)} className="btn btn-primary btn-small" onClick={() => handleSubmit()}>{t.getText("add")}</Button>&nbsp;
            <Button className="btn btn-inverse btn-small" onClick={onCancel}>{t.getText("cancel")}</Button>
        </ModalFooter>
    </>
}

export default AddTestDialog;
