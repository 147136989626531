
const StringFormat =
    function (str:string , argus:any[]) {
        if (str && argus.length) {
            var t = typeof argus[0];
            var key;
            var args = ("string" === t || "number" === t) ?
                Array.prototype.slice.call(argus)
                : argus[0];

            for (key in args) {
                str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
            }
        }

        return str;
    };



    export {StringFormat};