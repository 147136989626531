export const HexToRGB = function (hexString: string)
{
    const hex = parseInt(((hexString.indexOf('#') > -1) ? hexString.substring(1) : hexString), 16);
    return { r: hex >> 16, g: (hex & 0x00FF00) >> 8, b: (hex & 0x0000FF) };
};

export const DecimalToHex = function (d:number, paddingZeros?: string)
{
    if (paddingZeros == undefined)
        paddingZeros = "";
    
    var hex = d.toString(16);
    hex = paddingZeros.substring(0, paddingZeros.length - hex.length) + hex;
    return hex;
};