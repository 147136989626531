import { DeleteBodyDto, ObjectPermissionEditorDto, ObjectPermissionRowDto, PermissionsState, SetPermissionDto, UsersAndGroupsDto } from './PermissionTypes';
import { atom, getDefaultStore } from 'jotai';
import api from '../../services/api/axiosService';
import { produce } from 'immer';
import { IdAndNameAndTypeDto, IdAndNameWithNumDto } from 'src/types';
import coursePartService from 'src/coursepart/CoursePartService';
import { TinyAuthor } from 'src/coursepart/domain/TinyAuthor';



const store = getDefaultStore();
declare type StateMutater = (state: PermissionsState) => void;


export class PermissionsService {

    public permissionAtom = atom<PermissionsState>({ subjects: undefined });
    
    public usersAndGroupsAtom = atom<UsersAndGroupsDto>({ Users: [], Groups: [] });


    constructor(objectId: string,
        getListDataUrl: string,
        getUserAndGroupsUrl: string,
        private addOwnerCallback?: (objId: string, a: TinyAuthor) => void,
        private removeOwnerCallback?: (objId: string, id: string) => void

    ) {
        this.permissionAtom.debugLabel = "permissionAtom " + objectId;
        this.usersAndGroupsAtom.debugLabel = "usersAndGroupsAtom " + objectId;
        this.getListData(getListDataUrl);
        this.getUsersAndGroups(getUserAndGroupsUrl);
    }

    MutatePermissionState: (worker: StateMutater) => void = (worker) => {

        const state = store.get(this.permissionAtom);
        const newState = produce(state, draft => worker(draft));
        store.set(this.permissionAtom, newState);
    }

    MutateUserAndGroupsState: (worker: (state: UsersAndGroupsDto) => void) => void = (worker) => {

        const state = store.get(this.usersAndGroupsAtom);
        const newState = produce(state, draft => worker(draft));
        store.set(this.usersAndGroupsAtom, newState);
    }

    getUsersAndGroups(url: string) {
        api.get<UsersAndGroupsDto>(url)
            .then(response => {
                if (response) {
                    const data = {
                        Users: response.data.Users.map(u => ({ ...u, type: "user" }) as IdAndNameAndTypeDto),
                        Groups: response.data.Groups.map(u => ({ ...u, type: "group" }) as IdAndNameWithNumDto)
                    }
                    store.set(this.usersAndGroupsAtom, data);
                }
            });
    }

    getListData(url: string) {
        api.get<ObjectPermissionEditorDto>(url)
            .then(response => {
                if (response) {
                    this.setSubjects(response.data);
                }
            });
    };

    setSubjects(dto: ObjectPermissionEditorDto | undefined) {
        this.MutatePermissionState(state => {
            state.subjects = dto;
        });

    }

    async setPermission(url: string, postData: SetPermissionDto, subjectType: 'user' | 'group') {

        const response = await api.put<ObjectPermissionRowDto>(url, postData);
        if (!response) {
            return;
        }
        this.updatePermissionRow(subjectType, response.data);
        this.callBackForOwner(subjectType, response.data);
    }



    updatePermissionRow(subjectType: 'user' | 'group', data: ObjectPermissionRowDto) {

        this.MutatePermissionState(state => {

            if (state.subjects) {
                let array = null;

                if (subjectType === "group") {
                    array = state.subjects.Groups;
                }
                else {
                    array = state.subjects.Users;
                }
                let row = array.find(p => p.UserOrGroupId === data.UserOrGroupId);
                if (row) {
                    const index = array.indexOf(row);
                    array.splice(index, 1, data);
                }
            }
            return state;
        });
    };


    callBackForOwner(subjectType: string, data: ObjectPermissionRowDto) {

        if (subjectType === "user" && data.Permissions["owner"]) {

            if (data.Permissions["owner"].Set) {

                this.addOwnerCallback && this.addOwnerCallback(data.ObjectId, {
                    FirstName: data.Name,
                    Id: data.UserOrGroupId,
                    LastName: "",
                    LockedDate: "0001-01-01T00:00:00",
                    Locktype: 0,
                    Mail: "",
                    Phones: ""
                })
            }
            else {
                this.removeOwnerCallback && this.removeOwnerCallback(data.ObjectId, data.UserOrGroupId);
            }
        }
    }



    async addPermission(url: string, postData: SetPermissionDto, subjectType: 'user' | 'group') {

        const response = await api.put<ObjectPermissionRowDto>(url, postData);
        if (!response) {
            return;
        }
        if (subjectType === "user") {
            this.MutatePermissionState(state => {
                state.subjects!.Users = [response.data, ...state.subjects!.Users]
            })

            this.MutateUserAndGroupsState(state => {
                state.Users = state.Users.filter(g => g.Id !== postData.UserOrGroupId);
            });
        }

        if (subjectType === "group") {
            this.MutatePermissionState(state => {
                state.subjects!.Groups = [response.data, ...state.subjects!.Groups]
            });


            this.MutateUserAndGroupsState(state => {
                state.Groups = state.Groups.filter(g => g.Id !== postData.UserOrGroupId);
            });
        }

    }


    async deletePermission(url: string, postData: DeleteBodyDto, subjectType: 'user' | 'group') {

        await api.put(url, postData);

        const pState = store.get(this.permissionAtom);

        if (subjectType === "group") {

            const group = pState.subjects!.Groups.find(g => g.UserOrGroupId === postData.UserOrGroupId);

            this.MutatePermissionState(state => {
                state.subjects!.Groups = state.subjects!.Groups.filter(g => g.UserOrGroupId !== postData.UserOrGroupId);
            });

            if (group) {
                this.MutateUserAndGroupsState(state => {
                    state.Groups.push({ Id: postData.UserOrGroupId, Name: group.Name, Num: 0, type: "group" });
                });
            }

        }
        else {

            const user = pState.subjects!.Users.find(g => g.UserOrGroupId === postData.UserOrGroupId);

            this.MutatePermissionState(state => {
                state.subjects!.Users = state.subjects!.Users.filter(g => g.UserOrGroupId !== postData.UserOrGroupId);
            });

            if (user) {
                this.MutateUserAndGroupsState(state => {
                    state.Users.push({ Id: postData.UserOrGroupId, Name: user.Name, type: "user" });
                });
            }

            if (this.removeOwnerCallback) {
                this.removeOwnerCallback(postData.ObjectId, postData.UserOrGroupId);
            }
        }

    }


}
