import { LnCollapseGroup } from "src/components/LnCard/LnCollapseGroup";
import { FilesGroupModel } from "src/coursepart/domain/FilesGroupModel";
import ReadOnlyFileCard from "./ReadOnlyFileCard";



export default function ReadOnlyFileGroup(props: {
    group: FilesGroupModel,
    closed?: boolean, partId: string
}) {
    const { group, closed, partId } = props;

    const panel = <LnCollapseGroup groupName={group.Name} isOpen={!closed} >
        <div className="text-right mb-3">
        </div>

        <div>
            {group.Files.map((item) => (
                <ReadOnlyFileCard partId={partId} key={item.Id} file={item} />
            ))}
        </div>

    </LnCollapseGroup>;


    return <div id={"g_" + group.Id} role="listitem" key={group.Id} className="mb-4" >
        {panel}
    </div>;

}