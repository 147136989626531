import { ModalHeader, ModalBody, ModalFooter, CardFooter, CardBody } from "reactstrap";
import classes from './player.module.scss';

import { useI18n } from "src/utils/lni18n";
import { EditLectureDto, EditScreenDto } from "../domain/LectureState";

const Thumbs = (props: {
    currentLecture: EditLectureDto,
    onSubmit: (num: number) => void,
    onCancel: () => void,
}) => {

    const { currentLecture } = props;
    const { languageService: t } = useI18n();

    const handleThumbClick = (screenNum: number) => {
        props.onSubmit(screenNum);
    }

    const closeScreensDialog = () => {
        props.onCancel();
    }

    return (
        <>
            <ModalHeader>{t.getText("screens")}</ModalHeader>
            <ModalBody>
                <div className=" d-flex flex-wrap">
                    {currentLecture.Screens.map((s:EditScreenDto, i) => {
                        return <div key={s.ScreenId} className={classes.card + " card pointer d-flex flex-column"} onClick={() => handleThumbClick(s.Order)} >
                            <CardBody className={classes.screenThumbContainer + " d-flex flex-column"}>
                                <div className={classes['img-wrapper'] + "  m-auto"}>
                                    <img src={s.ThumbnailUrl} className={classes['lecture-img']} alt="" />
                                </div>
                            </CardBody>
                            <CardFooter className={" text-center"}>
                                <small>{(i + 1)}</small>
                            </CardFooter>
                        </div>

                    })}

                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-inverse btn-small" type="button" onClick={closeScreensDialog}> {t.getText('close')} </button>
            </ModalFooter>

        </>);


}
export default Thumbs;
