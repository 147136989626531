import { useI18n } from "src/utils/lni18n";
import { CoursePartLink } from "./domain";

const PrintPartLinks = (props: { links: CoursePartLink[] }) => {

  const { links } = props;
  const { languageService: t } = useI18n();

  if (!links || links.length === 0) return null;

  return (
    <div>
      <h3 className="mt-5 mb-3 border-bottom">{t.getText("links")}</h3>
      {links.map(link => <div key={link.Id} className="mt-3">
        <h4 >{link.Url}</h4>
        <div className="preserve-white" dangerouslySetInnerHTML={{ __html: link.Name }}></div>

      </div>)
      }
    </div>
  )
}
export default PrintPartLinks;