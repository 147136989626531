import api from "../services/api/axiosService";
import { atom, getDefaultStore } from "jotai";
import { GetCoursepartsResponse } from "./domain/GetCoursepartsResponse";
import { produce } from "immer";
import { IdAndNameDto } from "src/types";
import { waitRippleservice } from "src/services/WaitRippleService";
import { ErrorService } from "src/components/Error/ErrorService";
import languageService from "src/utils/languageService";
import coursePartService from "./CoursePartService";

const store = getDefaultStore();

export const partsToPingAtom = atom<string[]>([]);
partsToPingAtom.debugLabel = "partsToPingAtom";



const endPoints = {
    CHECK_PUBLISH_LOCK_OF_PARTS: `/author/courseparts/CheckAsyncPublish`,
}

export class CoursePartPingService {

    pingTimerHandle?: number;

    public addPartToCheck(partId: string) {
        const ids = store.get(partsToPingAtom);
        const startPing = ids.length === 0;
        store.set(partsToPingAtom, [...ids, partId]);

        if (startPing) {
            this.PingForEncoding();
        }
    }

    public removePartToCheck(partId: string) {
        const ids = store.get(partsToPingAtom);
        const newIds = ids.filter(id => id !== partId);
        store.set(partsToPingAtom, newIds);
        if (newIds.length === 0) {
            this.stopPing();
        }
    }

    public clear(){
        store.set(partsToPingAtom, []);
        window.clearTimeout(this.pingTimerHandle);
    }

    private stopPing() {
        window.clearTimeout(this.pingTimerHandle);
    }

    private async PingForEncoding() {

        window.clearTimeout(this.pingTimerHandle);
        const ids = store.get(partsToPingAtom);
        if (ids.length > 0) {

            const response = await api.post<IdAndNameDto[]>(endPoints.CHECK_PUBLISH_LOCK_OF_PARTS, ids);

            if (response.data.length > 0) {
                const text = `${languageService.getText("courseparts.published")}\r\n${response.data.map(d => `\r\n${d.Name}`)}`
                const newIds = ids.filter(id => !response.data.find(da => da.Id === id));
                store.set(partsToPingAtom, newIds);

                if (newIds.length > 0) {
                    this.pingTimerHandle = window.setTimeout(this.PingForEncoding.bind(this), 5 * 1000);
                }
                else{
                    this.clear();
                }
                coursePartService.publishedParts( response.data.map(d => d.Id) );

                ErrorService.setError({ header: languageService.getText("published"), message: text, when: new Date() });

            }
            else{
                this.pingTimerHandle = window.setTimeout(this.PingForEncoding.bind(this), 5 * 1000);
            }
        }

    }


}
const coursePartPingService = new CoursePartPingService();
export default coursePartPingService;