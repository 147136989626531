import { useCallback, useEffect, useRef } from "react";
import classes from "./ImagePicker.module.scss";
import ImageScaler from "./ImageScaler";
import { ImagePickerService, imageAtom } from "./ImagePickerService";
import { useAtom } from "jotai";
import fileUploadservice, { fileUploadData } from "../FileUpload/FileUploadService";
import WaitRipple from "../WaitRipple";
import { useI18n } from "../../utils/lni18n";
import { ImagePickerAddImagePostDto } from "./ImagePicker_types";
import { CompleteUpload } from "../FileUpload/FileUploader";
import FilePicker from "../FileUpload/FilePicker";
import imageDownScaler from "../FileUpload/ImageDownScaler";
import { currentUser } from "src/services/user/userService";


const ImageUpload = ({ imagePickerService }: { imagePickerService: ImagePickerService }) => {

  const { languageService: t } = useI18n();
  const [imageDataAtom] = useAtom(imageAtom);
  const { isUploading } = imageDataAtom;
  const [fileUploadAtom, setFileUploadData] = useAtom(fileUploadData);
  const { imagePreviews } = fileUploadAtom;
  const [user] = useAtom(currentUser);

  const pickedFile = useRef<File | undefined>(undefined);

  const imagePreviewOptions = [{ name: "full", maxheight: 1200, maxwidth: 1200 }];

  const fileOptions = {
    maxSize: "20mb",
    accepts: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "image/gif": [".gif"],
      "image/tiff": [".tif", ".tiff"],
      "image/bmp": [".bmp"],
    }
  };

  useEffect(() => {
    pickedFile.current = undefined;
    fileUploadservice.setImagePreviews(undefined);
  }, []);


  const doSaveImage = () => {
    if (pickedFile.current) {
      imagePickerService.setIsUploading(true);
      startUpload(user!.Id, pickedFile.current);
    }
  }

  const startUpload = (userId: string, fi: File) => {
    const response = fileUploadservice.uploadFile(undefined, userId, fi);
    response.promise.then((fi) => handleFileUploadReady(fi));
  }

  const handleFileUploadReady = async (file: CompleteUpload) => {

    const { imagePreviewWidth } = imageDataAtom;

    const data: ImagePickerAddImagePostDto = {
      Filename: file.name,
      UploadedFilename: file.Key!,
      MaxWidth: imagePreviewWidth
    }
    try {
      const newId = await imagePickerService.addImageToList(data);
      if (newId) {
        imagePickerService.setSelectedImage(newId);
        imagePickerService.setTab('selected');
      }

    }
    finally {
      fileUploadservice.setImagePreviews(undefined);
      imagePickerService.setIsUploading(false);
    }

  };

  const onFilePicked = (fi: File) => {

    pickedFile.current = fi;

    if (imagePreviewOptions && fi) {
      imageDownScaler(fi, imagePreviewOptions)
        .then((data) => {
          if (data) {
            fileUploadservice.setImagePreviews(data);
            window.setTimeout(() => {
              imagePickerService.setImagePreviewWidth(data.full.width);
            }, 200)

          }
        });
    }
  }


  return <div className={classes.scrollPanel}>

    {isUploading && <WaitRipple />}

    <div className="px-3">

      <h3>{t.getText("upload.file")}</h3>

      {imagePreviews ? <ImageScaler imagePickerService={imagePickerService} onSaveImage={doSaveImage} />
        : null
      }

      <div className="row">
        <div className="col-lg-8">
          <FilePicker className="mt-4" onFilePicked={onFilePicked} fileOptions={fileOptions} languageService={t} />
        </div>
      </div>
    </div>
  </div>
}

export default ImageUpload;
