import { DropdownItem, DropdownMenu } from 'reactstrap';
import classes from './TopMenu.module.scss';
import LnDropDownMenu from "src/components/LnDropDownMenu";
import { useI18n } from "src/utils/lni18n";
import { Link, useNavigate } from 'react-router-dom';
import { InputDialog, InputOptions } from "src/components/Modalservice/InputDialog";
import { useDialog } from '../Modalservice/Dialogservice';
import { useAtom } from 'jotai';
import courseService, { lastCoursesAtom } from 'src/course/CourseService';
import LnIcon from '../LnIcon';
import { urlService } from 'src/services/NavService';


const CourseMenu = () => {

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();
    const navigate = useNavigate();

    const [lastCourses] = useAtom(lastCoursesAtom);

    const navigateToCourse = (id: string) => {

        const url = courseService.getUrl(id);
        if( url) navigate(url);
      }

    // const newCourse = () => {
    //     dialogPortal({
    //         factory: (onSubmit, onCancel) => {
    //             const dprops: InputOptions = {
    //                 title: t.getText("courses.createnew"),
    //                 message: <div>{t.getText("mail.noname")}</div>,
    //                 languageService: t,
    //                 onCancel: onCancel,
    //                 onSubmit: onSubmit,
    //                 type: "input",
    //                 text: ""
    //             }
    //             return <InputDialog {...dprops} />
    //         },
    //         size: "md"
    //     }).then(async (text: string) => {
    //         const id = await courseService.(text);
    //         if (id) {
    //             navigate(`/coursepart/${id}/texts`);
    //         }
    //     });

    // }

    const newCourse = () => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: InputOptions = {
                    title: t.getText("createnewcourse"),
                    message: <div>{t.getText("mail.noname")}</div>,
                    languageService: t,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                    type: "input",
                    text: ""
                }
                return <InputDialog {...dprops} />
            },
            size: "md"
        }).then(async (text: string) => {
            const id = await courseService.createCourse(text);
            if (id) {
                navigate( urlService.urlToCourse(id));
            }
        });

    }


    return <div className={classes["user-info"]}>
        <LnDropDownMenu
            caret={false}
            toggleComponent={
                <h3 className='text-white m-3'>
                    <LnIcon name="course-tree" className='filter-white mr-2' />
                    {t.getText("course")}&nbsp;&nbsp;<span className='white-caret'></span>
                </h3>
            }
            toogleClassName="pointer"
            toogleTagName="div"
        >
            <DropdownMenu className="dropDownMenu" container="body">
                
                <DropdownItem tag="div">
                    <Link className='px-4 py-2' to="/allcourses" >
                        {t.getText("browse")}
                    </Link>
                </DropdownItem>

                <DropdownItem tag="div" onClick={newCourse}>
                    <div className="px-4" >{t.getText("createnewcourse")}...</div>
                </DropdownItem>

                {lastCourses && lastCourses.length > 0 && <>
                    
                        <hr />

                    { lastCourses.map(p => <DropdownItem key={p.Id} tag="div">
                    <Link className='px-4 py-2' to={`/course/${p.Id}/general`} >
                        {p.Name}
                    </Link>
                </DropdownItem>) }
                </>}

            </DropdownMenu>
        </LnDropDownMenu>
    </div >
}


export default CourseMenu;