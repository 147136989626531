import * as React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useI18n } from '../../utils/lni18n';
import { useAtom } from "jotai";
import scrapBookService, { scrapLecturesAtom, scrapScreensAtom } from "./ScrapBookService";
import ScrapScreen from "./ScrapScreen";
import ScrapLecture from "./ScrapLecture";

export enum ScrapType {
    screen,
    lecture
}

export type ScrapBookDialogResult = {
    type: ScrapType,
    id: string
}

const ScrapBookDialog: React.FC<{ readOnly?: boolean, contextId: string, type: ScrapType, onSubmit: (data: ScrapBookDialogResult) => void, onCancel: () => void }> = ({ readOnly, contextId, type, onSubmit, onCancel }) => {


    const [selected, setSelected] = React.useState<string | undefined>(undefined)

    const { languageService: t } = useI18n();

    const [screens] = useAtom(scrapScreensAtom);
    const [lectures] = useAtom(scrapLecturesAtom);

    const handleSubmit = () => {
        if (selected) {
            onSubmit({ type: type, id: selected });
        }
    }

    const screenSelected = (id: string) => {
        scrapBookService.selectScreen(id);
        setSelected(id);
    }

    const lectureSelected = (id: string) => {
        scrapBookService.selectLecture(id);
        setSelected(id);
    }

    const clearItems = () => {
        switch (type) {
            case ScrapType.screen:
                scrapBookService.clearscreens();
                break;

            case ScrapType.lecture:
                scrapBookService.clearLectures();
                break;
        }
    }



    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("scrapbook")}</ModalHeader>
        </span>
        <ModalBody>
            <div className="d-flex flex-wrap">
                {type === ScrapType.screen &&
                    screens.map(s => <ScrapScreen disabled={readOnly || contextId === s.LectureId} key={s.ScreenId} screen={s} select={(s: string) => screenSelected(s)} />)
                }

                {type === ScrapType.lecture &&
                    lectures.map(l => <ScrapLecture disabled={readOnly || contextId === l.PartId} key={l.Id} lecture={l} select={(s: string) => lectureSelected(s)} />)
                }

            </div>

            <div className="mt-3">
                <button className="btn btn-warning btn-small" onClick={clearItems}>{t.getText("clear")}</button>
            </div>
        </ModalBody>
        <ModalFooter>

            <Button disabled={readOnly || !selected} className="btn btn-primary btn-small" onClick={() => handleSubmit()}>{t.getText("paste.clipboard")}</Button>&nbsp;
            <Button className="btn btn-inverse btn-small" onClick={onCancel}>{t.getText("close")}</Button>
        </ModalFooter>
    </>
}

export default ScrapBookDialog;
