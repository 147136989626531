import { useLoaderData } from "react-router-dom";
import { BaseTestDataDTO } from "./DomainModels";
import EditTestForm from "./EditTestForm";
import { useAtom } from "jotai";
import { testDataAtom } from "./TestService";
import { Link } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import classes from "./tests.module.scss";
import { useEffect, useRef, useState } from "react";
import { usePartPath } from "src/utils/usePartPath";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useRect } from "@dnd-kit/core/dist/hooks/utilities";

const EditTest = (props: {}) => {

    const dialog = useRef<HTMLDialogElement | null>(null)
    const partPath = usePartPath();
    const { languageService: t } = useI18n();
    const [generalTestData] = useAtom(testDataAtom);
    const data: BaseTestDataDTO = useLoaderData() as BaseTestDataDTO;
    const [name, setName] = useState(data.Name);

    if (!data) return null;

    useEffect(() => {
        if (data) {
            dialog.current?.showModal();
        };

        return () => dialog?.current?.close();
    }, [data])

    const typeText = generalTestData.testTypes.find(t => t.TestType === data.Type);

    return (
        <dialog className="xl" ref={dialog}>

            <span className="moveable" data-dialog-draggable>
                <ModalHeader>{name}</ModalHeader>
            </span>
            <ModalBody>
                <div className="preserve-white">{typeText?.Description}</div>
                <div className={`${classes.testEdit}`} >
                    <EditTestForm onNameChange={setName} data={data} />

                </div>

            </ModalBody>
            <ModalFooter>
                <Link className="btn btn-primary btn-sm" to={`${partPath}/tests`} >{t.getText("close")}</Link>
            </ModalFooter>

        </dialog>)
}

export default EditTest;

