import { useState } from "react";
import { userService } from "../services/user/userService"
import { useI18n } from "../utils/lni18n";
import {  useAtom } from "jotai";
import { dataAtom } from "./LoginForm";

export default function SendPasswdEmail() {

    const { languageService: t } = useI18n();
    const [userData, setUserData] = useAtom(dataAtom);
    const [sendEmailMessage, setsendEmailMessage ] = useState<string|undefined>();

    const handleIdentityChange = (e: any) => {
        setUserData(data => { return { ...data, "Identifier": e.target.value } })
    }

    const sendPwEmail = async () => {
        const res = await userService.sendPwEmail(userData.Identifier);
        if(res && res.Message){
            setsendEmailMessage(res.Message);
        }
    }


    return (
        <>
            <div className="form-group">
                <label>{t.getText("mail")}</label>
                <input value={userData.Identifier} required autoFocus={true}
                    autoComplete="username" data-testid="inp-alias" type="text"
                    name="Identifier" onChange={(e) => handleIdentityChange(e)} className="input form-control custom-input" />

                <div className="my-3 preserve-white">{t.getText("forgot.passwd")}</div>

            </div>

            {sendEmailMessage && <div className="alert alert-primary" dangerouslySetInnerHTML={{__html:sendEmailMessage}} ></div>}


            <button disabled={userData.Identifier.length < 3} onClick={sendPwEmail} type="button" className="btn btn-primary">{t.getText("send.passwd")}</button>

        </>
    );

}