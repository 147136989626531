import { imagePreviewData } from "./FileUploader";


const filterType = /^(?:image\/bmp|image\/cis-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x-cmu-raster|image\/x-cmx|image\/x-icon|image\/x-portable-anymap|image\/x-portable-bitmap|image\/x-portable-graymap|image\/x-portable-pixmap|image\/x-rgb|image\/x-xbitmap|image\/x-xpixmap|image\/x-xwindowdump)$/i;

/**
 * Return a primise with downscaled images as defined in the options
 */
const imageDownScaler = async (file: File, options: { name: string, maxwidth: number, maxheight: number }[]) => {

    if (!filterType.test(file.type)) {
        return undefined;
    }

    if (!options || options.length === 0) {
        options = [{ name: "thumb", maxheight: 200, maxwidth: 200 }];
    }

    var fileReader = new FileReader();


    const shrinkImage = (image: HTMLImageElement, maxwidth: number, maxheight: number): imagePreviewData | undefined => {
        if (!image || !maxwidth || !maxheight) {
            return undefined;
        }

        let retVal: imagePreviewData = {
            originalWidth: image.width,
            originalHeight: image.height,
            src: "",
            width: 0,
            height: 0
        }

        var scale = image.height / image.width;
        var height = maxheight;
        var width = maxwidth;

        if (image.height <= maxheight && image.width <= maxwidth) {
            height = image.height;
            width = image.width;
        }
        else {
            if (scale > 1 && image.height > maxheight) { // standing up
                width = Math.round(height / scale);
            }

            if (scale < 1 && image.width > maxwidth) {
                height = Math.round(width * scale);
            }
        }

        retVal.height = height;
        retVal.width = width;

        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        if (context) {
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image,
                0, 0, image.width, image.height, 0, 0, width, height);

            retVal.src = canvas.toDataURL();
            return retVal;
        }

        return undefined;
    }

    // delivers a promise with a data url when the file is read
    const promise = new Promise<string>((resolve, reject) => {
        // first define what will happen when img is loaded
        fileReader.onload = (ev: any) => resolve(ev.target.result);

        // den load the img
        fileReader.readAsDataURL(file);
    });


    const createData = (fileUrl: string) => {

        return new Promise<Record<string, imagePreviewData> | undefined>((resolve, reject) => {
            try {
                var image = new Image();
                image.onload = () => {
                    let result: Record<string, imagePreviewData> = {};
                    options.forEach(o => {
                        const shrinkData = shrinkImage(image, o.maxwidth, o.maxheight);
                        shrinkData && (result[o.name] = shrinkData);
                    });
                    resolve(result);
                }
                image.src = fileUrl;
            } catch (error) {
                reject(error);
            }
        });
    }

    const fileUrl = await promise;
    const result = await createData(fileUrl);

    return result;

}


export default imageDownScaler