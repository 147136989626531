import { atom, getDefaultStore } from "jotai";
import { ChangeEvent } from "react";
import { arrayMoveMutate } from "src/utils/ArrayMethods";
import languageService from "src/utils/languageService";

export type MQData = {
  html: string;
  id: string;
  error?: string
}
const store = getDefaultStore();
export class MultipleQuestionService {
  public startValues: string[];
  public datatom;
  constructor(strings: string[]) {
    const data = strings.map(d => ({ html: d , id: this.getRndId()}));
    this.datatom = atom<MQData[]>(data);
    this.datatom.debugLabel = "MQ test datatom";
    this.startValues = [...strings]
  }

  private getRndId = () => {
    const d = new Date();
    return `id${d.getTime()}${Math.floor(Math.random() * 100000)}`;
  }

  public handleChange = (e: ChangeEvent<HTMLTextAreaElement>, index: number) => {

    const editVals = store.get(this.datatom);
    const question = editVals.at(index);
    if (!question) return;
    question!.html = e.currentTarget.value;

    if (question!.html === "") {
      question!.error = languageService.getText('required_field');
      store.set(this.datatom, [...editVals]);
      return undefined;
    }
    else {
      question!.error = undefined;
      const newData = [...editVals];
      store.set(this.datatom, newData);
      return newData.map(q => q.html);
    }
  };

  public addQuestion = () => {
    const editVals = store.get(this.datatom);
    const qs = [...editVals, { html: "", id: this.getRndId() }];
    const strings = qs.map(q => q.html);
    this.startValues = strings;
    store.set(this.datatom, qs);
    return strings;
  };

  public deleteQuestion = (id: string) => {
    const editVals = store.get(this.datatom);
    const qs = editVals.filter(q => id !== q.id);
    const strings = qs.map(q => q.html);
    this.startValues = strings;
    store.set(this.datatom, qs);
    return strings;
  };

  public reorderQuestion = (index: number, indexOver: number) => {
    const editVals = store.get(this.datatom);
    const qs = [...editVals];
    arrayMoveMutate( qs, index, indexOver )
    const strings = qs.map(q => q.html);
    this.startValues = strings;
    store.set(this.datatom, qs);
    return strings;
  };



}
