import { atom, getDefaultStore } from "jotai";
import { CompleteUpload, FilePickedResponse, FileUploadBackgroundData, FileUploadState, S3UploaderProcessData, UploadedFile, imagePreviewData } from "./FileUploader";
import { produce } from "immer";
import { S3Uploader } from "./S3Uploader";
import languageService from "../../utils/languageService";
import { v4 as uuidv4 } from "uuid";
import { CompleteMultipartUploadCommandOutput } from "@aws-sdk/client-s3";

const initialState: FileUploadState = {
    uploadedFile: undefined,
    isUploading: false,
    percentUploaded: 0,
    errorMess: undefined,
    imagePreviews: undefined,
};



export const fileUploadData = atom<FileUploadState>(initialState);
fileUploadData.debugLabel = "fileUploadData";

const dummy: FileUploadBackgroundData = {
    id: "iiid",
    percentUploaded: 50,
    backGroundMessage: "Laddar upp skärmar",
    errorMess: "Det gick inte så bra",
    killer: () => {
        const atom = store.get(backGroundUploads);
        store.set(backGroundUploads, atom.filter(d => d.id !== "iiid"));
    }

}

export const backGroundUploads = atom<FileUploadBackgroundData[]>([]);
backGroundUploads.debugLabel = "backGroundUploads";

const store = getDefaultStore();

const MutateFileUploadState = (method: (state: FileUploadState) => void) => {

    let state = store.get(fileUploadData);
    const newstate = produce(state, (draft) => {
        method(draft);
    })

    store.set(fileUploadData, newstate);
}

class FileUploadservice {

    private t = languageService;

    startUploadState() {
        MutateFileUploadState((state) => {
            state.isUploading = true;
            state.percentUploaded = 0;
            state.errorMess = undefined;
        });

    }
    setFile(file?: UploadedFile) {

        MutateFileUploadState((state) => {
            state.uploadedFile = file;
        });
    }
    setUploading(uploading: boolean) {

        MutateFileUploadState((state) => {
            if (uploading === false) {
                state.percentUploaded = 0;
            }
            state.isUploading = uploading;
        });
    }
    setError(error?: string) {

        MutateFileUploadState((state) => {
            state.errorMess = error;
        });

    }
    setPercentage(perc: number) {

        MutateFileUploadState((state) => {
            state.percentUploaded = perc;
        });



    }
    setImagePreviews(data?: Record<string, imagePreviewData>) {

        MutateFileUploadState((state) => {
            state.imagePreviews = data;
        });
    }

    private setUploadData(data: FileUploadBackgroundData) {

        const atom = store.get(backGroundUploads);
        store.set(backGroundUploads, [...atom, data]);
    }

    private setUploadDataReady(id: string) {

        const atom = store.get(backGroundUploads);
        store.set(backGroundUploads, atom.filter(d => d.id !== id));
    }

    private setUploadDataError(id: string, err: any) {

        const atom = store.get(backGroundUploads);
        const newAtom = produce(atom, a => {
            const data = a.find(d => d.id === id);
            if (data) {
                data.errorMess = err;
                data.killer = () => this.setUploadDataReady(id);
            }
        });

        store.set(backGroundUploads, newAtom);

    }

    private setUploadDatapercent(id: string, percentData: any) {

        const percent = 100 * percentData.loaded / percentData.total;
        const atom = store.get(backGroundUploads);
        const newAtom = produce(atom, a => {
            const data = a.find(d => d.id === id);
            if (data) {
                data.percentUploaded = percent;
            }
        });

        store.set(backGroundUploads, newAtom);

    }

    public uploadFile(backGroundMessage: string | React.ReactNode | undefined, userId: string, file: File): FilePickedResponse {

        if (backGroundMessage) {


            const promise = new Promise<CompleteUpload>((resolve, reject) => {

                const uploadResponse = S3Uploader.startUpload(userId, file,
                    (p: any) => this.setUploadDatapercent.bind(this)(uploadData.id, p));

                uploadResponse.promise.then(readyFile => {
                    this.setUploadDataReady.bind(this)(uploadData.id);
                    let transformedFileData: any = this.makeUploadReadyResponse(readyFile, file);
                    resolve(transformedFileData);
                })
                    .catch(e => {
                        this.setUploadDataError.bind(this)(uploadData.id, e);
                        reject(e);
                    });

                const uploadData: FileUploadBackgroundData = {
                    id: uuidv4(),
                    backGroundMessage: backGroundMessage,
                    percentUploaded: 0,
                    killer: () => {
                        uploadResponse.abort();

                    }
                }

                this.setUploadData(uploadData);

            });

            return {
                uploadQueued: true,
                promise: promise

            };
        }
        else {
            this.setUploading(true);
            const uploadResponse = S3Uploader.startUpload(userId, file,
                this.standardProgressHandler.bind(this)
            );
            return {
                promise: new Promise<CompleteUpload>((resolve, reject) => {

                    const readyHandler = (readyFile: CompleteMultipartUploadCommandOutput) => {

                        let transformedFileData: any = this.makeUploadReadyResponse(readyFile, file);
                        this.setFile(transformedFileData);
                        resolve(transformedFileData);
                    }


                    uploadResponse.promise.then(fi => readyHandler(fi))
                        .catch(e => reject(e))
                        .finally(() => {
                            this.setUploading(false);
                        });

                }),
                uploadQueued: false,
                killer: uploadResponse.abort

            }
        }

    }

    private standardProgressHandler(d: any) {
        const percent = 100 * d.loaded / d.total;
        this.setPercentage(percent);
    }

    private makeUploadReadyResponse(readyFile: any, file: File): CompleteUpload {
        return { ...readyFile, key: readyFile.Key, name: file.name, type: file.type, size: file.size, lastModifiedDate: new Date(file.lastModified) };

    }


}


const fileUploadservice = new FileUploadservice();

export default fileUploadservice;
