import { useEffect, useMemo, useRef } from "react";
import classes from './tests.module.scss';
import TestLink from "./TestLink";
import { useI18n } from '../../utils/lni18n';
import { useCallback, useState } from "react";
import { onePartatom } from "../CoursePartService";

import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MeasuringStrategy,
    PointerSensor,
    defaultDropAnimationSideEffects,
    useDroppable,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import type { Active, DragEndEvent, DragOverEvent, DropAnimation, Over, UniqueIdentifier } from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { arrayMoveMutate } from "../../utils/ArrayMethods";
import { createPortal } from "react-dom";
import { TestLinkDTO } from "../domain/TestLinkDto";
import { LnCollapseGroup } from "../../components/LnCard/LnCollapseGroup";
import TestLinkBody from './TestLinkBody';
import { Outlet, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import AddTestDialog from "./AddTestDialog";
import testService from "./TestService";
import { AddTestCommand } from "../domain/AddTestCommand";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import ReadOnlyTests from "../ReadOnly/Test/ReadOnlyTests";

const Tests = () => {

    const { partId, testId } = useParams();
    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);

    const part = partData.Data;
    const { languageService: t } = useI18n();
    const [active, setActive] = useState<Active | null>(null);
    const dialogPortal = useDialog();


    const [connectedTests, setConnectedTests] = useState<TestLinkDTO[] | undefined>(undefined);
    useEffect(() => {
        setConnectedTests(part?.Tests.filter(t => !!t.ConnectedLecture).map(t => {
            return { ...t }
        }));
    }, [part!.Tests]);

    const [nonConnectedTests, setNonConnectedTests] = useState<TestLinkDTO[] | undefined>(undefined);

    useEffect(() => {
        setNonConnectedTests(part?.Tests.filter(t => !t.ConnectedLecture).map(t => {
            return { ...t }
        }));
    }, [part!.Tests]);




    const getTestById = useCallback((id: UniqueIdentifier) => {
        return part?.Tests.find(t => t.Id === id);
    }, [part]);

    const dropAnimation: DropAnimation = {
        sideEffects: defaultDropAnimationSideEffects({
            styles: {
                active: {
                    opacity: '0.5',
                },
            },
        }),
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const addTest = () => {

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                return <AddTestDialog onSubmit={onSubmit} onCancel={onCancel} />
            },
            size: "xl"
        }).then((data: { name: string, type: number, lastInList: boolean }) => {
            const cmd: AddTestCommand = {
                Name: data.name,
                Type: data.type,
                PartId: partId!,
                LastInList: data.lastInList
            }
            testService.addTest(cmd);
        })
            .catch(() => {

            });
    }

    const deleteTest = (testId: string) => {

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("delete"),
                    message: <div className="preserve-white" >{t.getText("test.delete.test.warning")}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then((res: boolean) => {
            if (res) {
                testService.deleteTest({ partId: part!.Id, testId: testId });
            }
        });
    }


    if (!part) {
        return null;
    }


    const dragOver = useCallback((e: DragOverEvent) => {
        if (!e.over) {
            return;
        }
        if (e.active.data.current?.sortable.index === e.over?.data.current?.sortable.index) {
            return;
        }

        if (nonConnectedTests) {
            setNonConnectedTests(ts => {
                if (ts) {
                    const newList = [...ts];
                    arrayMoveMutate(newList, e.active.data.current?.sortable.index, e.over?.data.current?.sortable.index);
                    return newList;
                }
                return ts;
            })

        }

    }, [nonConnectedTests, setNonConnectedTests])

    const dragEnd = useCallback((dropResult: DragEndEvent) => {
        setActive(null);
        testService.setTestsOrder(part.Id, nonConnectedTests || []);
    }, [nonConnectedTests])


    if (part && part.Locked) return <ReadOnlyTests />



    return <>

        <Outlet ></Outlet>

        <div >
            <div className="d-flex mb-5 ">

                <h3 className="flex-fill">{t.getText("tests")}</h3>
                <button onClick={addTest} className="btn btn-primary float-right">{t.getText("add.test")}</button>
            </div>

            <div className="max-col-width">

                <DndContext
                    sensors={sensors}

                    onDragStart={({ active }) => {
                        setActive(active);
                    }}
                    onDragOver={dragOver}
                    onDragEnd={dragEnd}
                    onDragCancel={() => {
                        setActive(null);
                    }}
                >




                    {connectedTests && connectedTests.length > 0 &&
                        <div className="mb-4">
                            <LnCollapseGroup groupName={t.getText("tests.for.study.in.lecture")} isOpen={false} >
                                {connectedTests.map((test) => (
                                    <div key={test.Id} className={[classes['test-listing'], 'pl-3 py-3 my-3'].join(' ')} >
                                        <TestLinkBody readOnly={!!part.Locked} test={test} />
                                    </div>))}
                            </LnCollapseGroup>
                        </div>
                    }


                    <div className="mt-3">

                        {nonConnectedTests &&
                            <SortableContext items={nonConnectedTests.map(t => t.Id)} id="all_tests">
                                <div className="flex-column">
                                    {nonConnectedTests.map(item =>
                                        <TestLink readOnly={!!part.Locked} deleteHandler={deleteTest} active={active?.id} test={item} key={item.Id} />
                                    )}
                                </div>
                            </SortableContext>

                        }
                    </div>

                    {createPortal(
                        <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>

                            {active
                                ? <TestLink readOnly={!!part.Locked} deleteHandler={(() => { })} dragged={true} test={getTestById(active.id)!} />
                                : null}
                        </DragOverlay>,
                        document.body
                    )}

                </DndContext>
            </div>
        </div>




    </>

}

export default Tests;
