import { atom, getDefaultStore } from "jotai";
import { DrawingTestColor, DrawingTestDataDTO, SplinePoint } from "../DomainModels";
import { produce } from "immer";
import { DecimalToHex } from "src/utils/ColorHelpers";
import { imageEndPoints } from "src/components/ImagePicker/ImagePicker";
import languageService from "src/utils/languageService";

export const POINTRADIUS = 7;
export const LINEWIDTH = 2;
export const SPLINETENSION = 0.4;

export const hexFromColorData = (colorData: DrawingTestColor) => {
  return "#" + DecimalToHex(colorData.R, "00") + DecimalToHex(colorData.G, "00") + DecimalToHex(colorData.B, "00");
}

const colorDataFromHex = (hex: string) => {
  if (hex.length === 7) {
    return {
      R: parseInt(hex.substring(1, 3), 16),
      G: parseInt(hex.substring(3, 5), 16),
      B: parseInt(hex.substring(5, 7), 16),
      A: 0
    } as DrawingTestColor;
  }
  throw new Error(`Cannot parse the string "${hex}"`)

}

export type DrawingState = {
  AuthColor: string;
  StudColor: string;
  CurrentColor: string;
  Points: SplinePoint[],
  Selected?: SplinePoint;
  Closed: boolean;
  DrawHandles: boolean;
}

const store = getDefaultStore();

export class DrawingTestService {


  public currentQuestionIndex?: number;

  public dataAtom;

  constructor(data: DrawingTestDataDTO) {
    if (data.ServerImageID) {
      data.ServerImageUrl = imageEndPoints.GET_IMAGE_URL(data.ServerImageID);
    }
    this.dataAtom = atom<DrawingTestDataDTO>(data);
  }

  private MutateData(method: (state: DrawingTestDataDTO) => void) {
    const state = store.get(this.dataAtom);
    const newstate = produce(state, (draft) => {
      method(draft);
    })
    store.set(this.dataAtom, newstate);
    return newstate;
  }


  updateQuestionText(value: string) {
    return this.MutateData(state => {
      if (value === "") {
        state.QuestionError = languageService.getText('required_field');
      }
      else{
        state.QuestionError = undefined;
      }
      state.Question = value;
    });
  };

  public setCorrectPercent(value: number) {
    return this.MutateData(state => {
      state.RequiredCorrectness = value;
    });
  }


  public setExplainRquired(value: boolean) {
    return this.MutateData(state => {
      state.RequireUserComment = value;
    });
  }

  public setImageId(value: string) {
    return this.MutateData(state => {
      state.ServerImageID = value;
      state.ServerImageUrl = imageEndPoints.GET_IMAGE_URL(value);
    });
  }


  UpdateDrawingData(value: DrawingState) {
    return this.MutateData(state => {
      state.DrawingtestAdminData.AuthorColor = colorDataFromHex(value.AuthColor);
      state.DrawingtestAdminData.StudentColor = colorDataFromHex(value.StudColor);
      state.DrawingtestAdminData.SplinePoints = value.Points;
    });
  }




}
