
import { useI18n } from '../utils/lni18n';
import coursePartService, { onePartatom } from './CoursePartService';
import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';

export default function CoursePartLoadError() {

  const { partId} = useParams();
  const [partData] = useAtom(
    useMemo(() => onePartatom(partId || ""), [partId]));

  const { languageService: t } = useI18n();

  function forceOpen(): void {
    coursePartService.loadPart( {partId: partId!, forceReload:true, forceUnlock: true, openFromCourse: partData.CanBeForcedToOpen} );
  }

  return <>

    <div className={"scrollContent scroller "} aria-live="polite">
      <div className="card-box big-card-box">
        <div className="card-box-title card-title ">
          <h4>
          { partData.Error!.header || t.getText("error")}
          </h4>
        </div>
        <div className="card-box-content card-body max-col-width pb-5">
          <div className="mb-3" dangerouslySetInnerHTML={{__html: partData.Error!.message}}></div>
          { partData.CanBeForcedToOpen && <button onClick={forceOpen} className='mt-5 btn btn-primary' >
          {t.getText("yes")} &nbsp;
            {t.getText("open")}
            </button> }
        </div>
      </div>
    </div>
  </>



}