
import { FormatTimeDurationFromSeconds } from "../../utils/DateTimeFormat";
import { useI18n } from "../../utils/lni18n";
import classes from 'src/coursepart/Lecture/lecture.module.scss';
import LnIcon from '../../components/LnIcon';
import { EditScreenDto, ScreenType } from "src/coursepart/Lecture/domain/LectureState";
import scrapBookService from "./ScrapBookService";



const ScrapScreen = (props: { disabled: boolean, select: (id: string) => void, screen: EditScreenDto }) => {

    const { screen, select, disabled } = props;
    const { languageService: t } = useI18n();

    const removeFromScrap = () => {
        scrapBookService.removeScreen(screen.ScreenId);
    }

    const handleClick =()=>{
        if( !disabled){
            select(screen.ScreenId);
        }
    }

    const classNames = [
        (screen.Selected ? classes.selectedScreen : ""),
        classes.screenItem, 'pointer '].join(' ');

    return (
        <div onClick={handleClick} key={screen.ScreenId} role="listitem" className={classNames}>

            <div className={classes.screenBody} >
                {disabled && <div className="cover50"></div>}
                <div className={classes['card-title']}>
                    <div className={classes.nameDiv}>
                        <div className="m-2 badge badge-grey">&nbsp;</div>
                        <div className={classes.dragHandle}>
                            <button type="button" className="btn btn-link" onClick={removeFromScrap}>
                                <LnIcon name="delete" className="icon-small m-2" />
                            </button>
                        </div>
                    </div>

                </div>
                <div className={`${classes['img-wrapper']}`}>
                    <img className={classes['lecture-img']} src={screen.ThumbnailUrl} alt={screen.ScreenId} />
                </div>

                <div className={classes.footer}>

                    {screen.ScreenType === ScreenType.Video && <>
                        <span>
                            <LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="video" />
                        </span>
                        {screen.Duration > 0 &&
                            <span className="ml-auto"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDurationFromSeconds(screen.Duration, t)}</span>}
                    </>
                    }

                    {screen.ScreenType === ScreenType.ImageSound &&
                        <>
                            <span>
                                <LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="volume-black" />
                            </span>
                            {screen.Duration > 0 &&
                                <span className="ml-auto"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDurationFromSeconds(screen.Duration, t)}</span>}
                            {!!!screen.Duration && <span className="ml-auto">{t.getText("no.audio")}</span>}
                        </>
                    }
                    &nbsp;




                </div>


            </div>


        </div>
    );

}


export default ScrapScreen;