import { EditScreenDto } from "src/coursepart/Lecture/domain/LectureState";
import { ScrapBookDb } from "./scrapBookDd";
import { ScrapLecture, ScrapScreen } from "./domain";
import api from "src/services/api/axiosService";
import { atom, getDefaultStore } from "jotai";
import { produce } from "immer";
import { LectureDtoWithOriginals } from "src/coursepart/domain/LectureDtoWithOriginals";


const endPoints = {

    GET_SCREENS: `/author/coursepart/screen/list`,
    GET_LECTURES: `/author/coursepart/lecture/list`,
}

const store = getDefaultStore();

const screensPrivate = atom<EditScreenDto[]>([]);
screensPrivate.debugLabel = "screensPrivate";
export const scrapScreensAtom = atom<EditScreenDto[]>((get) => get(screensPrivate));
scrapScreensAtom.debugLabel = "scrapScreensAtom";

const lecturesPrivate = atom<LectureDtoWithOriginals[]>([]);
lecturesPrivate.debugLabel = "lecturesPrivate";

export const scrapLecturesAtom = atom<LectureDtoWithOriginals[]>((get) => get(lecturesPrivate));
scrapLecturesAtom.debugLabel = "scrapLecturesAtom";

export class ScrapBookService {

    private db: ScrapBookDb;

    constructor() {
        this.db = new ScrapBookDb("scrapbook");
    }

/* SCREENS */
    
    public async addScreen(screen: EditScreenDto, partId: string) {

        const dataAlready = await this.db.screens.get({ screenId: screen.ScreenId });
        // screen already in scrap => do nothing
        if (dataAlready !== undefined) return;

        const sc: ScrapScreen = {
            lectureId: screen.LectureId,
            partId: partId,
            screenId: screen.ScreenId
        }

        this.db.screens.add(sc);

        const screens = store.get(screensPrivate);
        store.set(screensPrivate, [...screens, { ...screen, Selected: false }])
    }

    public async selectScreen(screenId: string) {
        const screens = store.get(screensPrivate);
        const newData = produce(screens, (draft) => {
            draft.forEach(s => s.Selected = s.ScreenId === screenId);
        });
        store.set(screensPrivate, newData)
    }

    public async removeScreen(screenId: string) {

        const dbData = await this.db.screens.get({ screenId: screenId });
        if( dbData && dbData.id){
            await this.db.screens.delete( dbData.id! )
        }

        const screens = store.get(screensPrivate);
        store.set(screensPrivate, screens.filter(s => s.ScreenId !== screenId))
    }

    public async clearscreens() {

        await this.db.screens.clear()
        store.set(screensPrivate, [])
    }

    public async loadScreens() {

        const screenIds = (await this.db.screens.toArray()).map(sc => sc.screenId);
        const response = await api.post<EditScreenDto[]>(endPoints.GET_SCREENS, screenIds);

        if (response && response.status === 200) {
            store.set(screensPrivate, response.data);
        }

        return true;

    }

/* LECTURES */

    public async addLecture(lecture: LectureDtoWithOriginals) {

        const dataAlready = await this.db.lectures.get({ lectureId: lecture.Id });
        // lecture already in scrap => do nothing
        if (dataAlready !== undefined) return;


        const lect: ScrapLecture = {
            lectureId: lecture.Id,
            partId: lecture.PartId,
        }
        this.db.lectures.add(lect);

        const lectures = store.get(lecturesPrivate);
        store.set(lecturesPrivate, [...lectures, { ...lecture, Selected: false }])

    }

    public async selectLecture(lectureId: string) {
        const lecture = store.get(lecturesPrivate);
        const newData = produce(lecture, (draft) => {
            draft.forEach(l => l.Selected = l.Id === lectureId);
        });
        store.set(lecturesPrivate, newData)
    }

    public async removeLecture(lectureId: string) {

        const dbData = await this.db.lectures.get({ lectureId: lectureId });
        if( dbData && dbData.id){
            await this.db.lectures.delete( dbData.id! )
        }
        const lectures = store.get(lecturesPrivate);
        store.set(lecturesPrivate, lectures.filter(l => l.Id !== lectureId))
    }

    public async clearLectures() {

        await this.db.lectures.clear()
        store.set(lecturesPrivate, [])
    }

    public async loadLectures() {
        const lectureIds = (await this.db.lectures.toArray()).map(sc => sc.lectureId);
        const response = await api.post<LectureDtoWithOriginals[]>(endPoints.GET_LECTURES, lectureIds);
        if (response && response.status === 200) {
            store.set(lecturesPrivate, response.data);
        }

        return true;
    }

}

const scrapBookService = new ScrapBookService();

export default scrapBookService;