
import { ChangeEvent, useEffect } from 'react';
import { useI18n } from '../utils/lni18n';
import coursePartService, { onePartatom } from './CoursePartService';
import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { Outlet, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import WaitRipple from 'src/components/WaitRipple';
import screenEncodingService from './Lecture/Screen/ScreenEncodingService';
import classes from "./CoursePart.module.scss";
import LnIcon from 'src/components/LnIcon';
import CoursePartLoadError from './CoursePartLoadError';

export default function CoursePartComponent() {

  const { partId} = useParams();

  useEffect(() => {
    if (partId) {
      screenEncodingService.clearScreens(partId);
    }
  }, [partId]);


  const [partData] = useAtom(
    useMemo(() => onePartatom(partId || ""), [partId]));

  const { languageService: t } = useI18n();

  
  if (!partData || partData.Loading) {
    return <WaitRipple />
  }

  if (partData.Error) {
    return <CoursePartLoadError />
  }



  const part = partData.Data!;

  const setActive = ({ isActive, isPending }: { isActive: boolean, isPending: boolean }) => isActive ? "active" : "";

  return <>

    <div className={"scrollContent scroller "} aria-live="polite">
      <div className="card-box big-card-box">
        <div className="card-box-title card-title ">
          <div className='max-col-width w-100'>
            <h3>{part?.Name}</h3>
          </div>
        </div>
        <div className="card-box-content card-body">
          <div className="mb-5 d-print-none">
            <div className="custom-tab-heading-list" >

              <NavLink className={setActive} to="texts" >{t.getText("introtext")}</NavLink>
              <NavLink className={setActive} to="lectures" >{t.getText("lectures")}</NavLink>
              <NavLink className={setActive} to="files" >{t.getText("files")}</NavLink>
              <NavLink className={setActive} to="links" >{t.getText("links")}</NavLink>
              <NavLink className={setActive} to="tests" >{t.getText("tests")}</NavLink>
              <NavLink className={setActive} to="evaluations" >{t.getText("evaluations")}</NavLink>

              <div className={classes.spacer}>&nbsp;</div>
              
              <NavLink className={setActive} to="general" >{t.getText("general")}</NavLink>
              <NavLink className={setActive} to="print" >{t.getText("print")}</NavLink>
              <NavLink className={setActive} to="publish" >
                {t.getText("publish")}
                {partData?.Data?.Dirty && <LnIcon name="alert" className='icon-small ml-2' />}
              </NavLink>

              
            </div>
          </div>
          <main className="">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  </>



}