export enum ObjectTypes {
    lecture = -1,
    units,
    statictext,
    admintypes,
    permissiontypes,
    testtypes,
    users,
    course,
    theme,
    coursepart,
    groups,
    classes,
    subjectarea,
    proffareas,
    evaluations,
    roomtype,
    classrooms,
    identity,
    clienterrors,
    visitors,
    itemlocks,
    exam,
    webpageobjects,
    usertags,
    systemlanguages,
    survey,
    SurveyForCourse,
    SurveyForStudentClass,
    ImageFiles,
    Feeds,
    Notification,
    File,
    FileFolder
}