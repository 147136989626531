import * as React from "react";
import { recordingAtom } from "./Recoderservice";
import { useAtom } from "jotai";
import { VerticalProgress } from "src/components/VerticalProgress/VerticalProgress";
import classes from "../lecture.module.scss"
import { useI18n } from 'src/utils/lni18n';

export const RecorderProgress: React.FC = () => {

    const [recordingData] = useAtom(recordingAtom);
    const { languageService: t } = useI18n();



    return <div className={`${classes.lenProgress} d-flex flex-column align-items-center`}>

        <small className="mb-2">
            {t.gethhmmss(recordingData.maxLenInSec, true)}
        </small>

        <VerticalProgress className="w-100"
            color="primary"
            value={recordingData.recordingLength}
            max={100} min={0} ></VerticalProgress>

        <small className="mt-2">
            {t.gethhmmss(recordingData.recordingLength, true)}
        </small>
    </div>

};
