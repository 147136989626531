import { useState, useCallback } from "react";
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { useI18n } from "../utils/lni18n";
import UserImg from "./UserImg";
import { UserDto, UserMailDto} from "../services/user/types";
import { userEndPoints } from "../services/user/userService";


const UserAsyncFind = (props: { className?: string, userSearch: (query:string)=> 
    Promise<UserMailDto[]>, onUserSelected: (user: UserMailDto)=>void , focus?:boolean }) => {

    const { userSearch, onUserSelected, className, focus } = props;

    const [userSearchIsLoading, setUserSearchIsLoading] = useState(false);
    const [userSelected, setUserSelected] = useState([]);
    const [userOptions, setUserOptions] = useState<UserDto[]>([]);
    const { languageService: t } = useI18n();

    const handleUserFilterBy = () => true;

    const handleUserChange = (e:any) => {
        setUserSelected(e);
        onUserSelected(e[0]);
    }

    const handleUserSearch = useCallback(async (query:string) => {

        setUserSearchIsLoading(true);

        const options = await userSearch(query);

        setUserOptions(options);
        setUserSearchIsLoading(false);

    }, [userSearch]);

    

    const renderUserMenuItemChildren = (option:any, props:any, index:any) => {
        const userImage = userEndPoints.GET_USER_IMAGE_URL(option.Id);
        return <div key={option.Id} className="d-flex">
                <UserImg src={userImage} className={['rounded-circle', 'avatar-img', 'mr-4'].join(' ')} alt={t.getText('user')} />
                <div className="d-flex flex-column">
                    <span>
                        <Highlighter search={props.text}>
                            {option.FullName}
                        </Highlighter>
                    </span>
                    <small>
                        <Highlighter search={props.text}>
                            {option.Mail}
                        </Highlighter>
                    </small>
                </div>
            </div>
        ;
    }

    return <AsyncTypeahead
        className={className}
        highlightOnlyResult={true}
        filterBy={handleUserFilterBy}
        id="user-select"
        isLoading={userSearchIsLoading}
        labelKey={(option:any) => `${option.FullName} - ${option.Mail}`}
        minLength={3}
        multiple={false}
        onChange={handleUserChange}
        onSearch={handleUserSearch}
        options={userOptions}
        placeholder={t.getText('user')}
        renderMenuItemChildren={renderUserMenuItemChildren}
        selected={userSelected}
        autoFocus={focus}

    />
}

export default UserAsyncFind;
