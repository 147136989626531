import { useI18n } from "src/utils/lni18n";
import { CoursePartPrintDTO } from "./domain";

const PrintPartTexts = (props: { printData: CoursePartPrintDTO }) => {

  const { printData } = props;
  const { languageService: t } = useI18n();

  if (!printData) return null;

  return (
    <>
      {printData.Intro && <>

        <h3 className="mt-5 mb-3 border-bottom">{t.getText("texts")}</h3>
        <h4>{t.getText("introtext")}</h4>
        <div dangerouslySetInnerHTML={{ __html: printData.Intro }}></div>

        {printData.GoalContents && <>
          <h4>{t.getText("goal_contents")}</h4>
          <div dangerouslySetInnerHTML={{ __html: printData.GoalContents }}></div>
        </>}

        {printData.Litterature && <>
          <h4>{t.getText("litterature")}</h4>
          <div dangerouslySetInnerHTML={{ __html: printData.Litterature }}></div>
        </>}
      </>
      }
    </>
  )
}

export default PrintPartTexts;
