//@ts-check
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ImagePicker, { PickedImageData } from "./ImagePicker";
import { useI18n } from '../../utils/lni18n';


const ImagePickerDialog: React.FC<{ onSubmit: (d: PickedImageData) => void, onCancel: () => void }> = ({ onSubmit, onCancel }) => {

    const { languageService: t } = useI18n();

    const toggle = () => {
        onCancel();
    }

    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader className={""} toggle={toggle}>{t.getText("image")}</ModalHeader>
        </span>

        <ModalBody className={""} tabIndex={-1}>
            <ImagePicker onCancel={onCancel} onSubmit={onSubmit} />
        </ModalBody>
        <ModalFooter>

            <Button className="btn btn-inverse" onClick={toggle}>{t.getText("close")}</Button>
        </ModalFooter>

    </>


}

export default ImagePickerDialog;