import * as React from "react";
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LanguageService } from "../../utils/languageService";

export interface RunningPublishDialogOptions {
    languageService: LanguageService;
}

export const RunningPublishDialog: React.FC<RunningPublishDialogOptions> = ({
    languageService,
}) => {

    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{languageService.getText("publish")}</ModalHeader>
        </span>
        <ModalBody>
            {languageService.getText("publishing")}
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
    </>
};
