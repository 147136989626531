import { useState } from "react";
import { Input } from "reactstrap";
import { useI18n } from "../../utils/lni18n";
import { ImagePickerService, imageAtom } from "./ImagePickerService";
import { useAtom } from "jotai";
import LnIcon from "../LnIcon";
import UserAsyncFind from "../UserAsyncFind";

const ImagePermissions = (props: { imagePickerService: ImagePickerService }) => {

    const imagePickerService = props.imagePickerService;
    const { languageService: t } = useI18n();
    const [state] = useAtom(imageAtom);
    const { selectedImage, currentImage } = state;

    const [userSelected, setUserSelected] = useState<any>(null);


    const setPermission = (e: any, type: any) => {
        imagePickerService.setPermission(type, e.target.checked);
    }

    const userSearch = async (query: string) => {
        if (selectedImage) {
            const users = await imagePickerService.getUsersForPermissions(query, selectedImage.ImageId);
            return users;
        }

        return [];
    };
    const handlePermissionUserChange = (e: any) => {
        setUserSelected(e);
    }

    const onAddUserPermission = () => {
        imagePickerService.addUserPermission(userSelected);
    }

    const removePermission = (uid: string) => {
        imagePickerService.removeUserPermission(uid);
    }

    if (!currentImage) {
        return null;
    }

    return <>
        <div className="d-flex flex-column">
            <label>
                <input type="checkbox" className="mb-2" id="unitroots"
                    onChange={(e) => { setPermission(e, 'unitroots') }}
                    checked={currentImage.Permissions.UnitRootsCanRead} />
                &nbsp;{t.getText('imagepicker.permission.unitroots.allowed')}
            </label>
            <label>
                <input type="checkbox" className="mb-2" id="unitadmins"
                    onChange={(e) => { setPermission(e, 'unitadmins') }}
                    checked={currentImage.Permissions.UnitAdminsCanRead} />
                &nbsp;{t.getText('imagepicker.permission.unitadmins.allowed')}
            </label>

            <label>
                <input type="checkbox" className="mb-2" id="teachers"
                    onChange={(e) => { setPermission(e, 'teachers') }}
                    checked={currentImage.Permissions.TeachersCanRead} />
                &nbsp;{t.getText('imagepicker.permission.teachers.allowed')}
            </label>


        </div>
        <div className="mt-4">
            <div className="form-group mb-5">
                <div className="form-group mb-5">
                    <h4 className="mb-2">{t.getText('add')}</h4>
                    <div className="d-flex align-items-center">
                        <UserAsyncFind className="w-75" onUserSelected={handlePermissionUserChange} userSearch={userSearch} />
                        <button className="ml-4 btn btn-small btn-primary" onClick={() => onAddUserPermission()} >{t.getText("add")}</button>
                    </div>
                </div>
            </div>
            <div>
                {currentImage.Permissions.Users.map(u => {
                    return <div key={u.Id} className="mb-3 d-flex align-items-center">
                        <button onClick={() => removePermission(u.Id)} className="btn btn-link" ><LnIcon name="delete" className="icon-small" /></button>
                        <div>{u.FullName}</div>
                        <small>({u.Mail})</small>
                    </div>
                })}
            </div>
        </div>
    </>
}

export default ImagePermissions;
