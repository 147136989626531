import { atom, getDefaultStore } from "jotai";
import { ImportXLFileCommand, MCDataQuestion, MCDataQuestionWithIndex, MCTestDataDto } from "../DomainModels";
import { produce } from "immer";
import { OpticFor_ } from "optics-ts";
import { focusAtom } from "jotai-optics";
import languageService from "src/utils/languageService";
import api from "src/services/api/axiosService";

const endPoints = {
  POST_IMPORT_XL: (partId: string, testId: string) => `author/coursepart/${partId}/test/${testId}/ImportSpreadsheet`,

}

export type McEditData = {
  testData: MCTestDataDto;
  currentQuestionIndex?: number
}
const store = getDefaultStore();

export class MCTestService {

  public currentQuestionIndex?: number;

  public datatom;
  public currentQuestionAtom;
  public currentQuestionInitialAtom;

  constructor(data: MCTestDataDto) {
    
    this.datatom = atom<MCTestDataDto>(data);
    this.datatom.debugLabel = "McTestdatatom";
    this.currentQuestionAtom = (index: number) => {
      const atom = focusAtom(
        this.datatom,
        (optic: OpticFor_<MCTestDataDto>) => optic.prop("Questions").at(index));
      atom.debugLabel = "question: " + index;
      return atom;
    };
    this.currentQuestionInitialAtom = atom<MCDataQuestionWithIndex | undefined>(undefined);
  }

  private MutateMCState(method: (state: MCTestDataDto) => void) {
    const state = store.get(this.datatom);
    const newstate = produce(state, (draft) => {
      method(draft);
    })
    store.set(this.datatom, newstate);
    return newstate;
  }

  public setPercentage(value: number) {
    return this.MutateMCState(draft => {
      draft.Percentage = value;
    });

  }

  public handleChange = (data: MCDataQuestion) => {
    return this.MutateMCState(draft => {
      draft.Questions.splice(this.currentQuestionIndex!, 1, data);
    });
  };

  public setCurrentQuestion(index: number) {
    const data = store.get(this.datatom);
    if (index >= data.Questions.length) {
      throw new Error("index out of bound")
    }
    const initialData = { ...data.Questions[index], McAnswers: [...data.Questions[index].McAnswers], index: index };
    this.currentQuestionIndex = index;
    store.set(this.currentQuestionInitialAtom, initialData);
  }

  public closeCurrentQuestion() {
    store.set(this.currentQuestionInitialAtom, undefined);
    this.currentQuestionIndex = undefined;

  }

  updateQuestionText(value: string) {
    return this.MutateMCState(state => {

      state.Questions[this.currentQuestionIndex!].Question = value;
      state.Questions[this.currentQuestionIndex!].QuestionError
        = value === "" ? languageService.getText("required_field") : undefined;
    });

  }

  addAnswer() {
    return this.MutateMCState(state => {
      state.Questions[this.currentQuestionIndex!].McAnswers.push({ Text: "", IsCorrect: false });
    });
  }

  removeAnswer(index: number) {
    return this.MutateMCState(state => {
      state.Questions[this.currentQuestionIndex!].McAnswers.splice(index, 1);
    });
  }

  updateAnswerText(value: string, index: number) {
    return this.MutateMCState(state => {
      state.Questions[this.currentQuestionIndex!].McAnswers[index].Text = value;
      state.Questions[this.currentQuestionIndex!].McAnswers[index].Error
        = value === "" ? languageService.getText("required_field") : undefined;
    });
  }

  updateAnswerIsCorrect(checked: boolean, index: number) {
    return this.MutateMCState(state => {
      state.Questions[this.currentQuestionIndex!].McAnswers[index].IsCorrect = checked;
    });
  }

  setCanSelectMultipleAnswers(value: boolean) {

    return this.MutateMCState(state => {
      state.Questions[this.currentQuestionIndex!].CanSelectMultipleAnswers = value;
      if (value === false) {
        state.Questions[this.currentQuestionIndex!].McAnswers.forEach((a, i) => a.IsCorrect = i === 0)
      }
    });

  }

  public addQuestion() {

    return this.MutateMCState(state => {
      const q: MCDataQuestion = {
        Question: languageService.getText("click.to.edit"),
        CanSelectMultipleAnswers: false,
        McAnswers: [{ Text: "", IsCorrect: true }, { Text: "", IsCorrect: false }]
      }
      state.Questions.push(q);

    });
  }

  public deleteQuestion(index: number) {
    return this.MutateMCState(state => {
      if (this.currentQuestionIndex === index) {
        this.closeCurrentQuestion();
      }
      state.Questions.splice(index, 1);

    });
  }

  public async importFromXL(data: ImportXLFileCommand) {
    const newDataResponse = await  api.post<MCTestDataDto>(endPoints.POST_IMPORT_XL(data.PartId, data.TestId), data);
    if( newDataResponse.status === 200 ){
      this.MutateMCState(draft => draft.Questions = newDataResponse.data.Questions);
    }
  }



}
