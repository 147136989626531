class UrlService {

  
    public urlToCourse(courseId: string, feature?: string) {
        feature = feature || "general";
        return `/course/${courseId}/${feature}`;
    }

    public urlToPart(partId: string, feature?: string) {
        feature = feature || "texts";
        return `/coursePart/${partId}/${feature}`;
    }

    public urlToTheme(courseId: string, themeId: string) {
        return `/course/${courseId}/content/${themeId}`;
    }

    public urlToPartInCourse(courseId: string, themeId: string, partId: string, feature?: string) {
        feature = feature || "texts";
        return `/course/${courseId}/content/${themeId}/${partId}/${feature}`;
    }

}

export const urlService = new UrlService();

