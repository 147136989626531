import { useI18n } from "src/utils/lni18n";
import HtmlTextEditor from 'src/components/TextEditor/HtmlTextEditor';
import {  ChangeEventHandler} from 'react';
import { MQData } from "./MultipleQuestionService";
import LnIcon from "src/components/LnIcon";
import classes from "./QAedit.module.scss"
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from "@dnd-kit/sortable";
import { DragHandle } from "src/components/DragHandle/DragHandle";
import { useLocation } from "react-router-dom";

const Question = (props: {
  name: string ,
  val: MQData, canDelete: boolean,
  onChange: ChangeEventHandler<HTMLTextAreaElement>,
  initialValue: string,
  dragged?: boolean,
  active: any,
  index: number,
  headerText: string,
  draggable: boolean
  deleteQuestion: (id: string) => void
}) => {

  const { draggable, headerText, active, val, onChange, canDelete, initialValue, deleteQuestion, name, dragged, index } = props;
  const { languageService: t } = useI18n();
  const { state } = useLocation();

  const {
    attributes,
    setNodeRef,
    listeners,
    transform,
    transition,
  } = useSortable({ id: val.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: dragged ? "75%" : ""
  };


  return <div ref={setNodeRef} style={style} className={`mb-3 ${ active == val.id, classes.active, "" }`} key={val.id} > 
    <div className='mb-2 font-weight-bold d-flex align-items-center'>
      {headerText} {val.error && <span className="ml-4 text-danger small font-weight-bold">{val.error}</span>}
      { canDelete && <button disabled={state.readOnly} className="btn btn-link ml-3" type="button" onClick={() => deleteQuestion(val.id)}>
        <LnIcon name="delete" className='icon-small' />
      </button>}
      { !state.readOnly && draggable && <div {...attributes} {...listeners}><DragHandle /></div>}
    </div>
    <HtmlTextEditor readOnly={state.readOnly} className={val.error && classes.danger} value={val.html} onChange={onChange} initialValue={initialValue} name={name} />
    <div className="mt-2 text-grey">
      {t.getText("students.answer")} ...
    </div>
  </div>
}

export default Question;

