import * as React from "react";
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LanguageService } from "../../utils/languageService";
import { EvalQuestionType } from "../domain/EvaluationEditDataDTO";
import { IdAndNameDto } from "src/types";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import coursePartService from "../CoursePartService";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import WaitRipple from "src/components/WaitRipple";
import { Option } from "react-bootstrap-typeahead/types/types";

export type NewEvalQuestionRequest = {
    type?: EvalQuestionType;
    partToCopyFrom?: string;
}


export const AddQuestionDialog: React.FC<{ partId: string, languageService: LanguageService, onSubmit: (data: NewEvalQuestionRequest) => void, onCancel: () => void }> = (
    { languageService, onSubmit, onCancel, partId }
) => {
    const t = languageService;

    const [parts, setParts] = React.useState<IdAndNameDto[]>([]);
    const [selectedPart, setSelectedPart] = React.useState<string | undefined>();
    const [isLoading, setIsLoading] = React.useState(false);

    const validationSchema = React.useMemo(() => Yup.object({
        type: Yup.string().required(t.getText('required_field')),
    }), [t]);

    const { handleSubmit, register, watch, formState: { errors, isValid, isDirty } } = useForm({
        defaultValues: { type: null },
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    const handleSubmitForm = (values: any) => {
        onSubmit(values);
    }


    const filterBy = () => true;


    const handleSearch = async (query: string) => {
        setIsLoading(true);

        try {
            const result = await coursePartService.FindPartsWithCustomEvals(partId, query)
            setParts(result);
        }
        finally {
            setIsLoading(false);
        }
    };

    const partPicked = (opt: Array<Option>) => {

        const selected = opt as IdAndNameDto[];
        if (selected && selected.length > 0) {
            setSelectedPart(selected[0].Id);
        }
        else {
            setSelectedPart(undefined);
        }
    }

    const copyfromPart = () => {
        if (selectedPart) {
            onSubmit({ partToCopyFrom: selectedPart });
        }
    }

    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("add")}</ModalHeader>
        </span>
        <ModalBody>

            <h5 className="mb-3">{t.getText("create_new")}</h5>
            <div className="mb-2">{t.getText("pic_a_type")}</div>
            <form onSubmit={handleSubmit(handleSubmitForm)} className="d-flex mb-5">
                <div className="flex-fill">
                    <label>
                        <input {...register("type")} required type="radio" value={EvalQuestionType.FreeText} /> {t.getText("openquestion")}
                    </label>
                    <br />
                    <label>
                        <input {...register("type")} required type="radio" value={EvalQuestionType.MultipleChoice} /> {t.getText("multiplechoice.question")}
                    </label>

                    <ErrorMessage as="div" errors={errors} name="type" className="ml-3 mt-2" />
                </div>
                <div>
                    <button type="submit" className="btn btn-primary" disabled={!isValid}>{t.getText("create_new")}</button>
                </div>
            </form>

            <hr />

            <h5 className="mb-3">{t.getText("eval.copy.from.other")}</h5>
            <div className="d-flex align-items-center mb-5 position-relative">
                {isLoading && <WaitRipple />}
                <AsyncTypeahead className="flex-fill"
                    filterBy={filterBy}
                    id="getParts"
                    isLoading={isLoading}
                    labelKey="Name"
                    searchText={t.getText("searching")}
                    minLength={3}
                    onSearch={handleSearch}
                    options={parts}
                    onChange={partPicked}
                    placeholder={t.getText("subpart.open.search")}
                    renderMenuItemChildren={(o: Option) => {
                        const part = o as IdAndNameDto;
                        return <>
                            <span>{part.Name}</span>
                        </>
                    }
                    }
                />

                <button onClick={copyfromPart} type="button" className="ml-3 btn btn-primary" disabled={!selectedPart}>{t.getText("copy")}</button>
            </div>

        </ModalBody>
        <ModalFooter>
            <button type="button" className="btn btn-inverse" onClick={() => onCancel()}>{languageService.getText("cancel")}</button>&nbsp;
        </ModalFooter>
    </>
};
