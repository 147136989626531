import classes from "../../Link/links.module.scss";
import { LinkModel } from "src/coursepart/domain/LinksGroupModel";

export function ReadOnlyLink(props: { link: LinkModel }) {

  const { link } = props


  return <div id={"c_" + link.Id} key={link.Id}
        className={"row align-items-center py-2 mx-4 border-top "}>

        <div className="col-4">
          <div className={classes.linkInfo + " preserve-white"} dangerouslySetInnerHTML={{ __html: link.Description }}></div>
        </div>
        <div className="col-4">
          <a target="_blank" rel="noopener noreferrer" className="" href={link.Url}>
            {link.Url.split('://').pop()}
          </a>
        </div>
       

      </div>
   


}