import { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import screenEditService, { currentWaveDataAtom } from "../ScreenAudioEditService";
import classes from './waveedit.module.scss';
import WaveEditorControls from "./WaveEditorControls";
import { Progress } from "reactstrap";

const AudioEditor = (props: { children?: any }) => {

    const div1 = useRef<HTMLDivElement>(null);
    const div2 = useRef<HTMLDivElement>(null);
    const canvas1 = useRef<HTMLCanvasElement>(null);
    const canvas2 = useRef<HTMLCanvasElement>(null);

    const [currentWaveData] = useAtom(currentWaveDataAtom);
    const [numEditors, setNumEditors] = useState(0);

    const { loadProgressPercent, loading, loadingReady,screenItem } = currentWaveData;



    useEffect(() => {
        if (loadingReady && !loading) {
            if (screenEditService.waves.length > 0) {
                screenEditService.waves[0].connectToElement(div1.current!, canvas1.current!, "top");
                if (screenEditService.waves.length > 1) {
                    screenEditService.waves[1].connectToElement(div2.current!, canvas2.current!, "bottom");
                }
            }

            setNumEditors(screenEditService.waves.length);
        }
    }, [loadingReady, loading])

    return <div className={classes.waveEdit}>
        <div className={`${classes.topControl} d-flex`}>
            <img src={screenItem?.ThumbnailUrl} className="mr-4" />


        </div>

        <div className={[classes.editors, (numEditors < 2 ? classes.single : "")].join(" ")}>
            <div className={[classes.editor, (numEditors < 2 ? classes.single : "")].join(" ")} ref={div1}>
                <div className={`playerPosition ${classes.playerPosition}`}></div>
                <canvas ref={canvas1} className={classes.canvas}></canvas>
            </div>
            <div className={[classes.editor, (numEditors < 2 ? "off-screen" : "")].join(" ")} ref={div2}>
                <div className={`playerPosition ${classes.playerPosition}`}></div>
                <canvas ref={canvas2} className={classes.canvas}></canvas>
            </div>
        </div>

        <div className={`${classes.controls} d-flex flex-wrap`}>
            <div className="d-flex align-items-center m-3 w-100">
                {loading &&

                    <Progress color="success" min={0} max={100} value={loadProgressPercent} className="flex-fill" />
                }
                
            </div>

            <WaveEditorControls />
        </div>

    </div>

}


export default AudioEditor;