import classes from "../SideBar.module.scss";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { useI18n } from "src/utils/lni18n";

export default function PartCardDummy(props: { partId: string }) {

    const { languageService: t } = useI18n();
    const { partId } = props;
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: partId });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: ""
    };


    return <div style={style} ref={setNodeRef} id={partId} role="link" key={partId}
        className={`${classes.partDummy}`} >{t.getText("empty")}</div>


}