import { DropdownItem, DropdownMenu } from 'reactstrap';
import classes from './LanguageMenu.module.scss';
import LnDropDownMenu from "../LnDropDownMenu";
import languageService, { langAtom } from "../../utils/languageService";
import { useAtomValue } from "jotai";

const LanguageMenu = (props:any) => {

const {currentLang, languages} = useAtomValue(langAtom);

    const caretClass = [ classes["caret"] ,  props.caretClass || "white-caret"].join(" ");
    
    if( !currentLang){
        return null;
    }

    return <LnDropDownMenu
        caret={false}
        toggleComponent={
            <>
                <img
                    src={currentLang.flag}
                    className="mr-2"
                    alt={currentLang.DisplayName}
                />{" "}
                <span className={caretClass}></span>
            </>
        }
        toogleClassName={[classes["flag"], classes["toggle"], "btn btn-minimal pointer"].join(" ")}
        toogleTagName="div"
    >
        <DropdownMenu className={classes.dropDownMenu} end container="body">
            {languages.map(f => (
                <DropdownItem
                    key={f.Id}
                    onClick={() => languageService.changeLang(f.Id)}
                    className={classes.flagItem}
                    tag="div"
                >
                    <img src={f.flag} alt={f.DisplayName} />
                    &nbsp;{f.DisplayName}
                </DropdownItem>
            ))}
        </DropdownMenu>
    </LnDropDownMenu>
}

export default LanguageMenu;