import * as React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LanguageService } from "../../utils/languageService";

export enum ConfirmationButtons {
    OkCancel,
    YesNo
}

export interface ConfirmationOptions {
    title: string;
    message: React.ReactNode | string;
    className: string;
    languageService: LanguageService;
    show: boolean;
    onSubmit: (s: boolean) => void;
    onClose: () => void;
    buttons?: ConfirmationButtons
}

export const ConfirmationDialog: React.FC<ConfirmationOptions> = ({
    show,
    title,
    className,
    message,
    onSubmit,
    onClose,
    languageService,
    buttons
}) => {

    let okText = languageService.getText("ok");
    let cancelText = languageService.getText("cancel");
    if (buttons && buttons === ConfirmationButtons.YesNo) {
        okText = languageService.getText("yes");
        cancelText = languageService.getText("no");
    }

    const handleSubmit = () => {
        onSubmit(true);
    }

    const handleClose = () => {
        onSubmit(false);
    }

    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{title}</ModalHeader>
        </span>
        <ModalBody>
            {message}
        </ModalBody>
        <ModalFooter>

            <Button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>{okText}</Button>&nbsp;
            <Button type="button" className="btn btn-inverse" onClick={() => handleClose()}>{cancelText}</Button>
        </ModalFooter>
    </>
};
