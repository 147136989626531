import { NavLink } from "react-router-dom";
import { TestLinkDTO } from "../domain/TestLinkDto";
import { useI18n } from "src/utils/lni18n";
import LnIcon from "src/components/LnIcon";

const TestLinkBody = (props: { test: TestLinkDTO, readOnly: boolean }) => {

    const { test, readOnly } = props;
    const { languageService: t } = useI18n();

    return (
        <div>
            <NavLink state={{readOnly}} to={`${test.Id}/${test.TestType}`} >{test.Name}</NavLink>
            {!!test.ConnectedLecture && <>
                <div className="preserve-white">
                    {t.getText("test.connected.to.lecture", test.ConnectedLecture.Name)}
                </div>
                <NavLink state={{readOnly}} to={`../lectures/${test.ConnectedLecture.Id}`} >{test.ConnectedLecture.Name}</NavLink>
            </>}
        </div>)

}

export default TestLinkBody;

