import { useAtom } from 'jotai';
import { ModalBody, ModalFooter, ModalHeader, Progress } from 'reactstrap';
import { useI18n } from "src/utils/lni18n";
import { useEffect } from 'react';
import { fileUploadData } from './FileUploadService';


const FileSaverDialog = (props: { onCancel: () => void }) => {

    const [savedata] = useAtom(fileUploadData);
    const { percentUploaded, isUploading } = savedata;
    const { languageService: t } = useI18n();
    const { onCancel } = props;

    const handleCancel = () => {
        onCancel();
    }

    useEffect(() => {
        if (!isUploading) {
            onCancel();
        }
    }, [isUploading])


    return <>

        <span className="moveable" data-dialog-draggable>
            <ModalHeader>
                {t.getText("saving")}
            </ModalHeader>
        </span>

        <ModalBody>

            <Progress color="warning" min={0} max={100} value={percentUploaded} className="mt-3" />

        </ModalBody>
        <ModalFooter >
            <button type='button' onClick={handleCancel} value="cancel" className='btn btn-warning' >{t.getText("cancel")}</button>
        </ModalFooter>


    </>

}

export default FileSaverDialog;