import {  Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import { useI18n } from "../../utils/lni18n";
import LnIcon from "../LnIcon";

const PermissionsTypeahead = (props: any) => {

    const { itemChosen, items } = props;

    const selected:any[] = [];

    const { languageService: t } = useI18n();

    const handleItemChosen = (e:any) => {

        if (e && e.length > 0 && e[0].Id) {
            itemChosen(e[0]);
        }
    }


    const renderItems = (option:any, props:any) => {
        return <div key="Receiver" className="d-flex">
            <LnIcon name={option.type} className="more" />
            <div className="ml-2">
                <span>
                    <Highlighter search={props.text}>
                        {option.Name}
                    </Highlighter>
                </span>

            </div>
        </div>
            ;
    }

    return <Typeahead
        id="permissions-select"
        labelKey="Name"
        minLength={3}
        multiple={false}
        onChange={handleItemChosen}
        options={items}
        placeholder={t.getText("name")}
        renderMenuItemChildren={renderItems}
        selected={selected}
        dropup={true}
        
    />

}


export default PermissionsTypeahead;