import vtt from "videojs-vtt.js";
import api from "src/services/api/axiosService";
import { ThumbnailItem, ThumbnailSrc } from "../domain/ThumbnailSrc";


class ThumbnailService {

    items: ThumbnailItem[] = [];

 
    async Init(src: ThumbnailSrc) {
        if (src) {
            try {
                const response = await api.get(src.url);
                if( !response){
                    this.items = [];
                    return;
                }
                this.items = this.Parse(src.url, response.data);
            }
            catch (error) {
                this.items = [];
                throw error;
            }
        } else {
            this.items = [];
        }
    }

    /* public */
    GetItem(time:number) {
        if (!this.items || this.items.length === 0) {
            return undefined;
        }

        // Binary search
        var l = 0;
        var r = this.items.length - 1;

        var i = 0;

        while (l <= r) {
            var m = Math.floor((l + r) / 2);
            var item = this.items[m];

            if (item.start <= time && time <= item.end) {
                return item;
            }
            else if (time < item.start) {
                r = m - 1;
            }
            else if (item.end < time) {
                l = m + 1;
            } else {
                // Something's wrong if we've ended up here, so just exit.
                return undefined;
            }

            i += 1
            if (i >= 100) {
                console.error("Error: Loop ran for too long.");
                console.error({
                    items: this.items,
                    time: time,
                    left: l,
                    right: r
                });
                break;
            }
        }

        return undefined;
    }

   
    private Parse(url:string, data: any) {
        var baseUrl = url.substring(0, url.lastIndexOf("/") + 1);

        let items:ThumbnailItem[] = [];

        var parser = new vtt.WebVTT.Parser(window, vtt.WebVTT.StringDecoder());
        parser.oncue = (cue:any) => {
            try {
                var match = (cue.text).match(/([^#]+)#xywh=([\d,]+)/)
                var parts = match[2].split(",").map((n:string) => parseInt(n, 10));

                items.push({
                    start: cue.startTime,
                    end: cue.endTime,
                    url: baseUrl + match[1],
                    x: parts[0],
                    y: parts[1],
                    w: parts[2],
                    h: parts[3]
                });

            } catch (error) {
                console.error(error);
            }
        }

        parser.parse(data);
        parser.flush();

        // Pre-load the first image (and most likely the only image).
        if (items.length > 0) {
            // this.LoadImage(items[1].url);
        }

        return items;
    }

}

const thumbnailService = new ThumbnailService();

export default thumbnailService;