import { atom, getDefaultStore } from "jotai";
import api from "../../../services/api/axiosService";
import { produce } from "immer";
import lectureService, { lecturesAtom } from "../LectureService";
import { CheckEncodingResponse } from "../domain/CheckEncodingResponse";
import { onePartatom } from "src/coursepart/CoursePartService";

const store = getDefaultStore();

const endPoints = {

    CHECK_ENCODNG: `/author/coursepart/lecture/CheckScreenEncodings`,
}

export const screensInEncodingAtom = atom<string[]>([]);
screensInEncodingAtom.debugLabel = "screensInEncoding";

export class ScreenEncodingService {

    private timerId: number | undefined;

    public async addScreens(screenIds: string[]) {
        const screensAdded = store.get(screensInEncodingAtom);
        const toAdd = screenIds.filter(s => screensAdded.indexOf(s) < 0);
        const newData = produce(screensAdded, data => {
            toAdd.forEach(id => data.push(id));
        });
        store.set(screensInEncodingAtom, newData);
        await this.runCheck.bind(this)();
    }

    public clearScreens(partId: string) {

        // present running conversions
        const presentValues = store.get(screensInEncodingAtom);
        if( !presentValues || presentValues.length == 0 ) return;

        
        const part = store.get(onePartatom(partId));
        const lectureData = store.get(lecturesAtom);
        if (!part?.Data || !lectureData) {
            return;
        }

        // get all possible screenids in this part;
        const allScreenIds = part.Data.Lectures.map(l => {
            const lect = lectureData[l.Id];
            if( !lect || !lect.Screens || lect.Screens.length === 0) return undefined;
            return lect.Screens.map(s => s.ScreenId);
        }).flat().filter(s => !!s );

        // filter out screens for this part
        const vals  = presentValues.filter((item) => allScreenIds.indexOf(item) === -1);

        store.set(screensInEncodingAtom, vals);
        this.runCheck.bind(this)();
    }

    removeScreen(screenId: string) {
        const screens = store.get(screensInEncodingAtom);
        const newData = produce(screens, data => {
            data = data.filter(s => s !== screenId);
        });
        store.set(screensInEncodingAtom, newData);
    }


    private async runCheck() {

        if (this.timerId != undefined) {
            window.clearTimeout(this.timerId);
            this.timerId = undefined;
        }
        let screensIds = store.get(screensInEncodingAtom);
        if (screensIds.length > 0) {

            var response = await api.post<CheckEncodingResponse[]>(endPoints.CHECK_ENCODNG, screensIds);
            if (response && response.status == 200 && response.data != null) {

                let idsHandled: string[] = [];

                if (response.data && response.data.length > 0) {

                    response.data.forEach(resp => {
                        lectureService.UpdateScreensFromEncodingResults(resp);
                        idsHandled = [...idsHandled, ...resp.Screens.map(sc => sc.ScreenId)];
                    });

                    const newData = produce(screensIds, changeScreens => {
                        changeScreens = changeScreens.filter(sc => !idsHandled.includes(sc));
                    })

                    store.set(screensInEncodingAtom, newData);
                }
            }

            screensIds = store.get(screensInEncodingAtom);
            this.timerId = undefined;
            if (screensIds.length > 0) {
                this.timerId = window.setTimeout(this.runCheck.bind(this), 5 * 1000);
            }
        }
    }
}

const screenEncodingService = new ScreenEncodingService();

export default screenEncodingService;