import { useAtom } from 'jotai';
import { useI18n } from 'src/utils/lni18n';
import { oneLectureatom } from '../LectureService';
import { useCallback, useMemo, useRef, useState } from 'react';
import coursePartService, { oneLectureAtom, onePartatom } from 'src/coursepart/CoursePartService';
import LnIcon from 'src/components/LnIcon';
import { LectureFileDto } from '../domain/LectureState';
import FileUpload from 'src/components/FileUpload/FileUpload';
import { FileUploadProps } from 'src/coursepart/domain/FileDialogsettings';
import { Primitive } from 'react-hook-form';
import { CompleteUpload } from 'src/components/FileUpload/FileUploader';
import { Alert } from 'reactstrap';


const OriginalFilesForm = (props: { partId: string, lectureId: string }) => {

    const { languageService: t } = useI18n();
    const { lectureId, partId } = props;

    const [showAddFile, setShowAddFile] = useState(false);
    const [fileToDelete, setFileTodelete] = useState<LectureFileDto | undefined>(undefined);

    const [lecure] = useAtom(oneLectureAtom(partId, lectureId));

    const fileOptions = {
        maxSize: "300mb",
        accepts: undefined
    };

    const handleUploadPromise = (promise: Promise<CompleteUpload>) => {
        promise.then(d => {
            coursePartService.addOriginalFile(lectureId, partId, { Name: d.name, Key: d.Key! })

        });
    }
    const fileUploadProps: FileUploadProps = {
        fileOptions: fileOptions,
        className: "border-0",
        languageService: t,
        onClose: () => { },
        onSubmit: handleUploadPromise,
        backGroundMessage: null,
        showUploadedFilename: false
    };

    const deleteThisFile = (f: LectureFileDto) => {
        setFileTodelete(f);
    };

    const deleteWork = useCallback(async () => {
        if (fileToDelete === undefined) return;

        await coursePartService.deleteOriginalFile(lectureId, partId, fileToDelete!.Id);
        setFileTodelete(undefined);

    }, [fileToDelete])

    return <>

        <div >
            {lecure && lecure.OriginalFiles && lecure.OriginalFiles.map(f => <div key={f.Id} className='d-flex'>
                <a className='mb-2 flex-grow-1' href={f.Url} target='_blank' rel="noopener noreferrer">{f.Name}</a>
                <button className="btn p-2 btn-link ml-3" type="button" onClick={() => deleteThisFile(f)}>
                    <LnIcon name="delete" className='icon-small' />
                </button>
            </div>)}
            <Alert className='my-3' color="danger" isOpen={!!fileToDelete} toggle={() => setFileTodelete(undefined)}>
                <div className='d-flex'>
                    <span>{t.getText("file.delete.file.warning")}<br />"{fileToDelete?.Name}"</span>

                    <button className="btn p-2 btn-inverse btn-small ml-3" type="button" onClick={() => deleteWork()}>
                        <span className='px-3'>{t.getText("yes")}</span>
                    </button>

                </div>

            </Alert>
            <button type='button' onClick={() => setShowAddFile(s => !s)} className='mt-3 btn btn-primary btn-small' >{t.getText("add")}
                <b className='ml-3' dangerouslySetInnerHTML={{ __html: (showAddFile ? "&mdash;" : "+") }} ></b></button>
        </div>

        {showAddFile &&
            <div className='mt-3' >
                <FileUpload className="nope" {...fileUploadProps} />
            </div>
        }

    </>

}

export default OriginalFilesForm;