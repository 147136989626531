import * as React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useI18n } from '../../utils/lni18n';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LinkModel } from "../domain/LinksGroupModel";
import classes from "./links.module.scss";

let url: string = "";


const AddLinkDialog: React.FC<{ data?: LinkModel, onSubmit: (data: LinkModel) => void, onCancel: () => void }> = ({ data, onSubmit, onCancel }) => {

    const { languageService: t } = useI18n();

    const validationSchema = React.useMemo(() => Yup.object({
        Url: Yup.string().url().required(t.getText('required_field')),
        Description: Yup.string().required(t.getText('required_field'))
    }), [t]);

    const { getValues, watch, handleSubmit, register, formState: { errors, isValid, isDirty } } = useForm({
        defaultValues: data || { Url: "", Description: "" },
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });



    const handleSubmitForm = (values: any) => {
        onSubmit({ ...values, Id: data?.Id });
    }

    return <form onSubmit={handleSubmit(handleSubmitForm)}>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("add.link")}</ModalHeader>
        </span>
        <ModalBody>
            <div className="form-group mt-4">
                <label>{t.getText("url")}</label>
                <input type="url" {...register("Url")} className="form-control"></input>
            </div>

            <div className="form-group mt-4">
                <label>{t.getText("description")}</label>
                <textarea {...register("Description")} className="form-control"></textarea>
            </div>

        </ModalBody>
        <ModalFooter>
            <Button type='submit' disabled={!(isValid && isDirty)} className="btn btn-primary btn-small">{t.getText("save.short")}</Button>&nbsp;
            <Button type='button' className="btn btn-inverse btn-small" onClick={onCancel}>{t.getText("cancel")}</Button>
        </ModalFooter>
    </form>
}

export default AddLinkDialog;
