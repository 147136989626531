import React, { useState } from "react";
import { Dropdown, DropdownToggle } from 'reactstrap';
import { Direction } from "reactstrap/types/lib/Dropdown";


interface dropDownData{
    className?:string,
     children: React.ReactElement[] | React.ReactElement,
    caret:any, 
    toggleComponent: React.ReactElement, 
    toogleClassName:string, 
    toogleTagName:any, 
    direction?:Direction
}

const LnDropDownMenu = (props:dropDownData) => {

    const { className, children, caret, toggleComponent, toogleClassName, toogleTagName, direction } = props;
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const dir = direction || "down"

    return (
        <Dropdown className={className} a11y={false} direction={dir} isOpen={dropdownOpen} toggle={toggle} >
            <DropdownToggle caret={caret} className={toogleClassName} tag={toogleTagName}>
                {toggleComponent}
            </DropdownToggle>
            {children}
        </Dropdown >
    );
};


export default LnDropDownMenu;