import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useI18n } from "src/utils/lni18n";
import { FileUploadProps } from 'src/coursepart/domain/FileDialogsettings';
import FileUpload from './FileUpload';


const FileUploadDialog = (props: FileUploadProps) => {

    const { languageService: t } = useI18n();

    const handleCancel = () => {
        props.onClose();
    }


    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>
                {t.getText("add")}
            </ModalHeader>
        </span>

        <ModalBody>
            <FileUpload {...props} />
        </ModalBody>
        <ModalFooter >
            <button type='button' onClick={handleCancel} value="cancel" className='btn btn-warning' >{t.getText("cancel")}</button>
        </ModalFooter>


    </>

}

export default FileUploadDialog;