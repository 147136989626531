import { useParams } from "react-router-dom"

export const usePartPath = ()=>{
  const {partId, courseId, themeId} = useParams();

  if( courseId && themeId ){
    return `/course/${courseId}/content/${themeId}/${partId}`;
  }
  else{
    return `/coursepart/${partId}`;
  }
}