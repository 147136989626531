import { AudioContextService } from "./AudioContextService";
import { AudioSequence } from "./AudioSequence";

export class AudioPlaybackService {

    private audioBufferSize: number = 1024;
    private audioBuffer!: AudioBuffer;
    private audioBufferNode!: AudioBufferSourceNode;
    private audioContextTime: number = 0;
    animationFrame: any;
    timerId?: number;

    private audioDataRef?: Array<number>[] = undefined;

    // Playback information
    private playStart: number = 0;
    private playEnd: number = 0;
    private currentPlayPosition: number = 0;
    isPlaying: boolean = false;

    // Callback information
    private updateListener: Array<(pos: number) => void> = [];
    private playbackUpdateInterval: number = 0.0; // in Seconds
    private lastPlaybackUpdate: number = 0;

    constructor(private audioContextService: AudioContextService) {
        if (audioContextService.ready == false)
            return;
    }

    public play(audioDataRef: Array<number>[], sampleRate: number, isLooped: boolean, start?: number, end?: number) {
        // check if already playing or no data was given
        if (this.isPlaying || audioDataRef === undefined || audioDataRef.length < 1 ||
            sampleRate === undefined || sampleRate <= 0) return;

        start = start || 0;
        end = end || audioDataRef[0].length;

        const sameAudio = this.audioBuffer && (this.playStart == start && this.playEnd == end);

        // update playback variables
        this.audioDataRef = audioDataRef;
        this.playStart = start;
        this.playEnd = (end - this.audioBufferSize < this.playStart || end >= audioDataRef[0].length) ? audioDataRef[0].length : end;
        this.currentPlayPosition = this.playStart;
        

        if (!sameAudio) {
            this.audioBuffer = new AudioBuffer({
                length: this.playEnd - this.playStart,
                sampleRate: sampleRate,
                numberOfChannels: audioDataRef.length
            });

            audioDataRef.forEach((d, i) => {
                const data = this.audioBuffer.getChannelData(i);
                let bIndex = 0;
                for (let index = this.playStart; index < this.playEnd; index++) {
                    data[bIndex] = d[index];
                    bIndex += 1;
                }
            });
        }

        this.audioBufferNode = new AudioBufferSourceNode(this.audioContextService.Context, {
            buffer: this.audioBuffer
        });

        this.audioBufferNode.connect(this.audioContextService.Context.destination);

        this.audioBufferNode.addEventListener('ended', this.playBackEnded);

        this.audioContextTime = this.audioContextService.Context.currentTime;
        this.isPlaying = true;

        this.audioBufferNode.start();

        this.timerId = window.setInterval(() => {
            this.currentPlayPosition = this.playStart    
                + sampleRate * (this.audioContextService.Context.currentTime - this.audioContextTime);
            this.notifyUpdateListener();
        }, 50);


        // inform updatelistener
        this.notifyUpdateListener();
    }

    /**
     * Stops the playback and set all references to undefined (no resume possible)
     */
    public stop() {
        // no playing audio, nothing to stop
        if (this.isPlaying === false) return;

        this.audioBufferNode.stop();
        this.audioBufferNode.disconnect(this.audioContextService.Context.destination);
        this.audioBufferNode.removeEventListener('ended', this.playBackEnded);

        // set all playback information to default
        this.playStart = 0;
        this.playEnd = 0;
        this.currentPlayPosition = 0;
        this.isPlaying = false;
        this.lastPlaybackUpdate = 0;

        // remove reference to the audio data
        this.audioDataRef = undefined;

        this.playBackEnded(0);
    }

    /**
     * Pause the playback of the audio
     */
    public pause() {
        // no playing audio, nothing to pause
        if (this.isPlaying === false) return;
        this.isPlaying = false;
        this.lastPlaybackUpdate = 0;

        this.audioBufferNode.stop();
        this.audioBufferNode.disconnect(this.audioContextService.Context.destination);

        // inform updatelistener
        this.notifyUpdateListener();
    }

    /**
     * Resume the audio playback from the last position
     */
    public resume() {
        // check if already playing or no data was given
        if (this.isPlaying || this.audioDataRef === undefined || this.audioDataRef.length < 1) return;
        this.isPlaying = true;

        // connect the node, play!
        //this.audioJavaScriptNode.connect(this.analyserNode);
        this.audioBufferNode.connect(this.audioContextService.Context.destination);
        this.audioBufferNode.start();

        this.notifyUpdateListener();
    }

    /**
     * Add an update listener, which gets informed about changes in playback
     */
    public addUpdateListener(updateCallback: (pos: number) => void) {
        this.updateListener.push(updateCallback);
    }

    /**
     * Notifies all update listener
     */
    private notifyUpdateListener() {
        for (var i = 0; i < this.updateListener.length; ++i) {
            this.updateListener[i](this.currentPlayPosition);
        }
    }

    private playBackEnded = (ev:any) => {
        if (this.timerId) {
            window.clearTimeout(this.timerId);
            this.timerId = undefined;
        }
        this.isPlaying = false;
        this.notifyUpdateListener();
    };


}
