import { useCallback, useEffect, useState } from "react";

import { Button, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { useI18n } from "../../../utils/lni18n";

const FloatBoxAlign =
{
    none: "none",
    left: "left",
    right: "right"
}

const FloatBoxStyle =
{
    Facts: "Facts",
    Info: "Info"
}

export declare type boxState = { align: string, style: string, width: number, canDelete?: boolean };


const FloatBoxDialog = (props: { boxState: boxState, floatCallback: (res: any) => void }) => {

    const { languageService: t } = useI18n();

    const { boxState, floatCallback } = props;

    const [state, setState] = useState<boxState | null>(null);

    useEffect(() => {
        if (!boxState) {
            setState(null);
        }
        else {
            setState({ ...boxState });
        }
    }, [setState, boxState]);

    const closeAndSubmit = () => {
        floatCallback({ ...state });
        setState(null);
    }

    const closeAndDelete = () => {
        floatCallback({ delete: true });
        setState(null);
    }

    const closeDialog = () => {
        setState(null);
        floatCallback(null);
    }


    const setStyle = useCallback((style: string) => {
        setState((old) => { return !old ? old : { ...old, style: style } });
    }, [setState]);

    const setAlign = useCallback((align: string) => {

        setState(old => { return !old ? old : { ...old, align: align } });
    }, [setState]);

    const setWidth = useCallback((w: number) => {
        setState(old => { return !old ? old : { ...old, width: w } });
    }, [setState]);

    if (!state) {
        return null;
    }



    return <>

        <ModalHeader className={""} toggle={() => closeDialog()}>Box</ModalHeader>
        <ModalBody className={""} tabIndex={-1}>

            <div className="row">
                <div className="col-4 col-lg-2">

                    <div className="mb-4">
                        <h4>{t.getText("box.style")}</h4>
                        <div className="form-check">
                            <label>
                                <Input onChange={() => setStyle(FloatBoxStyle.Facts)} type="radio" id="boxstyleFacts" checked={state.style === FloatBoxStyle.Facts} />
                                {t.getText("box.facts")}
                            </label>
                        </div>
                        <div className="form-check">
                            <label>
                                <Input onChange={() => setStyle(FloatBoxStyle.Info)} type="radio" id="boxstyleInfo" checked={state.style === FloatBoxStyle.Info} />
                                {t.getText("box.info")}
                            </label>
                        </div>
                    </div>

                    <div className="mb-4">
                        <h4>{t.getText("align")}</h4>
                        <div className="form-check">
                            <label>
                                <Input onChange={() => setAlign(FloatBoxAlign.none)} type="radio" id="boxNone" checked={state.align === FloatBoxAlign.none} />
                                {t.getText("none")}
                            </label>
                        </div>

                        <div className="form-check">
                            <label>
                                <Input onChange={() => setAlign(FloatBoxAlign.left)} type="radio" id="boxNone" checked={state.align === FloatBoxAlign.left} />
                                {t.getText("left")}
                            </label>
                        </div>

                        <div className="form-check">
                            <label>
                                <Input onChange={() => setAlign(FloatBoxAlign.right)} type="radio" id="boxNone" checked={state.align === FloatBoxAlign.right} />
                                {t.getText("right")}
                            </label>
                        </div>
                    </div>

                    <div className="mb-4">
                        <h4>{t.getText("width")}</h4>
                        {[250, 300, 350, 400, 450, 500, 550].map(n => {
                            return <div key={n} className="form-check">
                                <label>
                                    <Input key={n} onChange={() => setWidth(n)} type="radio" id={"boxW" + n} checked={state.width === n} />
                                    {n + " px"}
                                </label>
                            </div>
                        })}

                    </div>

                </div>
                <div className="col-8 col-lg-10">

                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus gravida blandit molestie.
                        Duis non fringilla arcu. Duis non tortor ultricies, convallis eros ac, porttitor purus.
                        Sed scelerisque ullamcorper mauris, ut ornare urna accumsan et. Vestibulum suscipit

                        <div className={["box ", state.align + "box", state.style, "w" + state.width].join(' ')}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt est ac dolor ...
                        </div>
                        Nam sodales, augue at fermentum imperdiet, ligula nisi auctor lectus, sit amet volutpat nulla enim non velit. Suspendisse lobortis varius egestas. Nullam tincidunt nunc ligula, vel tempus velit egestas ut. Fusce efficitur augue vitae commodo viverra. Nulla facilisi. Nam non nisi eleifend, pellentesque enim sed, tristique urna.
                    </div>
                </div>

            </div>


        </ModalBody>
        <ModalFooter>
            <div>
                <div>
                    <Button className="btn btn-primary mr-4" onClick={() => closeAndSubmit()}>{t.getText("ok")}</Button>
                    <Button className="btn btn-inverse" onClick={() => closeDialog()}>{t.getText("cancel")}</Button>
                </div>
                {state.canDelete &&
                    <div className="mt-3 d-flex justify-content-end">
                        <Button className="btn btn-danger btn-inverse" onClick={() => closeAndDelete()}>{t.getText("delete")}</Button>
                    </div>
                }
            </div>

        </ModalFooter>
    </>

}

export default FloatBoxDialog;
