import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useMemo } from "react";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useI18n } from 'src/utils/lni18n';
import { CuePointForumData } from '../domain/LectureState';
import classes from "../lecture.module.scss"


const CuePointForumDialog = (props: { forumData?: CuePointForumData, onSubmit: (val: CuePointForumData) => void, onCancel: () => void }) => {

    const { languageService: t } = useI18n();
    const { onSubmit, onCancel, forumData } = props;


    const validationSchema = useMemo(() => Yup.object({
        Header: Yup.string().required(t.getText('required_field')),
        Text: Yup.string().required(t.getText('required_field'))
    }), [t]);

    const { handleSubmit, register, formState: { errors, isValid, isDirty } } = useForm({
        defaultValues: forumData || { Text: "", Header: "" },
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    const handleSubmitForm = (values: any) => {
        onSubmit(values);
    }

    return <form name="forumForm" method="dialog" onSubmit={handleSubmit(handleSubmitForm)} >

        <span className="moveable" data-dialog-draggable>
            <ModalHeader>
                {t.getText("forum")}
            </ModalHeader>
        </span>

        <ModalBody>

            <div className="form-group">
                <label>{t.getText("header")}</label>
                <input {...register("Header")} required name="Header" type='text' className='form-control' />

                <ErrorMessage as="div" errors={errors} name="Header" className="ml-3 mt-2" />
            </div>

            <div className="form-group">
                <label>{t.getText("forum_start_header")}</label>
                <textarea {...register("Text")} required name="Text" className={`form-control ${classes.cuepointForumBox}`} />
                <ErrorMessage as="div" errors={errors} name="Text" className="ml-3 mt-2" />
            </div>

        </ModalBody>
        <ModalFooter >
            <button type='button' onClick={onCancel} value="cancel" className='btn btn-inverse btn-small' >{t.getText("cancel")}</button>
            <button disabled={!isValid || !isDirty} value="submit" type="submit" className='btn btn-primary btn-small' >{t.getText("save.short")}</button>

        </ModalFooter>


    </form>

}

export default CuePointForumDialog;