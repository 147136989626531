import  {  useMemo } from 'react';
import { onePartatom } from '../CoursePartService';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useI18n } from 'src/utils/lni18n';

const ReadOnlyPartInfo = () => {

        const { partId } = useParams();

        const partAtom = useMemo(() => onePartatom(partId!), [partId]);
        const [partData] = useAtom(partAtom);
        const currentPart = partData.Data!;

        const { languageService: t } = useI18n();

    
        return  <div className={`max-col-width`}>

                        <article className="mb-4">
                                <h2>{t.getText("introtext")}</h2>
                                <div dangerouslySetInnerHTML={{ __html: currentPart.Intro }}></div>
                        </article>
                        <article className="mb-4">
                                <h2>{t.getText("goal_contents")}</h2>
                                <div dangerouslySetInnerHTML={{ __html: currentPart.Goals }}></div>
                        </article>
                        <article className="mb-4">
                                <h2>{t.getText("litterature")}</h2>
                                <div dangerouslySetInnerHTML={{ __html: currentPart.Literature }}></div>
                        </article>
                </div>
}
export default ReadOnlyPartInfo;