

import { atom, getDefaultStore } from "jotai";
import { ControlsApi, CuePointForumData, EditScreenDto, ScreenApi, ScreenCuePointDTO } from "../domain/LectureState";
import { LectureState } from "../domain/LectureState";
import { produce } from "immer";
import api from "src/services/api/axiosService";
import wavfileService from "./waveEdit/WaveFileService";
import { AudioSequence } from "./waveEdit/AudioSequence";
import { FileType, ScreenFileUploadedRequest } from "../domain/ScreenFileUploadedRequest";
import lectureService, { LECTUREFILE_UPLOADED, oneLectureatom } from "../LectureService";
import screenEncodingService from "../Screen/ScreenEncodingService";
import localAudioService from "../LocalAudioService";
import fileUploadservice from "src/components/FileUpload/FileUploadService";
import languageService from "src/utils/languageService";
import testService from "src/coursepart/Test/TestService";
1
const store = getDefaultStore();

export enum ScreenJumps {
    first,
    last,
    prev,
    next,
    specificNumber
}


const endPoints = {
    UPDATE_HTML: (screenId: string, lectureId: string) => `/author/coursepart/lecture/${lectureId}/screen/${screenId}/UpdateHtml`,
    SAVE_UPDATE_CUEPOINT: (screenId: string, lectureId: string) => `/author/coursepart/lecture/${lectureId}/screen/${screenId}/SaveUpdateCuePoint`,
    DELETE_CUEPOINT: (screenId: string, lectureId: string, pointId: number) => `/author/coursepart/lecture/${lectureId}/screen/${screenId}/${pointId}/deleteCuePoint`,

}

const dummyAudioUrl = "data:audio/mp3;base64,/+MYxAAAAANIAAAAAExBTUUzLjk4LjIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA";
const dummyVideoUrl = "data:video/mp4;base64,AAAAIGZ0eXBpc29tAAACAGlzb21pc28yYXZjMW1wNDEAAAAIZnJlZQAAAAhtZGF0AAAA1m1vb3YAAABsbXZoZAAAAAAAAAAAAAAAAAAAA+gAAAAAAAEAAAEAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAAABidWR0YQAAAFptZXRhAAAAAAAAACFoZGxyAAAAAAAAAABtZGlyYXBwbAAAAAAAAAAAAAAAAC1pbHN0AAAAJal0b28AAAAdZGF0YQAAAAEAAAAATGF2ZjU3LjQxLjEwMA==";

const currentLecturePlayingAtomPrivate = atom<LectureState | undefined>(undefined);
currentLecturePlayingAtomPrivate.debugLabel = "currentLecturePlayingAtomPrivate";

export const currentLecturePlayingAtom = atom<LectureState | undefined>((get) => get(currentLecturePlayingAtomPrivate));
currentLecturePlayingAtom.debugLabel = "currentLecturePlayingAtom";

export const currentScreenAtom = atom(get => {
    const state = get(currentLecturePlayingAtomPrivate);
    if (!state
        || !state.currentLecture
        || !state.currentLecture.Screens
        || state.currentLecture.Screens.length === 0
        || state.currentLecture.CurrentScreenIndex < 0
        || state.currentLecture.CurrentScreenIndex > state.currentLecture.Screens.length - 1) {

        return undefined;
    }

    return state.currentLecture.Screens[state.currentLecture.CurrentScreenIndex];
},
    (get, set, newVal: EditScreenDto) => {
        const allstate = get(currentLecturePlayingAtomPrivate);
        if (!allstate) return;

        const newState = produce(allstate, state => {
            state.currentLecture.Screens = state.currentLecture.Screens.map((sc, i) => {
                if (state.currentLecture.CurrentScreenIndex === i) {
                    return newVal;
                }
                else {
                    return sc;
                }
            })
        });

        set(currentLecturePlayingAtomPrivate, newState);

    });



export class LecturePlayerService {


    fadeControlsTimerId?: number = undefined;
    forceShowControls = false;
    waitingForVideoToStop = false;
    controlapi?: ControlsApi = undefined;
    screenapi: ScreenApi[] = [];
    controls?: ControlsApi = undefined;
    currentScreen = -1;

    constructor() {
        this.screenapi = [];
        this.controlapi = undefined;
    };

    public clearLecture() {
        store.set(currentLecturePlayingAtomPrivate, undefined);
    }
    public setVisibleControls(show: boolean) {
        this.alterState((state) => {
            state.controlsState!.visible = show;
        });
    }

    public toggeleTextEdit() {
        this.alterState((state) => {
            state.currentLecture.Screens[state.currentLecture.CurrentScreenIndex].EditHtml =
                !state.currentLecture.Screens[state.currentLecture.CurrentScreenIndex].EditHtml;
        });
    }

    togglePlayingState() {
        this.alterState((state) => {
            state.doPlay = !state.doPlay;
        });
    }

    toggleTranscriptEdit() {
        this.alterState((state) => {

            state.showTranscript = !state.showTranscript;
        });
    }


    shiftScreenNumber(jumpType: ScreenJumps, screenNum?: number) {

        const state = store.get(currentLecturePlayingAtomPrivate);
        if (!state) {
            return -1;
        }

        let slideNum = -1;

        switch (jumpType) {
            case ScreenJumps.first:
                if (state.currentLecture.CurrentScreenIndex > 0) {
                    slideNum = 0;
                }
                break;

            case ScreenJumps.last:
                if (state.currentLecture.CurrentScreenIndex < state.currentLecture.Screens.length - 1) {
                    slideNum = state.currentLecture.Screens.length - 1;
                }
                break;

            case ScreenJumps.prev:
                if (state.currentLecture.CurrentScreenIndex > 0) {
                    slideNum = state.currentLecture.CurrentScreenIndex - 1;
                }
                break;

            case ScreenJumps.next:
                if (state.currentLecture.CurrentScreenIndex < state.currentLecture.Screens.length - 1) {
                    slideNum = state.currentLecture.CurrentScreenIndex + 1;
                }
                break;

            case ScreenJumps.specificNumber:
                if (screenNum !== undefined && screenNum >= 0 && screenNum < state.currentLecture.Screens.length
                    && screenNum !== state.currentLecture.CurrentScreenIndex) {
                    slideNum = screenNum
                }

                break;

            default:
                break;
        }

        if (slideNum > -1) {
            this.alterState(draft => {
                draft.currentLecture.CurrentScreenIndex = slideNum;
            });
        }
        return slideNum;

    }


    setFullScreen(fullScreen: boolean) {

        this.alterState((state) => {
            state.controlsState!.fullscreen = fullScreen;
        });
    }

    setVolume(vol: number) {
        this.alterState((state) => {

            state.volume = Math.max(vol, 0);
        });
    }

    setPlaybackRate(rate: number) {
        this.alterState((state) => {

            state.playbackRate = rate;
        });
    }

    async setCurrentLecture(lectureId: string, index: number) {

        const storedData = store.get(currentLecturePlayingAtomPrivate);

        if (storedData
            && storedData.currentLecture.LectureId === lectureId) {

            this.alterState(state => {
                state.currentLecture.CurrentScreenIndex = index;
                state.currentLecture.AtFirstScreen = index === 0;
                state.currentLecture.AtLastScreen = index == state.currentLecture.Screens.length - 1;

                const currentScreen = state.currentLecture.Screens[index];
                if (currentScreen && currentScreen.CuePoints) {
                    currentScreen.CuePoints.forEach(cp => cp.percentPosition = cp.position / 10 / currentScreen.Duration);
                }
            });

            return store.get(currentLecturePlayingAtomPrivate);
        }

        this.controlapi = undefined;

        const lectData = store.get(oneLectureatom(lectureId));

        const newData = produce(lectData, draft => {
            draft.AtFirstScreen = index === 0;
            draft.AtLastScreen = index === draft.Screens.length - 1;
            draft.CurrentScreenIndex = Math.min(Math.max(0, index), draft.Screens.length - 1);
            const currentScreen = draft.Screens[index];
            if (currentScreen && currentScreen.CuePoints) {
                currentScreen.CuePoints.forEach(cp => cp.percentPosition = cp.position / 10 / currentScreen.Duration);
            }
        });

        store.set(oneLectureatom(lectureId), newData);

        const lect = store.get(oneLectureatom(lectureId));



        await localAudioService.AttachLocalAudioToScreens(lect.Screens);

        const state: LectureState = {
            currentLecture: lect,
            showTranscript: false,
            controlsState: {
                visible: true,
                fullscreen: false,
                showingMenu: false

            },
            volume: 100,
            changeDirection: null,
            autoRepeat: false,
            autoNext: false,
            autoNextTimerId: 0,
            doPlay: false,
            stoppedAtCuePoint: false,
            isPlaying: false,
            playbackRate: 100,
            printstyle: 0,
            showForumDialog: false,
            showTestDialog: false,
            currentPlayed: 0

        }

        store.set(currentLecturePlayingAtomPrivate, state);

        return state;
    }

    private alterState(method: (state: LectureState) => void) {

        var data = store.get(currentLecturePlayingAtomPrivate);
        if (!data) return;

        const newstate = produce(data, (draft) => {
            method(draft);
        })

        store.set(currentLecturePlayingAtomPrivate, newstate);

    }

    RegisterScreen = (api: ScreenApi) => {
        this.screenapi.push(api);
    }

    RegisterControls = (ctrlApi: ControlsApi) => {
        this.controlapi = ctrlApi;
    }


    SeekTo = (fraction: number, screenType: number) => {
        if (this.screenapi) {
            try {
                this.screenapi.forEach(a => a.seekTo && a.seekTo(fraction, screenType));
            } catch (error) {
                console.log(error);
            }

        }
    }

    mediaPositionChanged(positionInseconds: number, running: boolean) {

        const currentScreen = store.get(currentScreenAtom);

        if (currentScreen && currentScreen.Duration < positionInseconds) {

            const state = store.get(currentLecturePlayingAtomPrivate);
            const newData = produce(state, data => {
                data!.stoppedAtCuePoint = true;
            });

            store.set(currentLecturePlayingAtomPrivate, newData);

            if (this.waitingForVideoToStop) {
                return null;
            }

            this.waitingForVideoToStop = true;

            return positionInseconds / currentScreen.Duration;
        }

        return null;
    }

    setPlayedPercent(percent: number) {
        this.alterState(state => {
            state.currentPlayed = percent;
        });
    }

    setPlayerRunningState(runState: boolean) {
        this.alterState(state => {
            if (state.currentLecture && state.currentLecture.Screens) {
                state.isPlaying = runState;
                state.doPlay = runState;
            }
        });

    }

    setWantedPlayingState(playState: boolean) {
        this.alterState(state => {
            state.doPlay = playState;
        });

    }

    openForumDialog = async (threadId: number) => {
        // const classid = dispatchService.getState().classData.currentClass.Id;
        // forumDuck.getForumDataForClassAndCuePoint(classid, threadId, dispatchService.dispatch);
        // dispatchService.dispatch(SetShowForumDialog(true));

    }

    openTestDialog = async (testid: string) => {


        // //const classid = dispatchService.getState().classData.currentClass.Id;
        // dispatchService.dispatch(courseDuck.setTestInDialog(true));
        // testService.getTestWithoutTypename({ dispatch: dispatchService.dispatch, classid, testid, studyMode: true });

        // dispatchService.dispatch(SetShowTestDialog(true));
    };

    HandleMouseDown = () => {

        this.alterState(state => {

            if (state.controlsState?.fullscreen) {

                if (state.controlsState.showingMenu) {
                    state.controlsState!.showingMenu = false;
                    if (state.doPlay === true) {
                        this.StartControlsHidingTimer();
                    }
                }
                else if (state.controlsState.visible) {
                    if (state.doPlay === false) {
                        this.StartControlsHidingTimer();
                    }
                    if (!state.stoppedAtCuePoint) {
                        state.doPlay = !state.doPlay;
                    }
                }
                else {
                    state.controlsState!.visible = true;
                    this.StartControlsHidingTimer();
                }
            }
            else {
                if (!state.stoppedAtCuePoint) {
                    state.doPlay = !state.doPlay;
                }
            }
        });
    };

    SetFullScreenControls = () => {
        this.alterState((s) => {
            s.controlsState!.fullscreen = true;
            s.controlsState!.visible = false;
        });

        if (this.fadeControlsTimerId !== undefined) {
            window.clearTimeout(this.fadeControlsTimerId);
        }
    }

    UnsetFullScreenControls = () => {
        this.alterState(state => {
            state.controlsState!.fullscreen = false;
            state.controlsState!.visible = true;
        });
        if (this.fadeControlsTimerId !== undefined) {
            window.clearTimeout(this.fadeControlsTimerId);
        }
    }

    StartControlsHidingTimer = () => {
        if (this.fadeControlsTimerId !== undefined) {
            window.clearTimeout(this.fadeControlsTimerId);
        }
        this.alterState(state => {
            state.controlsState!.visible = true;
        });

        this.fadeControlsTimerId = window.setTimeout(() => {
            this.alterState(state => {
                state.controlsState!.visible = false;
            });
        }, 2000);
    }

    HandleControlsMenuShowing = (showing: boolean) => {

        this.alterState(state => {
            if (state.controlsState!.fullscreen) {
                if (showing) {
                    state.controlsState!.visible = true;
                    if (this.fadeControlsTimerId !== undefined) {
                        window.clearTimeout(this.fadeControlsTimerId);
                    }
                }
                else {
                    this.StartControlsHidingTimer();
                }
            }
        });
    }

    ShowControlsPermanent = (show: boolean) => {

        if (show === true) {
            this.forceShowControls = true;
            this.alterState(state => {
                state.controlsState!.visible = true;
            });
        }
        else {
            this.forceShowControls = false;
            this.StartControlsHidingTimer();
        }
    }

    QuitLecture = () => {
        this.controlapi?.Quit();
    }

    async UpdateHtmlInCurrentScreen(lectureId: string, html: string, type: "transcript" | "htmlScreen") {

        const currentScreen = store.get(currentScreenAtom);
        await api.post<void>(endPoints.UPDATE_HTML(currentScreen!.ScreenId, lectureId), { html, type });

        const screenUpdated = produce(currentScreen!, (screen) => {
            if (type === "transcript") {
                screen.Transcript = html;
            }
            if (type === "htmlScreen") {
                screen.Html = html;
            }
        });

        store.set(currentScreenAtom, screenUpdated);



    }

    public addAudioToScreen(userId: string, lectureId: string, screenId: string, audioData?: AudioSequence[]) {

        if (!audioData) {
            throw new Error("No data to add");
        }

        const file = wavfileService.GetWaveFile(audioData);
        if (!file) {
            throw new Error("No data to add");
        }

        const uploadData = fileUploadservice.uploadFile(null, userId, file);
        const duration = audioData[0].getLengthInSeconds();

        uploadData.promise
            .then((fileData) => {
                this.saveAudioFileData(userId, lectureId, fileData.Key!, screenId, duration, file);
            }).catch((e) => {
                console.log(e);
            });

        return uploadData;
    }

    public removeAudioFromScreen(lectureId: string, screenId: string) {

        let currentScreen = store.get(currentScreenAtom);
        if (currentScreen) {

            if (currentScreen.ScreenId === screenId) {
                this.alterState(state => {

                    const screen = state.currentLecture.Screens[state.currentLecture.CurrentScreenIndex];

                    screen.Duration = 0;
                    screen.AudioUrl = undefined;
                });

                localAudioService.ClearAudioData(screenId);
            }
        }
    }

    public saveAudioFileData(userId: string,
        lectureId: string,
        fileKey: string,
        screenId: string,
        duration: number,
        file: File) {

        const data: ScreenFileUploadedRequest = {
            UserID: userId,
            LectureID: lectureId,
            FileKey: fileKey,
            FileName: fileKey,
            FileType: FileType.Sound,
            ScreenID: screenId,
            InsertAfterThisScreen: null
        }

        api.post<EditScreenDto>(LECTUREFILE_UPLOADED(lectureId), data)
            .then(async (response) => {
                if (response && response.data) {
                    screenEncodingService.addScreens([response.data.ScreenId]);
                    lectureService.UpdateScreen(response.data);

                    const url = await localAudioService.SaveNewFile(file, screenId, duration);
                    this.alterState(draft => {
                        draft.currentLecture.Screens[draft.currentLecture.CurrentScreenIndex].AudioUrl = url;
                    });
                }
            });
    }

    public async addCuePoint(position: number) {
        const currentScreen = store.get(currentScreenAtom);
        const currentLecture = store.get(currentLecturePlayingAtom)?.currentLecture;

        const cuePoint: ScreenCuePointDTO = {
            id: 0,
            coursePart: currentLecture!.CoursePartId,
            forum: undefined,
            position: Math.round(position),
            mandatoryTestPassed: false,
            test: undefined,
            CuePointID: "",
            Passed: false,
            ThreadId: null,
            percentPosition: 0
        }

        const response = await api.post<ScreenCuePointDTO>(endPoints.SAVE_UPDATE_CUEPOINT(currentScreen!.ScreenId, currentLecture!.LectureId), cuePoint);

        if (response.status === 200) {
            const screenUpdated = produce(currentScreen!, (screen) => {
                const cp = response.data;
                cp.percentPosition = cp.position / 10 / currentScreen!.Duration;
                screen.CuePoints.push(cp);
            });

            store.set(currentScreenAtom, screenUpdated);
        }
    }

    public async setCuePointPosition(percent: number, cuepoint: ScreenCuePointDTO) {
        const currentScreen = store.get(currentScreenAtom);
        const currentLecture = store.get(currentLecturePlayingAtom)?.currentLecture;

        let newPoint = { ...cuepoint, position: Math.round(percent * currentScreen!.Duration * 10) };
        const response = await api.post<ScreenCuePointDTO>(endPoints.SAVE_UPDATE_CUEPOINT(currentScreen!.ScreenId, currentLecture!.LectureId), newPoint);
        if (response.status === 200) {
            this.updateAtomAfterAPI(currentScreen!, response.data);
        }
    }

    public async deleteCuePoint(cuePoint: ScreenCuePointDTO) {
        const currentScreen = store.get(currentScreenAtom);
        const currentLecture = store.get(currentLecturePlayingAtom)?.currentLecture;

        const response = await api.delete<void>(endPoints.DELETE_CUEPOINT(currentScreen!.ScreenId, currentLecture!.LectureId, cuePoint.id));
        if (response.status === 200) {
            const screenUpdated = produce(currentScreen!, (screen) => {
                screen.CuePoints = screen.CuePoints.filter(p => p.id !== cuePoint.id);
            });
            store.set(currentScreenAtom, screenUpdated);

            if (cuePoint.test) {
                testService.updateTestCuepointData(currentLecture!, cuePoint.test!, true);
            }
        }
    }

    public async updateCuePointForum(cuepoint: ScreenCuePointDTO, forumData: CuePointForumData) {

        const currentScreen = store.get(currentScreenAtom);
        const currentLecture = store.get(currentLecturePlayingAtom)?.currentLecture;

        let newPoint: ScreenCuePointDTO = { ...cuepoint, forum: forumData };
        const response = await api.post<ScreenCuePointDTO>(endPoints.SAVE_UPDATE_CUEPOINT(currentScreen!.ScreenId, currentLecture!.LectureId), newPoint);
        if (response.status === 200) {
            this.updateAtomAfterAPI(currentScreen!, response.data);
        }

    }

    public async updateCuePointTest(cuepoint: ScreenCuePointDTO, testId: string) {
        const currentScreen = store.get(currentScreenAtom);
        const currentLecture = store.get(currentLecturePlayingAtom)?.currentLecture;

        let newPoint: ScreenCuePointDTO = { ...cuepoint, test: testId };
        const response = await api.post<ScreenCuePointDTO>(endPoints.SAVE_UPDATE_CUEPOINT(currentScreen!.ScreenId, currentLecture!.LectureId), newPoint);
        if (response.status === 200) {
            const cp = response.data;
            this.updateAtomAfterAPI(currentScreen!, cp);

            if (cp.test) {
                testService.updateTestCuepointData(currentLecture!, cp.test);
            }
        }
    }


    public timeFromPercent(percent: number): string {
        const currentScreen = store.get(currentScreenAtom);
        return languageService.gethhmmss(percent * currentScreen!.Duration / 100, true);
    }


    private updateAtomAfterAPI(currentScreen: EditScreenDto, newPoint: ScreenCuePointDTO) {

        const screenUpdated = produce(currentScreen!, (screen) => {
            screen.CuePoints = screen.CuePoints.map(p => {
                if (p.id == newPoint.id) return { ...newPoint, percentPosition: newPoint.position / 10 / currentScreen!.Duration };
                return p;
            })
        });
        store.set(currentScreenAtom, screenUpdated);
    }


}

const lecturePlayerService = new LecturePlayerService();
export default lecturePlayerService;