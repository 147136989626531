import { atom, getDefaultStore } from "jotai";
export const waitRippleAtom = atom<boolean>(false);
waitRippleAtom.debugLabel = "waitRippleAtom";
const store = getDefaultStore();

export class WaitRippleservice{
    public setState( state: boolean ){
        store.set(waitRippleAtom, state );
    }
}
export const waitRippleservice = new WaitRippleservice();
