import React, { useCallback, useMemo, useState } from "react";

import { useI18n } from "src/utils/lni18n";
import * as Yup from 'yup';

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CourseAddOnDTO } from "../domain/CourseAddonDTO";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import HtmlTextEditor from "src/components/TextEditor/HtmlTextEditor";
import { ErrorMessage } from "@hookform/error-message";


const EditAddonDialog: React.FC<{ addOn: CourseAddOnDTO, onSubmit: (data: CourseAddOnDTO) => void, onCancel: () => void }> = ({ addOn, onSubmit, onCancel }) => {

    const { languageService: t } = useI18n();

    const validationSchema = React.useMemo(() => Yup.object({
        Header: Yup.string().required(t.getText('required_field')),
        Description: Yup.string().required(t.getText('required_field')),
        Link: Yup.string().required(t.getText('required_field'))
            .url(t.getText('not.a.valid.url')),

    }), [t]);

    const { handleSubmit, control, watch, register, formState: { errors, isDirty, isValid }, setValue } = useForm({
        defaultValues: { ...addOn },
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    const handleSubmitForm = (values: any) => {
        onSubmit(values);
    }

    return (<form name="createForm" onSubmit={handleSubmit(handleSubmitForm)} >
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("edit")}</ModalHeader>
        </span>
        <ModalBody>


            <div className="form-group mt-4">
                <label>{t.getText("header")}</label>
                <input type="text" {...register("Header")} className="form-control"></input>
                <ErrorMessage as="div" errors={errors} name="Header" className="text-danger small mt-2" />
            </div>

            <div className="form-group mt-4">
                <label>{t.getText("description")}</label>
                <Controller
                    control={control}
                    name="Description"
                    render={({ field: { onChange, value } }) => (
                        <HtmlTextEditor readOnly={false} value={value} onChange={onChange} initialValue={addOn.Description} name="Question"  ></HtmlTextEditor>
                    )}
                />

                <ErrorMessage as="div" errors={errors} name="Description" className="text-danger small mt-2" />

            </div>

            <div className="form-group mt-4">
                <label>{t.getText("web_address")}</label>
                <input type="url" {...register("Link")} className="form-control"></input>
                <ErrorMessage as="div" errors={errors} name="Link" className="text-danger small mt-2" />
            </div>





        </ModalBody>
        <ModalFooter>

            <button type="submit" disabled={!isValid || !isDirty} className="btn btn-primary btn-small" >{t.getText("save.short")}</button>&nbsp;
            <button className="btn btn-inverse btn-small" onClick={onCancel}>{t.getText("cancel")}</button>
        </ModalFooter>
    </form>


    )
}

export default EditAddonDialog;
