import classes from './tests.module.scss';
import { TestLinkDTO } from "../domain/TestLinkDto";
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from '../../components/DragHandle/DragHandle';
import TestLinkBody from './TestLinkBody';
import LnIcon from 'src/components/LnIcon';

const TestLink = (props: { readOnly:boolean, deleteHandler: (testId: string) => void, test: TestLinkDTO, active?: UniqueIdentifier, 
  dragged?: boolean, fixed?: boolean }) => {

  const { test, active, dragged, deleteHandler, readOnly } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: test.Id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: dragged ? "75%" : ""
  };


  return (
    <div ref={setNodeRef} id={test.Id} style={style} className={[classes['test-listing'], 'pl-3 py-3 my-3 d-flex align-items-center', (active === test.Id ? classes.active : "")].join(' ')} >
      <TestLinkBody readOnly={readOnly} test={test} />
      <div className='ml-auto d-flex align-items-center'>
      {!test.AuthoringOk && <LnIcon name="alert" className=" icon-small" />}
        <button className="btn btn-link mx-3 px-2 pt-3 pb-2" type="button" onClick={() => deleteHandler(test.Id)}>
          <LnIcon name="delete" className='icon-small' />
        </button>
        <div {...attributes} {...listeners}><DragHandle /></div>
      </div>


    </div>)

}

export default TestLink;

