export class debouncer{

    func: Function = ()=>{};
    timer?:number = undefined;
    timeout = 300;
    bindTo :any;

    constructor(func: Function, timeout = 300, bind: any = undefined){
        this.func = func;
        this.timeout = timeout;
        this.bindTo = bind || this;
    }
    
    debounce = (...args: any[]) => {
      clearTimeout(this.timer);
      this.timer = window.setTimeout(() => { this.func.apply( this.bindTo, args); }, this.timeout);
    };

    clear = ()=>{
        clearTimeout(this.timer);
    }
  }