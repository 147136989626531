import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useI18n } from "../utils/lni18n";
import LanguageMenu from "../components/LanguageMenu/LanguageMenu";
import LoginForm from "./LoginForm";

import classes from "./Login.module.scss";
import SendPasswdEmail from "./SendPasswdEmail";

export default function LoginComponent() {

    const [activeTab, setActiveTab] = useState('login');
    const { languageService: t } = useI18n();

    const toggle = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    }



    return <div className="pt-5 position-fixed zindex-2 h-100 w-100 scrollContent">
        <dialog open className={classes.loginbox}>

            <div className="w-50">
                <img src="/imgs/logo.svg" />
            </div>

            <div className="d-flex align-items-center my-5">
                <h2 className="d-flex">
                    LäraNära Author Login
                </h2>

                <div className="ml-auto">
                    <LanguageMenu caretClass="dark-caret" />
                </div>
            </div>



            <Nav tabs className="nav-justified">
                <NavItem>
                    <NavLink onClick={() => { toggle('login'); }}
                        className={activeTab === 'login' ? 'active ' : ''} >
                        {t.getText("login")}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        onClick={() => { toggle('sendpw'); }}
                        className={activeTab === 'sendpw' ? 'active ' : ''} >
                        <div className="">{t.getText("password.forgot")}</div>

                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="login">
                    <div className="border border-top-0 p-5">
                        <LoginForm />
                    </div>
                </TabPane>
                <TabPane tabId="sendpw">
                    <div className="border border-top-0 p-5">
                        <SendPasswdEmail />
                    </div>
                </TabPane>
            </TabContent>

        </dialog>
    </div>

}