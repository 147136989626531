import { useI18n } from 'src/utils/lni18n';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import OriginalFilesForm from './OriginalFilesForm';
import { LectureDtoWithOriginals } from 'src/coursepart/domain/LectureDtoWithOriginals';

import classes from "../lecture.module.scss";


const AllOriginalFilesDialog = (props: { partId: string, lectures: LectureDtoWithOriginals[], onSubmit: (d: any) => void, onCancel: () => void }) => {

    const { languageService: t } = useI18n();
    const { onSubmit, lectures, partId } = props;

    return <>

        <span className="moveable" data-dialog-draggable>
            <ModalHeader>
                {t.getText("lecture.edit.files")}
            </ModalHeader>
        </span>

        <ModalBody>
            {lectures.map(l => <div key={l.Id} className={`pb-4 border-bottom ${classes.originalForm}`}>
                <h5 className='mb-2'>{l.Name}</h5>
                <OriginalFilesForm partId={partId} lectureId={l.Id} />
            </div>)}

        </ModalBody>

        <ModalFooter>
            <button type="submit" onClick={onSubmit} className='btn btn-primary btn-small' >{t.getText("close")}</button>

        </ModalFooter>


    </>

}

export default AllOriginalFilesDialog;