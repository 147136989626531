
import { useI18n } from '../utils/lni18n';
import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { coursesAtom } from './CourseCollectionService';

export default function AllCourses() {

  const { languageService: t } = useI18n();
  const [courseData] = useAtom(coursesAtom);
  const [searchText, setSearchText] = useState("");
  const [filteredSet, setFilteredSet] = useState(courseData);

  const filterCourses = (e: React.ChangeEvent<HTMLInputElement>) => {
    let t = e.currentTarget.value;
    setSearchText(t);

    if (t && t.length > 1) {
      const reg = new RegExp(`${t}.*`, "i");
      setFilteredSet({
        Courses: courseData.Courses.filter(p => reg.test(p.Name)),
        Loading: false
      });
    } else {
      setFilteredSet(courseData);
    }
  }

return <div>

    <div className={"scrollContent h-100"} aria-live="polite">
      <div className="card-box big-card-box">
        <div className="card-box-title card-title">
          <h3>{t.getText("courses")}</h3>
          <div>
            <input value={searchText} type="text" onChange={(e) => filterCourses(e)} placeholder={t.getText("find")} className="input form-control custom-input" />

          </div>
        </div>
        <div className="card-box-content card-body scroller">

          <div className="mb-5 row">
            <div className='col-sm-6 col-xs-12'>
              {filteredSet.Courses.map((course) => (

                <div key={course.Id}>
                  <Link to={`/course/${course.Id}/general`} >
                    {course.Name}
                  </Link>
                </div>
              ))}
            </div>

          

            <div></div>
          </div>


        </div>
      </div>
    </div>
  </div>



}