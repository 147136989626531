import { useMemo } from "react";
import classes from '../../Lecture/lecture.module.scss';
import { LectureDtoWithOriginals } from '../../domain/LectureDtoWithOriginals';

import {
    Menu,
    Item,
    useContextMenu,
    ItemParams
} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import { onePartatom } from '../../CoursePartService';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { useParams } from 'react-router-dom';
import scrapBookService from 'src/components/ScrapBook/ScrapBookService';
import ScrapBookDialog, { ScrapType } from 'src/components/ScrapBook/ScrapBookDialog';
import { useI18n } from "src/utils/lni18n";
import { useAtom } from "jotai";
import ReadOnlyLectureLink from "./ReadOnlyLectureLink";
import lectureService, { lecturesDiffAtom } from "src/coursepart/Lecture/LectureService";

const MENU_ID = "lect-menu-id";

export type LetureEvent<T> = { value: T };
export const LectureEventnames = { newLecture: "newLecture" };

const ReadOnlyLectures = () => {


    const { partId } = useParams();
    const [diff] = useAtom(lecturesDiffAtom);

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();

    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);
    const part = partData.Data;


    const { show } = useContextMenu({
        id: MENU_ID
    });


    const lects = part?.Lectures;


    if (lects === undefined) {
        return null;
    }





    function displayContextMenu(e: React.MouseEvent, lect: LectureDtoWithOriginals) {
        show({
            event: e,
            props: lect
        });
    }

    async function handleItemClick(params: ItemParams<LectureDtoWithOriginals, any>) {
        const { id, props }: ItemParams<LectureDtoWithOriginals, any> = params;


        switch (id) {

            case "copy":
                if (props) {
                    await scrapBookService.addLecture(props!);
                }
                break;
        }
    }

    const setDiff = (state: boolean) => {
        lectureService.setDiffMode(state);
    }

    const showScrapBook = () => {

        scrapBookService.loadLectures();
        dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <ScrapBookDialog readOnly={true} contextId={partId!} type={ScrapType.lecture} onCancel={onCancel} onSubmit={onSubmit} />
            }
        });

    }

    return <>

        <div className='d-flex'>
            <button type="button" onClick={showScrapBook} className='ml-3 btn btn-primary btn-small'>{t.getText("scrapbook")}</button>
            <div className='ml-auto'>
                    <button type="button" onClick={()=>setDiff(false)} className='ml-auto btn btn-inverse btn-small' >{ diff ? t.getText("close"): "" } {t.getText("differences")}</button>
                </div>
        </div>

        <div className={"mt-5 flex-row flex-wrap " + classes.cardWrapper}>
            {lects?.map(le => <ReadOnlyLectureLink onContextMenu={(e:any) => displayContextMenu(e, le)} partId={partId!}  lecture={le} key={le.Id} />)}
        </div>




        <Menu id={MENU_ID}>
          
            <Item id="copy" onClick={handleItemClick}>
                {t.getText("copy")}
            </Item>

        </Menu>


    </>

}

export default ReadOnlyLectures;
