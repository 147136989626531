import { useNavigate} from 'react-router-dom';
import { useI18n } from 'src/utils/lni18n';
import { useAtom } from 'jotai';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import courseService, { courseListAtom, currentCourseAtom, oneCourseatom } from 'src/course/CourseService';
import LnDropDownMenu from '../LnDropDownMenu';
import { useMemo } from 'react';
import CoursesTree from './CoursesTree/CoursesTree';


export default function CoursesPanel() {
  const { languageService: t } = useI18n();
  const navigate = useNavigate();
  const [courseList] = useAtom(courseListAtom);

  const [courseId] = useAtom(currentCourseAtom);
   const oneCourseAtom = useMemo(() => oneCourseatom(courseId || ""), [courseId]);

  const [currentCourse] = useAtom(oneCourseAtom);


  const navigateToCourse = (id: string) => {
    if (id === courseId) return;
    const url = courseService.getUrl(id);
    const course = courseService.getCourse(id);

    if (url && course) navigate(url, { state: { readOnly: !!course.Locked } });
  }

  if (!courseList || courseList.length === 0) return null;

  return (
    <div>
      {(courseList.length > 1 || !currentCourse) && (
        <LnDropDownMenu
          caret={false}
          toggleComponent={
            <h3 className='my-4'>
              {t.getText("courses.in.edit.now")}&nbsp;&nbsp;<span className='dark-caret'></span>
            </h3>
          }
          toogleClassName="pointer"
          toogleTagName="div"
        >
          <DropdownMenu className="dropDownMenu" container="body">
            {courseList.map(c =>
              <DropdownItem key={c.Id} tag="div">
                <button type='button' className='btn btn-link px-4 py-2' onClick={() => navigateToCourse(c.Id)}>
                  {c.Name}
                </button>

              </DropdownItem>
            )}


          </DropdownMenu>
        </LnDropDownMenu>
      )}

      {currentCourse && <CoursesTree course={currentCourse} />}


    </div>
  )
}
