
import { CardBody, CardFooter, CardTitle } from "reactstrap";
import { FormatTimeDuration } from "../../utils/DateTimeFormat";
import { useI18n } from "../../utils/lni18n";
import { LectureDtoWithOriginals } from "src/coursepart/domain/LectureDtoWithOriginals";
import LnIcon from "../LnIcon";
import classes from 'src/coursepart/Lecture/lecture.module.scss';


const ScrapLecture = (props: { select: (id: string) => void, lecture: LectureDtoWithOriginals, disabled: boolean }) => {

    const { languageService: t } = useI18n();
    const { lecture, disabled, select } = props;



    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if(!disabled){
            select( lecture.Id )
        }
    }

    return (
        <div onClick={handleClick} role="listitem"
            className={[(lecture.Selected ? classes.selectedLecture : ""), classes.card, 'card pointer'].join(' ')} >

            <div className="h-100" >
                { disabled && <div className="cover50"></div>}
                <CardBody className={`p-3 ${classes.cardBody}`}>
                    <CardTitle className={classes['card-title']}>
                        <div className={classes.nameDiv}>
                            {lecture.Name}
                        </div>

                    </CardTitle>
                    <div className={classes['img-wrapper']}>
                        <img className={classes['lecture-img']} src={lecture.ImageUrl} alt={lecture.Name} />
                    </div>



                </CardBody>

                <CardFooter className={classes['card-footer']}>
                    <span><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="slides" />
                        &nbsp;{lecture.NumOfScreens} <span className="d-none d-sm-inline">{t.getText('lecture.screens')}</span></span>
                    {
                        FormatTimeDuration(lecture.Duration, t) &&
                        <span className="ml-1"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDuration(lecture.Duration, t)}</span>
                    }
                </CardFooter>
            </div>
        </div>
    );

}


export default ScrapLecture;