export interface ScreenFileUploadedRequest {
    UserID: string | null;
    LectureID: string;
    FileKey: string;
    FileName: string;
    FileType: FileType;
    ScreenID: string | null;
    InsertAfterThisScreen: string | null;
}

export enum FileType {
    Notset,
    Sound,
    Image,
    Video,
    Presentation
}