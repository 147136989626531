import { useAtom } from "jotai";
import { useI18n } from "../../utils/lni18n";
import { ImagePickerService, imageAtom } from "./ImagePickerService";
import { align } from "./ImagePicker_types";
import LnIcon from "../LnIcon";

const Preview = (props:
    {
        className?: string, onSubmit: import("react").MouseEventHandler<HTMLButtonElement>, src: string,
        imagePickerService: ImagePickerService
    }) => {

    const { onSubmit, src, className, imagePickerService } = props;

    const { languageService: t } = useI18n();
    const [state] = useAtom(imageAtom);
    const { imageAlign, alignData } = state;


    const setAlign = (align: align) => {
        imagePickerService.setImageAlign(align);
    }

    let align = "";
    let widthCss = "mw-50";

    if (imageAlign === "block") {
        align = "d-block";
    }
    if (imageAlign === "left" || imageAlign === "right") {
        widthCss = "mw-75";

        align = imageAlign === "left" ? "float-left" : "float-right";
    }

    const imgclasses = `m-3 ${align} ${align} ${widthCss}`

    return <div className={className}>

        <div className="d-flex justify-content-between flex-wrap">
            <div className="btn-group btn-group-toggle mb-3" data-toggle="buttons" >
                <button type="button" className={"btn btn-primary " + (imageAlign === "none" ? "active" : "")} onClick={() => setAlign('none')} >
                    <LnIcon name="noalign" className="filter-white" />
                </button>
                <button type="button" className={"btn btn-primary " + (imageAlign === "block" ? "active" : "")} onClick={() => setAlign('block')} >
                    <LnIcon name="d-block" className="filter-white" />
                </button>
                <button type="button" className={"btn btn-primary " + (imageAlign === "left" ? "active" : "")} onClick={() => setAlign('left')} >
                    <LnIcon name="leftalign" className="filter-white" />
                </button>
                <button type="button" className={"btn btn-primary " + (imageAlign === "right" ? "active" : "")} onClick={() => setAlign('right')} >
                    <LnIcon name="rightalign" className="filter-white" />
                </button>
            </div>

            <button onClick={onSubmit} className="mb-3 ml-3 btn btn-primary">
                {alignData?.changeOnly ? t.getText("change") : t.getText("insertimage")}
            </button>




        </div>

        <div className="mt-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse quis magna faucibus, elementum nisl vel, ullamcorper odio.
            Fusce pulvinar, ligula et ullamcorper luctus, magna erat tincidunt lectus, at congue augue leo ac dolor.
            Nulla lacinia massa mi, vel efficitur quam consectetur sed. Suspendisse fermentum rhoncus pellentesque.
            <img src={src} alt="" className={imgclasses} />          
            Maecenas aliquam arcu lectus,at malesuada neque viverra aliquam.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Suspendisse quis magna faucibus, elementum nisl vel, ullamcorper odio. Fusce pulvinar,
            ligula et ullamcorper luctus, magna erat tincidunt lectus, at congue augue leo ac dolor.
            Nulla lacinia massa mi, vel efficitur quam consectetur sed. Suspendisse fermentum rhoncus pellentesque.
            Maecenas aliquam arcu lectus,at malesuada neque viverra aliquam.


        </div>

    </div>
}

export default Preview;
