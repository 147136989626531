import { useI18n } from "src/utils/lni18n";
import { CoursePartFile } from "./domain";

const PrintPartFiles = (props: { files: CoursePartFile[] }) => {

  const { files } = props;
  const { languageService: t } = useI18n();

  if (!files || files.length === 0) return null;

  return (
    <div>
      <h3 className="mt-5 mb-3 border-bottom">{t.getText("files")}</h3>
      {files!.map(file => <div key={file.ID} className="mt-3">
        <h4 >{file.Url}</h4>
        <div dangerouslySetInnerHTML={{ __html: file.Name }}></div>

      </div>)
      }
    </div>
  )
}
export default PrintPartFiles;