import classes from '../../File/files.module.scss';
import { API_URL } from 'src/utils/constants';
import LnIcon from 'src/components/LnIcon';
import { FileModel } from 'src/coursepart/domain/FilesGroupModel';
import { useI18n } from 'src/utils/lni18n';
import FileIcon from 'src/components/FileIcons/FileIcon';



const ReadOnlyFileCard = (props: { partId: string, file: FileModel}) => {

    const { languageService: t } = useI18n();
    const { file, partId } = props;

    if (!file) {
        return null;
    }
    const url = `${API_URL}/author/coursepart/${partId}/file/${file.Id}/download?name=${encodeURIComponent(file.FileName)}`
    
    return <div key={file.Id}
                className={"row align-items-center py-2 mx-4 border-top "}>


                <div className="col-1">

                    <FileIcon className={["icon-small",classes['file-icon']].join(' ')} name={(file.FileName || '').split('.').pop()} />

                </div>
                

                <div className={"col-4 " + classes.lineClamp} dangerouslySetInnerHTML={{ __html: file.Description }}>
                </div>

               

                <div className="col-3">
                <div className={classes.fileInfo}>{file.FileName}</div>
                    <span >{t.getMediumDateString(file.LastModified)}</span>
                </div>

                <div className='col-1'>
                    <a target="_blank" rel="noopener noreferrer" title={t.getText('download')} className="" href={url}>
                        <LnIcon name='download2' className='icon-small' />
                    </a>

                </div>
            </div>

}

export default ReadOnlyFileCard;
