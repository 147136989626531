//@ts-check
import { useCallback, useEffect, useRef } from "react";
import { useCurrentUser, userService } from "../../services/user/userService";
import { release } from "../../utils/constants";
import api from "../../services/api/axiosService";
import { useDialog } from "../Modalservice/Dialogservice";
import ReloadDialog from "./ReloadDialog";
import { LoginPingDto, UserDto } from "src/services/user/types";
import { PurgeAudioData } from "src/services/db/AuthorDB";
import { atom, useAtom } from "jotai";

export const pingEndPoints = {
    PING_URL: '/login/PingAuthor',
    GET_CDN_COOKIE_URL: `/amazon/cloudfront/cookies/url`,
}

const cdnUrlAtom = atom<string | undefined>(undefined);
cdnUrlAtom.debugLabel = "cdnUrlAtom";

export let applicationSessionId: string = "";

const Pinger = () => {

    const [cdnUrl, setCdnUrl] = useAtom(cdnUrlAtom);
    const { currentUser } = useCurrentUser();
    const dialogPortal = useDialog();

    const apiTimerId = useRef<number | undefined>(undefined);
    const cdnTimerId = useRef<number | undefined>(undefined);

    const ping = useCallback(async () => {
        let postData = { ReleaseId: release };
        let result = null;
        try {

            result = await api.post<LoginPingDto>(pingEndPoints.PING_URL, postData);

        } catch (error) {
            if (apiTimerId.current) {
                window.clearTimeout(apiTimerId.current);
                apiTimerId.current = undefined;
                return;
            }
        }

        const data = result ? result.data : null;

        if (!data || data.IsLoggedIn === false) {

            if (apiTimerId.current) {
                window.clearTimeout(apiTimerId.current);
                apiTimerId.current = undefined;
            }

            userService.logoutUser();
            return;
        }

        if (data.Release
            && (data.Release !== release)
            && (release !== null)) {

            dialogPortal({
                factory: (onSubmit, onCancel) => {
                    return <ReloadDialog onCancel={onCancel} onSubmit={onSubmit} />
                },
                size: "md"
            });
        }

        if (data.SessionId !== applicationSessionId) {
            PurgeAudioData(data.SessionId);
            applicationSessionId = data.SessionId;
        }

        //TODO: handle system messages in data after ping call

        apiTimerId.current = window.setTimeout(
            ping,
            (1000 * 60 * data.SessionTTL) / 2
        );
    }, []);

    const pingCdn = useCallback(async () => {

        // if( this.doRun === false){
        //     return;
        // }

        let result = null;
        try {
            result = await api.get(pingEndPoints.GET_CDN_COOKIE_URL);
        } catch (error) {
            window.clearTimeout(cdnTimerId.current);
            cdnTimerId.current = undefined;

        }

        if (result && result.data) {

            const data = result.data;

            // min 1 minute until next call
            const timeOut = Math.max(data.ValidUntil * 1000 - Date.now(), 1000 * 60);
            cdnTimerId.current = window.setTimeout(pingCdn, timeOut);

            setCdnUrl(data.Url);
        }


    }, []);



    useEffect(() => {

        if (!currentUser) {
            if (apiTimerId.current) {
                window.clearTimeout(apiTimerId.current);
                apiTimerId.current = undefined;
            }
        } else {
            if (!apiTimerId.current) {
                apiTimerId.current = window.setTimeout(ping, 1000 * 3);
                cdnTimerId.current = window.setTimeout(pingCdn, 1000 * 3);
            }
        }

    }, [currentUser]);


    return <>
        <iframe title="cdn" className="d-none" src={cdnUrl}></iframe>
    </>;
}



export default Pinger;
