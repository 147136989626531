
import LnIcon from 'src/components/LnIcon';
import classes from '../../Lecture/lecture.module.scss';
import { useRef } from "react";
import WaitRipple from "src/components/WaitRipple";
import { EditScreenDto, ScreenType } from "src/coursepart/Lecture/domain/LectureState";
import screenService from 'src/coursepart/Lecture/Screen/ScreenService';
import { FormatTimeDurationFromSeconds } from 'src/utils/DateTimeFormat';
import { useI18n } from 'src/utils/lni18n';



const ReadOnlyScreenLink = (props: { playLecture?: (num: number) => void, onContextMenu: React.MouseEventHandler<HTMLElement>, screen: EditScreenDto }) => {

    const { languageService: t } = useI18n();
    const { screen, onContextMenu } = props;

    var timerId = useRef<number | undefined>(undefined);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.detail === 1) {
            timerId.current = window.setTimeout(() => {
                screenService.SelectScreen(screen, e.ctrlKey, e.shiftKey);
                timerId.current = undefined;
            }, 250);
        }
        else {
            if (props.playLecture) {
                if (timerId.current) {
                    window.clearTimeout(timerId.current);
                    timerId.current = undefined;
                }
                props.playLecture(screen.Order);
                screenService.SelectScreen(screen, false, false, true);
            }
        }
    }

    const classNames = [
        (screen.Selected ? classes.selectedScreen : ""),
        classes.screenItem, 'pointer '].join(' ');

    return (
        <div onContextMenu={onContextMenu} onClick={handleClick} key={screen.ScreenId} role="listitem"
            className={classNames} >

            <div className={classes.screenBody}>
                {(screen.PptConversion || screen.RunningConversion) &&
                    <>
                        <div className="cover50"></div>
                        <WaitRipple className="restrictTextWidth" text={t.getText("waiting.for.encoding")} />
                    </>
                }
                <div className={classes['card-title']}>
                    <div className={classes.nameDiv}>
                        <div className="m-2 badge badge-grey">{(screen.Order + 1)}</div>
                    </div>

                </div>
                <div className={`${classes['img-wrapper']} ${screen.RunningConversion ? classes.opacity3 : ""}`}>
                    <img className={classes['lecture-img']} src={screen.ThumbnailUrl} alt={screen.ScreenId} />
                </div>

                <div className={classes.footer}>

                    {screen.ScreenType === ScreenType.Video && <>
                        <span>
                            <LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="video" />
                        </span>
                        {screen.Duration > 0 &&
                            <span className="ml-auto"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDurationFromSeconds(screen.Duration, t)}</span>}
                    </>
                    }

                    {screen.ScreenType === ScreenType.ImageSound &&
                        <>
                            <div className={`w-100 p-2 d-flex`}>
                                <span>
                                    <LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="volume-black" />
                                </span>
                                {screen.Duration > 0 &&
                                    <span className="ml-auto"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDurationFromSeconds(screen.Duration, t)}</span>}
                                {!!!screen.Duration && <span className="ml-auto">{t.getText("no.audio")}</span>}
                            </div>
                        </>
                    }
                    &nbsp;




                </div>


            </div>


        </div>
    );

}


export default ReadOnlyScreenLink;