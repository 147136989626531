import { useI18n } from "src/utils/lni18n";
import { ChangeEvent, useMemo } from 'react';
import { EditTestDataDTO } from '../DomainModels';
import { useAtom } from "jotai";
import { MultipleQuestionService } from "./MultipleQuestionService";
import Question from "./Question";


const SimpleSelfAssessement = (props: { data: EditTestDataDTO<string[]>, onChange: (d: string[]) => void }) => {

  const { data, onChange } = props;
  const { languageService: t } = useI18n();
  const service = useMemo(() => new MultipleQuestionService(data.TestData), []);
  const [editVals] = useAtom(service.datatom);


  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const strings = service.handleChange(e, index);
    if (strings) {
      onChange(strings);
    }
  };

  if (!data) return null;

  return <div className="mt-5">
          <Question draggable={false} headerText={ `${t.getText("question")}`} index={0} active={null} deleteQuestion={(id) =>{}} val={editVals[0]} canDelete={false} initialValue={service.startValues[0]} name={`inp_0`} onChange={(e) => handleChange(e, 0)} />
          <Question draggable={false} headerText={ `${t.getText("experts_answer")}`} index={1} active={null} deleteQuestion={(id) =>{}} val={editVals[1]} canDelete={false} initialValue={service.startValues[1]} name={`inp_1`} onChange={(e) => handleChange(e, 1)} />
    </div >

}

export default SimpleSelfAssessement;