
import { useParams } from 'react-router-dom';
import { useI18n } from 'src/utils/lni18n';
import { useMemo } from 'react';
import { onePartatom } from 'src/coursepart/CoursePartService';
import { useAtom } from 'jotai';
import EvalQuestionReadOnly from 'src/coursepart/Evaluations/EvalQuestionReadOnly';

export default function ReadOnlyEvaluations() {

    const { partId } = useParams();
    const { languageService: t } = useI18n();
    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);
    const part = partData.Data;

    return <>
        <div className="d-flex mb-5 ">
            <h3 className="flex-fill">{t.getText("evaluations")}</h3>
        </div>

        <div>
            {part && part.EvalQuestions && part.EvalQuestions.CustomQuestions && part.EvalQuestions.CustomQuestions.map((q, i) => (
                <div key={q.Id} >
                    <EvalQuestionReadOnly readOnly={true} partId={partId!} active={undefined} q={q} num={i} />
                </div>
            ))}

        </div>
    </>



}