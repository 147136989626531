
/**
 * This color will be bg color for initials avatar if no image exists. see the value at CSS > :root > --user-bg-color
 */
const bgColor = getComputedStyle(document.documentElement).getPropertyValue('--user-bg-color').replace("#", "").trim();

const UserImg = (props: {src: string, className?: string, alt: string }) => {

    const {src, className, alt} = props;
    if( src === null || src === "" ){
        return null;
    }
    
    let url =src
    if( src.indexOf("?") === -1 ){
        url = url + "?color=" + bgColor;
    }
    else{
        url = url + "&color=" + bgColor;
    }


    return <img className={ className } src={url} alt={alt}/>

}
export default UserImg;