import { useCallback } from "react";
import {  ModalHeader, ModalBody } from 'reactstrap';
import Preview from "./Preview";
import { useI18n } from "../../utils/lni18n";
import { useAtom } from "jotai";
import imagePickerService, { imageAtom } from "./ImagePickerService";



const ImageAlignDialog: React.FC<{ onSubmit: (d: any) => void, onCancel: () => void }> = ({ onSubmit, onCancel }) => {

  const { languageService: t } = useI18n();
  const [state] = useAtom(imageAtom);
  const { alignData, imageAlign } = state;

  const onSubmitImg = useCallback(() => {
    onSubmit({ ...alignData, imageAlign: imageAlign });

  }, [imageAlign, alignData, imagePickerService]);

  if (!alignData) {
    return null;
  }

  return <>

    <ModalHeader className={""} toggle={onSubmitImg}>{t.getText("image")}</ModalHeader>
    <ModalBody className={""} tabIndex={-1}>
      <Preview imagePickerService={imagePickerService} onSubmit={onSubmitImg} src={alignData.url} />
    </ModalBody>
  </>

}

export default ImageAlignDialog;
