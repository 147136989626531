import Dexie from "dexie";

export class AuthorDb extends Dexie {
    audioData!: Dexie.Table<{id?: number, screenId: string, blob: Blob , duration: number, sessionId: string, url:string}, number>;
    constructor (name:string) {
        super(name);
        this.version(1).stores({
            audioData: '++id, [screenId+sessionId]'
        });
    }
}

export async function PurgeAudioData(sessionId:string){
    const posts = await db.audioData.filter(d => d.sessionId !== sessionId).toArray();
    if( !posts || posts.length === 0) return;

    const ids = posts.map(p => p.id!);
    db.audioData.bulkDelete( ids );

}

const db = new AuthorDb("authordata");

export default db;