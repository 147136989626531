// import AWS, { CognitoIdentityCredentials } from "aws-sdk/global";
// import S3 from "aws-sdk/clients/s3";
import { CompleteMultipartUploadCommandOutput, S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { fromCognitoIdentity } from "@aws-sdk/credential-providers";
import { XhrHttpHandler } from "@aws-sdk/xhr-http-handler";
import { v4 as uuidv4 } from 'uuid'
import api from "../../services/api/axiosService";
import { S3UploaderProcessData } from "./FileUploader";


export const s3Endpoints = {
    GET_S3_TOKEN_URL: `/s3fileupload/GetToken`,
    GET_CDN_COOKIE_URL: `/amazon/cloudfront/cookies/url`

}

export type S3UploadEvent = {
    type: string,
    loaded: number,
    total: number,
};

export class S3Uploader {

    constructor() {
        this.abort = this.abort.bind(this);
    }

     public static startUpload(
        userId: string,
        fi: File,
        progressHandler: (d: S3UploadEvent) => void ) :S3UploaderProcessData {


            const s3Uploader = new S3Uploader();
            const prom = new Promise<CompleteMultipartUploadCommandOutput>((resolve, reject) => {
    
                s3Uploader.uploadToS3(
                    fi,
                    userId,
                    progressHandler,
                    (f) => resolve(f),
                    (err: any) => {
                        reject(err);
                    }
                );
            });
            return { promise: prom, abort: s3Uploader.abort, file:fi }

        };

    region = "eu-west-1";

    private uploadParallel: Upload | undefined = undefined;

    public abort() {
        if (this.uploadParallel) {
            this.uploadParallel.abort();
        }
    }

    async uploadToS3(file: File, userId: string, progress: (s: S3UploadEvent) => void,
        success: (s: CompleteMultipartUploadCommandOutput) => void, handleError: (s: any) => void) {
        const response = await api.get(s3Endpoints.GET_S3_TOKEN_URL);
        if (!response) {
            return;
        }
        const tokendata = response.data;

        const creds = fromCognitoIdentity({
            identityId: tokendata.IdentityId,
            clientConfig: { region: this.region },
            logins: {
                "cognito-identity.amazonaws.com": tokendata.Token
            }
        });

        const client = new S3Client({

            region: this.region,
            requestHandler: new XhrHttpHandler({}) as any,
            credentials: creds,

        })



        const ext = /^.+\.([^.]+)$/.exec(file.name);
        const extension = ext == null ? "" : "." + ext[1];

        const key = userId + "_" + uuidv4() + extension;

        const params = {
            Bucket: tokendata.BucketName,
            Key: key,
            Body: file,
            region: this.region

        };

        this.uploadParallel = new Upload({
            client: client,
            queueSize: 4,
            partSize: 5 * 1024 * 1024, // optional size of each part§
            leavePartsOnError: false,
            params: params
        });

        this.uploadParallel.on("httpUploadProgress", pr => {
            progress({
                type: "uploadprogress",
                loaded: pr.loaded!,
                total: pr.total!,
            });
        });

        try {
            const data = await this.uploadParallel.done();
            success(data);
        } catch (error: any) {
            handleError(error);
        }

    };

}
