//@ts-check
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LnIcon from "src/components/LnIcon";
import UserAsyncFind from "src/components/UserAsyncFind";
import UserImg from "src/components/UserImg";
import { UserMailDto } from "src/services/user/types";
import { userEndPoints, userService } from "src/services/user/userService";
import { useI18n } from "src/utils/lni18n";


const SwitchUser = () => {

    const navigate = useNavigate();
    const findUser = useRef<any>(null);
    //const { user: currentUser } = useTypedSelector(state => state.login);
    const { languageService: t } = useI18n();

    const [userSelected, setUserSelected] = useState<UserMailDto | undefined>(undefined);

    const handleSwitch = async () => {
        if (userSelected) {
            const res = await userService.switchUser(userSelected!.Id);
            if( res){
                navigate("/")
            }
        }
    }

    useEffect(() => {
        if (findUser.current) {
            findUser.current.focus();
        }
    }, [findUser])



    const handleUserChange = (e: any) => {
        setUserSelected(e);

    }

    const userSearch = async (query: string) => {
        const users = await userService.findUsers(query, "switchOnly", true);

        return users && users.map((i: any) => ({
            Mail: i.Mail,
            Initials: i.Initials,
            Id: i.Id,
            FullName: i.FullName
        })) || [];
    };

    return (
        <div className="card-box big-card-box max-col-width m-auto">
            <div className="card-header ln-nostyle d-flex justify-content-between">
                <h3>
                    <LnIcon className="header-icon" name="info-icon" />
                    {t.getText("switch.user")}
                </h3>
            </div>
            <div className="card-body pt-5 max-col-width">
                <div className="d-flex">
                    <div className="flex-fill">

                        <UserAsyncFind focus={true} onUserSelected={handleUserChange} userSearch={userSearch} />

                    </div>
                    {userSelected && <UserImg alt={userSelected.FullName} className="rounded-circle ml-3 avatar-img" src={userEndPoints.GET_USER_IMAGE_URL(userSelected.Id)} />}
                </div>

                <div className="mt-4 d-flex">
                    <button onClick={handleSwitch} disabled={!userSelected} className="btn btn-primary" >{t.getText("switch.user")}</button>

                </div>
            </div>
        </div>
    )
}



export default SwitchUser;