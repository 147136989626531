import { atom, getDefaultStore } from "jotai";
import { produce } from "immer";
import api from "src/services/api/axiosService";
import lecturePlayerService, { currentLecturePlayingAtom, currentScreenAtom } from "./LecturePlayerService";
import { WaveEditData } from "../domain/WaveEditData";
import audioContextService from "./waveEdit/AudioContextService";
import { WaveDisplay } from "./waveEdit/WaveDisplay";
import { AudioSequence } from "./waveEdit/AudioSequence";
import { AudioPlaybackService } from "./waveEdit/AudioPlaybackService";
import { CompleteMultipartUploadCommandOutput } from "@aws-sdk/client-s3";
import { currentUser } from "src/services/user/userService";
import wavfileService from "./waveEdit/WaveFileService";
import localAudioService from "../LocalAudioService";
import fileUploadservice from "src/components/FileUpload/FileUploadService";

const store = getDefaultStore();

export enum ScreenJumps {
    first,
    last,
    prev,
    next,
    specificNumber
}



const endPoints = {
    GET_WAV_FILE_URL: (screenId: string, lectureId: string) => `/author/coursepart/lecture/${lectureId}/screen/${screenId}/GetOriginalAudioUrl`
}

const defaultWave = {
    canRedo: false,
    canUndo: false,
    hasStereo: false,
    isDirty: false,
    isPlaying: false,
    haveSelection: false,
    loading: false,
    loadProgressPercent: 0,
    saveProcent: 0,
    isSaving: false,
    screenItem: undefined,
    audioContext: audioContextService.Context,
    loadingReady: false
};


export const currentWaveDataAtom = atom<WaveEditData>({ ...defaultWave });
currentWaveDataAtom.debugLabel = "currentWaveDataAtom";



export class ScreenAudioEditService {

    private loadAbortSignal?: AbortController;
    public waves: WaveDisplay[] = [];
    playbackService: AudioPlaybackService;

    constructor() {
        this.playbackService = new AudioPlaybackService(audioContextService);
        this.playbackService.addUpdateListener(this.updatePlayState);
    }

    private updatePlayState = (sec: number) => {

        const pos = this.playbackService.isPlaying ? sec : 0;

        this.waves.forEach(w => {
            w.playbackPos = pos;
            w.paintPlayBackPosition()
        });


        this.alterWavState(state => {
            if (this.playbackService.isPlaying != state.isPlaying) {
                state.isPlaying = !state.isPlaying;

            }
        });



    }

    private alterWavState(method: (state: WaveEditData) => void) {

        var data = store.get(currentWaveDataAtom);
        if (!data) return;

        const newstate = produce(data, (draft) => {
            method(draft);
        })

        store.set(currentWaveDataAtom, newstate);

    }



    public resetData() {
        this.waves = [];
        store.set(currentWaveDataAtom, { ...defaultWave });
        if (this.loadAbortSignal) {
            this.loadAbortSignal.abort();
            this.loadAbortSignal = undefined;
        }
    }

    public async fetchAudio(screenId: string, lectureId: string) {

        this.waves = [];
        const lectData = store.get(currentLecturePlayingAtom);

        let screenData: WaveEditData = { ...defaultWave };
        if (lectData) {
            screenData.screenItem = lectData.currentLecture.Screens.find(s => s.ScreenId === screenId);
        }

        screenData.loading = true;
        screenData.loadingReady = false;
        screenData.loadProgressPercent = 0;

        store.set(currentWaveDataAtom, screenData);

        let buffer: ArrayBuffer | null = await localAudioService.GetAudioData(screenId);

        if (!buffer) {

            const urlResponse = await api.get<string>(endPoints.GET_WAV_FILE_URL(screenId, lectureId));
            if (!urlResponse || urlResponse.status != 200) {
                throw new Error("url not found")
            }

            this.loadAbortSignal = new AbortController();
            var url = urlResponse.data
            const response = await api.get<ArrayBuffer>(url, {
                responseType: "arraybuffer",
                signal: this.loadAbortSignal.signal,
                onDownloadProgress: (progressEvent) => {
                    this.alterWavState(data => {
                        data.loadProgressPercent = (progressEvent.progress || 0) * 100;
                    })
                },
            });
            this.loadAbortSignal = undefined;

            if (response.status === 200) {
                buffer = response.data;
            }


        }

        if (buffer) {
            screenData = store.get(currentWaveDataAtom)!;
            screenData.audioContext.decodeAudioData(buffer, this.decodeSuccess, this.decodeFailure)
                .then(() => {
                    screenData = store.get(currentWaveDataAtom)!;
                    this.alterWavState(state => {
                        state.loading = false;
                        state.loadingReady = true;
                    });
                    return screenData;
                });


        }
    }

    public playStop(){
        if (this.playbackService.isPlaying) {
            this.playbackService.stop();
            this.alterWavState((state) => {
                state.isPlaying = false;
            });
            return true;
        }
        return false;
    }

    public playToggle = (fromstart: Boolean) => {
        if (this.playStop() === false) {
            this.play(fromstart);
            this.alterWavState((state) => {
                state.isPlaying = true;
            });
        }
    }

    private play = (fromstart: Boolean) => {
        const audioDataRefs: Array<number>[] = this.waves.map(w => w.getData()!);
        var selectionStart = this.waves[0].selectionStart;
        var selectionEnd = this.waves[0].selectionEnd;
        if (fromstart) {
            selectionStart = 0;
            selectionEnd = this.waves[0].getData()!.length;
        }


        if (selectionStart != selectionEnd) {
            this.playbackService.play(audioDataRefs,
                this.waves[0].getSampleRate(), false, selectionStart, selectionEnd);
        }
        else {
            this.playbackService.play(audioDataRefs,
                this.waves[0].getSampleRate(), false, selectionStart);
        }


    }

    private decodeFailure: DecodeErrorCallback = (err: DOMException) => {
        throw err;
    }

    private decodeSuccess: DecodeSuccessCallback = (buffer) => {

        if (buffer.numberOfChannels > 2)
            throw "Not enought wavedisplays";

        this.waves = [];

        for (var i = 0; i < buffer.numberOfChannels; i++) {

            const seq = new AudioSequence(buffer.sampleRate, buffer.getChannelData(i));
            const disp = new WaveDisplay(seq, this.updateEditorSelection);
            this.waves.push(disp);
        }

        this.alterWavState((state) => {
            state.hasStereo = buffer.numberOfChannels > 1;
            state.loading = false;
            state.loadingReady = true;
        })
    }

    public updateEditorSelection = (data: any) => {

        this.alterWavState((state) => {
            state.haveSelection = data.selectionStart != data.selectionEnd;
        });

        this.waves.forEach(wave => {
            wave.updateSelection(data);

        });

    }

    public saveAudio() {

        const correntScreenData = store.get(currentScreenAtom);
        if (!correntScreenData) {
            return;
        }

        const user = store.get(currentUser);
        var sequenceList =
            this.waves.map(w => w.audioSequence!);

        const duration = sequenceList[0].getLengthInSeconds();

        const file = wavfileService.GetWaveFile(sequenceList);
        if (file === undefined) return;

        const uploadData = fileUploadservice.uploadFile(null, user!.Id, file);

        if (uploadData) {
            uploadData.promise.then((res) => {
                this.handleUploadsucces(res, file, duration);
            });
            return uploadData;
        } else {
            return null
        }

    }

    private handleUploadsucces = async (res: CompleteMultipartUploadCommandOutput, file: File, duration: number) => {
        const lectPlayData = store.get(currentLecturePlayingAtom)!;
        const currentScreen = store.get(currentScreenAtom);

        this.alterWavState(state => {
            state.isDirty = false;
        });

        const user = store.get(currentUser);
        lecturePlayerService.saveAudioFileData(user!.Id, lectPlayData.currentLecture.LectureId, res.Key!, 
            currentScreen!.ScreenId, duration, file);
    }

    public selectToTheRight(ctrlKey: boolean, shiftKey: boolean) {
        this.waves.forEach(w => w.selectToTheRight(ctrlKey, shiftKey));
    }

    public selectToTheLeft(ctrlKey: boolean, shiftKey: boolean) {
        this.waves.forEach(w => w.selectToTheLeft(ctrlKey, shiftKey));
    }

    public undo = () => {

        let canUndo = false, canRedo = false;

        this.waves.forEach(w => {
            w.undo();
            canUndo ||= w.canUndo();
            canRedo ||= w.canRedo();
        });

        this.alterWavState(state => {
            state.canRedo = canRedo;
            state.canUndo = canUndo;
        })
    }

    public redo = () => {

        let canUndo = false, canRedo = false;

        this.waves.forEach(w => {
            w.redo();
            canUndo ||= w.canUndo();
            canRedo ||= w.canRedo();
        });

        this.alterWavState(state => {
            state.canRedo = canRedo;
            state.canUndo = canUndo;
        });
    }

    public del = () => {
        this.waves.forEach(w => {
            w.del();
        });
        this.alterWavState(state => {
            state.canUndo = true;
            state.isDirty = true;
        });
    };

    public copy = () => {
        this.waves.forEach(w => {
            w.copy();
        });
    };

    public paste = () => {
        this.waves.forEach(w => {
            w.paste();
        });
        this.alterWavState(state => {
            state.isDirty = true;
            state.canUndo = true;
        });
    };

    public cut = () => {
        this.waves.forEach(w => {
            w.cut();
        });
        this.alterWavState(state => {
            state.isDirty = true;
            state.canUndo = true;
        });
    };

    public selectAll = () => {
        this.waves.forEach(w => {
            w.selectAll();
        });
    };

    public filterNormalize = () => {
        this.waves.forEach(w => {
            w.filterNormalize();

        });
        this.alterWavState(state => {
            state.isDirty = true;
            state.canUndo = true;
        });
    };

    public filterFadeIn = () => {
        this.waves.forEach(w => {
            w.filterFade(true);
        });
        this.alterWavState(state => {
            state.isDirty = true;
            state.canUndo = true;
        });
    };

    public filterFadeOut = () => {
        this.waves.forEach(w => {
            w.filterFade(false);

        });
        this.alterWavState(state => {
            state.isDirty = true;
            state.canUndo = true;
        });
    };

    public filterGain = (decibel: number) => {
        this.waves.forEach(w => {
            w.filterGain(decibel);

        });
        this.alterWavState(state => {
            state.isDirty = true;
            state.canUndo = true;
        });
    };

    public filterSilence = () => {
        this.waves.forEach(w => {
            w.filterSilence();

        });
        this.alterWavState(state => {
            state.isDirty = true;
            state.canUndo = true;
        });
    };

    public MergeToMono = () => {

        if (this.waves.length < 2)
            return;
        if (this.waves.find(w => !w.audioSequence)) return;

        var mono: Float32Array = new Float32Array(this.waves[0].audioSequence!.data.length);

        var sampleRate = this.waves[0].audioSequence!.sampleRate;

        for (var i = 0; i < this.waves[0].audioSequence!.data.length; i++) {
            mono[i] = (this.waves[0].audioSequence!.data[i] + this.waves[1].audioSequence!.data[i]) / 2;
        }

        this.waves.forEach(w => {
            w.setAudioSequence(undefined);
        });

        this.waves[1].destroy();
        delete (this.waves[1]);
        this.waves = [this.waves[0]]

        this.waves[0].setAudioSequence(new AudioSequence(sampleRate, mono));

        window.setTimeout(() => {
            this.waves[0].resizeCanvas();
        }, 10);

        this.alterWavState(state => {
            state.isDirty = true;
            state.hasStereo = false;
        });
    }

    public zoomIntoSelection = () => {
        var data = store.get(currentWaveDataAtom);
        if (!data.haveSelection)
            return;

        this.waves.forEach(w => {
            w.zoomIntoSelection();
        });
    };

    public zoomToFit = () => {

        this.waves.forEach(w => {
            w.zoomToFit();
        });
    };

    public insertAudio = (data?: AudioSequence[]) => {

        if (!data) {
            throw new Error("No data to insert");
        }
        if (data.length !== this.waves.length) {
            throw new Error("Data has not same # of channels");
        }

        this.waves.forEach((w, i) => w.insertAudio(data[i]));

        this.alterWavState(state => {
            state.isDirty = true;
            state.canUndo = true;
        });

        this.zoomToFit();
    };

    public insertSilence = (time: number) => {

        var rate = this.waves[0].getSampleRate();

        var data = new Float32Array(rate * time);
        var silence = new AudioSequence(rate, data);
        this.waves.forEach(w => {
            w.insertAudio(silence);
        });

        this.alterWavState(state => {
            state.isDirty = true;
            state.canUndo = true;
        });
    };

}

const screenEditService = new ScreenAudioEditService();
export default screenEditService;