import { getDefaultStore } from "jotai";
import api from "../../../services/api/axiosService";
import { produce } from "immer";
import { lecturesAtom } from "../LectureService";
import lecturePlayerService from "../Player/LecturePlayerService";
import { EditScreenDto } from "../domain/LectureState";

const store = getDefaultStore();

const endPoints = {

    REMOVE_SCREEN_SOUND: (lectureId: string, screenId: string) => `/author/coursepart/lecture/${lectureId}/${screenId}/RemoveSound`,
}

export class ScreenService {
    
    
    public SelectScreen(screenToSet: EditScreenDto, ctrlKey: boolean, shiftKey: boolean, forceThis?: boolean) {

        const lectureData = store.get(lecturesAtom);
        const newData = produce(lectureData, data => {


            const lecture = data[screenToSet.LectureId];
            if (!lecture) {
                return;
            }
            const screen = lecture.Screens.find(s => s.ScreenId === screenToSet.ScreenId);
            if (!screen) {
                return;
            }

            

            if (screen) {
                
                if( forceThis){
                    lecture.Screens.forEach(s => s.Selected = s.ScreenId === screen.ScreenId);
                    return;   
                }

                if( screen.Selected){
                    screen.Selected = false;
                    return;
                }

                if (ctrlKey) {
                    screen.Selected = true
                }
                else if (shiftKey) {
                    const screenIndex = screen.Order;
                    const selectedScreens = lecture.Screens.filter(s => s.Selected);
                    if (selectedScreens.length > 0) {
                        const minIndex = selectedScreens[0].Order;
                        const maxIndex = selectedScreens[selectedScreens.length-1].Order;
                        if( screenIndex < minIndex ){
                            for (let index = screenIndex; index <= minIndex; index++) {
                                lecture.Screens[index].Selected = true;

                            }
                        }
                        else{
                            if( screenIndex < maxIndex){
                                for (let index = minIndex; index <= screenIndex; index++) {
                                    lecture.Screens[index].Selected = true;
    
                                }
                            }
                            else{
                                for (let index = maxIndex; index <= screenIndex; index++) {
                                    lecture.Screens[index].Selected = true;
    
                                }
                            }
                        }
                    }
                }
                else{
                    lecture.Screens.forEach(s => s.Selected = s.ScreenId === screen.ScreenId);
                }
            }
        });
        store.set(lecturesAtom, newData);


    }

    public async RemoveSound(screenToChange: EditScreenDto) {

        await api.get<void>(endPoints.REMOVE_SCREEN_SOUND(screenToChange.LectureId, screenToChange.ScreenId));

        const lectureData = store.get(lecturesAtom);
        const newData = produce(lectureData, data => {
            var screen = data[screenToChange.LectureId].Screens.find(s => s.ScreenId === screenToChange.ScreenId);
            if (screen) {
                screen.Duration = 0;
                screen.GotSound = false;
                screen.RunningConversion = false;
            }
        });
        store.set(lecturesAtom, newData);
        lecturePlayerService.removeAudioFromScreen( screenToChange.LectureId, screenToChange.ScreenId );
    }

}

const screenService = new ScreenService();

export default screenService;