import classes from "./VerticalProgress.module.scss";

export type VerticalProgressProps = { className?: string, value: number, color?: string, min:number, max:number, children?:any };

export const VerticalProgress: React.FC<VerticalProgressProps> = (props: VerticalProgressProps) => {

const bgColor = props.color ? `bg-${props.color}` : 'bg-primary';

const percent =  Math.min( 100, Math.max(  (props.value) / (props.max- props.min) * 100, 0 ));

    return (

        <div className={`${classes.progress} ${props.className}`}>
            <div className={`${classes.progressBar} ${bgColor}`} style={ {height: `${percent}%`} }>
                {props.children}
            </div>
        </div>
    )
};
