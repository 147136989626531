import { ChangeEvent, useState } from "react";
import coursePartService from "../CoursePartService";

import { useI18n } from "src/utils/lni18n";
import LnIcon from "src/components/LnIcon";
import { CoursePartEditResponseModel } from "../domain/CoursePartEditResponseModel";

const CopyProtect = (props: { part: CoursePartEditResponseModel, disabled?: boolean }) => {


    const part = props.part;
    const partId = part.Id;
    const { languageService: t } = useI18n();
    const [explainCopyProof, setExplainCopyProof] = useState(false);

    function setCopyProtect(event: ChangeEvent<HTMLInputElement>): void {
        coursePartService.setCopyProtected(partId!, event.currentTarget.checked);
    }


    return (
        <>
            <div className="d-flex">
                <label className="pointer">
                    <h5 className="mr-3">{t.getText("copy.protected")}</h5>

                    <input type="checkbox" disabled={props.disabled} checked={part!.CopyProtected} onChange={setCopyProtect} />&nbsp;&nbsp;
                </label>
                <button title={t.getText("explaination")} onClick={() => setExplainCopyProof(e => !e)} className="mt-1 ml-3 btn btn-link">
                    <LnIcon name="questionmark-icon" className="icon-small" />
                </button>
            </div>

            {explainCopyProof &&
                <div className="alert alert-grey small preserve-white">
                    {t.getText("copy.protected.explain")}
                </div>
            }
        </>


    )
}

export default CopyProtect;
