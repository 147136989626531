import { useI18n } from "../../utils/lni18n";
import Singlequestion from "./QAedit/SingleQuestion";
import { BaseTestDataDTO } from "./DomainModels";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TestType } from "../domain/TestTypeInfoDTO";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import HtmlTextEditor from "src/components/TextEditor/HtmlTextEditor";
import testService from "./TestService";
import { ErrorMessage } from "@hookform/error-message";
import MultipleQuestion from "./QAedit/MultipleQuestion";
import FileTest from "./FileTest/FileTest";
import SelfAssessement from "./QAedit/SelfAssessement";
import SimpleSelfAssessement from "./QAedit/SimpleSelfAssessement";
import MCTest from "./MCTest/MCTest";
import DrawingTest from "./DrawingTest/DrawingTest";
import { useLocation } from "react-router-dom";

const EditTestForm = (props: { data: BaseTestDataDTO, onNameChange: (name: string) => void }) => {

  const { languageService: t } = useI18n();
  const { data, onNameChange } = props;
  const { state } = useLocation();


  const [thisTest, setThisTest] = useState(data);

  let editor: any | undefined = undefined;

  const validationSchema = React.useMemo(() => Yup.object({
    Name: Yup.string().required(t.getText('required_field')),
  }), [t]);

  const { control, watch, register, formState: { errors }, setValue } = useForm({
    defaultValues: thisTest,
    resolver: yupResolver(validationSchema),
    mode: "onChange"
  });


  useEffect(() => {
    setValue("Name", data.Name);
  }, [data.Name])



  const setData = useCallback((value: any) => {

    const newData: BaseTestDataDTO = { ...thisTest, ...value }
    setThisTest(newData);
    testService.testChanged(newData);
    onNameChange(value.Name)

  }, [thisTest]);

  React.useEffect(() => {
    const subscription = watch((value) => {
        setData(value)
    })
    return () => {
      subscription.unsubscribe();
    }
  }, [watch])


  switch (data.Type) {

    case TestType.drawingtest:
      editor = <DrawingTest data={data} onChange={(d => setData({ TestData: d }))} />
      break;

    case TestType.multiplechoice:
    case TestType.multiplechoice2:
      editor = <MCTest data={data} onChange={(d => setData({ TestData: d }))} />
      break;

    case TestType.multiplequestion:
      editor = <MultipleQuestion data={data} onChange={(d => setData({ TestData: d }))} />
      break;

    case TestType.selfassessement:
      editor = <SelfAssessement data={data} onChange={(d => setData({ TestData: d }))} />
      break;

    case TestType.simpleselfassessement:
      editor = <SimpleSelfAssessement data={data} onChange={(d => setData({ TestData: d }))} />
      break;

    case TestType.fileupload:
      editor = <FileTest data={data} onChange={(d => setData({ TestData: d }))} />
      break;

    case TestType.singlequestion:
    case TestType.simplyreply:
      editor = <Controller
        control={control}
        name="TestData"

        render={({ field: { onChange, value } }) => (
          <Singlequestion data={data} value={value} onChange={onChange} />
        )}
      />

      break;
  }

  return (
    <>
      <div className="mb-5">
        <div className="row">
          <div className="col-lg-6">
            <span className="font-weight-bold">
              {t.getText("name")}
            </span>
            <ErrorMessage errors={errors} name="Name" render={({ message }) => <span className="ml-4 text-danger small font-weight-bold">{message}</span>} />
            <input disabled={state.readOnly} {...register("Name")} className="form-control" type="text" />

          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mb-3 mb-lg-0 mt-5 d-flex flex-col" >
            <div className="mt-auto">
              <label className="font-weight-bold preserve-white">
                {t.getText("extra.instructions.to.students")}
              </label>
              <Controller
                control={control}
                name="Instructions"

                render={({ field: { onChange, value } }) => (
                  <HtmlTextEditor readOnly={state.readOnly} onChange={onChange} value={value} initialValue={data.Instructions} name="Instructions"  ></HtmlTextEditor>
                )}
              />
            </div>

          </div>
          <div className="col-lg-6 mt-5 d-flex flex-col">
            <div className="mt-auto">
              <label className="font-weight-bold preserve-white">
                {t.getText("guidelines")}
              </label>
              <Controller
                control={control}
                name="Meta"

                render={({ field: { onChange, value } }) => (
                  <HtmlTextEditor readOnly={state.readOnly} onChange={onChange} value={value} initialValue={data.Meta} name="Meta"  ></HtmlTextEditor>
                )}
              />
            </div>
          </div>

        </div>
      </div>
      {editor}
    </>
  )

}

export default EditTestForm;

