import classes from "./ImagePicker.module.scss";
import { ImagePickerImageDto } from "./ImagePicker_types";
import { API_HOST } from "../../utils/constants";


const GET_IMAGE_THUMB_URL = (imageid:string) => `${API_HOST}/image/get/${imageid}/thumbnail`;

const ImageList = ( props: {images?: ImagePickerImageDto[], onClick:Function}) => {

  const {images, onClick } = props;

    return  <div className={ classes.imgList} >
    {images && images.map(i => {
      return <div key={i.ImageId} onClick={()=>onClick( i.ImageId )} className={"float-left  m-2 pointer " + classes.thumbBox} id={"img" + i.ImageId}>
        <img src={GET_IMAGE_THUMB_URL(i.ImageId)} alt="" />
      </div>
    })}
  </div>
}

export default ImageList;
