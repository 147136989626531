//@ts-check
import { useCallback, useEffect, useMemo } from "react";

import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';

import { useState } from "react";
import Preview from "./Preview";
import classes from "./ImagePicker.module.scss";
import { useI18n } from "../../utils/lni18n";
import { ImagePickerService, imageAtom } from "./ImagePickerService";
import { useAtom } from "jotai";

let timerId: any = null;

const ImageExternUrl = (props: { onSubmit: Function, imagePickerService: ImagePickerService }) => {

    const imagePickerService = props.imagePickerService;
    const [imgUrl, setImgUrl] = useState<string | null>(null);
    const [imageAtomData] = useAtom(imageAtom);
    const imageAlign = imageAtomData.imageAlign
    const { languageService: t } = useI18n();

    const onSubmit = props.onSubmit;

    const isUrlOrEmpty = (url: any) => {

        const u = new URL(url);
        return !!u 
    };

    const validationSchema = useMemo(() => Yup.object({
        url: Yup.string()
            .test(
                'is_url',
                t.getText('not.a.valid.url'),
                isUrlOrEmpty,
            )
    }), [t]);



    const { register, watch, formState: { errors }, formState } = useForm({
        defaultValues: { url: "" },
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });



    const handleInsert = useCallback(() => {
        onSubmit({ imageAlign: imageAlign, selectedUrl: imgUrl });
    }, [imageAlign, imgUrl, onSubmit]);


    const watchUrl = watch('url');

    const handleUrl = useCallback((url: string) => {

        /**
         * @type {HTMLImageElement}
         */
        var image = new Image();
        image.onload = () => {
            setImgUrl(old => url);
        }

        try {
            image.src = url;
        } catch (e) {
            setImgUrl(null);
        }
    }, [setImgUrl]);

    useEffect(() => {

        if (timerId != null) {
            window.clearTimeout(timerId);
        }

        timerId = window.setTimeout(() => {
            timerId = null;
            if (formState.isValid) {
                if (watchUrl !== imgUrl) {
                    handleUrl(watchUrl);
                }
            }
            else {
                setImgUrl(null);
            }
        }, 1000);



    }, [watchUrl, formState, imgUrl, handleUrl]);


    return <div className={classes.scrollPanel + " row"}>

        <div className="col-lg-6">
            <div className="mb-3">{t.getText("enter.image.url")}</div>
            <input {...register("url")} type="url" className="form-control" name="url" placeholder="http://www.exempel.com/img.jpg" ></input>
            <ErrorMessage as="div" errors={errors} name="url" className="ml-3 mt-2" />

            <div>
                {imgUrl && <Preview imagePickerService={imagePickerService} className="mt-4" onSubmit={() => handleInsert()} src={imgUrl} />}
            </div>
        </div>
    </div>
}

export default ImageExternUrl;
