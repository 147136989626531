import { useAtom } from "jotai";
import { backGroundUploads } from "../FileUpload/FileUploadService";
import classes from "./FileUploadPanel.module.scss";
import { Alert, Card, CardBody, CardHeader, CardTitle, Progress } from "reactstrap";

const FileUploadPanel = () => {

    const [state] = useAtom(backGroundUploads);

    if (state.length < 1) {
        return null;
    }

    return (
        <div className={classes.fileUploadPanel} >
            {state.map(b => (

                <Card key={b.id} className={"mb-3 border" + (b.errorMess ? " border-danger" : "")} >
                    <CardHeader>
                        <CardTitle>
                        { b.killer && <button onClick={()=>b.killer!()} type="button" className="close round"></button> }
                            {b.backGroundMessage}
                            
                            { b.errorMess && <Alert className="mt-3" color="danger">{b.errorMess.toString()}</Alert>} 
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Progress value={b.percentUploaded} max="100" />
                    </CardBody>
                </Card>
            ))}
        </div>
    )


}

export default FileUploadPanel;
