import { useEffect, useRef } from "react";
import { CanvasDraw } from "./CanvasDraw";
import { useI18n } from "src/utils/lni18n";
import LnIcon from "src/components/LnIcon";
import { useAtom } from "jotai";
import { DrawingTestCanvasParams } from "./DrawingTestCanvas";



const ReadOnlyDrawingTestCanvas = (props: DrawingTestCanvasParams) => {

    const { fileUrl, state, children, canvasService } = props

    const [model] = useAtom(canvasService.drawingStateAtom);

    let canvas = useRef<HTMLCanvasElement | null>(null);
    let canvasWrap = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        if (model?.CurrentColor) {
            const drawModel = canvasService.getModel();
            const context2 = canvas.current!.getContext("2d");
            if (context2) {
                CanvasDraw(context2, canvas.current!.width, canvas.current!.height, drawModel, false, model.CurrentColor);
            }
        }

    }, [model.CurrentColor])




    useEffect(() => {
        if (canvas.current && canvasWrap.current) {
            const img = new Image();
            img.onload = () => {
                canvas.current!.width = img.width;
                canvas.current!.height = img.height;
                canvasWrap.current!.style.background = 'url(' + fileUrl + ') no-repeat';
                const model = canvasService.getModel();
                if (model.Points && model.Points.length > 0) {
                    const context2 = canvas.current!.getContext("2d");
                    CanvasDraw(context2!, canvas.current!.width, canvas.current!.height, model, false, model.CurrentColor);
                }
            }
            img.src = fileUrl;
        }
    }, [fileUrl, canvas.current, canvasWrap.current]);




    return (
        <div className="mb-5">
            <div className="mt-3">
                <div ref={canvasWrap} className="position-relative">
                    <canvas tabIndex={-1} ref={canvas} />
                    {model.Closed && <LnIcon className="done-icon-left-top natural" name="checked" />}
                </div>
                
                {children}
            </div>
        </div>)
}


export default ReadOnlyDrawingTestCanvas;

