
import { useEffect } from 'react';
import { useI18n } from '../utils/lni18n';
import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { Outlet, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import WaitRipple from 'src/components/WaitRipple';
import classes from "./CoursePart.module.scss";
import LnIcon from 'src/components/LnIcon';
import courseService, { oneCourseatom } from './CourseService';
import LockedReason from 'src/components/LockedReason';
import { LockReason } from 'src/types';

export default function CourseComponent() {

  const { courseId } = useParams();
  const [course] = useAtom(
    useMemo(() => oneCourseatom(courseId || ""), [courseId]));

  const { languageService: t } = useI18n();


  if (!course) {
    return <WaitRipple />
  }

  // if (course.Error) {
  //   return <CoursePartLoadError />
  // }

  const unlock = ()=>{
    courseService.loadCourse(course.Course.Id, true);
  }

  const setActive = ({ isActive, isPending }: { isActive: boolean, isPending: boolean }) => isActive ? "active" : "";
  let openAgainButton = null;
  if (course.Locked && course.Locked!.Reason === LockReason.LockedBySelf) {
    openAgainButton = <button className='mt-3 ml-3 btn btn-warning btn-small' onClick={unlock} >{t.getText("yes")}</button>;
  }

  return <>

    <div className={"scrollContent scroller "} aria-live="polite">
      <div className="card-box big-card-box">
        <div className="card-box-title card-title ">
          <div className='max-col-width w-100 d-flex align-items-start'>
            <h3>
              {course?.Course.Name}
              {course?.Locked && <LnIcon className='ml-3' name="locked" />}

            </h3>
            <div className='ml-auto'>
              <LockedReason info={course!.Locked!} >{openAgainButton}</LockedReason>
            </div>
          </div>
        </div>
        <div className="card-box-content card-body">

          <main className="">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  </>



}