import Dexie from "dexie";
import { ScrapLecture, ScrapScreen } from "./domain";

export class ScrapBookDb extends Dexie {
    screens!: Dexie.Table<ScrapScreen, number>;
    lectures!: Dexie.Table<ScrapLecture, number>;
    constructor (name:string) {
        super(name);
        this.version(1).stores({
            screens: '++id, screenId',
            lectures: '++id, lectureId'
        });
    }
}
