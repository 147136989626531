import classes from './Screen.module.scss';
import { CuePointForumData, ScreenCuePointDTO } from "../domain/LectureState";
import LnIcon from 'src/components/LnIcon';
import Draggable, { DraggableEvent } from 'react-draggable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import lecturePlayerService from '../Player/LecturePlayerService';
import { useI18n } from 'src/utils/lni18n';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import CuePointForumDialog from './CuePointForumDialog';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from 'src/components/Modalservice/ConfirmationDialog';
import CuePointTestDialog from './CuePointTestDialog';

const CuePoint = (props: ScreenCuePointDTO) => {

  const dialogPortal = useDialog();
  const { languageService: t } = useI18n();
  const [startX, setstartX] = useState(0);
  const [viewInfo, setviewInfo] = useState(false);
  const [position, setposition] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);


  const cuepoint = useRef<HTMLDivElement | null>(null);

  const style = useMemo(() => {
    if (!cuepoint.current) return { left: `${props.percentPosition}%` };

    return { left: `calc(${props.percentPosition}% - ${cuepoint.current.offsetWidth * props.percentPosition / 100}px` }
  }, [cuepoint.current]);

  const editCuepoint = () => {
    if (dropdownOpen) {
      closeMenu();
      return;
    }
    setDropdownOpen(true);
    window.setTimeout(() => document.addEventListener("click", closeMenu), 10);
  };

  const closeMenu = useCallback(() => {
    setDropdownOpen(false);
    document.removeEventListener("click", closeMenu);
  }, []);

  useEffect(() => {
    return () => closeMenu();
  }, []);

  const onStart = useCallback((e: DraggableEvent) => {
    if (dropdownOpen) {
      return false;
    }

    setstartX((e as React.MouseEvent<HTMLDivElement> ).clientX);
    e.preventDefault();
    e.stopPropagation();
  }, [dropdownOpen]);

  const onDrag = (e: DraggableEvent) => {
    setviewInfo(true);

    if (cuepoint.current) {
      const bounds = cuepoint.current.getBoundingClientRect();
      const parentBounds = cuepoint.current.parentElement!.getBoundingClientRect();
      const diff = bounds.x - parentBounds.x;
      var p = Math.min(100, Math.max(0, 100 * diff / (parentBounds.width - bounds.width)));
      setposition(lecturePlayerService.timeFromPercent(p));
    }

  }
  const onEnd = useCallback((e: DraggableEvent) => {

    setviewInfo(false);

    if (dropdownOpen) {
      closeMenu();
      return;
    }

    const clientX = (e as React.MouseEvent<HTMLDivElement> ).clientX;
    if (Math.abs(clientX - startX) < 3) {
      editCuepoint();
      return;
    }
    if (cuepoint.current) {
      const bounds = cuepoint.current.getBoundingClientRect();
      const parentBounds = cuepoint.current.parentElement!.getBoundingClientRect();
      const diff = bounds.x - parentBounds.x;
      var p = Math.min(100, Math.max(0, 100 * diff / (parentBounds.width - bounds.width)));
      setstartX(0);
      lecturePlayerService.setCuePointPosition(p, props);
    }


  }, [startX, dropdownOpen]);

  const handleForum = () => {
    var p = dialogPortal({
      factory: (onSubmit, onCancel) => {

        return <CuePointForumDialog forumData={props.forum} onCancel={onCancel} onSubmit={onSubmit} />
      }
    });

    p.then((data: CuePointForumData) => {

      lecturePlayerService.updateCuePointForum(props, data);

    }).catch(() => { });
  }

  const handleTest = () => {
    var p = dialogPortal({
      factory: (onSubmit, onCancel) => {

        return <CuePointTestDialog testId={props.test} onCancel={onCancel} onSubmit={onSubmit} />
      },
      size: "xl"
    });

    p.then((testId: string) => {

      lecturePlayerService.updateCuePointTest(props, testId);

    }).catch(() => { });
  }

  const deleteCue = () => {

    let prompt = t.getText("really.sure");
    if( !!props.test ){
      prompt = `${prompt} \r\n(${t.getText("test.will.not.be.deleted")})`
    }

    var p = dialogPortal({
      factory: (onSubmit, onCancel) => {
        const dprops: ConfirmationOptions = {
          className: "",
          title: t.getText("delete"),
          message: <div className="preserve-white" >{prompt}</div>,
          languageService: t,
          show: true,
          onClose: onCancel,
          onSubmit: onSubmit,
          buttons: ConfirmationButtons.YesNo
        }
        return <ConfirmationDialog {...dprops} />
      },
      size: "md"
    });

    p.then((res: boolean) => {
      if (res) {
        lecturePlayerService.deleteCuePoint(props);
      }
    })



  }

  return <Draggable nodeRef={cuepoint} axis='x' bounds="parent" onDrag={onDrag} onStart={onStart} onStop={onEnd}>
    <div ref={cuepoint} key={props.CuePointID} id={props.id.toString()}
      style={style}
      className={classes.cuepoint} >
      <div className={classes.cuePointCircle}>&nbsp;</div>

      <LnIcon name="position" className="filter-primary" />
      {viewInfo && <div className={`text-center ${classes.cuepointInfo}`} >
        <small>{position}</small>
      </div>}
      {dropdownOpen &&
        <div className={`dropdown dropup show ${classes.cueMenu}`} >
          <div tabIndex={0} role="menu" aria-hidden="false" className="dropdown-menu show" data-popper-placement="top-start" >
            <button onClick={handleTest} disabled={!!props.forum} type="button" tabIndex={0} role="menuitem" className="dropdown-item">{t.getText("test")}</button>
            <button onClick={handleForum} disabled={!!props.test} type="button" tabIndex={0} role="menuitem" className="dropdown-item">{t.getText("forum")}</button>
            <div tabIndex={-1} className="dropdown-divider"></div>
            <button onClick={deleteCue} type="button" tabIndex={0} role="menuitem" className="dropdown-item">{t.getText("delete")}</button>
          </div>
        </div>
      }
    </div>
  </Draggable>

}


export default CuePoint;