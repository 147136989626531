import { useI18n } from 'src/utils/lni18n';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import OriginalFilesForm from './OriginalFilesForm';


const OriginalFilesDialog = (props: { partId: string, lectureId: string, onSubmit: (d: any) => void, onCancel: () => void }) => {

    const { languageService: t } = useI18n();
    const { onSubmit, lectureId, partId } = props;

    return <>

        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("lecture.edit.files")}</ModalHeader>
        </span>

        <ModalBody>
            <OriginalFilesForm partId={partId} lectureId={lectureId} />
        </ModalBody>

        <ModalFooter>
            <button type="submit" onClick={onSubmit} className='btn btn-primary btn-small' >{t.getText("close")}</button>

        </ModalFooter>


    </>

}

export default OriginalFilesDialog;