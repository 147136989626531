import api from "../services/api/axiosService";
import { atom, getDefaultStore } from "jotai";
import { produce } from "immer";
import { IdAndNameDto } from "src/types";
import { API_URL } from "src/utils/constants";
import { GetCoursesResponse } from "./domain/GetCoursesResponse";

const store = getDefaultStore();

const initialState: GetCoursesResponse = {Courses: []};

export const coursesAtom = atom<GetCoursesResponse>(initialState);
coursesAtom.debugLabel = "coursesAtom";

const endPoints = {
    GET_COURSES: `/author/courses`,
    GET_LAST_COURSES: (limit: number)=> `/author/courses/${limit}/getlast`,
}

export class CourseCollectionService {

    public removeCourse( courseId: string ){

        const state = store.get(coursesAtom);
        const newState = produce( state, draft => {
            draft.Courses = draft.Courses.filter(p => p.Id !== courseId);
        } );

        store.set(coursesAtom, newState);
    }

    public addCourse( course: IdAndNameDto ){
        const state = store.get(coursesAtom);

        const newState = produce( state, draft => {
            const newCourse = { ...course, LastEdit: (new Date()).toUTCString() };
            draft.Courses =  [ newCourse, ...draft.Courses];
        } );

        store.set(coursesAtom, newState);
    }
   
    public async getCourses(force?: boolean) {
        if (!force) {
            const state = store.get(coursesAtom);
            if (state.Courses.length > 0 ) {
                return state;
            }
        }

        try {
            store.set(coursesAtom, {...initialState, Loading: true});
            const response = await api.get<IdAndNameDto[]>(endPoints.GET_COURSES)
            if (response && response.data) {
                const data:GetCoursesResponse = { Courses : response.data, Loading: false};
                store.set(coursesAtom, data);
            }

            return response;
        } catch (error:any) {
            if( error.handled){
                store.set(coursesAtom, initialState);
                return {};
            }
            throw error.response;
        }
    }

    public clearCollection(){
        store.set(coursesAtom, initialState);
    }


}
const courseCollectionService = new CourseCollectionService();
export default courseCollectionService;