import { useEffect, useMemo } from "react";

import { useState } from "react";
import classes from "../../Test/tests.module.scss"

import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { onePartatom } from "src/coursepart/CoursePartService";
import { useI18n } from "src/utils/lni18n";
import { TestLinkDTO } from "src/coursepart/domain/TestLinkDto";
import { LnCollapseGroup } from "src/components/LnCard/LnCollapseGroup";
import TestLinkBody from "src/coursepart/Test/TestLinkBody";
const ReadOnlyTests = () => {

    const { partId } = useParams();
    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);

    const part = partData.Data;
    const { languageService: t } = useI18n();

    const [connectedTests, setConnectedTests] = useState<TestLinkDTO[] | undefined>(undefined);
    useEffect(() => {
        setConnectedTests(part?.Tests.filter(t => !!t.ConnectedLecture).map(t => {
            return { ...t }
        }));
    }, [part!.Tests]);

    const [nonConnectedTests, setNonConnectedTests] = useState<TestLinkDTO[] | undefined>(undefined);

    useEffect(() => {
        setNonConnectedTests(part?.Tests.filter(t => !t.ConnectedLecture).map(t => {
            return { ...t }
        }));
    }, [part!.Tests]);

    if (!part) {
        return null;
    }


    return <>

        <div className="d-flex mb-5 ">

            <h3 className="flex-fill">{t.getText("tests")}</h3>
        </div>

        <div className="max-col-width">

            {connectedTests && connectedTests.length > 0 &&
                <div className="mb-4">
                    <LnCollapseGroup groupName={t.getText("tests.for.study.in.lecture")} isOpen={false} >
                        {connectedTests.map((test) => (
                            <div key={test.Id} className={[classes['test-listing'], 'pl-3 py-3 my-3'].join(' ')} >
                                <TestLinkBody readOnly={true} test={test} />
                            </div>))}
                    </LnCollapseGroup>
                </div>
            }


            <div className="mt-3">

                {nonConnectedTests &&
                    <div className="flex-column">
                        {nonConnectedTests.map(item =>
                            <div key={item.Id} className={[classes['test-listing'], 'pl-3 py-3 my-3'].join(' ')} >
                                <TestLinkBody readOnly={true} test={item} />
                            </div>
                        )}
                    </div>

                }
            </div>

        </div>
    </>

}

export default ReadOnlyTests;
