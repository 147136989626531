import { useCallback } from "react";
import classes from "./ImagePicker.module.scss";
import ImagePermissions from "./ImagePermissions";
import ImageInfo from "./ImageInfo";
import Preview from "./Preview";
import { useAtom } from "jotai";
import { ImagePickerService, imageAtom } from "./ImagePickerService";
import { currentUser } from "../../services/user/userService";
import { useI18n } from "../../utils/lni18n";
import { subtabstate } from "./ImagePicker_types";
import { imageEndPoints } from "./ImagePicker";


const SelectedImage = ( props: { onSubmit:Function, imagePickerService: ImagePickerService }) => {

    const imagePickerService = props.imagePickerService;
    const {onSubmit} = props;
    const [state] = useAtom( imageAtom );
    const {  selectedImage, currentImage, subTab, imageAlign } = state;
    const [user]  = useAtom(currentUser);
    const { languageService: t } = useI18n();

    const onSubmitUrl = useCallback(()=>{
        onSubmit({ imageAlign: imageAlign, selectedImage: selectedImage?.ImageId});
    }, [ imageAlign, selectedImage, onSubmit ]);


    if (!selectedImage) {
        return null;
    }


    const setSubTab = (tab:subtabstate) => {
        imagePickerService.setSubTab(tab);
    }

    return <div className={classes.scrollPanel}>
        
        <ul className="nav nav-tabs col-12 col-lg-8 mx-auto">
            <li className="nav-item">
            
                <button  className={"nav-link" + (subTab === 'preview' ? " active" : "")} onClick={() => setSubTab('preview')} >{t.getText("preview")}</button>

            </li>
            {user?.Id === selectedImage.User.Id && currentImage && <li className="nav-item">
                <button className={"nav-link" + (subTab === 'information' ? " active" : "")} onClick={() => setSubTab('information')} >{t.getText("information")}</button>
            </li>
            }

            {user?.Id === selectedImage.User.Id && <li className="nav-item">
                <button className={"nav-link" + (subTab === 'permissions' ? " active" : "")} onClick={() => setSubTab('permissions')} >{t.getText("permissions")}</button>
            </li>
            }


        </ul>

        <div className="col-12 col-lg-8 mx-auto mt-4">


            {subTab === 'preview' && <Preview imagePickerService={imagePickerService} src={imageEndPoints.GET_IMAGE_URL(selectedImage.ImageId)} onSubmit={onSubmitUrl} /> }

            {subTab === 'information' && <ImageInfo imagePickerService={imagePickerService} />}

            {subTab === 'permissions' && <ImagePermissions imagePickerService={imagePickerService}  />}
        </div>
    </div>
}

export default SelectedImage;
