import { useI18n } from '../../utils/lni18n';
import { useCallback, useEffect, useState } from 'react';
import { useAtom } from "jotai";
import testService, { testDataAtom } from "./TestService";

export type CreateTestData = { name?: string, type?: number, lastInList: boolean };

const AddTestForm = (props: { onChange: (data: CreateTestData) => void }) => {

    const { languageService: t } = useI18n();
    const [dataForCreation, setDataForCreation] = useState<CreateTestData| undefined>(undefined);
    const [testData] = useAtom(testDataAtom);
    const types = testData.testTypes;
    

    const { onChange } = props;

    const setData = useCallback((type: "name" | "type" | "lastInList", value: any) => {

        let data: any = { ...dataForCreation };
        data[type] = value;

        if( type === "lastInList" && testData.addtestLastInList !== value){
            testService.setAddLastInList(value);
        }

        setDataForCreation(data);

        onChange(data);

    }, [dataForCreation, testData.addtestLastInList]);

    useEffect(()=>{
        setDataForCreation(data => ({...dataForCreation, lastInList: testData.addtestLastInList }));
    }, [])

    return <>

        <div className="px-3">
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group mt-4">
                        <label>{t.getText("name")}</label>
                        <input type="text" value={dataForCreation?.name} onChange={(e) => setData("name", e.currentTarget.value)} className="form-control" />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group mt-4">
                        <label>{t.getText("add.to.list.as")}</label>

                        <div>
                            <label className="mr-5">
                                <input type="radio" checked={!dataForCreation?.lastInList} onChange={() => setData("lastInList", false)} className="mr-2" />
                                {t.getText("first")}
                            </label>
                            <label >
                                <input type="radio" checked={dataForCreation?.lastInList} onChange={() => setData("lastInList", true)} className="mr-2" />
                                {t.getText("last")}
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        {types.map(info => <div key={info.TestType} className={"mb-1 p-3 " + (dataForCreation?.type === info.TestType ? "bg-primary-lightest" : "")}>
            <label className="pointer d-flex align-items-start" >
                <input className='mt-2' type="radio" checked={dataForCreation?.type === info.TestType} onChange={() => setData("type", info.TestType)} value={info.TestType} name="pickedtesttype" />
                <div className="ml-3">
                    <strong >
                        {info.Name}
                    </strong>
                    <div className="preserve-white">
                        {info.Description}
                    </div>
                </div>
            </label>
        </div>
        )}


    </>
}

export default AddTestForm;
