import { CardBody } from "reactstrap";
import classes from "./links.module.scss";
import { useSortable } from "@dnd-kit/sortable";
import { DragHandle } from "../../components/DragHandle/DragHandle";
import { LinkModel } from "../domain/LinksGroupModel";
import { CSS } from '@dnd-kit/utilities';
import { UniqueIdentifier } from "@dnd-kit/core";
import LnIcon from "src/components/LnIcon";

export function Link(props: { deleteLink: (id: string) => void, edit: (data: LinkModel) => void, link: LinkModel, dragged?: boolean, dummy?: boolean, active?: UniqueIdentifier }) {

  const { link, dragged, dummy, active, edit, deleteLink } = props

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: link.Id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: dragged ? "75%" : ""
  };

  const editLink = () => {
    edit(link);
  }

  const deleteThisLink = () => {
    deleteLink(link.Id);
  }

  return <>

    {!dummy &&
      <div ref={setNodeRef} id={"c_" + link.Id} key={link.Id}
        className={"row align-items-center py-2 mx-4 border-top " + (active === link.Id ? " " + classes.active : "")}
        style={style}>

        <div className="col-4">
          <div className={classes.linkInfo + " preserve-white"} dangerouslySetInnerHTML={{ __html: link.Description }}></div>
        </div>
        <div className="col-4">
          <a target="_blank" rel="noopener noreferrer" className="" href={link.Url}>
            {link.Url.split('://').pop()}
          </a>
        </div>
        <div className="col-1">

          <button className="btn btn-link ml-3" type="button" onClick={editLink}>
            <LnIcon name="edit" className='icon-small' />
          </button>
        </div>
        <div className="col-1">

          <button className="btn btn-link ml-3" type="button" onClick={deleteThisLink}>
            <LnIcon name="delete" className='icon-small' />
          </button>
        </div>

        <div className="col-1">
          <div className={classes.dragHandle} {...attributes} {...listeners}><DragHandle /></div>
        </div>

      </div>
    }

    {dummy &&
      <div ref={setNodeRef} id={"c_" + link.Id} key={link.Id} className={classes.dummyCard} style={style} >
      </div>
    }
  </>


}