
import { CardBody, CardFooter, CardTitle } from "reactstrap";
import { LectureDtoWithOriginals } from "../../domain/LectureDtoWithOriginals";
import classes from '../../Lecture/lecture.module.scss';
import coursePartService from "../../CoursePartService";
import { Link } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import LnIcon from "src/components/LnIcon";
import { FormatTimeDuration } from "src/utils/DateTimeFormat";
import { LectureEntityStatus } from "src/coursepart/domain/LectureEntityStatus";


const ReadOnlyLectureLink = (props: {  onContextMenu: React.MouseEventHandler<HTMLElement>, partId: string, lecture: LectureDtoWithOriginals }) => {

    const { languageService: t } = useI18n();
    const { lecture: l, partId, onContextMenu } = props;


    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        coursePartService.selectLecture(partId, l, e.ctrlKey);

    }

    const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        coursePartService.selectLecture(partId, l, false);
        onContextMenu(e);

    }

    return (
        <div onContextMenu={handleContextMenu} onClick={handleClick} key={l.Id} role="listitem"
            className={[(l.Selected ? classes.selectedLecture : ""), classes.card, 'card pointer'].join(' ')} >

            <Link className="h-100" to={`${l.Id}`}>

                <CardBody className={`p-3 ${classes.cardBody}`}>
                    <CardTitle className={classes['card-title']}>
                        <div className={classes.nameDiv}>
                            {l.Name}
                        </div>

                    </CardTitle>
                    <div className={classes['img-wrapper']}>
                        <img className={classes['lecture-img']} src={l.ImageUrl} alt={l.Name} />
                    </div>



                </CardBody>

                <CardFooter className={classes['card-footer']}>
                    <span><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="slides" />
                        &nbsp;{l.NumOfScreens} <span className="d-none d-sm-inline">{t.getText('lecture.screens')}</span></span>
                    {
                        FormatTimeDuration(l.Duration, t) &&
                        <span className="ml-1"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDuration(l.Duration, t)}</span>
                    }
                </CardFooter>
            </Link>
        </div>
    );

}


export default ReadOnlyLectureLink;