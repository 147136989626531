import Dexie from "dexie";

export type OpenedEditData = {
    id?: number, itemid: string, name: string, dato: Date, userid: string
}
export class LastOpenedDB extends Dexie {
    courses!: Dexie.Table<OpenedEditData, number>;
    courseParts!: Dexie.Table<OpenedEditData, number>;
    constructor (name:string) {
        super(name);
        this.version(2).stores({
            courses: '++id, [itemid+userid]',
            courseParts: '++id, [itemid+userid]'
        });
    }
}

const lastDataDB = new LastOpenedDB("lastOpenedData");
export default lastDataDB;