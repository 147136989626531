import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useI18n } from '../../utils/lni18n';
import { useMemo } from "react";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { AddLectureData } from '../domain/AddLectureData';


const CreateLectureForm = (props: { header: string, oldValues?: { name: string, description: string }, onSubmit: (val: AddLectureData) => void, onCancel: () => void }) => {

    const { languageService: t } = useI18n();
    const { onSubmit, onCancel, header, oldValues } = props;


    const validationSchema = useMemo(() => Yup.object({
        name: Yup.string().required(t.getText('required_field')),
        description: Yup.string().required(t.getText('required_field'))
    }), [t]);

    const { handleSubmit, register, watch, formState: { errors, isValid, isDirty } } = useForm({
        defaultValues: oldValues || { name: "", description: "" },
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    const handleSubmitForm = (values: any) => {
        onSubmit(values);
    }

    const handleCancel = () => {
        onCancel();
    }


    return <form name="createForm" method="dialog" onSubmit={handleSubmit(handleSubmitForm)} >

        <span className="moveable" data-dialog-draggable>
            <ModalHeader>
                {header}
            </ModalHeader>
        </span>

        <ModalBody>

            <div className="form-group">
                <label>{t.getText("name")}</label>
                <input {...register("name")} required name="name" type='text' className='form-control' />

                <ErrorMessage as="div" errors={errors} name="name" className="ml-3 mt-2" />
            </div>

            <div className="form-group">
                <label>{t.getText("description")}</label>
                <textarea {...register("description")} required name="description" className='form-control' />
                <ErrorMessage as="div" errors={errors} name="description" className="ml-3 mt-2" />
            </div>

        </ModalBody>
        <ModalFooter >
            <button type='button' onClick={handleCancel} value="cancel" className='btn btn-inverse btn-small' >{t.getText("cancel")}</button>
            <button disabled={!isValid} value="submit" type="submit" className='btn btn-primary btn-small' >{t.getText("save.short")}</button>

        </ModalFooter>


    </form>

}

export default CreateLectureForm;