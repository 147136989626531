import {  useState } from "react";
import coursePartService from "../CoursePartService";

import { useI18n } from "src/utils/lni18n";
import LnIcon from "src/components/LnIcon";
import { CoursePartEditResponseModel } from "../domain/CoursePartEditResponseModel";

const   Categories = (props:{part: CoursePartEditResponseModel, disabled?: boolean}) => {

    
    const part = props.part;
    const partId = part.Id;
    const { languageService: t } = useI18n();
    const [explainCategories, setExplainCategories] = useState(false);

    function setCategory(Id: number, checked: boolean): void {
        coursePartService.setCategory(partId!, Id, checked)
    }

    return (
        <>

            <h5>{t.getText("coursepart.categories")}
                <button title={t.getText("explaination")} onClick={() => setExplainCategories(e => !e)} className="ml-3 btn btn-link">
                    <LnIcon name="questionmark-icon" className="icon-small" />
                </button>
            </h5>
            {part!.Categories.map(cat => <div key={cat.Id}>
                <label>
                    <input disabled={props.disabled} className="mr-3" type="checkbox" checked={cat.Checked} onChange={(e) => setCategory(cat.Id, e.currentTarget.checked)} />
                    {cat.Name}
                </label>
            </div>)}

            {explainCategories &&
                <div className="alert alert-grey small preserve-white">
                    {t.getText("coursepart.categories.description")}
                </div>
            }
        </>


    )
}

export default Categories;
