import * as React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LanguageService } from "../../utils/languageService";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";

export interface CopyDialogOptions {
    languageService: LanguageService;
    onSubmit: (d: CopyPartData) => void;
    onCancel: () => void;
    partName: string
}

export type CopyPartData = {
    name: string;
    type: boolean;

}

export const CopyDialog: React.FC<CopyDialogOptions> = ({
    onSubmit,
    onCancel,
    languageService,
    partName,
}) => {

    const t = languageService;

    const validationSchema = React.useMemo(() => Yup.object({
        type: Yup.boolean().required(t.getText('required_field')),
        name: Yup.string().required(t.getText('required_field')),
    }), [t]);

    const { handleSubmit, register, formState: { errors, isValid, } } = useForm({
        defaultValues: { type: "true", name: `${partName} - ${t.getText("a.copy")}` },
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    const handleSubmitForm = (values: any) => {
        onSubmit(values);
    }

    const handleCancel = () => {
        onCancel();
    }


    return <>
        <form className="mt-2 mb-5" onSubmit={handleSubmit(handleSubmitForm)}>
            <span className="moveable" data-dialog-draggable>
                <ModalHeader>{t.getText("copy")}</ModalHeader>
            </span>
            <ModalBody>


                {t.getText("copy.coursepart.explain")}


                <div className="font-weight-bold my-3">
                    {t.getText("name.new")}
                </div>

                <div className="input-group align-items-center">
                    <input {...register("name")} type="text" className="form-control" />
                </div>
                <ErrorMessage as="div" errors={errors} name="name" className="ml-3 mt-2 text-danger" />



                <div className="mt-4 font-weight-bold">{t.getText("version")}</div>

                <div className="input-group mt-3">
                    <label>
                        <input {...register("type")} type="radio" value="true" className="mr-3" />{t.getText("course.mode.publish")}
                    </label>
                </div>
                <div className="input-group mt-1">
                    <label>
                        <input {...register("type")} type="radio" value="false" className="mr-3" />{t.getText("in.edit")}
                    </label>
                </div>


            </ModalBody>
            <ModalFooter>
                <button disabled={!isValid} type="submit" className="btn btn-primary" >{t.getText("ok")}</button>&nbsp;
                <Button type="button" className="btn btn-warning" onClick={() => handleCancel()}>{t.getText("cancel")}</Button>
            </ModalFooter>
        </form>
    </>
};
