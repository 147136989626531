import { DropdownItem, DropdownMenu } from 'reactstrap';
import classes from './TopMenu.module.scss';
import LnDropDownMenu from "src/components/LnDropDownMenu";
import UserImg from "src/components/UserImg";
import { useI18n } from "src/utils/lni18n";
import { useCurrentUser, userEndPoints, userService } from "src/services/user/userService";
import { CurrentUserDto } from "src/services/user/types";
import { LanguageService } from "src/utils/languageService";
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from '../Modalservice/Dialogservice';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from '../Modalservice/ConfirmationDialog';
import LnIcon from '../LnIcon';
import { urlService } from 'src/services/NavService';


const UserMenu = () => {

    const { languageService: t } = useI18n();
    const navigate = useNavigate();
    const dialogPortal = useDialog();
    const { currentUser } = useCurrentUser();
    const userImage = currentUser && userEndPoints.GET_USER_IMAGE_URL(currentUser!.Id);


    async function logOut() {
        const logOutResult = await userService.logoutUser();

        if (logOutResult !== undefined) {

            dialogPortal({
                factory: (onSubmit, onCancel) => {
                    const dprops: ConfirmationOptions = {
                        className: "",
                        title: t.getText("logoff"),
                        message: <><LnIcon name="alert" /><div className="preserve-white" >{t.getText("open.courseparts.in.edit")}</div></>,
                        languageService: t,
                        show: true,
                        onClose: onCancel,
                        onSubmit: onSubmit,
                        buttons: ConfirmationButtons.YesNo
                    }
                    return <ConfirmationDialog {...dprops} />
                },
                size: "md"
            }).then((res: boolean) => {

                window.setTimeout(() => {
                    if (!res) {
                        userService.logoutUser(true);
                        navigate("/");
                    }
                    else {
                        if (logOutResult.course) {
                            navigate(urlService.urlToCourse(logOutResult.course, "publish"));
                            return;
                        }
                        if (logOutResult.parts) {
                            navigate(urlService.urlToPart(logOutResult.parts, "publish"));
                        }
                    }
                }, 50);


            });
        }
    }


    function getItems(user: CurrentUserDto, t: LanguageService) {
        return [
            {
                visible: user.UserState === 4,
                type: "cmd",
                text: t.getText("switch.user"),
                action: () => { navigate("/switchuser") }
            },
            {
                visible: true,
                type: "cmd",
                text: t.getText("print"),
                // delay to get the menu away before printing
                action: () => window.setTimeout(() => window.print(), 100)
            },
            {
                visible: true,
                type: "cmd",
                text: t.getText("menu.systeminfo"),
                action: () => { }
            },
            {
                visible: true,
                type: "separator"
            },
            {
                visible: true,
                type: "cmd",
                text: t.getText("logoff"),
                action: () => logOut()
            }
        ];

    }

    const items = getItems(currentUser!, t);

    let index = 0;
    const userMenuItems = items.filter(i => i.visible).map(i => {
        if (i.type === "cmd") {
            return (<DropdownItem key={index++} onClick={i.action} tag="div">
                <div className="px-4 pt-1 pb-2" >{i.text}</div>
            </DropdownItem>)

        } else {
            return (<DropdownItem key={index++} disabled tag="div">
                <hr />
            </DropdownItem>)
        }
    });


    return <div className={classes["user-info"]}>
        <LnDropDownMenu
            caret={false}
            toggleComponent={
                <>
                    <i className={classes["user-img"]}>
                        <UserImg src={userImage!} alt={t.getText('user')} />
                    </i>
                    <span className={[classes.caret, "white-caret"].join(" ")} />
                </>
            }
            toogleClassName="dropDownItem btn btn-minimal pointer"
            toogleTagName="div"
        >
            <DropdownMenu className="dropDownMenu" end container="body">
                {userMenuItems}
            </DropdownMenu>
        </LnDropDownMenu>
    </div>;
}




export default UserMenu;