import { TinyTheme } from "src/course/domain/CourseEditModel";
import classes from "../SideBar.module.scss";
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import LnIcon from "src/components/LnIcon";
import courseService from "src/course/CourseService";
import PartCard from "./PartCard";

import { useSortable, SortableContext } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import PartCardDummy from "./PartCardDummy";
import { urlService } from "src/services/NavService";
import { useNavigate } from "react-router-dom";
import React from "react";

export default function ThemeCard(props: { readOnly: boolean, dragged?: boolean, theme?: TinyTheme, themeId?: string, partId?: string, currentCourseId: string }) {

    const { theme, partId, themeId, currentCourseId, dragged, readOnly } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: theme!.Id });

    const navigate = useNavigate();
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: dragged ? "75%" : ""
    };

    function themeClick(themeId: any, e: React.MouseEvent<HTMLTableCellElement, MouseEvent>): void {

        if (e.detail === 2) {
            toggleClick(themeId);
        }
        navigate(urlService.urlToTheme(currentCourseId, themeId), { state: { readOnly } });
    }

    function toggleClick(themeId: any): void {
        courseService.toggleThemeOpen(currentCourseId!, themeId);
    }

    if (!theme) return null;

    return <div ref={setNodeRef} key={theme.Id} id={theme.Id} style={style}>
        <Card className={[classes.theme, "border border-primary"].join(' ')}>
            <CardHeader className={((!partId && theme.Id === themeId) ? classes.selected + " " : "") + classes.header + " pb-2 px-1 pt-0"} >

                <table >
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    {!readOnly &&
                                        <div className="ns-drag-cursor" {...attributes} {...listeners}>
                                            <LnIcon name="book" className="mr-2 mt-2" />
                                        </div>
                                    }
                                    {readOnly &&
                                        <LnIcon name="book" className="mr-2 mt-2" />
                                    }
                                </div>
                            </td>

                            <td onClick={(e) => themeClick(theme.Id, e)} className="pl-2 pointer">
                                    <div className={classes.themename + " word-break-word"} >{theme.Name}</div>
                            </td>

                            <td className={`${classes.indicator} pointer`} onClick={() => toggleClick(theme.Id)}>
                                <LnIcon name="right-arrow" className={theme.Open ? classes.down : ""} />
                            </td>

                        </tr>
                    </tbody>
                </table>


            </CardHeader>
            <Collapse isOpen={theme.Open}>
                <CardBody className={classes.parts}>
                    <SortableContext items={theme.Parts.map(l => l.Id)} id={theme.Id}>
                        {
                            theme.Parts.map((p) => (
                                <PartCard readOnly={readOnly} key={p.Id} part={p} partId={partId} theme={theme} currentCourseId={currentCourseId} />
                            ))
                        }
                        {(!theme.Parts || theme.Parts.length === 0) &&
                            <PartCardDummy partId={`dummypart_${theme.Id}`} />
                        }
                    </SortableContext>
                </CardBody>
            </Collapse>
        </Card >
    </div>

}