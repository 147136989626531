import coursePartService from "src/coursepart/CoursePartService";
import languageService from "src/utils/languageService";

class UnloadService{

    public setupOnUnload(){
        
        addEventListener("beforeunload",  (event) => {

            if( coursePartService.partsDirty() ){
                event.preventDefault()
            }
        });

        window.addEventListener("unload", (ev) => {
            coursePartService.closeAllParts();
        }) 
    }
  
  }
  
  export const unloadService = new UnloadService();
  
  