import { LnCollapseGroup } from "src/components/LnCard/LnCollapseGroup";
import { LinksGroupModel } from "src/coursepart/domain/LinksGroupModel";
import { ReadOnlyLink } from "./ReadOnlyLink";


export default function ReadOnlyLinkGroup(props: {
    group: LinksGroupModel, closed?: boolean,partId: string
}) {

    const { group, closed } = props;

const panel = <LnCollapseGroup groupName={group.Name} isOpen={!closed}>
  
            <div className="text-right mb-3">
            </div>

            <div>
                {group.Links.map((item) => (
                    <ReadOnlyLink key={item.Id} link={item} />
                ))}
            </div>
    </LnCollapseGroup>;

    return  <div id={"g_" + group.Id} role="listitem" key={group.Id} className="mb-4" >{panel}</div>
}