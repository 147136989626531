import { TinyCourse, } from 'src/course/domain/CourseEditModel';
import DirtyTheme from './DirtyTheme';

export default function DirtyPartsTree(props: { course: TinyCourse }) {

  return (
    <div className='mb-3'>

            { props.course.Themes.filter(t => t.Parts.find(p => p.Dirty))
              .map(t => <DirtyTheme  key={t.Id} courseId={props.course.Id} theme={t}  />)}

    </div>
  )
}
