import * as React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LanguageService } from "../../utils/languageService";

export interface ResetDialogOptions {
    languageService: LanguageService;
    onSubmit: (d: { includeLectures: boolean }) => void;
    onCancel: () => void;
}

export const ResetDialog: React.FC<ResetDialogOptions> = ({
    onSubmit,
    onCancel,
    languageService,
}) => {

    const t = languageService;
    const [includeLects, setIncludeLects] = React.useState(false);

    const doReset = React.useCallback(() => {
        onSubmit({ includeLectures: includeLects })
    }, [includeLects]);

    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("reset")}</ModalHeader>
        </span>
        <ModalBody>

            <div className="preserve-white">
                {t.getText("coursepart.reset.confirm")}
            </div>

            <hr />

            <div className="">
                <label>
                    <input className="mr-3" type="checkbox" checked={includeLects} onChange={(e) => setIncludeLects(e.currentTarget.checked)} />
                    {t.getText("coursepart.reset.include.lectures")}
                </label>
            </div>

        </ModalBody>
        <ModalFooter>
            <button type="button" className="btn btn-primary" onClick={doReset} >{t.getText("reset")}</button>&nbsp;
            <Button type="button" className="btn btn-warning" onClick={() => onCancel()}>{t.getText("cancel")}</Button>
        </ModalFooter>
    </>
};
