import { useI18n } from "../../utils/lni18n";
import LnIcon from "../LnIcon";
import { SetPermissionCallback } from "./PermissionsDialog";
import { ObjectPermissionRowDto } from "./PermissionTypes";

const PermissionRow = (props: {
    readOnly: boolean,
    onChange: SetPermissionCallback,
    subjectType: 'user' | 'group',
    setFlags: string[],
    permission: ObjectPermissionRowDto
}) => {

    const { onChange, subjectType, permission, setFlags,readOnly } = props;
    const { languageService: t } = useI18n();

    const iconName = subjectType === "group" ? "group" : "user-icon";

    let boxes = [];

    for (let i = 0; i < setFlags.length; i++) {
        const flag = setFlags[i];
        const p = permission.Permissions[flag];
        if (p) {
            boxes.push({ ...p, name: flag });
        }
    }


    return <tr >
        <td className="">
            <LnIcon  name={iconName} className="more ml-2" />
        </td>
        <td className="overflow-hidden" title={permission.Name}>
            {permission.Name}
        </td>
        <td className="d-flex flex-wrap">
            {
                boxes.map((p, index) => <div key={index} >

                    <div className="mb-4 ml-4 mt-1">
                        <label className="" htmlFor={"l" + permission.UserOrGroupId + p.name} >
                            <input disabled={readOnly || !p.Enabled} type="checkbox" className="mr-1"
                                onChange={(e) => onChange(e.currentTarget.checked, permission.UserOrGroupId, subjectType, p.name, false)} checked={p.Set} id={"l" + permission.UserOrGroupId + p.name} />
                            {t.getText(p.name)}
                        </label>
                    </div>
                </div>)
            }

            <div className="ml-4">
                <button disabled={readOnly} title={t.getText("remove")} className="p-2 btn btn-link" onClick={(e) => onChange(false, permission.UserOrGroupId, subjectType, 'read', true)}>
                    <LnIcon className="icon-small" name="delete" />
                </button>
            </div>
        </td>

    </tr>

}

export default PermissionRow;