import { useI18n } from "src/utils/lni18n";
import classes from "../Print.module.scss";
import { CoursePartPrintDTO } from "./domain";
import PrintPartLectures from "./PrintPartLectures";
import PrintPartTests from "./PrintPartTests";
import PrintPartFiles from "./PrintPartFiles";
import PrintPartLinks from "./PrintPartLinks";
import PrintPartTexts from "./PrintPartTexts";

const PrintPartComponent = (props: { printData: CoursePartPrintDTO }) => {

  const { printData } = props;
  const { languageService: t } = useI18n();

  if (!printData) return null;

  return (

    <div className={classes.print}>

      <h3 className="my-3">{t.getText("owner")}</h3>
      {printData.Authors.map(a => <div key={a.Id}>{a.FirstName} {a.LastName} {a.Mail}</div>)}

      <PrintPartTexts printData={printData} />

      <PrintPartLectures lectures={printData!.Lectures} />

      <PrintPartFiles files={printData.Files} />

      <PrintPartLinks links={printData.Links} />

      <PrintPartTests tests={printData.Tests} />

    </div>

  )
}

export default PrintPartComponent;
