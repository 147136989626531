import { useCallback, useState } from "react";

import { useI18n } from "src/utils/lni18n";


export type PartPrintItem = {
  checked?: boolean, text: string, num: number, details?: PartPrintItem, key?: string, parent?: string
}

export const PartItems: Record<any, PartPrintItem> = {
  Texts: { text: "texts", num: 0, checked: true },
  Lectures: { text: "lectures", num: 3, checked: false, details: { parent: "Lectures", text: "details", num: 7, checked: false, key: "ScreenDetails" } },
  Files: { text: "files", num: 2, checked: false },
  Link: { text: "links", num: 1, checked: false },
  //Evals: {text: "evals", num: 4},
  Tests: { text: "tests", num: 5, checked: false, details: { parent: "Tests", text: "details", num: 6, key: "TestDetails", checked: false } }
}

const SelectPartDetails = (props: { onChange: () => void, getPrint: (items: number[]) => void }) => {

  const { languageService: t } = useI18n();

  const { getPrint, onChange } = props;

  const [items, setItems] = useState({ ...PartItems });

  function setItem(checked: boolean, key: string, parent?: string): void {

    key = parent || key;
    setItems(data => {
      const returVal = { ...data };
      const toChange = { ...returVal[key] };

      if (parent && toChange.details) {
        toChange.details = { ...toChange.details, checked: checked };
      }
      else {
        toChange.checked = checked;
        if (toChange.details && checked === false) toChange.details.checked = false;
      }


      returVal[key] = toChange;

      return returVal;
    })

    onChange();
  }

  const submit = useCallback(() => {
    const types: number[] = []

    Object.keys(items).forEach(k => {
      const item = items[k];
      if (item.checked) types.push(item.num);
      if (item.details?.checked) types.push(item.details!.num);
    });

    getPrint(types);

  }, [items]);

  return (


    <div className="row">
      <div className="col-9">
        <div className="d-flex flex-wrap">
          {Object.keys(items).map(key => {
            const item = items[key];
            return <div className="m-2 p-2" key={key}>
              <label>
                <input type="checkbox" className="mr-3" checked={item.checked} onChange={(e) => setItem(e.currentTarget.checked, key)} /> {t.getText(item.text)}
              </label>

              {item.checked && item.details &&
                <div className="ml-3 small" key={item.details.key}>
                  <label>
                    <input type="checkbox" className="mr-3" checked={item.details!.checked} onChange={(e) => setItem(e.currentTarget.checked, item.details!.key!, item.details!.parent)} /> {t.getText(item.details.text)}
                  </label></div>}
            </div>
          })
          }
        </div>
        {(items["Tests"].details!.checked || items["Lectures"].details!.checked) && <div className="mt-3 alert alert-warning preserve-white">{t.getText("coursepart.details.warning")}</div>}
      </div>

      <div className="col-3">
        <button onClick={submit} className="btn btn-primary btn-small">{t.getText("view")}</button>
      </div>

    </div>

  )
}

export default SelectPartDetails;
