import PermissionRow from "./PermissionRow";
import PermissionsTypeahead from "./PermissionsTypeahead";
import { DeleteBodyDto, DialogData, PermissionsUrlCreator, SetPermissionDto } from "./PermissionTypes";
import { useAtom } from "jotai";
import { useI18n } from "../../utils/lni18n";
import { IdAndNameAndTypeDto } from "../../types";
import { SetPermissionCallback } from "./PermissionsDialog";
import WaitRipple from "../WaitRipple";
import { PermissionsService } from "./PermissionService";



const PermissionsForm = (props: { readOnly: boolean, showData: DialogData, urlCreator: PermissionsUrlCreator, permissionService: PermissionsService }) => {

    const { showData, urlCreator, permissionService, readOnly } = props;
    const [permissions] = useAtom(permissionService.permissionAtom);
    const [usersAndGroups] = useAtom(permissionService.usersAndGroupsAtom);
    const { languageService: t } = useI18n();


    const itemChosen = (item: IdAndNameAndTypeDto) => {

        const data: SetPermissionDto = {
            ObjectId: showData.objectId,
            ObjectType: showData.objType,
            Flag: 'read', UserOrGroupId: item.Id,
            Value: true, SetForAllsubItems: showData.setForallSubitems
        };
        const url = urlCreator.SetPermissionUrl()
        permissionService.addPermission(url, data, item.type);
    }

    const onChange: SetPermissionCallback = (checked: boolean, userOrGroupId: string, subjectType: 'user' | 'group', name: string, deleteRow: boolean) => {

        if (deleteRow === true) {
            const data: DeleteBodyDto = {
                ObjectId: showData.objectId as string,
                ObjectType: showData.objType,
                UserOrGroupId: userOrGroupId,
            };
            permissionService.deletePermission(urlCreator.DeletePermissionUrl(), data, subjectType);
        }
        else {
            const data: SetPermissionDto = {
                ObjectId: showData.objectId, ObjectType: showData.objType,
                Flag: name, UserOrGroupId: userOrGroupId,
                Value: checked, SetForAllsubItems: showData.setForallSubitems
            };
            permissionService.setPermission(urlCreator.SetPermissionUrl(), data, subjectType);
        }

    }


    let index = 0;

    if (!permissions?.subjects) {
        return <WaitRipple />;
    }



    return (
        <>
            <h5 className="mb-3">{t.getText("permissions")}</h5>
            {!readOnly &&
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mb-5">
                            <label htmlFor="receiver-select">{t.getText('add.user')}</label>
                            <PermissionsTypeahead itemChosen={itemChosen} items={usersAndGroups.Users} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group mb-5">
                            <label htmlFor="receiver-select">{t.getText('add.group')}</label>
                            <PermissionsTypeahead itemChosen={itemChosen} items={usersAndGroups.Groups} />
                        </div>
                    </div>

                </div>
            }

            <table className="table">
                <tbody>

                    {permissions.subjects.Users.map((s) => (
                        <PermissionRow readOnly={readOnly} subjectType={'user'} onChange={onChange} key={index++} setFlags={permissions.subjects!.Flags} permission={s} />
                    ))
                    }

                    {permissions.subjects.Groups.map((s) => (
                        <PermissionRow readOnly={readOnly} subjectType={'group'} onChange={onChange} key={index++} setFlags={permissions.subjects!.Flags} permission={s} />
                    ))
                    }
                </tbody>
            </table>


        </>
    )


}


export default PermissionsForm;