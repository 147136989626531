import authorDb from "src/services/db/AuthorDB";
import { EditScreenDto } from "./domain/LectureState";
import { applicationSessionId } from "src/components/Pinger/Pinger";
import lectureService from "./LectureService";



export class LocalAudioService {

    /**
     * if we have local wave data in DB attach each of these to the screen.
     */
    public async AttachLocalAudioToScreens(screens: EditScreenDto[]) {

        const screenIdsAsMap = screens.map(s => s.ScreenId)
            .reduce((acc: Record<string, boolean>, curr) => (acc[curr] = true, acc), {});

        const savedAudioData = await authorDb.audioData
            .filter(sc => !!screenIdsAsMap[sc.screenId])
            .toArray();

        savedAudioData.forEach(auData => {

            URL.revokeObjectURL(auData.url);

            const sc = screens.find(s => s.ScreenId === auData.screenId);
            if (sc) {

                lectureService.mutateLecturesState(sc.LectureId, (lecture => {
                    const screen = lecture.Screens.find(s => s.ScreenId === sc.ScreenId);
                    if (screen) {
                        screen.AudioUrl = URL.createObjectURL(auData.blob);
                        screen.Duration = auData.duration;
                    }
                }));
            }
        });
    }

    public async SaveNewFile(file: File, screenId: string, duration: number) {
        const url = URL.createObjectURL(file);
        const auData = await authorDb.audioData.get({ screenId: screenId, sessionId: applicationSessionId });
        if (auData) {
            URL.revokeObjectURL(auData.url);
            auData.blob = file;
            auData.sessionId = applicationSessionId;
            auData.url = url;
            auData.duration = duration;
            authorDb.audioData.update(auData.id!, auData)
        }
        else {
            authorDb.audioData.add({ screenId: screenId, duration, blob: file, sessionId: applicationSessionId, url })
        }

        return url;

    }

    public async GetAudioData(screenId: string) {
        const localUrlData = await authorDb.audioData.get({ screenId: screenId, sessionId: applicationSessionId });
        if (localUrlData && localUrlData.blob) {
            return await localUrlData.blob.arrayBuffer();
        }
        return null;
    }

    public async ClearAudioData(screenId: string) {
        const localUrlData = await authorDb.audioData.get({ screenId: screenId, sessionId: applicationSessionId });
        if (localUrlData && localUrlData.id) {
            return await authorDb.audioData.delete(localUrlData.id);
        }
    }

}

const localAudioService = new LocalAudioService();

export default localAudioService;