import { useRef, useEffect } from "react";
import ReactPlayer from 'react-player/file'
import lecturePlayerService from "../Player/LecturePlayerService";
import { useCallback } from "react";
import { useState } from "react";
import { EditScreenDto, LectureState, ScreenType } from "../domain/LectureState";
import { OnProgressProps } from "react-player/base";

const VideoPlayer = (props: { currentScreen: EditScreenDto, lecturedata: LectureState, className: string, onReady: () => void }) => {

    const { doPlay, volume, playbackRate } = props.lecturedata;
    const {currentScreen, onReady} = props;


    const [running, setRunning] = useState(false);

    const mediaElement = useRef<any>(undefined);

    const getPlayerTime = useCallback(() => {
        if (mediaElement && mediaElement.current) {
            const time = mediaElement.current.getCurrentTime();
            if (time != null) {
                return time;
            }
        }
        return 0;
    }, []);

  

    const handleProgress = useCallback((e:OnProgressProps) => {
        lecturePlayerService.setPlayedPercent(e.played * 100);
        lecturePlayerService.mediaPositionChanged.bind(lecturePlayerService)(e.playedSeconds, running);

    }, [currentScreen, running]);

    const handleMediaAtEnd = async () => {
        await handleMediaPause();
    }

    const handleMediaPause = async () => {
        setRunning(false);
        lecturePlayerService.setPlayerRunningState(false);
        lecturePlayerService.setWantedPlayingState(false);
    }

    const handlePlay = () => {
        setRunning(true);
        lecturePlayerService.setPlayerRunningState(true);
    }


    const seekTo = async (fraction:number, screenType: number) => {
        if (screenType !== ScreenType.Video) {
            return;
        }

        if (mediaElement.current) {
            mediaElement.current.seekTo(fraction);
        }
    };

    useEffect(() => {
        lecturePlayerService.RegisterScreen({ seekTo: seekTo });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return <div className={props.className + " w-100 h-100 d-flex align-items-center " + (currentScreen?.ScreenType !== ScreenType.Video ? "off-screen" : "")}>

        <ReactPlayer
            onReady={onReady}
            onEnded={handleMediaAtEnd}
            width="100%"
            height="100%"
            onPause={handleMediaPause}
            onPlay={handlePlay}
            onProgress={handleProgress} 
            progressInterval={100}
            playing={doPlay}
            ref={mediaElement}
            controls={false}
            className={"ml-auto mr-auto"}
            volume={volume / 100}
            url={currentScreen?.VideoUrl}
            pip={false}
            playbackRate={playbackRate}
            config={
                {
                    forceVideo: true,
                    attributes: {
                        controlsList: "nodownload",
                        playsInline: true,
                        autoPlay: true
                    },
                    tracks: [],
                    

                }}
        />
    </div>
}


export default VideoPlayer;