import { LockItemInfo, LockReason } from "src/types";
import { useI18n } from "src/utils/lni18n";


const LockedReason = (props: { info?: LockItemInfo, children?: any }) => {

    
    if( !props.info) return null;


    const { languageService: t } = useI18n();

    let message = "";
    switch (props.info.Reason) {
        case LockReason.LockedByOther:
            message = t.getText("itemlock.error.locked.by", `${props.info.LockedBy.FirstName} ${props.info.LockedBy.LastName}`, props.info.LockedBy.Mail || ""  );
        break;

        case LockReason.LockedBySelf:
            message = t.getText("weblock.by.you");
        break;

        case LockReason.InEncoding:
            message = t.getText("publish.asyncmessage");
        break;

        case LockReason.ReadOnly:
            message = t.getText("courepart.no.edit.permission");
        break;

        
    }

    return <div className='alert alert-warning preserve-white'>
        {message}
        {props.children}
    </div>

}

export default LockedReason;