import { AudioSequence } from "./AudioSequence";
import { UndoManager } from "./UndoManger";

export class WaveDisplay {
    active = false;
    title?: string;
    onSelectionUpdated;
    wrapperElement?: HTMLElement;

    // Handle copy & cut & paste
    clipboardAudioSequence?: AudioSequence = undefined;

    // references to the elements
    canvasReference?: HTMLCanvasElement = undefined;
    playerPositionElement?: HTMLElement = undefined;
    audioSequence?: AudioSequence = undefined;

    private undoManager = new UndoManager();

    // properties for the selection feature (mouse, ...)    

    // is the mouse inside of the editor (for background coloring)
    mouseInside = false;
    // state of the mouse button
    mouseDown = false;
    // is the mouse clicked inside of the selection
    mouseInsideOfSelection = false;

    // is the start or end bar selected
    mouseSelectionOfStart = false;
    mouseSelectionOfEnd = false;

    // current and previous position of the mouse
    mouseX = 0;
    previousMouseX = 0;

    // position of the selection (if equal, the selection is disabled)
    selectionStart = 0;
    selectionEnd = 0;

    // color states (gradient from top to bottom)

    // colors when the mouse is outside of the editor box
    colorInactiveTop = "#d7e5c7";
    colorInactiveBottom = "#d7e5c7";
    // colors when the mouse is inside of the editor box
    colorActiveTop = "#EEE";
    colorActiveBottom = "#CCC";
    // color when the mouse is pressed during inside of the editor box
    colorMouseDownTop = "#EEE";
    colorMouseDownBottom = "#CDC";
    // color of the selection frame
    colorSelectionStroke = "rgba(255,0,0,0.5)";
    colorSelectionFill = "rgba(255,0,0,0.2)";

    waveStroke = "rgba(0,0,0,0.5)";

    // temporary optimized visualization data    
    visualizationData: {
        min?: number,
        max?: number,
        x?: number,
        y?: number
    }[] = [];

    plotTechnique: number = 1;

    // handle focus for copy, paste & cut
    hasFocus = true;

    // movement
    movePos = 0;
    movementActive = false;

    // zoom
    viewResolution = 10; // default 10 seconds
    viewPos = 0; // at 0 seconds

    // playback
    playbackPos = 0;

    private ctrlStep: number = 0.02; // 2%
    private normalStep: number = 0.001; // 0,1 %


    constructor(sequence: AudioSequence, onSelectionUpdated: (s: any) => void) {
        this.active = false;
        this.onSelectionUpdated = onSelectionUpdated;
        // Handle copy & cut & paste
        this.clipboardAudioSequence = undefined;


        // references to the elements
        this.canvasReference = undefined;
        this.audioSequence = sequence;

        // handle focus for copy, paste & cut
        this.hasFocus = true;

        this.updateVisualizationData = this.updateVisualizationData.bind(this);
        this.resizeCanvas = this.resizeCanvas.bind(this);

        window.addEventListener('resize', this.resizeCanvas);


    }
    public getSampleRate() {

        return this.audioSequence?.sampleRate || 0;
    }

    public connectToElement(parentElement: HTMLElement, canvas: HTMLCanvasElement, title: string) {
        this.wrapperElement = parentElement;
        this.canvasReference = canvas;
        this.title = title;
        this.selectionStart = this.selectionEnd = 0;
        this.undoManager.clear();
        this.createEditor();

        const zoom = this.zoomToFit.bind(this);

        window.setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
            zoom();
        }, 10);
    }

    getData() { return this.audioSequence?.data };

    /**
     * Create a new editor instance with the given audio sequence reference
     * @param sequence reference to the audio sequence which will be edited
     */
    setAudioSequence(sequence?: AudioSequence) {

        this.active = sequence != undefined;

        this.audioSequence = sequence;
        this.selectionStart = this.selectionEnd = 0;
        this.undoManager.clear();
        this.updateVisualizationData();
    };

    public undo() {
        this.undoManager.undo();
        return this.undoManager.hasUndo();
    };

    public redo() {
        this.undoManager.redo();
        return this.undoManager.hasRedo();
    };

    public canRedo() {
        return this.undoManager.hasRedo();
    };

    public canUndo() {
        return this.undoManager.hasUndo();
    };



    public resizeCanvas() {
        if (!this.wrapperElement) {
            return;
        }
        this.canvasReference!.width = this.wrapperElement!.offsetWidth;
        this.canvasReference!.height = this.wrapperElement!.offsetHeight;
        if (this.audioSequence != null) {
            window.setTimeout(() => {
                this.updateVisualizationData();
            }, 0);
        }
        else {
            this.repaint();
        }
    }

    public selectToTheRight(ctrl: boolean, shift: boolean) {

        if (this.audioSequence == null)
            return;

        if (ctrl)
            this.selectionEnd = Math.floor(Math.min(this.selectionEnd + this.audioSequence!.data.length * this.ctrlStep, this.audioSequence!.data.length));
        else
            this.selectionEnd = Math.floor(Math.min(this.selectionEnd + this.audioSequence!.data.length * this.normalStep, this.audioSequence!.data.length));

        if (!shift) {
            this.selectionStart = this.selectionEnd;
        }
        this.selectionUpdated();
    }

    public moveToEnd(start: boolean, shift: boolean) {

        if (this.audioSequence == null)
            return;


        if (start) {
            this.selectionStart = 0;
            if (!shift)
                this.selectionEnd = this.selectionStart;
        } else {
            this.selectionEnd = this.audioSequence!.data.length;
            if (!shift)
                this.selectionStart = this.selectionEnd;
        }

        this.selectionUpdated();
    }

    public selectToTheLeft(ctrl: boolean, shift: boolean) {

        if (this.audioSequence == null)
            return;


        if (ctrl)
            this.selectionEnd = Math.floor(Math.max(this.selectionEnd - this.audioSequence!.data.length * this.ctrlStep, 0));
        else
            this.selectionEnd = Math.floor(Math.max(this.selectionEnd - this.audioSequence!.data.length * this.normalStep, 0));

        if (!shift) {
            this.selectionStart = this.selectionEnd;
        }
        this.selectionUpdated();
    }



    public destroy() {
        window.removeEventListener('resize', this.resizeCanvas);
        this.wrapperElement!.remove();
    };



    selectionUpdated() {

        if (this.onSelectionUpdated) {
            var data = {
                selectionStart: this.selectionStart,
                selectionEnd: this.selectionEnd,
                viewPos: this.viewPos,
                viewResolution: this.viewResolution
            };

            this.onSelectionUpdated(data);
        }


    };


    updateSelection(data: any) {
        this.selectionStart = data.selectionStart;
        this.selectionEnd = data.selectionEnd;

        if (this.viewPos !== data.viewPos || this.viewResolution !== data.viewResolution) {
            this.viewPos = data.viewPos;
            this.viewResolution = data.viewResolution;
            this.updateVisualizationData();
        }
        this.repaint();

    }

    /**
     * Create a new editor instance
     */
    createEditor() {
        // Create a canvas element from code and append it to the audiolayer

        const positionElements = this.wrapperElement!.getElementsByClassName("playerPosition");
        if (positionElements && positionElements.length > 0) {
            this.playerPositionElement = positionElements[0] as HTMLElement
        }
        // add the mouse listener to the canvas
        this.addEventlistener();
        // do an intial repaint
        window.dispatchEvent(new Event("resize"));
    };



    updateVisualizationData() {
        if (!!this.audioSequence)
            this.getDataInResolution(this.viewResolution, this.viewPos, this.canvasReference?.offsetWidth || 0);

        this.repaint();
    }


    getDataInResolution(resolution: number, offset: number, width: number) {
        this.visualizationData = [];
        var data = this.audioSequence!.data;
        var offsetR = this.audioSequence!.sampleRate * offset;

        // get the offset and length in samples
        var from = Math.round(offset * this.audioSequence!.sampleRate);
        var len = Math.round(resolution * this.audioSequence!.sampleRate);

        // when the spot is to large
        if (len > width) {
            var dataPerPixel = len / width;
            for (var i = 0; i < width; ++i) {
                var dataFrom = i * dataPerPixel + offsetR;
                var dataTo = (i + 1) * dataPerPixel + offsetR + 1;

                if (dataFrom >= 0 && dataFrom < data.length &&
                    dataTo >= 0 && dataTo < data.length) {
                    var peakAtFrame = this.getPeakInFrame(dataFrom, dataTo, data);
                    this.visualizationData.push(peakAtFrame);
                }
                else {
                    this.visualizationData.push({
                        min: 0.0,
                        max: 0.0
                    });
                }
            }
            this.plotTechnique = 1;
        }
        else {
            var pixelPerData = width / len;
            var x = 0;
            for (var i = from; i <= from + len; ++i) {
                // if outside of the data range
                if (i < 0 || i >= data.length) {
                    this.visualizationData.push({
                        y: 0.0,
                        x: x
                    });
                }
                else {
                    this.visualizationData.push({
                        y: data[i],
                        x: x
                    });
                }
                x += pixelPerData;
            }
            this.plotTechnique = 2;
        }
    }

    /**
     * adding of several event listener for mouse and keyboard
     */
    addEventlistener() {

        if (!this.canvasReference) throw new Error("No canvas");

        this.canvasReference!.addEventListener("mouseover", () => {
            this.mouseInside = true;
            this.repaint.bind(this)();
        }, true);



        this.canvasReference!.onmousemove = (e: any) => {
            if (this.mouseDown == true)
                return;

            this.canvasReferenceOnmousemove(e);
        }

        this.canvasReference!.onmousedown = (e: any) => {

            this.mouseDown = true;

            if (this.movementActive == false) {
                var selectionStartPixel = this.getAbsoluteToPixel(this.selectionStart);
                var selectionEndPixel = this.getAbsoluteToPixel(this.selectionEnd);


                // is the mouse on the left bar of the selection
                if (this.mouseX - 5 < selectionStartPixel &&
                    this.mouseX + 5 > selectionStartPixel) {
                    this.mouseSelectionOfStart = true;
                }
                // is the mouse on the right bar of the selection
                else if (this.mouseX - 5 < selectionEndPixel &&
                    this.mouseX + 5 > selectionEndPixel) {
                    this.mouseSelectionOfEnd = true;
                }
                // if the mouse is somewhere else, start a new selection
                else {
                    this.selectionStart = this.getPixelToAbsolute(this.mouseX);
                    this.selectionEnd = this.selectionStart;
                }
            }


            this.selectionUpdated();

            document.addEventListener("mousemove", this.documentOnmousemove);
            document.addEventListener("mouseup", this.documentOnmouseup);

        };





        this.canvasReference!.ondblclick = () => {
            // deselect on double click
            if (this.selectionStart != this.selectionEnd) {
                this.selectionStart = 0;
                this.selectionEnd = 0;
            }
            else {
                this.selectionStart = 0;
                this.selectionEnd = this.getPixelToAbsolute(this.canvasReference!.width);
            }

            this.mouseDown = false;
            this.mouseSelectionOfStart = false;
            this.mouseSelectionOfEnd = false;
            this.mouseInsideOfSelection = false;
            this.selectionUpdated();
        };





    };

    private documentOnmousemove = (e: any) => {
        this.canvasReferenceOnmousemove(e);
    }

    private documentOnmouseup = (e: any) => {

        this.canvasReferenceOnmouseup();
        document.removeEventListener("mousemove", this.documentOnmousemove);
        document.removeEventListener("mouseup", this.documentOnmouseup);
    }

    private canvasReferenceOnmouseup = () => {
        // swap the selection position if start is bigger then end
        if (this.selectionStart > this.selectionEnd) {
            var temp = this.selectionStart;
            this.selectionStart = this.selectionEnd;
            this.selectionEnd = temp;
        }

        // reset the selction mouse states for the selection
        this.mouseInsideOfSelection = false;
        this.mouseSelectionOfStart = false;
        this.mouseSelectionOfEnd = false;
        this.mouseDown = false;
        this.selectionUpdated();
    };

    private canvasReference_onmouseout = () => {
        if (this.selectionStart > this.selectionEnd) {
            var temp = this.selectionStart;
            this.selectionStart = this.selectionEnd;
            this.selectionEnd = temp;
        }

        // reset the selction mouse states for the selection
        this.mouseInsideOfSelection = false;
        this.mouseSelectionOfStart = false;
        this.mouseSelectionOfEnd = false;
        this.mouseDown = false;
        this.mouseInside = false;


        this.selectionUpdated();
    };



    private canvasReferenceOnmousemove = (e: any) => {

        this.previousMouseX = this.mouseX;
        this.mouseX = Math.min(Math.max(0, e.clientX - this.canvasReference!.offsetLeft), this.canvasReference!.width);
        var mouseXDelta = this.mouseX - this.previousMouseX;

        if (this.mouseDown && this.movementActive == false) {
            // if the mouse is inside of a selection, then move the whole selection
            if (this.mouseInsideOfSelection) {
                var absDelta = this.getPixelToAbsolute(this.mouseX) -
                    this.getPixelToAbsolute(this.previousMouseX);

                // move the selection with the delta
                this.selectionStart += absDelta;
                this.selectionEnd += absDelta;

            }
            // if the left bar is selected, then move it only
            else if (this.mouseSelectionOfStart) {
                this.selectionStart = this.getPixelToAbsolute(this.mouseX);
            }
            // if the right bar is selected (default during creation of a selection), then move it only
            else {
                this.selectionEnd = this.getPixelToAbsolute(this.mouseX);
            }
        }

        if (this.mouseDown && this.movementActive) {
            var movementResolution = this.viewResolution / this.canvasReference!.width;
            this.viewPos -= mouseXDelta * movementResolution;
            this.selectionStart -= mouseXDelta * movementResolution;
            this.selectionEnd -= mouseXDelta * movementResolution;
            this.updateVisualizationData();

        }

        if (this.mouseDown)
            this.selectionUpdated();


    };


    /**
     * Repaint of the editor window
     */
    public repaint = () => {
        // no canvas, no paint
        if (this.canvasReference === undefined) return;

        this.canvasReference!.width = this.wrapperElement!.offsetWidth;

        // get the context for the sub methos
        var canvasContext = this.canvasReference!.getContext('2d')!;
        // clear the drawing area
        this.clearCanvas(canvasContext);

        // draw background
        this.paintBackground(canvasContext);

        // if no audio sequence is attached, nothing can be rendered
        if (this.audioSequence === undefined) {
            this.paintEmpty(canvasContext);
        }
        else {
            // draw the normal waveform 
            this.paintWaveform(canvasContext);

            // draw the selector rectangle
            this.paintSelector(canvasContext);

            this.paintTextInfo(canvasContext);
        }

    };

    /**
     * clear the canvas for redrawing
     * @param canvasContext reference to the drawing context of the canvas
     */
    public clearCanvas = (canvasContext: CanvasRenderingContext2D) => {
        canvasContext.clearRect(0, 0, this.canvasReference!.width, this.canvasReference!.height);
    };

    /**
     * paint in case of no sequence available
     * @param canvasContext reference to the drawing context of the canvas
     */
    private paintEmpty = (canvasContext: CanvasRenderingContext2D) => {
        var oldFont = canvasContext.font;
        var oldTextAlign = canvasContext.textAlign;
        var oldBaseline = canvasContext.textBaseline;

        canvasContext.font = 'italic 40px Calibri';
        canvasContext.textAlign = 'center';
        canvasContext.textBaseline = "middle"
        this.paintTextWithShadow("Drag audio file here to edit", canvasContext.canvas.clientWidth / 2.0, canvasContext.canvas.clientHeight / 2.0, "rgba(0,0,0,1)", canvasContext);

        canvasContext.font = oldFont;
        canvasContext.textAlign = 'left';
        canvasContext.textBaseline = 'top';
    };

    /**
     * paint the background of the editor
     * @param canvasContext reference to the drawing context of the canvas
     */
    private paintBackground = (canvasContext: CanvasRenderingContext2D) => {
        var gradient = canvasContext.createLinearGradient(0, 0, 0, this.canvasReference!.offsetHeight);
        gradient.addColorStop(0, this.colorActiveTop);
        gradient.addColorStop(1, this.colorInactiveBottom);
        canvasContext.fillStyle = gradient;
        canvasContext.fillRect(0, 0, this.canvasReference!.width, this.canvasReference!.height);
    };

    /**
     * Draw the waveform of the referenced audio sequence
     * @param canvasContext reference to the drawing context of the canvas
     */
    private paintWaveform = (canvasContext: CanvasRenderingContext2D) => {
        var seq = this.audioSequence!;
        var center = this.canvasReference!.height / 2;

        // if the signal is above the 0db border, then a vertical zoomout must be applied
        var verticalMultiplier = (seq.gain < 1.0) ? 1.0 : 1.0 / seq.gain;

        // for later use of sequencial context        
        var data = seq.data;

        //canvasContext.setLineWidth(1);
        canvasContext.strokeStyle = this.waveStroke;
        canvasContext.beginPath();
        canvasContext.moveTo(0, center);

        // choose the drawing style of the waveform
        if (this.plotTechnique == 1) {
            // data per pixel
            for (var i = 0; i < this.canvasReference!.width; ++i) {
                var peakAtFrame = this.visualizationData[i];

                if (peakAtFrame) {
                    canvasContext.moveTo(i + 0.5, center + peakAtFrame.min! * verticalMultiplier * -center);
                    canvasContext.lineTo(i + 0.5, (center + peakAtFrame.max! * verticalMultiplier * -center) + 1.0);
                }
            }

        }
        else if (this.plotTechnique == 2) {
            var s = 1;

            for (var i = 0; i < this.visualizationData.length; ++i) {
                var x = this.visualizationData[i].x!;
                var y = center + this.visualizationData[i].y! * verticalMultiplier * -center;

                canvasContext.lineTo(x, y);

                // draw edges around each data point
                canvasContext.moveTo(x + s, y - s);
                canvasContext.lineTo(x + s, y + s);
                canvasContext.moveTo(x - s, y - s);
                canvasContext.lineTo(x - s, y + s);
                canvasContext.moveTo(x - s, y + s);
                canvasContext.lineTo(x + s, y + s);
                canvasContext.moveTo(x - s, y - s);
                canvasContext.lineTo(x + s, y - s);

                canvasContext.moveTo(x, y);
            }
        }

        canvasContext.stroke();

        // draw the horizontal center line
        //canvasContext.setLineWidth(1.0);
        canvasContext.strokeStyle = "rgba(0, 0, 0,0.5)";
        canvasContext.beginPath();
        canvasContext.moveTo(0, center);
        canvasContext.lineTo(this.canvasReference!.width, center);
        canvasContext.stroke();
    };

    /**
     * Draw the selector
     * @param canvasContext reference to the drawing context of the canvas
     */
    private paintSelector = (canvasContext: CanvasRenderingContext2D) => {
        var selectionStartPixel = this.getAbsoluteToPixel(this.selectionStart);
        var selectionEndPixel = this.getAbsoluteToPixel(this.selectionEnd);

        if (this.selectionStart !== this.selectionEnd) {

            var start = (selectionStartPixel < selectionEndPixel) ? selectionStartPixel : selectionEndPixel;
            var width = (selectionStartPixel < selectionEndPixel) ? selectionEndPixel - selectionStartPixel : selectionStartPixel - selectionEndPixel;

            canvasContext.fillStyle = this.colorSelectionFill;
            canvasContext.fillRect(start, 0, width, this.canvasReference!.height);

            canvasContext.strokeStyle = this.colorSelectionStroke;
            canvasContext.strokeRect(start, 0, width, this.canvasReference!.height);
        }
        else {
            canvasContext.strokeStyle = this.colorSelectionStroke;
            //canvasContext.setLineWidth(1.0);               
            canvasContext.beginPath();
            canvasContext.moveTo(selectionStartPixel, 0);
            canvasContext.lineTo(selectionStartPixel, this.canvasReference!.height);
            canvasContext.stroke();

        }
        this.playerPositionElement!.style.left = "-10000px";

    };


    public paintPlayBackPosition = () => {

        var playbackPixelPos = this.getAbsoluteToPixel(this.playbackPos);

        if (playbackPixelPos > 0 && playbackPixelPos < this.canvasReference!.width) {
            this.playerPositionElement!.style.left = playbackPixelPos + "px";
        } else {
            this.playerPositionElement!.style.left = "-10000px";
        }

    }


    private getPeakInFrame = (from: number, to: number, data: any[]) => {
        var fromRounded = Math.round(from);
        var toRounded = Math.round(to);
        var min = 1.0;
        var max = -1.0;

        if (fromRounded < 0 || toRounded > data.length) debugger;

        for (var i = fromRounded; i < toRounded; ++i) {
            var sample = data[i];

            max = (sample > max) ? sample : max;
            min = (sample < min) ? sample : min;
        }

        return {
            min: min,
            max: max
        };
    };

    private paintTextInfo = (canvasContext: CanvasRenderingContext2D) => {
        var start, end;
        if (this.selectionStart <= this.selectionEnd) {
            start = Math.floor(this.selectionStart / this.audioSequence!.sampleRate * 100) / 100;
            end = Math.floor(this.selectionEnd / this.audioSequence!.sampleRate * 100) / 100;
        } else {
            end = Math.floor(this.selectionStart / this.audioSequence!.sampleRate * 100) / 100;
            start = Math.floor(this.selectionEnd / this.audioSequence!.sampleRate * 100) / 100;
        }


        var len = Math.abs(Math.floor((end - start) * 100) / 100);

        if (end - start == 0) {
            this.paintTextWithShadow(`${start} s`, 1, 10, "rgb(0,0,0)", canvasContext);
        }
        else {
            this.paintTextWithShadow(`${start} s  >  ${end} s   (${len} s)`, 1, 10, "rgb(0,0,0)", canvasContext);
        }
    }

    private paintTextWithShadow = (text: string, x: number, y: number, style: string, canvasContext: CanvasRenderingContext2D) => {
        //canvasContext.fillStyle = "rgba(0,0,0,0.25)";
        //canvasContext.fillText(text, x + 1, y + 1);

        canvasContext.fillStyle = style;
        canvasContext.fillText(text, x, y);
    };

    private getSelectionInDataRange = () => {
        var start = Math.round(this.audioSequence!.data.length / this.canvasReference!.width * this.selectionStart);
        var end = Math.round(this.audioSequence!.data.length / this.canvasReference!.width * this.selectionEnd);

        return {
            start: start,
            end: end
        };
    };

    private selectDataInRange = (start: number, end: number) => {
        this.selectionStart = Math.round(this.canvasReference!.width / this.audioSequence!.data.length * start);
        this.selectionEnd = Math.round(this.canvasReference!.width / this.audioSequence!.data.length * end);
    }

    private getPixelToAbsolute = (pixelValue: number) => {
        if (this.audioSequence === undefined) return 0;

        var totalSamplesInResolution = this.viewResolution * this.audioSequence!.sampleRate;
        var totalSamplesOffset = this.viewPos * this.audioSequence!.sampleRate;

        return Math.round(totalSamplesInResolution / this.canvasReference!.width * pixelValue + totalSamplesOffset);
    };

    private getAbsoluteToPixel = (absoluteValue: number) => {
        if (this.audioSequence === undefined) return 0;

        var totalSamplesInResolution = this.viewResolution * this.audioSequence!.sampleRate;
        var totalSamplesOffset = this.viewPos * this.audioSequence!.sampleRate;
        return (absoluteValue - totalSamplesOffset) / totalSamplesInResolution * this.canvasReference!.offsetWidth;
    };


    private getAbsoluteToSeconds = (absoluteValue: number) => {
        if (this.audioSequence === undefined) return 0;

        return absoluteValue / this.audioSequence!.sampleRate;
    };

    private getSecondsToAbsolute = (seconds: number) => {
        if (this.audioSequence === undefined) return 0;

        return seconds * this.audioSequence!.sampleRate;
    };

    public zoomIntoSelection = () => {
        if (this.audioSequence == null)
            return;

        this.viewResolution = this.getAbsoluteToSeconds(this.selectionEnd - this.selectionStart);
        this.viewPos = this.getAbsoluteToSeconds(this.selectionStart);

        this.updateVisualizationData();
        //this.selectionUpdated();
    };

    public zoomToFit = () => {

        this.viewPos = 0;
        if (this.audioSequence == null)
            return;

        this.viewResolution = this.getAbsoluteToSeconds(this.audioSequence!.data.length);

        this.updateVisualizationData();
        //this.selectionUpdated();
    };

    private undoByReplace = (undoData: AudioSequence, start: number, len?: number) => {
        this.audioSequence!.trim(start, len);
        this.audioSequence!.merge(undoData, start);
        if (!!start) {
            this.selectionStart = start;
            if (!!len)
                this.selectionEnd = start + len;
            else
                this.selectionEnd = start;
        } else {
            this.selectionStart = this.selectionEnd = 0;
        }

        this.updateVisualizationData();
    };


    // APPLY EFFECTS
    public filterNormalize = () => {

        if (this.audioSequence == null)
            return;

        var start = (this.selectionStart < 0) ? 0 : (this.selectionStart >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionStart;
        var end = (this.selectionEnd < 0) ? 0 : (this.selectionEnd >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionEnd;
        var len = 0;
        var correction: number = 0;

        if (start == end) {
            start = len = -1;;
        }
        else {
            len = end - start;
        }
        correction = this.audioSequence!.filterNormalize(start, len);

        this.undoManager.add({
            undo: () => {
                if (correction > 0) {
                    this.audioSequence!.filterNormalize(start, len, 1 / correction);
                    this.updateVisualizationData();
                }

            },
            redo: () => {
                this.audioSequence!.filterNormalize(start, len);
                this.updateVisualizationData();
            }
        });

        this.updateVisualizationData();
    };

    public filterFade = (fadeIn: boolean) => {
        if (this.audioSequence == null)
            return;

        let start = (this.selectionStart < 0) ? 0 : (this.selectionStart >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionStart;
        let end = (this.selectionEnd < 0) ? 0 : (this.selectionEnd >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionEnd;

        let undoData: AudioSequence;
        let len = 0;
        if (start == end) {
            start = len = -1;
        }
        else {
            len = end - start;
        }

        undoData = this.audioSequence!.clone(start, len);
        this.audioSequence!.filterLinearFade((fadeIn === true) ? 0.0 : 1.0, (fadeIn === true) ? 1.0 : 0.0, start, len);

        this.undoManager.add({
            undo: () => this.undoByReplace(undoData, start, len),
            redo: () => {
                this.audioSequence!.filterLinearFade((fadeIn === true) ? 0.0 : 1.0, (fadeIn === true) ? 1.0 : 0.0, start, len);
                this.updateVisualizationData();
            }
        });

        this.updateVisualizationData();
    };

    public filterGain = (decibel: number) => {
        if (this.audioSequence == null)
            return;

        var start = (this.selectionStart < 0) ? 0 : (this.selectionStart >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionStart;
        var end = (this.selectionEnd < 0) ? 0 : (this.selectionEnd >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionEnd;

        var undoData: AudioSequence;
        var len = 0;
        if (start == end) {
            start = len = -1;
        }
        else {
            len = end - start;
        }

        undoData = this.audioSequence!.clone(start, len);

        this.audioSequence!.filterGain(this.getQuantity(decibel), start, len);

        this.undoManager.add({
            undo: () => this.undoByReplace(undoData, start, len),
            redo: () => {
                this.audioSequence!.filterGain(this.getQuantity(decibel), start, len);
                this.updateVisualizationData();
            }
        });

        this.updateVisualizationData();
    };

    public filterSilence = () => {

        if (this.audioSequence == null)
            return;

        var start = (this.selectionStart < 0) ? 0 : (this.selectionStart >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionStart;
        var end = (this.selectionEnd < 0) ? 0 : (this.selectionEnd >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionEnd;

        var undoData: AudioSequence;
        var len = 0;
        if (start == end) {
            start = len = -1;
        }
        else {
            len = end - start;
        }

        undoData = this.audioSequence!.clone(start, len);
        this.audioSequence!.filterSilence(start, len);

        this.undoManager.add({
            undo: () => this.undoByReplace(undoData, start, len),
            redo: () => {
                this.audioSequence!.filterSilence(start, len);
                this.updateVisualizationData();
            }
        });

        this.updateVisualizationData();
    };

    private getDecibel(signalValue: number, signalMaxium: number) {
        return 20.0 * Math.log(signalValue / signalMaxium) / Math.LN10;
    };

    private getQuantity = (decibel: number) => {
        return Math.exp(decibel * Math.LN10 / 20.0);
    };

    // CLIPBOARD FUNCTIONALITY


    public selectAll = () => {

        if (this.audioSequence == null)
            return;

        this.selectionStart = 0;
        this.selectionEnd = this.audioSequence!.data.length;
        this.updateVisualizationData();
    };

    public copy = () => {
        if (this.audioSequence == null)
            return;

        var start = (this.selectionStart < 0) ? 0 : (this.selectionStart >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionStart;
        var end = (this.selectionEnd < 0) ? 0 : (this.selectionEnd >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionEnd;

        this.clipboardAudioSequence = this.audioSequence!.clone(start, end - start);


    };


    public insertAudio = (audio: AudioSequence) => {

        var start = this.selectionStart;
        this.audioSequence!.merge(audio, start);

        this.undoManager.add({
            undo: () => {
                this.audioSequence!.trim(start, audio.data.length);
                this.selectionEnd = this.selectionStart = start;
                this.updateVisualizationData();
            },
            redo: () => {
                this.audioSequence!.merge(audio, start);
                this.updateVisualizationData();
            }
        });

        this.selectionEnd = this.selectionStart + audio.data.length;
        this.updateVisualizationData();
    };

    public paste = (redo?: Boolean, clipSequence?: AudioSequence, start?: number, end?: number) => {

        if (this.audioSequence == null)
            return;

        if (this.clipboardAudioSequence === undefined) return;

        var undoData: AudioSequence;

        if (!redo) {
            clipSequence = this.clipboardAudioSequence;
            start = this.selectionStart;
            end = this.selectionEnd;

            if (end != start)
                undoData = this.audioSequence!.clone(start, end - start);

        }

        if (clipSequence === undefined) return;

        if (start != end) {
            this.del(false, start, end);
        }

        // paste before the data block begins 
        if (start && end && (end < 0)) {
            // fill the space with zeros
            this.viewPos -= this.getAbsoluteToSeconds(start);
            this.audioSequence!.createZeroData(-end, 0);
            this.audioSequence!.merge(clipSequence, 0);
            this.selectionStart = 0;
            this.selectionEnd = clipSequence.data.length;

        }
        // paste beyond the data block
        else if (start && (start > this.audioSequence!.data.length)) {
            this.audioSequence!.createZeroData(start - this.audioSequence!.data.length);
            this.audioSequence!.merge(clipSequence);
            this.selectionEnd = this.selectionStart + clipSequence.data.length;
        }
        // paste inside of the datablock
        else {
            this.audioSequence!.merge(clipSequence, start);
            this.selectionStart = start!;
            this.selectionEnd = this.selectionStart + clipSequence.data.length;
        }

        if (!redo) {
            this.undoManager.add({
                undo: () => {
                    this.audioSequence!.trim(start!, clipSequence!.data.length);

                    if (undoData != undefined) {
                        this.audioSequence!.merge(undoData, start);
                    }

                    this.selectionEnd = this.selectionStart = start!;
                    this.updateVisualizationData();
                },
                redo: () => {
                    this.paste(true, clipSequence, start, end);
                }
            });
        }



        this.updateVisualizationData();

    };


    public cut = () => {
        if (this.audioSequence == null)
            return;

        var start = (this.selectionStart < 0) ? 0 : (this.selectionStart >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionStart;
        var end = (this.selectionEnd < 0) ? 0 : (this.selectionEnd >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionEnd;


        this.clipboardAudioSequence = this.audioSequence!.clone(start, end - start);


        this.del();
        this.selectionEnd = this.selectionStart;
        this.updateVisualizationData();

    };





    public del = (keepUndo?: Boolean, start?: number, end?: number) => {
        if (this.audioSequence == null)
            return;

        if (keepUndo == undefined)
            keepUndo = true;


        if (start == undefined)
            start = (this.selectionStart < 0) ? 0 : (this.selectionStart >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionStart;

        if (end == undefined)
            end = (this.selectionEnd < 0) ? 0 : (this.selectionEnd >= this.audioSequence!.data.length) ? this.audioSequence!.data.length - 1 : this.selectionEnd;

        var len = Math.abs(end - start);

        var undoData = this.audioSequence!.clone(start, len);

        this.audioSequence!.trim(start, len);
        window.setTimeout(() => {
            this.selectionEnd = this.selectionStart = start!;

            if (this.getAbsoluteToPixel(this.audioSequence!.data.length) < this.canvasReference!.width)
                this.zoomToFit();
            else
                this.updateVisualizationData();
        }, 100);

        if (keepUndo) {
            this.undoManager.add({
                undo: () => {
                    var full = this.getAbsoluteToPixel(this.audioSequence!.data.length) <= this.canvasReference!.width;
                    this.audioSequence!.merge(undoData, start);

                    window.setTimeout(() => {
                        this.selectionStart = start!;
                        this.selectionEnd = start! + len;
                        if (full)
                            this.zoomToFit();
                        else
                            this.updateVisualizationData();
                    }, 100);
                },
                redo: () => {
                    this.del(false, start, end);
                }
            });
        }

    };






}