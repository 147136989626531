import * as React from "react";
import { recordingAtom } from "../Recoderservice";
import { useAtom } from "jotai";



// Settings
const max = 100;
const width = 20;
const height = 200;
const boxCount = 10;
const boxCountRed = 2;
const boxCountYellow = 3;
const boxGapFraction = 0.05;

// Colors
const redOn = "#e57373ff";
const redOff = "rgba(64,12,8,0.9)";
const yellowOn = "#eec55dff";
const yellowOff = "rgba(64,53,0,0.9)";
const greenOn = "#5faa45ff";
const greenOff = "rgba(13,64,8,0.9)";


export function VuMeter() {
    const stage = React.useRef<any>();
    const [recordingData] = useAtom(recordingAtom);

    // Gap between boxes and box height
    const boxHeight = height / (boxCount + (boxCount + 1) * boxGapFraction);
    const boxGapY = boxHeight * boxGapFraction;

    const boxWidth = width - boxGapY * 2;
    const boxGapX = (width - boxWidth) / 2;

    React.useEffect(() => {
        if(stage.current){
            (stage.current as any).gain = recordingData.gain;
        }
    },[ recordingData.gain ] );

    React.useEffect(() => {

        if (stage.current === undefined )
            return;
        const canvas = stage.current as HTMLCanvasElement;
        const c = canvas.getContext("2d")!;

        c.fillStyle = "green";
        c.strokeStyle = "black";

        c.shadowBlur = 5;

        let drawId = 0;

        const draw = function () {
            const targetVal = ((stage.current as any)?.gain || 0) * 100;

            // Draw the container
            c.save();
            c.beginPath();
            c.rect(0, 0, width, height);
            c.fillStyle = "rgb(12,10,8)";
            c.fill();
            c.restore();

            // Draw the boxes
            c.save();
            c.translate(boxGapX, boxGapY);
            for (let i = 0; i < boxCount; i++) {
                const id = Math.abs(i - (boxCount - 1)) + 1;

                c.beginPath();
                if (id <= Math.ceil((targetVal / max) * boxCount)) {
                    c.shadowBlur = 10;
                    c.shadowColor = getBoxColor(id, targetVal);
                }
                c.rect(0, 0, boxWidth, boxHeight);
                c.fillStyle = getBoxColor(id, targetVal);
                c.fill();
                c.translate(0, boxHeight + boxGapY);
            }
            c.restore();

            drawId = requestAnimationFrame(draw);
        };

        // Get the color of a box given it's ID and the current value
        function getBoxColor(id:number, val:number) {
            if (id > boxCount - boxCountRed) {
                return id <= Math.ceil((val / max) * boxCount) ? redOn : redOff;
            }
            if (id > boxCount - boxCountRed - boxCountYellow) {
                return id <= Math.ceil((val / max) * boxCount) ? yellowOn : yellowOff;
            }
            return id <= Math.ceil((val / max) * boxCount) ? greenOn : greenOff;
        }
        
        requestAnimationFrame(draw);

        return () => {
            cancelAnimationFrame(drawId);
        }
    }, []);

    return (
        <div style={{ position: "relative" }}>
            <canvas ref={stage} width={width} height={height} />
        </div>
    );
}

