import { useState, useMemo, ChangeEvent } from "react";
import coursePartService, { onePartatom } from "../CoursePartService";

import { useNavigate, useParams } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import { useAtom } from "jotai";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import { CopyDialog, CopyDialogOptions, CopyPartData } from "../Dialogs/CopyDialog";
import WaitRipple from "src/components/WaitRipple";
import { partPermissionsUrlCreator } from "../Permissions/CoursePartPermissionsUrlCreator";
import { ObjectTypes } from "../domain/ObjectTypes";
import { PermissionsService } from "src/components/Permissions/PermissionService";
import Categories from "../General/Categories";
import CopyProtect from "../General/CopyProtect";
import PermissionsForm from "src/components/Permissions/PermissionForm";
import { DialogData } from "src/components/Permissions/PermissionTypes";
import { urlService } from "src/services/NavService";


const ReadOnlyGeneral = () => {

  const { partId } = useParams();

  const partAtom = useMemo(() => onePartatom(partId!), [partId]);
  const [partData] = useAtom(partAtom);
  const part = partData.Data;
  const { languageService: t } = useI18n();
  const dialogPortal = useDialog();
  const navigate = useNavigate();
  const [resetting, setResetting] = useState(false);


  async function copyPart() {

    dialogPortal({
      factory: (onSubmit, onCancel) => {
        const dprops: CopyDialogOptions = {
          languageService: t,
          partName: part!.Name,
          onCancel: onCancel,
          onSubmit: onSubmit,
        }
        return <CopyDialog {...dprops} />
      },
      size: "md"
    }).then(async (res: CopyPartData) => {
      if (res && res.name && res.type !== undefined) {
        const newPartData = await coursePartService.copyPart(partId!, res);
        if (newPartData) {
          navigate(urlService.urlToPart(newPartData!.Id));
        }
      }
    });
  }



  return (
    <div className="mb-5">
      <h3 className="mb-4">{t.getText("general")}</h3>
      <div className="row">
        <div className="col-sm-12 col-md-8 position-relative">

          {resetting && <WaitRipple />}

          <h5>{t.getText("name")}</h5>
          <div>{part?.Name}</div>

          <div className="my-4">
            <h5 className="">{t.getText("owner")}</h5>
            {part!.Authors.map((a, i) => <div key={i} className="mb-3">
              {a.FirstName} {a.LastName}{a.Mail && ` (${a.Mail})`}
            </div>)}
          </div>

          <hr />

          <CopyProtect disabled={true} part={part!} />
          <hr />

          <div className="mt-3">
            <Categories disabled={true} part={part!} />
          </div>




        </div>
        <div className="col-md-4  col-12 d-flex flex-column">
          <button onClick={copyPart} className="ml-md-auto  mb-3 btn btn-primary">{t.getText("copy")}</button>
        </div>
      </div>
      <hr />
     

    </div >
  )
}

export default ReadOnlyGeneral;
