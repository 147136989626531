export interface TestTypeInfoDTO {
    TestType: TestType;
    Name: any;
    Description: any;
}

export enum TestType {
    multiplechoice = 1,
    selfassessement,
    basictest,
    authorwaretest,
    logtest,
    fileupload,
    simpleselfassessement,
    singlequestion,
    simplyreply,
    drawingtest,
    multiplequestion,
    multiplechoice2
}