import * as React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { CourseCopyCommand } from "./domain/CourseCopyCommand";
import { LanguageService } from "src/utils/languageService";
import { TinyCourse } from "./domain/CourseEditModel";
import LnIcon from "src/components/LnIcon";

type node = {
    name: string,
    id: string,
    nodes: node[]
}

export interface CopyCourseDialogOptions {
    languageService: LanguageService;
    onSubmit: (d: CourseCopyCommand) => void;
    onCancel: () => void;
    course: TinyCourse

}

export type CopyPartData = {
    name: string;
    type: boolean;

}

export const CopyCourseDialog: React.FC<CopyCourseDialogOptions> = ({
    onSubmit,
    onCancel,
    languageService,
    course
}) => {

    const t = languageService;

    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);

    const defaultValues: CourseCopyCommand = {
        Name: "",
        CourseId: course.Id,
        DeepCopy: false,
        DeepPublish: false,
        PartNameReplacePattern: [],
        PartExtension: ""
    }


    const [courseTree, setCourseTree] = React.useState<node>({ name: "", id: "id", nodes: [] });

    const validationSchema = React.useMemo(() => Yup.object({
        Name: Yup.string().min(3, t.getText("min.x.chars", "3")).required(t.getText('required_field')),
        PartNameReplacePattern: Yup.array().when(["PartExtension", "DeepCopy"],
            {
                is: (PartExtension: string, DeepCopy: boolean) => {
                    return DeepCopy && (!PartExtension || PartExtension.length === 0);
                },
                then: (schema) => schema.min(1).of(
                    Yup.object({
                        old: Yup.string().min(1).required(),
                        newNamepart: Yup.string().min(3).required()
                    })
                ),
                otherwise: (schema) => schema
            }
        ),
        PartExtension: Yup.string().matches(/.{3,}/, { excludeEmptyString: true, message: t.getText("min.x.chars", "3") }

        )
    }), [t]);



    React.useEffect(() => {
        if (course) {
            viewCourseTree(defaultValues);
        }
    }, [course])



    const { control, handleSubmit, register, getValues, watch, formState: { errors, isValid } } = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    const { fields, append, remove, } = useFieldArray({
        control,
        name: "PartNameReplacePattern"
    });

    const handleSubmitForm = (values: any) => {
        onSubmit(values);
    };

    const handleCancel = () => {
        onCancel();
    };

    const viewCourseTree = React.useCallback((data: CourseCopyCommand) => {
        if (!course) return;
        const c: node = {
            name: data.Name || course.Name, id: course.Id, nodes: course.Themes.map(t => {
                return { id: t.Id, name: t.Name, nodes: t.Parts.map(p => ({ id: p.Id, name: makePartName(p.Name, data.PartExtension, data.PartNameReplacePattern), nodes: [] })) }
            })
        }

        setCourseTree(c);
    }, [course]);

    const makePartName = (orig: string, ext?: string, patterns?: { old: string; newNamepart: string; }[]) => {

        let name = orig;
        if (patterns && patterns.length > 0) {
            patterns.forEach(p => {
                if (p.old.length > 0 && p.newNamepart.length > 2) {
                    const re = new RegExp(`${p.old.replace(".", "\\.")}`, "gi");
                    name = name.replace(re, `<b class="danger">${p.newNamepart}</b>`);
                }
            })
        }

        if (ext && ext.length > 2) {
            name = `${name} - ${ext}`
        }

        return name;
    }

    watch((data, { name, type }) => {

        if (data["DeepCopy"] || name === "Name") {
            viewCourseTree(data as CourseCopyCommand);
        }

        forceUpdate();

    });


    return <>
        <form className="mt-2 mb-5" onSubmit={handleSubmit(handleSubmitForm)}>
            <span className="moveable" data-dialog-draggable>
                <ModalHeader>{t.getText("copy")}</ModalHeader>
            </span>
            <ModalBody>
                <div className="preserve-white">
                    {t.getText("copy.course.explain.2")}
                </div>
                <div className="row mt-3">
                    <div className="col-4">


                        <div className="font-weight-bold my-3">
                            {t.getText("name.new")}
                        </div>

                        <div className="input-group align-items-center">
                            <input {...register("Name")} type="text" className="form-control" />
                        </div>
                        <ErrorMessage as="div" errors={errors} name="Name" className="ml-3 mt-2 text-danger" />



                        <div className="mt-4 font-weight-bold">{t.getText("courseparts")}</div>

                        <div className="input-group mt-3">
                            <label>
                                <input {...register("DeepCopy")} type="checkbox" value="true" className="mr-3" />{t.getText("copy.course.part.label")}
                            </label>
                        </div>

                        {getValues("DeepCopy") && <>
                            <table className="table table-condensed mt-5 table-borderless">
                                <thead>
                                    <tr>
                                        <th>{t.getText("text")}</th>
                                        <th>{t.getText("replace.with")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {fields.map((field, index) => (
                                        <tr key={index}>
                                            <td>
                                                <input className={`form-control ${errors.PartNameReplacePattern?.[index]?.old ? "border-danger" : ""}`}
                                                    key={field.id} // important to include key with field's id
                                                    {...register(`PartNameReplacePattern.${index}.old`)}
                                                />
                                            </td>
                                            <td>

                                                <input className={`form-control ${errors.PartNameReplacePattern?.[index]?.newNamepart ? "border-danger" : ""}`}
                                                    key={field.id} // important to include key with field's id
                                                    {...register(`PartNameReplacePattern.${index}.newNamepart`)}
                                                />
                                            </td>
                                            <td>
                                                <button className="btn btn-link" onClick={() => remove(index)}>
                                                    <LnIcon name="delete" className="m-3 icon-small" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}


                                </tbody>
                            </table>



                        </>}


                        {getValues("DeepCopy") && <>


                            <button className="mt-1 btn btn-primary btn-small" onClick={() => append({ old: "", newNamepart: "" })}>
                                {t.getText("add")}
                            </button>

                            <div className="font-weight-bold mb-3 mt-5">
                                {t.getText("optional.suffix")}
                            </div>

                            <div className="input-group align-items-center">
                                <input {...register("PartExtension")} type="text" className="form-control" />
                            </div>
                            <ErrorMessage as="div" errors={errors} name="PartExtension" className="ml-3 mt-2 text-danger" />

                        </>
                        }

                    </div>
                    <div className="col-8">

                        <div>
                            <div className="font-weight-bold my-3">
                                {courseTree.name}
                            </div>


                            <div className="ml-4 my-2">
                                {courseTree.nodes.map(n => (<div key={n.id}>
                                    <div>&bull; {n.name}</div>
                                    <div className="ml-4 my-2">
                                        {n.nodes.map(part => <div key={part.id} className="mb-2 d-flex">

                                            <span className="ml-2" dangerouslySetInnerHTML={{ __html: part.name }}></span>
                                        </div>)}
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </ModalBody>
            <ModalFooter>
                <button disabled={!isValid} type="submit" className="btn btn-primary" >{t.getText("ok")}</button>&nbsp;
                <Button type="button" className="btn btn-warning" onClick={() => handleCancel()}>{t.getText("cancel")}</Button>
            </ModalFooter>
        </form>
    </>
};
