import { atom, getDefaultStore } from "jotai";
import { TestType, TestTypeInfoDTO } from "../domain/TestTypeInfoDTO";
import api from "src/services/api/axiosService";
import { TestLinkDTO } from "../domain/TestLinkDto";
import coursePartService from "../CoursePartService";
import { AddTestCommand } from "../domain/AddTestCommand";
import { BaseTestDataDTO } from "./DomainModels";
import { API_URL } from "src/utils/constants";
import { EditLectureDto } from "../Lecture/domain/LectureState";
import { produce } from "immer";
import { debouncer } from "src/utils/debouncer";

const store = getDefaultStore();

export type TestEditData = {
    testTypes: TestTypeInfoDTO[];
    fileTypes: string;
    addtestLastInList: boolean;
}

const initialData: TestEditData = {
    testTypes: [],
    fileTypes: "",
    addtestLastInList: false
}

export const testDataAtom = atom(initialData);

export const currentTestAtom = atom<BaseTestDataDTO | undefined>(undefined);
currentTestAtom.debugLabel = "currentTestAtom";

const endPoints = {
    GET_TESTTYPES: () => `/author/testtypes`,
    REORDER_TESTS: (coursePartID: string) => `/author/coursepart/${coursePartID}/tests/SetTestsOrder`,
    ADD_TEST: (coursePartId: string) => `/author/coursepart/${coursePartId}/tests/add`,
    DELETE_TEST: (coursePartId: string, testId: string) => `/author/coursepart/${coursePartId}/tests/${testId}/delete`,

    GET_TEST: (coursePartId: string, testId: string) => `/author/coursepart/${coursePartId}/tests/${testId}/get`,
    SAVE_TEST: (coursePartId: string, testId: string) => `/author/coursepart/${coursePartId}/tests/${testId}/save`,

}


export class TestService {

    savedebounce : debouncer;

    constructor(){
        this.savedebounce = new debouncer( this.saveTestData, 500, this );
    }

    public async getTestTypes() {
        //const response = await api.get<TestEditData>(endPoints.GET_TESTTYPES());

        const response = await fetch(`${API_URL}/${endPoints.GET_TESTTYPES()}`, { credentials: "include" });

        if (response && response.status == 200) {
            const data = await response.json();
            store.set(testDataAtom, data);
        }
    }

    public setAddLastInList(state: boolean) {
        const data = store.get(testDataAtom);
        if( data.addtestLastInList === state){
            return;
        }
        
        const newData = produce(data, draft => {
            draft.addtestLastInList = state;
        });

        store.set(testDataAtom, newData);

    }

    public async addTest(cmd: AddTestCommand) {
        const response = await api.post<TestLinkDTO>(endPoints.ADD_TEST(cmd.PartId), cmd);

        if (response && response.status == 200 && response.data) {

            coursePartService.MutatePartState(cmd.PartId, draft => {
                if (cmd.LastInList) {
                    draft.Data!.Tests = [...draft.Data!.Tests, response.data]
                }
                else {
                    draft.Data!.Tests = [response.data, ...draft.Data!.Tests];
                }

                draft.Data!.NewTests.push({ Id: response.data.Id, Name: response.data.Name });
            })

            return response.data.Id
        }

        return undefined;
    }


    public async deleteTest(args: { testId: string, partId: string }) {
        const response = await api.delete<TestLinkDTO>(endPoints.DELETE_TEST(args.partId, args.testId));

        if (response && response.status < 300 && response.data) {
            coursePartService.MutatePartState(args.partId, draft => {
                draft.Data!.Tests = draft.Data!.Tests.filter(t => t.Id !== args.testId);
                draft.Data!.NewTests = draft.Data!.NewTests.filter(te => te.Id !== args.testId);
            })
        }
    }


    public async setTestsOrder(partId: string, tests: TestLinkDTO[]) {
        const tstIds = tests.map(l => l.Id)
        const reponse = await api.post<void>(endPoints.REORDER_TESTS(partId), tstIds);

        if (reponse && reponse.status === 200) {
            coursePartService.MutatePartState(partId, draft => {
                draft.Data!.Tests = tests;
            })
        }
    }

    public async getTestData(partId: string, testId: string) {
        const response = await api.get<BaseTestDataDTO>(endPoints.GET_TEST(partId, testId));
        store.set(currentTestAtom, response.data);
        return response.data;
    }

    public clearTestData() {
        store.set(currentTestAtom, undefined);
    }

    public testChanged (data: BaseTestDataDTO){
        coursePartService.updateTestInPart(data.PartID, data.TestId, data.Name);
        this.savedebounce.debounce( data );
    }

    public async saveTestData(data: BaseTestDataDTO) {
        const response = await api.post<boolean>(endPoints.SAVE_TEST(data.PartID, data.TestId), data);
        coursePartService.updateTestAuthoringOKInPart(data.PartID, data.TestId, response.data);
    }

    public updateTestCuepointData(lecture: EditLectureDto, testId: string, clear: boolean = false) {

        coursePartService.MutatePartState(lecture.CoursePartId!, draft => {
            const test = draft.Data!.Tests.find(t => t.Id === testId);
            if (test) {
                if (clear) {
                    test.ConnectedLecture = undefined;
                }
                else {
                    test.ConnectedLecture = {
                        Id: lecture.LectureId,
                        Name: lecture.Name,
                        Ready: false
                    }
                }
            }
        })
    }

}

const testService = new TestService();
export default testService;