import { useState } from "react";
import { useI18n } from 'src/utils/lni18n';
import AddTestForm, { CreateTestData } from 'src/coursepart/Test/AddTestForm';
import { useAtom } from 'jotai';
import { openPartsDataAtom } from 'src/coursepart/CoursePartService';
import { AddTestCommand } from "src/coursepart/domain/AddTestCommand";
import testService from "src/coursepart/Test/TestService";


const CuePointPickTest = (props: { partId: string, testId?: string, onChange: (val: string, startEdit: boolean) => void }) => {

    const { languageService: t } = useI18n();
    const { onChange, testId, partId } = props;
    const [createData, setCreateData] = useState<CreateTestData>({ name: undefined, type: undefined, lastInList: false });
    const [parts] = useAtom(openPartsDataAtom);
    const partData = parts[partId!];
    const [existingTest, setExistingTest] = useState<string>(testId || "")

    // const handleSubmitForm = (values: any) => {
    //     onSubmit(values);
    // }

    const pickExistingTest = (id: string) => {
        setExistingTest(id);
        onChange(id, false);
    }

    const onChangeInCreateForm = (data: CreateTestData) => {
        data.name = data.name?.trimStart();
        setCreateData(data);
    }

    const createTest = async () => {
        const data = { ...createData, name: createData.name?.trim() }
        const cmd: AddTestCommand = {
            Name: data.name!,
            Type: data.type!,
            PartId: partId!,
            LastInList: data.lastInList
        }
        const id = await testService.addTest(cmd);
        if (id) {
            onChange(id, true);
        }

    }

    return <>

        <div className='d-flex flex-wrap'>
            <div className='col-md-6 mb-4'>
                <h3>{t.getText("test.create")}</h3>
                <div className='mt-3'>
                    <button disabled={!((createData.name && createData.name.length > 3) && createData.type)} className="btn btn-primary btn-small" onClick={createTest}>{t.getText("create")}</button>
                </div>
                <AddTestForm onChange={onChangeInCreateForm} />

            </div>
            <div className='col-md-6 pl-md-5'>
                <h3>{t.getText("tests")}</h3>
                <div className='mt-3'>
                    {partData.Data?.Tests.map(t => {
                        const muted = testId !== t.Id && !!t.ConnectedLecture;
                        return <div key={t.Id} className={`p-2 ${(existingTest === t.Id ? "bg-primary-lightest" : "")}`}>
                            <label className={`d-flex pointer ${muted ? "text-muted" : ""}`}>
                                <input type='radio' disabled={muted} onChange={() => pickExistingTest(t.Id)} name="existingTest" checked={existingTest === t.Id} />
                                <span className='ml-3'>{t.Name}</span>
                            </label>
                        </div>
                    })}
                </div>
            </div>
        </div>



    </>

}

export default CuePointPickTest;