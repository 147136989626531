import { LanguageService } from "./languageService";

export const FormatTimeDuration = (duration:string, t:LanguageService) => {
    const
        durationArr = duration.split(':'),
        hour = parseInt(durationArr[ 0 ]),
        minute = parseInt(durationArr[ 1 ]),
        second = parseInt(durationArr[ 2 ]);
    let output = '';
    output += (hour ? hour + ' ' + t.getText('hour') + ' ' : '');
    output += (minute ? minute + ' ' + t.getText('minute') + ' ' : '');
    output += ((!hour && !(minute > 15) && second )? second + ' ' + t.getText('second') : '');

    return output.replace(/, +$/, "");
}

export const FormatTimeDurationFromSeconds = (lenght:number, t:LanguageService) => {
        const duration =  t.gethhmmss( lenght, false, true );
        return FormatTimeDuration(duration, t);
}


