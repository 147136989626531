import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useCallback, useEffect, useState } from "react";
import { useI18n } from 'src/utils/lni18n';
import { useParams } from 'react-router-dom';
import CuePointPickTest from './CuePointPickTest';
import { BaseTestDataDTO } from 'src/coursepart/Test/DomainModels';
import testService from 'src/coursepart/Test/TestService';
import WaitRipple from 'src/components/WaitRipple';
import CuePointEditTest from './CuePointEditTest';


const CuePointTestDialog = (props: { testId?: string, onSubmit: (val: string) => void, onCancel: () => void }) => {

    const { languageService: t } = useI18n();
    const { onCancel, testId, onSubmit } = props;
    const { partId } = useParams();
    const [editTest, setEditTest] = useState(!!testId);
    const [existingTest, setExistingTest] = useState<string>(testId || "")

    const [currentTestInEdit, setCurrentTestInEdit] = useState<BaseTestDataDTO | undefined>(undefined);

    const onChangeInTests = (testid: string, startEdit: boolean) => {
        setExistingTest(testid);

        if (startEdit) {
            window.setTimeout(() => showEditTest(testid), 50);
        }
    }

    useEffect(() => {
        if (testId) {
            showEditTest(testId);
        }
    }, [testId])

    const showEditTest = useCallback(async (testid?: string) => {
        setEditTest(true);
        let id = testid;
        if (!id) id = existingTest;
        const data = await testService.getTestData(partId!, id)
        if (data) {
            setCurrentTestInEdit(data);
        }

    }, [existingTest]);

    const hideEditTest = () => {
        setEditTest(false);
        testService.clearTestData();
    }

    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>
                {t.getText("test")}
                <div className='my-3'>

                    {!!existingTest && !editTest &&
                        <button className="btn btn-primary btn-small" onClick={() => showEditTest()}>{t.getText("edit")}</button>
                    }

                    {editTest &&
                        <button className="btn btn-primary btn-small" onClick={() => hideEditTest()}>{t.getText("cuepoint.other.test")}</button>
                    }
                </div>
            </ModalHeader>
        </span>

        <ModalBody>
            {!editTest &&
                <CuePointPickTest partId={partId!} testId={testId} onChange={onChangeInTests} />
            }
            {editTest && <>

                {!currentTestInEdit && <WaitRipple />}

                {currentTestInEdit &&
                    <CuePointEditTest data={currentTestInEdit} />
                }
            </>
            }
        </ModalBody>
        <ModalFooter >
            <button type='button' onClick={onCancel} value="cancel" className='btn btn-inverse btn-small' >{t.getText("cancel")}</button>
            <button type="button" onClick={() => onSubmit(existingTest)} className='btn btn-primary btn-small' >{t.getText("save.short")}</button>

        </ModalFooter>


    </>

}

export default CuePointTestDialog;