import { useStore } from "jotai";
import { AppErrors, ErrorService } from "./ErrorService";
import { useEffect } from "react";
import { useDialog } from "../Modalservice/Dialogservice";
import ErrorDialog from "./ErrorDialog";

const ErrorComponent = () => {

    const store = useStore();
    const dialogPortal = useDialog();

    useEffect(() => {
        const unSub = store.sub(AppErrors, () => {
            const errors = store.get(AppErrors);
            if (errors.length > 0) {

                const error = ErrorService.getError();
                if (error) {
                    dialogPortal({
                        factory: (onSubmit, onCancel) => {
                            return <ErrorDialog onSubmit={onSubmit} onCancel={onCancel} error={error} />
                        },
                        size: "md"
                    }).finally(() => ErrorService.clearError(error));
                }
            }
        });
        return ()=> unSub();
    }, [])

    return <></>
}

export default ErrorComponent; 