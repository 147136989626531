import { useI18n } from "../../utils/lni18n";
import { ModalBody, ModalFooter } from 'reactstrap';
import { createPortal } from 'react-dom';
import { useCallback } from "react";

const ReloadDialog = (props: { onSubmit: (val: any) => void, onCancel: () => void }) => {

    const { languageService: t } = useI18n();
    const { onCancel } = props;

    return <form action={window.location.toString()} method="POST" >
            <ModalBody>
                {t.getText("web.site.new.release")}
            </ModalBody>
            <ModalFooter >
                <button type='button' onClick={onCancel} value="cancel" className='btn btn-inverse btn-small' >{t.getText("cancel")}</button>
                <button type="submit" className='btn btn-primary btn-small' >{t.getText("refresh")}</button>

            </ModalFooter>
        </form>
}

export default ReloadDialog; 