import { useState, useMemo, ChangeEvent } from "react";
import coursePartService, { onePartatom } from "../CoursePartService";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import { useAtom } from "jotai";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import { CopyDialog, CopyDialogOptions, CopyPartData } from "../Dialogs/CopyDialog";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import { ResetDialog, ResetDialogOptions } from "../Dialogs/ResetDialog";
import WaitRipple from "src/components/WaitRipple";
import { partPermissionsUrlCreator } from "../Permissions/CoursePartPermissionsUrlCreator";
import { ObjectTypes } from "../domain/ObjectTypes";
import { PermissionsService } from "src/components/Permissions/PermissionService";
import Categories from "./Categories";
import CopyProtect from "./CopyProtect";
import PermissionsForm from "src/components/Permissions/PermissionForm";
import { DialogData } from "src/components/Permissions/PermissionTypes";
import ReadOnlyGeneral from "../ReadOnly/ReadOnlyGeneral";
import { currentCourseAtom } from "src/course/CourseService";
import { urlService } from "src/services/NavService";


const CoursePartGeneral = () => {

    const { partId } = useParams();

    const [currentCourse] = useAtom(currentCourseAtom);

    const { state } = useLocation();

    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);
    const part = partData.Data;
    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();
    const navigate = useNavigate();
    const [resetting, setResetting] = useState(false);




    const permissionService = useMemo(() => {
        if (partId && partData.Data!.UserIsAdmin) {
            return new PermissionsService(partId!,
                partPermissionsUrlCreator.GetstartUrl(partId!, ObjectTypes.coursepart),
                partPermissionsUrlCreator.GetSearchUrl(ObjectTypes.coursepart, partId!),
                coursePartService.addAuthor.bind(coursePartService),
                coursePartService.removeAuthor.bind(coursePartService)
            );
        }
        else {
            return null;
        }
    }, [partId]);

    const permissionShowData: DialogData = useMemo(() => ({
        objectId: partId!,
        objType: ObjectTypes.coursepart,
        setForallSubitems: false,
        setFlags: []
    }), [partId])

    const updateName = (e: ChangeEvent<HTMLInputElement>) => {
        coursePartService.updatePartName(e.currentTarget.value, partId!);
    }



    async function copyPart() {

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: CopyDialogOptions = {
                    languageService: t,
                    partName: part!.Name,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                }
                return <CopyDialog {...dprops} />
            },
            size: "md"
        }).then(async (res: CopyPartData) => {
            if (res && res.name && res.type !== undefined) {
                const partData = await coursePartService.copyPart(partId!, res);
                if (partData)
                    navigate(urlService.urlToPart(partData!.Id));
            }
        });
    }

    async function deletePart() {

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("delete"),
                    message: <div className="preserve-white" >{t.getText("sure_to_delete")}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then(async (res: boolean) => {
            if (res) {
                const nextData = await coursePartService.deletePart(partId!);
                if (currentCourse) {
                    navigate(urlService.urlToCourse(currentCourse));
                }
                if (nextData?.currentCourse) {
                    navigate(urlService.urlToCourse(nextData!.currentCourse));
                }
                else if (nextData?.partId) {
                    navigate(urlService.urlToPart(nextData!.partId));
                }
                else {
                    navigate(`/`);
                }

            }
        });
    }

    function resetPart(): void {

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ResetDialogOptions = {
                    languageService: t,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                }
                return <ResetDialog {...dprops} />
            },
            size: "md"
        }).then(async (res: { includeLectures: boolean }) => {

            if (res) {
                setResetting(true);
                try {
                    await coursePartService.resetPart(partId!, res.includeLectures);
                } finally {
                    setResetting(false);
                }

            }
        });
    }

    if (state && state.readOnly) return <ReadOnlyGeneral />

    return (
        <div className="mb-5">
            <h3 className="mb-4">{t.getText("general")}</h3>
            <div className="row">
                <div className="col-sm-12 col-md-8 position-relative">

                    {resetting && <WaitRipple />}

                    <h5>{t.getText("name")}</h5>
                    <input type="text" className="form-control" onChange={updateName} value={part?.Name} />

                    <div className="my-4">
                        <h5 className="">{t.getText("owner")}</h5>
                        {part!.Authors.map((a, i) => <div key={i} className="mb-3">
                            {a.FirstName} {a.LastName}{a.Mail && ` (${a.Mail})`}
                        </div>)}
                    </div>

                    <hr />

                    <CopyProtect part={part!} />
                    <hr />

                    <div className="mt-3">
                        <Categories part={part!} />
                    </div>




                </div>
                <div className="col-md-4  col-12 d-flex">
                    <div className="ml-auto">
                        <button onClick={copyPart} className="d-block mb-3 btn btn-primary">{t.getText("copy")}</button>
                        <button onClick={resetPart} className="d-block mb-3 btn btn-inverse">{t.getText("reset")}</button>
                        <button onClick={deletePart} className="d-block mb-3 btn btn-warning">{t.getText("delete")}</button>
                    </div>
                </div>
            </div>
            <hr />


            {partData.Data?.UserIsAdmin &&

                <div className="mt-3">
                    <PermissionsForm readOnly={false} permissionService={permissionService!} showData={permissionShowData} urlCreator={partPermissionsUrlCreator} />
                </div>
            }

        </div >
    )
}

export default CoursePartGeneral;
