import { useState } from 'react';
import {  Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import { useI18n } from 'src/utils/lni18n';


const CoursePartTypeahead = (props: {items: any[], itemChosen: (item:any)=>void}) => {

    const { itemChosen } = props;

    const[ items ] = useState([...props.items].sort((a,b) => {
        const nameA = a.Name.toUpperCase();
        const nameB = b.Name.toUpperCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    }));


    const[selected, setSelected ] = useState<any[]>([])


    const { languageService: t } = useI18n();

    const handleItemChosen = (e:any) => {

        if (e && e.length > 0 && e[0].Id) {
            itemChosen(e[0]);
            // setSelected([e[0]]);
        }
    }


    const renderItems = (option:any, props:any) => {
        return <div key="Receiver" className="d-flex">
            <div className="ml-2">
                <span>
                    <Highlighter search={props.text}>
                        {option.Name}
                    </Highlighter>
                </span>

            </div>
        </div>
            ;
    }

    return <Typeahead
        id="permissions-select"
        labelKey="Name"
        minLength={1}
        multiple={false}
        onChange={handleItemChosen}
        options={items}
        placeholder={t.getText("name")}
        renderMenuItemChildren={renderItems}
        dropup={true}
        positionFixed={true}
        onInputChange={()=>itemChosen({Id:undefined})}
        
    />

}


export default CoursePartTypeahead;