import { useCallback, useMemo, useState } from "react";

import { useI18n } from "src/utils/lni18n";
import { useAtom } from "jotai";

import courseService, { currentCourseAtom, oneCourseatom } from "src/course/CourseService";
import SelectPartDetails from "src/Print/SelectPartDetails";
import PrintCourseComponent from "src/Print/Course/PrintCourseComponent";



const PrintCourse = () => {

  const [courseId] = useAtom(currentCourseAtom);
  const courseAtom = useMemo(() => oneCourseatom(courseId || ""), [courseId]);
  const [courseData] = useAtom(courseAtom);

  const { PrintData } = courseData;
  const { languageService: t } = useI18n();

  function setItem(): void {
      courseService.clearPrintData(courseId!);
  }

  const getPrint = (types: number[]) => {
    courseService.fetchPrintData(courseId!, types, true);
  };

  return (
    <div className="mb-5">
      <div className="d-print-none">
        <h3 className="mb-3">{t.getText("print")}</h3>

        <SelectPartDetails onChange={setItem} getPrint={getPrint} />

      </div>

      {PrintData && <PrintCourseComponent printData={PrintData} /> }


    </div>
  )
}

export default PrintCourse;
