import { UncontrolledCollapse } from "reactstrap";
import { Alert } from "reactstrap";
import { parseFileSize, getFileSizeString } from "../../utils/FileSizeUtils";

import { atom, useAtom } from "jotai";
import LnResultIcon from "../LnResultIcon";
import LnIcon from "../LnIcon";
import { useDropzone } from "react-dropzone";
import { TestResultStatus } from "../../coursepart/Test/TestEnums";
import { FileOptions } from "../../coursepart/domain/FileDialogsettings";
import { LanguageService } from "src/utils/languageService";
import { useEffect } from "react";

export declare type FilePickerProps = {
    fileOptions: FileOptions,
    languageService: LanguageService,
    onFilePicked: (file: File) => void,
    className?: string,
};

const errorAtom = atom<string | undefined>(undefined);
errorAtom.debugLabel = "filePickerError";


const FilePicker = (props: FilePickerProps) => {

    const { fileOptions, languageService, onFilePicked, className } = props;
    const t = languageService;

    const maxSizeInBytes = parseFileSize(fileOptions.maxSize);
    const maxSizeString = getFileSizeString(maxSizeInBytes);

    let allowedTypes = fileOptions.accepts ? Object.values(fileOptions.accepts).flat().map(v => v.replace(".", "")).join(", ") : t.getText("all");


    allowedTypes = allowedTypes || t.getText("all");

    const _className = className ; //+ ' border rounded-lg p-3';

    const [errorMess, setError] = useAtom(errorAtom);

    useEffect(() => {
        // clear all errors when shown (again)
        setError(undefined);
    },[]);

    const onDrop = (files: File[]) => {
        if (files.length === 0) {
            setError(t.getText("allowed.file.types") + " " + allowedTypes);
            return;
        }

        const fi = files[0];

        if (fi.size > maxSizeInBytes) {
            setError(t.getText("file.too.big", fi.name, getFileSizeString(fi.size), maxSizeString));
            return;
        }
        onFilePicked(fi);
    }

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: fileOptions.accepts,
        noClick: true,
        multiple: false
    });

    return (
        <div className={_className + (isDragActive ? " bg-primary-lightest" : "")}

            {...getRootProps()} >

            <Alert className="d-flex flex-row" isOpen={!!errorMess} toggle={() => setError(undefined)} color="danger">
                <LnResultIcon className="mr-3" status={TestResultStatus.FollowUpNeeded} />
                <div className="preserve-white ">{errorMess}</div>
            </Alert>

            <input {...getInputProps()} />

            <button type="button" className="mt-3  btn btn-small btn-inverse" onClick={open}>
                <LnIcon name="upload" />
                &nbsp;{t.getText("choose.file")}
            </button>

            <div className="mb-3 small text text-muted">
                {t.getText("drag.to.upload")}
                &nbsp;&nbsp;
                {t.getText("max.filesize")} {maxSizeString}

                <div>
                    <button type="button" id="allowedTypesBtn" className="btn btn-link btn-small">{t.getText("allowed.file.types")}</button>
                    <UncontrolledCollapse toggler="#allowedTypesBtn">{allowedTypes}</UncontrolledCollapse>
                </div>
            </div>

        </div>
    )
}
export default FilePicker;