import { useCallback, useState, useMemo, useEffect } from "react";
import LectureLink from './LectureLink';
import classes from './lecture.module.scss';
import { LectureDtoWithOriginals } from '../domain/LectureDtoWithOriginals';
import { Active, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, UniqueIdentifier, useSensors, useSensor, DragOverlay, DropAnimation, defaultDropAnimationSideEffects, DragOverEvent } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import { useImmerAtom } from 'jotai-immer'

import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu,
    ItemParams
} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import CreateLectureForm from './CreateLectureForm';
import { AddLectureData } from '../domain/AddLectureData';
import coursePartService, { onePartatom } from '../CoursePartService';
import { arrayMoveMutate } from '../../utils/ArrayMethods';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { ConfirmationDialog, ConfirmationOptions } from '../../components/Modalservice/ConfirmationDialog';
import { useParams } from 'react-router-dom';
import scrapBookService from 'src/components/ScrapBook/ScrapBookService';
import ScrapBookDialog, { ScrapBookDialogResult, ScrapType } from 'src/components/ScrapBook/ScrapBookDialog';
import { useI18n } from "src/utils/lni18n";
import { CopyLectureCommand } from "./domain/CopyLectureCommand";
import ReadOnlyLectures from "../ReadOnly/Lecture/ReadOnlyLectures";
import AllOriginalFilesDialog from "./OriginalFiles/AllOriginalFilesDialog";
import lectureService  from "./LectureService";
import { Link } from "react-router-dom";

const MENU_ID = "lect-menu-id";

export type LetureEvent<T> = { value: T };
export const LectureEventnames = { newLecture: "newLecture" };

const Lectures = () => {


    const { partId } = useParams();

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();

    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData, setPartDataFunction] = useImmerAtom(partAtom);
    const part = partData.Data;


    const [lects, setLects] = useState<LectureDtoWithOriginals[] | undefined>([]);

    const [active, setActive] = useState<Active | null>(null);

    const { show } = useContextMenu({
        id: MENU_ID
    });

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );


    useEffect(() => {
        if (part && part.Lectures) {
            setLects(part.Lectures)
        }

    }, [part?.Lectures])


    if (lects === undefined) {
        return null;
    }

    const dragOver = useCallback((ev: DragOverEvent) => {
        const { active, over } = ev;
        if (!over) {
            return;
        }
        if (over && active.id !== over?.id) {
            if (part) {
                setPartDataFunction(data => {
                    arrayMoveMutate(data.Data!.Lectures, active.data.current?.sortable.index, over.data.current?.sortable.index);
                });
            }
        }
    }, [lects]);

    const dragEnd = useCallback(async (ev: DragEndEvent) => {
        setActive(null);
        await coursePartService.saveLecturesOrder(part!.Id);

    }, [lects, setActive]);


    const dropAnimation: DropAnimation = {
        sideEffects: defaultDropAnimationSideEffects({
            styles: {
                active: {
                    opacity: '0.5',
                },
            },
        }),
    };

    const getLectById = useCallback((id: UniqueIdentifier) => {
        return lects.find(t => t.Id === id);
    }, [part, lects]);

    function displayContextMenu(e: React.MouseEvent, lect: LectureDtoWithOriginals) {
        show({
            event: e,
            props: lect
        });
    }

    async function handleItemClick(params: ItemParams<LectureDtoWithOriginals, any>) {
        const { id, props }: ItemParams<LectureDtoWithOriginals, any> = params;


        switch (id) {

            case "delete":
                if (!props) return;

                var p = dialogPortal({
                    factory: (onSubmit, onCancel) => {
                        const dprops: ConfirmationOptions = {
                            className: "",
                            title: t.getText("lecture"),
                            message: <><div className='mb-3'><strong>{props.Name}</strong></div><div>{t.getText("delete_general_confirm")}</div></>,
                            languageService: t,
                            show: true,
                            onClose: onCancel,
                            onSubmit: onSubmit
                        }
                        return <ConfirmationDialog {...dprops} />
                    },
                    size: "md"
                });

                p.then((res: boolean) => {
                    if (res) {
                        coursePartService.deleteLecture(part!.Id, props);
                    }
                })
                    .catch(() => { });

                break;

            case "copy":
                if (props) {
                    await scrapBookService.addLecture(props!);
                }
                break;
        }
    }

    function addLecture() {

        var p = dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <CreateLectureForm header={t.getText("add.lecture")} onCancel={onCancel} onSubmit={onSubmit} />
            }
        });

        p.then((lectData: any) => {
            createNewLecture(lectData);
        }).catch(() => { });


    }

    function createNewLecture(values: AddLectureData) {
        values.partId = part!.Id;
        coursePartService.addLecture(values);
    }

    const showScrapBook = () => {

        scrapBookService.loadLectures();
        const promise = dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <ScrapBookDialog contextId={partId!} type={ScrapType.lecture} onCancel={onCancel} onSubmit={onSubmit} />
            }
        });

        promise.then((data: ScrapBookDialogResult) => {
            const cmd: CopyLectureCommand = {
                destCoursepart: partId!,
                srcLecture: data.id
            }
            coursePartService.copyLecture(cmd)
                .then(() => scrapBookService.removeLecture(data.id));
        })

    }

    const handleOriginals = () => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <AllOriginalFilesDialog partId={partId!} lectures={lects} onCancel={onCancel} onSubmit={onSubmit} />
            }
        });
    };
    const setDiff = (state: boolean) => {
        lectureService.setDiffMode(state);
    }

    if (part && part.Locked) return <ReadOnlyLectures />

    return <>
        <DndContext
            sensors={sensors}
            onDragStart={({ active }) => {
                setActive(active);
            }}
            onDragOver={dragOver}
            onDragEnd={dragEnd}
            onDragCancel={() => {
                setActive(null);
            }}
        >
            <div className='d-flex flex-wrap'>
                <button type="button" onClick={showScrapBook} className='ml-3 btn btn-primary btn-small'>{t.getText("scrapbook")}</button>
                <button type="button" onClick={handleOriginals} className='ml-3 mb-3 btn btn-primary btn-small'>{t.getText("lecture.edit.files")}</button>
                <button onClick={addLecture} className='ml-3 mb-3 btn btn-primary btn-small'>{t.getText("add.lecture")}</button>
                
                <Link to={"../lecturesdiff"}  className='ml-auto btn btn-inverse btn-small' >{t.getText("differences")}</Link>
            </div>

            <div className={"mt-5 flex-row flex-wrap " + classes.cardWrapper}>
                <SortableContext items={lects?.map(l => l.Id as UniqueIdentifier)}>
                    {lects?.map(le => <LectureLink onContextMenu={e => displayContextMenu(e, le)} partId={partId!} active={active?.id} lecture={le} key={le.Id} />)}
                </SortableContext>
            </div>

            {createPortal(
                <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>

                    {active
                        ? <LectureLink onContextMenu={a => { }} partId={partId!} dragged={true} lecture={getLectById(active.id)!} />
                        : null}
                </DragOverlay>,
                document.body
            )}


        </DndContext>
        <Menu id={MENU_ID}>
            <Item id="delete" onClick={handleItemClick}>
                {t.getText("delete")}
            </Item>

            <Item id="copy" onClick={handleItemClick}>
                {t.getText("copy")}
            </Item>

        </Menu>


    </>

}

export default Lectures;
