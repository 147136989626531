export class AudioContextService {
    private audioContext!: AudioContext;

    constructor() {
        if (window["AudioContext"])
            this.audioContext = new AudioContext();
    }

    public get ready(): Boolean {
        return !!this.audioContext;
    }

    public get Context(): AudioContext {
        if (this.audioContext?.state == 'suspended')
            this.audioContext.resume();

        return this.audioContext;
    }
}

const audioContextService = new AudioContextService();
export default audioContextService;