import React from 'react';


const FileIcon = (props:{name?:string, className?:string }) => {

    const ext = props.name ? props.name.toUpperCase() : "";

    return <svg
       xmlns="http://www.w3.org/2000/svg"
       width="16"
       height="16"
       viewBox="0 0 16 16" className={props.className}>
      <path
         style={{fill:"#495057"}}
         d="M14.341 3.579c-0.347-0.473-0.831-1.027-1.362-1.558s-1.085-1.015-1.558-1.362c-0.806-0.591-1.197-0.659-1.421-0.659h-7.75c-0.689 0-1.25 0.561-1.25 1.25v13.5c0 0.689 0.561 1.25 1.25 1.25h11.5c0.689 0 1.25-0.561 1.25-1.25v-9.75c0-0.224-0.068-0.615-0.659-1.421v0zM12.271 2.729c0.48 0.48 0.856 0.912 1.134 1.271h-2.406v-2.405c0.359 0.278 0.792 0.654 1.271 1.134v0zM14 14.75c0 0.136-0.114 0.25-0.25 0.25h-11.5c-0.135 0-0.25-0.114-0.25-0.25v-13.5c0-0.135 0.115-0.25 0.25-0.25 0 0 7.749-0 7.75 0v3.5c0 0.276 0.224 0.5 0.5 0.5h3.5v9.75z"
         id="path4" />
      <text
         textAnchor="middle" fill="#495057"
         style={{ fontSize:"5.5px", fontWeight:"bold", fontFamily:"PT Sans"}}
         x="8"
         y="11"
         id="text824">{ext}</text>
    </svg>


}

export default FileIcon;

