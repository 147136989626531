import { TinyTheme } from "src/course/domain/CourseEditModel";
import classes from "../SideBar.module.scss";
import LnIcon from "src/components/LnIcon";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from '../../Modalservice/ConfirmationDialog';
import { IdAndNameAndSelectDto } from 'src/types';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { useI18n } from "src/utils/lni18n";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import courseService from "src/course/CourseService";
import { urlService } from "src/services/NavService";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import coursePartService from "src/coursepart/CoursePartService";

export default function PartCard(props: { readOnly: boolean, dragged?: boolean, partId?: string, part: IdAndNameAndSelectDto, theme: TinyTheme, currentCourseId: string }) {

    const { part, theme, currentCourseId, partId, dragged, readOnly } = props;

    const dialogPortal = useDialog();
    const { languageService: t } = useI18n();
    const navigate = useNavigate();

    const [selected, setSelected] = useState(false);
    
    useEffect(() => {
        setSelected(part.Id === partId);
     }, [partId, part.Id])

    const { partId: urlPartId } = useParams();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: part.Id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: dragged ? "75%" : ""
    };



    function removePart(e: React.MouseEvent, themeId: string, part: IdAndNameAndSelectDto): void {
        e.preventDefault();
        e.stopPropagation();

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("remove"),
                    message: <div className="preserve-white" >{t.getText("remove.x.from.course", `${part.Name}`)}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then(async (res: boolean) => {
            if (res) {
                const nextId = await courseService.removePart(currentCourseId, theme.Id, part.Id);

                if (urlPartId === partId) { // we are removing a part in edit - must navigate to other view
                    if (nextId) {
                        navigate(urlService.urlToPartInCourse(currentCourseId, themeId, nextId!));
                    }
                    else {
                        navigate(urlService.urlToCourse(currentCourseId));
                    }
                }
            }
        });

    }


    function goToPart(themeId: string, nextPartId: string): void {

        if( partId  && coursePartService.isPartDirty( partId )){
         
            dialogPortal({
                factory: (onSubmit, onCancel) => {
                    const dprops: ConfirmationOptions = {
                        className: "",
                        title: t.getText("close.editor"),
                        message: <div className="preserve-white" >{t.getText("publish.before.close")}</div>,
                        languageService: t,
                        show: true,
                        onClose: onCancel,
                        onSubmit: onSubmit,
                        buttons: ConfirmationButtons.YesNo
                    }
                    return <ConfirmationDialog {...dprops} />
                },
                size: "md"
            }).then(async (res: boolean) => {
                if (!res) {
                    navigate(urlService.urlToPartInCourse(currentCourseId, themeId, nextPartId, "texts"));
                }
                else {
                    navigate(urlService.urlToPartInCourse(currentCourseId, theme.Id, partId, "publish"));
                }
            });
    
        }
        else{
            navigate(urlService.urlToPartInCourse(currentCourseId, themeId, nextPartId, "texts"));
        }
    }

    return <div style={style} ref={setNodeRef} id={part.Id} role="link" onClick={() => goToPart(theme.Id, part.Id)} key={part.Id}
        className={`pointer ${dragged ? classes.dragged : ""} ${classes.part} ${selected ? "selected" : ""}`}  >

        <div className='d-flex align-content-center w-100'>
            {!readOnly &&
                <div className={`ns-drag-cursor pr-3 ${classes.dragh}`} {...attributes} {...listeners}>
                    <LnIcon name="draghandle" className='icon-small' />
                </div>
            }

            <div >{part.Name}</div>
            <div className="ml-auto d-flex">
                {part.Dirty && <LnIcon name="alert" className='icon-small mb-2 mr-2' />}
                <button onClick={(e) => removePart(e, theme.Id, part)} className="btn btn-link" type="button">
                    <LnIcon name="delete" className='icon-small' />
                </button>
            </div>
        </div>

    </div>


}