
import { useI18n } from "src/utils/lni18n";
import { useDialog } from "src/components/Modalservice/Dialogservice";

import { PermissionsService } from "src/components/Permissions/PermissionService";
import PermissionsForm from "src/components/Permissions/PermissionForm";
import { DialogData } from "src/components/Permissions/PermissionTypes";
import { ObjectTypes } from "src/coursepart/domain/ObjectTypes";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAtom } from "jotai";
import courseService, { currentCourseAtom, oneCourseatom } from "./CourseService";
import LnIcon from "src/components/LnIcon";
import { useNavigate } from "react-router-dom";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import HtmlTextEditor from "src/components/TextEditor/HtmlTextEditor";
import { debouncer } from "src/utils/debouncer";
import { InputDialog, InputOptions } from "src/components/Modalservice/InputDialog";
import { coursePermissionsUrlCreator } from "./CoursePermissionsUrlCreator";
import { CopyCourseDialog, CopyCourseDialogOptions } from "./CopyCourseDialog";
import { CourseCopyCommand } from "./domain/CourseCopyCommand";
import { urlService } from "src/services/NavService";

const CourseGeneral = () => {

    const [courseId] = useAtom(currentCourseAtom);
    const saveDescDebounce = useRef<debouncer | undefined>(undefined);
    const saveNameDebounce = useRef<debouncer | undefined>(undefined);
    const courseAtom = useMemo(() => oneCourseatom(courseId || ""), [courseId]);
    const [courseData] = useAtom(courseAtom);

    const [initialData, setInitialData] = useState<{ name: string, desc: string }>({ name: "", desc: "" });
    const [cType, setcType] = useState<number>(courseData.Course.CourseType);

    useEffect(() => {
        if (courseData) {
            setInitialData({ desc: courseData.Course.Description, name: courseData.Course.Name });
        }
    }, [courseId]);

    useEffect(() => {
        if (courseData) {
            setcType(courseData.Course.CourseType);
        }
    }, [courseData]);

    useEffect(() => {
        saveDescDebounce.current = new debouncer(courseService.saveCourseDescription, 500, courseService);
        saveNameDebounce.current = new debouncer(courseService.saveCourseName, 500, courseService);
        return () => saveDescDebounce.current?.clear();
    }, [])

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();
    const navigate = useNavigate();

    const readOnly = !!courseData.Locked;
    const haveClasses = courseData.Course.StudentClasses && courseData.Course.StudentClasses.length > 0;

    const permissionService = useMemo(() => {
        if (courseId && courseData.UseCoursePermissionsTable) {
            return new PermissionsService(courseId!,
                coursePermissionsUrlCreator.GetstartUrl(courseId!, ObjectTypes.course),
                coursePermissionsUrlCreator.GetSearchUrl(ObjectTypes.course, courseId!),
                () => { },
                (a: any) => { }
            );
        }
        else {
            return null;
        }
    }, [courseId]);

    const permissionShowData: DialogData = useMemo(() => ({
        objectId: courseId!,
        objType: ObjectTypes.course,
        setForallSubitems: false,
        setFlags: []
    }), [courseId])



    const copyCourse = useCallback(() => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: CopyCourseDialogOptions = {
                    languageService: t,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                    course: courseData.Course
                }
                return <CopyCourseDialog {...dprops} />
            },
            size: "xl"
        }).then(async (res: CourseCopyCommand) => {
            const id = await courseService.copyCourse(res);
            if (id) {
                navigate(urlService.urlToCourse(id));
            }
        });
    }, [courseData]);


    function deleteCourse(): void {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("delete"),
                    message: <div className="preserve-white" >{t.getText("delete_course_confirm")}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then(async (res: boolean) => {
            if (res) {
                const res = await courseService.deleteCourse(courseId!);
                if (res) {
                    navigate(urlService.urlToCourse(res));
                }
                else {
                    navigate(`/`);
                }
            }
        });
    }


    function saveDescription(e: ChangeEvent<HTMLTextAreaElement>): void {
        const { value } = e.currentTarget;
        saveDescDebounce.current && saveDescDebounce.current.debounce(courseId!, value);
    }

    function saveName(e: ChangeEvent<HTMLInputElement>) {
        const { value } = e.currentTarget;
        saveNameDebounce.current && saveNameDebounce.current.debounce(courseId!, value);
    }

    function addTheme(): void {

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: InputOptions = {
                    title: t.getText("add.theme"),
                    message: <div>{t.getText("name")}</div>,
                    languageService: t,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                    type: "input",
                }
                return <InputDialog {...dprops} />
            },
            size: "md"
        }).then((name: string) => {
            if (name !== "") {
                courseService.addTheme(courseId!, name);
            }
        });

    }


    return (
        <div className="mb-5">
            <h3 className="mb-4">
                {courseData.IsDirty && <LnIcon name="alert" className='icon-small mr-3' />}
                {t.getText("general")}
            </h3>
            <div className="row">
                <div className="col-sm-12 col-md-8 position-relative mb-4">
                    <h5 className="mb-2">{t.getText("name")}</h5>
                    <input disabled={readOnly} type="text" defaultValue={initialData.name} className="form-control" onChange={saveName} />

                    <h5 className="mt-4 mb-2">{t.getText("description")}</h5>
                    <HtmlTextEditor readOnly={readOnly} onChange={saveDescription} initialValue={initialData.desc} name="Description"  ></HtmlTextEditor>

                </div>

                <div className="col-md-4  col-12 d-flex flex-column">
                    {!readOnly &&
                        <button onClick={addTheme} className="ml-auto  mb-5 btn btn-primary">{t.getText("add.theme")}</button>
                    }
                    {courseData.CanCopy &&
                        <button onClick={copyCourse} className="ml-auto  mb-3 btn btn-primary">{t.getText("copy")}</button>
                    }
                    {!readOnly &&
                        <button disabled={haveClasses} onClick={deleteCourse} className="ml-auto mb-3 btn btn-warning">{t.getText("delete")}</button>
                    }
                    {haveClasses && <div className="ml-auto w-lg-50 alert alert-warning">{t.getText("course.in.use")}</div>

                    }

                </div>

            </div>

            {courseData.UseCoursePermissionsTable &&
                <div className="mt-3">
                    <PermissionsForm readOnly={readOnly} permissionService={permissionService!} showData={permissionShowData} urlCreator={coursePermissionsUrlCreator} />
                </div>
            }

        </div >
    )
}

export default CourseGeneral;
