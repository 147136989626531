export interface SavePartTextDto {
    Partid: string;
    Html: string;
    Type: TextEditTypes;
}

export enum TextEditTypes {
    Intro,
    Goals,
    Litterature,
    ItemName,
    GuideLines,
    SearchWords,
    Testtext,
    PartbaseText,
    PartExtendedText
}