import { useEffect, useCallback, useRef } from "react";
import { CanvasDraw } from "./CanvasDraw";
import { useI18n } from "src/utils/lni18n";
import LnIcon from "src/components/LnIcon";
import { DrawingState, DrawingTestService } from "./DrawingTestService";
import { useAtom } from "jotai";
import { CanvasService } from "./CanvasService";


export type DrawingTestCanvasParams = { canvasService: CanvasService, fileUrl: string, state: DrawingState, children?: any };


const DrawingTestCanvas = (props: DrawingTestCanvasParams) => {

    const { fileUrl, state, children, canvasService } = props
    const { languageService: t } = useI18n();

    const [model] = useAtom(canvasService.drawingStateAtom);

    let canvas = useRef<HTMLCanvasElement | null>(null);
    let canvasWrap = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        if (model?.CurrentColor) {
            const drawModel = canvasService.getModel();
            const context2 = canvas.current!.getContext("2d");
            if (context2) {
                CanvasDraw(context2, canvas.current!.width, canvas.current!.height, drawModel, false, model.CurrentColor);
            }
        }

    }, [model.CurrentColor])




    const mouseDown = useCallback((e: MouseEvent) => {

        if (e.button !== 0) {
            return false;
        }
        const pt = getPos(e);
        const model = canvasService.handleMousePress(pt);
        if (model) {
            const canvas = e.currentTarget as HTMLCanvasElement;
            const context2 = canvas.getContext("2d");
            if (context2) {
                CanvasDraw(context2, canvas.width, canvas.height, model, false, model.CurrentColor);
            }
        }
    }, [model.CurrentColor, model]);

    const mouseUp = useCallback((e: MouseEvent) => {

        if (e.button !== 0) {
            return false;
        }
        const { model, closedStateChanged } = canvasService.handleMouseUp();
        if (closedStateChanged) {
            canvasService.setCurveClosed(model.Closed);
        }

        if (model) {
            const canvas = e.target as HTMLCanvasElement;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2!, canvas.width, canvas.height, model, false, model.CurrentColor);
        }
    }, [model, model.CurrentColor]);

    const mouseMove = useCallback((e: MouseEvent) => {

        if (e.button !== 0) {
            return false;
        }
        const pt = getPos(e);
        const model = canvasService.handleMouseMove(pt);
        if (model) {
            const canvas = e.target as HTMLCanvasElement;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2!, canvas.width, canvas.height, model, false, model.CurrentColor);
        }
    }, [model, model.CurrentColor]);

    const mouseOut = useCallback((e: MouseEvent) => {
        const model = canvasService.handleMouseOut();
        if (model) {
            const canvas = e.target as HTMLCanvasElement;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2!, canvas.width, canvas.height, model, false, model.CurrentColor);
        }
    }, [model, model.CurrentColor]);

    const doubleClick = useCallback((e: MouseEvent) => {
        const model = canvasService.handleDblClick();
        if (model) {
            canvasService.setCurveClosed(model.Closed);
            const canvas = e.target as HTMLCanvasElement;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2!, canvas.width, canvas.height, model, false, model.CurrentColor);
        }
    }, [model, model.Points, model.CurrentColor]);

    const focus = useCallback((e: FocusEvent) => {
        const model = canvasService.setHandlesState(true);
        if (model) {
            const canvas = e.target as HTMLCanvasElement;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2!, canvas.width, canvas.height, model, false, model.CurrentColor);
        }
    }, [model, model.CurrentColor]);

    const blur = useCallback((e: FocusEvent) => {
        const model = canvasService.setHandlesState(false);
        if (model) {
            const canvas = e.target as HTMLCanvasElement;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2!, canvas.width, canvas.height, model, false, model.CurrentColor);
        }
    }, [model, model.CurrentColor]);

    useEffect(() => {
        if (canvas.current && canvasWrap.current) {
            const img = new Image();
            img.onload = () => {
                canvas.current!.width = img.width;
                canvas.current!.height = img.height;
                canvasWrap.current!.style.background = 'url(' + fileUrl + ') no-repeat';
                const model = canvasService.getModel();
                if (model.Points && model.Points.length > 0) {
                    const context2 = canvas.current!.getContext("2d");
                    CanvasDraw(context2!, canvas.current!.width, canvas.current!.height, model, false, model.CurrentColor);
                }
            }
            img.src = fileUrl;
        }
    }, [fileUrl, canvas.current, canvasWrap.current]);


    useEffect(() => {
        if (canvas.current && canvasWrap.current) {

            const currentCanvas = canvas.current;

            canvas.current.addEventListener('dblclick', doubleClick);
            canvas.current.addEventListener('mousedown', mouseDown);
            canvas.current.addEventListener('mouseup', mouseUp);
            canvas.current.addEventListener('mousemove', mouseMove);
            canvas.current.addEventListener('mouseout', mouseOut);
            canvas.current.addEventListener('focus', focus);
            canvas.current.addEventListener('blur', blur);

            return () => {
                currentCanvas.removeEventListener('mousedown', mouseDown);
                currentCanvas.removeEventListener('mouseup', mouseUp);
                currentCanvas.removeEventListener('mousemove', mouseMove);
                currentCanvas.removeEventListener('mouseout', mouseOut);
                currentCanvas.removeEventListener('focus', focus);
                currentCanvas.removeEventListener('blur', blur);
                currentCanvas.removeEventListener('dblclick', doubleClick);
            }

        }
    }, [blur, doubleClick, focus, mouseDown, mouseMove, mouseUp, mouseOut]);

    function getPos(event: MouseEvent) {
        const element = event.currentTarget as HTMLElement;
        const rect = element.getBoundingClientRect();
        return {
            X: event.clientX - rect.left,
            Y: event.clientY - rect.top,
        }
    }

    const handleErase = useCallback(() => {
        const model = canvasService.erasePath();
        canvasService.setCurveClosed(false);

        if (model && canvas.current) {

            const context2 = canvas.current!.getContext("2d");
            CanvasDraw(context2!, canvas.current.width, canvas.current.height, model, false, model.CurrentColor);
        }
    }, [canvas.current, model, model.CurrentColor]);

    return (
        <div className="mb-5">
            <div className="mt-3">
                <div ref={canvasWrap} className="position-relative">
                    <canvas tabIndex={-1} ref={canvas} />
                    {model.Closed && <LnIcon className="done-icon-left-top natural" name="checked" />}
                </div>
                {model.Points.length > 0 && <div>
                    <button onClick={handleErase} className="btn btn-warning btn-small mt-2" >{t.getText("drawingtestadmin.btnerase")}</button>

                </div>
                }
                {children}
            </div>
        </div>)
}


export default DrawingTestCanvas;

