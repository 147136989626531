import { DropdownItem, DropdownMenu } from 'reactstrap';
import classes from './TopMenu.module.scss';
import LnDropDownMenu from "src/components/LnDropDownMenu";
import { useI18n } from "src/utils/lni18n";
import { Link, useNavigate } from 'react-router-dom';
import { InputDialog, InputOptions } from "src/components/Modalservice/InputDialog";
import { useDialog } from '../Modalservice/Dialogservice';
import coursePartService, { lastCoursePartsAtom } from 'src/coursepart/CoursePartService';
import { useAtom } from 'jotai';
import { urlService } from 'src/services/NavService';


const CoursePartMenu = () => {

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();
    const navigate = useNavigate();

    const [lastParts] = useAtom(lastCoursePartsAtom);

    const navigateToPart = (id: string) => {

        const url = coursePartService.getLastUrl(id);
        if (url) navigate(url);
    }

    const newPart = () => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: InputOptions = {
                    title: t.getText("courseparts.createnew"),
                    message: <div>{t.getText("mail.noname")}</div>,
                    languageService: t,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                    type: "input",
                    text: ""
                }
                return <InputDialog {...dprops} />
            },
            size: "md"
        }).then(async (text: string) => {
            const part = await coursePartService.createPart(text);
            if (part) {
                navigate(urlService.urlToPart(part.Id));
            }
        });

    }

    return <div className={classes["user-info"]}>
        <LnDropDownMenu
            caret={false}
            toggleComponent={
                <h3 className='text-white m-3'>
                    {t.getText("coursepart")}&nbsp;&nbsp;<span className='white-caret'></span>
                </h3>
            }
            toogleClassName="pointer"
            toogleTagName="div"
        >
            <DropdownMenu className="dropDownMenu" container="body">

                <DropdownItem tag="div">
                    <Link className='px-4 py-2' to="/allparts" >
                        {t.getText("browse")}
                    </Link>
                </DropdownItem>

                <DropdownItem tag="div" onClick={newPart}>
                    <div className="px-4 py-2" >{t.getText("courseparts.createnew")}...</div>
                </DropdownItem>

                {lastParts && lastParts.length > 0 && <>
                    <hr />
                    {lastParts.map(p => <DropdownItem key={p.Id} tag="div">
                        <Link className='px-4 py-2' to={`/coursepart/${p.Id}/texts`} >
                            {p.Name}
                        </Link>
                    </DropdownItem>)}
                </>}

            </DropdownMenu>
        </LnDropDownMenu>
    </div >
}


export default CoursePartMenu;