import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { MCDataQuestion, MCTestDataDto } from '../DomainModels';
import { useI18n } from "src/utils/lni18n";
import HtmlTextEditor from 'src/components/TextEditor/HtmlTextEditor';
import LnIcon from 'src/components/LnIcon';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from 'src/components/Modalservice/ConfirmationDialog';
import { MCTestService } from './MCTestService';
import { useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';


const QuestionEditor = (props: { questionIndex: number, data: MCDataQuestion, onChange: (d: MCTestDataDto) => void, service: MCTestService }) => {
  const { languageService: t } = useI18n();
  const dialogPortal = useDialog();
  const { data, onChange, service, questionIndex } = props;
  const [mcData] = useAtom(service.datatom);
  const editData = mcData.Questions[questionIndex];
  const { state } = useLocation();

  if (!data || !editData) return null;

  function handleQuestionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    const data =  service.updateQuestionText(e.currentTarget.value);
    onChange(data);
  }

  function handleAnswerChange(e: ChangeEvent<HTMLTextAreaElement>, index: number) {
    console.debug("answer changed ", index)
    const data = service.updateAnswerText(e.currentTarget.value, index);
    onChange(data);
  }

  function handleAnswerCorrect(e: ChangeEvent<HTMLInputElement>, index: number) {
    const data = service.updateAnswerIsCorrect(e.currentTarget.checked, index);
    onChange(data);
  }

  function addAnswer() {
    const data = service.addAnswer();
    onChange(data);
  }

  function setCanSelectMultipleAnswers(e: ChangeEvent<HTMLInputElement>): void {
    const data =  service.setCanSelectMultipleAnswers(e.currentTarget.checked)
    onChange(data);
  }

  function deleteAnswer(index: number) {

    dialogPortal({
      factory: (onSubmit, onCancel) => {
        const dprops: ConfirmationOptions = {
          className: "",
          title: t.getText("delete"),
          message: <div className="preserve-white" >{t.getText("delete.answeroption")}</div>,
          languageService: t,
          show: true,
          onClose: onCancel,
          onSubmit: onSubmit,
          buttons: ConfirmationButtons.YesNo
        }
        return <ConfirmationDialog {...dprops} />
      },
      size: "md"
    }).then((res: boolean) => {
      if (res) {
        const data = service.removeAnswer(index);
        onChange(data);
      }
    });


  }

  const numAnswers = editData.McAnswers.length;

 

  return <div className="border p-5">

    
    <div className='mb-3'>
      <label className="font-weight-bold pointer">
        <input disabled={state.readOnly} name="CanSelectMultipleAnswers" checked={editData.CanSelectMultipleAnswers} onChange={setCanSelectMultipleAnswers} type="checkbox" className='mr-3 pointer' />
        {t.getText("can.select.multiple.answers")}
      </label>
    </div>


    <label className="font-weight-bold">{t.getText("question")}</label>
    { editData.QuestionError && <span className="ml-4 text-danger font-weight-bold">{editData.QuestionError}</span>}
    <HtmlTextEditor readOnly={state.readOnly} onChange={handleQuestionChange} value={""} initialValue={data.Question} 
      name="Question"
      className={`${editData.QuestionError ? 'border border-danger' : ''}`}  ></HtmlTextEditor>


    <div className="mt-4">
      <div className='d-flex mb-2'>
        <label className="font-weight-bold">{t.getText("options")}</label>
        <button disabled={state.readOnly} onClick={addAnswer} className='ml-auto btn btn-primary btn-small'>{t.getText("add")}</button>
      </div>


      {editData.McAnswers.map((answer, index) => <div key={index} className='mb-3'>

        <div className='d-flex'>
          <div className='mr-3'>
            {(!editData.CanSelectMultipleAnswers && index === 0) && <input disabled={state.readOnly} type="radio" defaultChecked value="true" />}
            {(!editData.CanSelectMultipleAnswers && index > 0) && <input disabled type="radio" />}
            {editData.CanSelectMultipleAnswers && <input disabled={state.readOnly} type="checkbox" checked={answer.IsCorrect} onChange={(e) => handleAnswerCorrect(e, index)} />}

          </div>
          <div className='flex-fill d-flex'>
            <div className='flex-fill '>
              <div>
                <strong>{answer.IsCorrect ? t.getText("correct") : t.getText("error")}</strong>
                { answer.Error && <span className="ml-4 text-danger font-weight-bold">{answer.Error}</span>}
              </div>
              <HtmlTextEditor readOnly={state.readOnly} name={`McAnswers.${index}.Text`} className={`${answer.IsCorrect ? 'border border-primary' : ''} ${answer.Error ? 'border border-danger' : ''}`}
                onChange={(e) => handleAnswerChange(e, index)}
                value={""} initialValue={data.McAnswers[index]?.Text || ""} />
            </div>

            {(numAnswers > 2) && <button disabled={state.readOnly} className="btn btn-link ml-3" type="button" onClick={() => deleteAnswer(index)}>
              <LnIcon name="delete" className='icon-small' />
            </button>}
          </div>

        </div>
      </div>

      )}

    </div>



  </div >

}

export default QuestionEditor;

