import * as React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useI18n } from '../../../utils/lni18n';
import { useState } from 'react';
import { API_HOST } from "src/utils/constants";
import FileUpload from "src/components/FileUpload/FileUpload";
import { FileUploadProps } from "src/coursepart/domain/FileDialogsettings";
import { useAtom } from "jotai";
import { fileUploadData } from "src/components/FileUpload/FileUploadService";


const ImportXLDialog: React.FC<{ onSubmit: (d: { key: string, replace: boolean }) => void, onCancel: () => void }> = ({ onSubmit, onCancel }) => {

    const { languageService: t } = useI18n();
    const [uploadState] = useAtom(fileUploadData);

    const [replace, setReplace] = useState(false);


    const fileOptions = {
        accepts: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"], },
        maxSize: "10MB"
    }

    const dprops: FileUploadProps = {
        fileOptions: fileOptions,
        languageService: t,
        onClose: onCancel,
        onSubmit: (e) => { },
        backGroundMessage: null,
        showUploadedFilename: true
    };

    const handleSubmit = React.useCallback(() => {
        if (uploadState.uploadedFile) {
            onSubmit({ key: uploadState.uploadedFile!.key, replace });
        }
    }, [replace, uploadState.uploadedFile]);

    return <> <ModalHeader>{t.getText("import.from.xl")}</ModalHeader>
        <ModalBody>
            <div className="preserve-white">
                {t.getText("multichoice.add.excelfile.explain")}
            </div>
            <div className="mt-3">
                <a target="_blank" href={`${API_HOST}/api/author/test/mctest/SpreadsheetTemplate`} >{t.getText("gettemplate")}</a>
            </div>
            <div className="mt-3">
                <label className="pointer">
                    <input className="mr-3" type="checkbox" checked={replace} value="true" onChange={(e) => setReplace(e.currentTarget.checked)} />
                    {t.getText("xlimport.replace.all")}
                </label>
            </div>
            <div className="mt-5">
                <FileUpload className="nope" {...dprops} />
            </div>

        </ModalBody>
        <ModalFooter>

            <Button disabled={!uploadState.uploadedFile} className="btn btn-primary btn-small" onClick={handleSubmit}>{t.getText("submit")}</Button>&nbsp;
            <Button className="btn btn-inverse btn-small" onClick={onCancel}>{t.getText("cancel")}</Button>
        </ModalFooter>
    </>
}

export default ImportXLDialog;
