import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useI18n } from 'src/utils/lni18n';

import { TinyCourse, TinyTheme, } from 'src/course/domain/CourseEditModel';
import { Card, CardBody, CardHeader } from 'reactstrap';
import LnIcon from 'src/components/LnIcon';
import { Link } from 'react-router-dom';
import { urlService } from 'src/services/NavService';

export default function DirtyTheme(props: { courseId: string, theme: TinyTheme }) {

  return (
    <div className='mb-3'>
      <Card className={["border border-primary"].join(' ')}>
        <CardHeader className={" px-1 py-0"} >

          <div className={`w-100 d-flex my-2 py-1 pl-3 pr-0`} >
            <LnIcon name="book" className="mr-2 mt-2 icon-small" />
            <div>{props.theme.Name}</div>
          </div>

        </CardHeader>
        <CardBody>
          <div className='pl-5'>
            {props.theme.Parts.filter(p => p.Dirty).map(p => <div className='d-flex mb-2'>
              <LnIcon name="alert" className='icon-small mt-2 mr-3' />
              <Link to={urlService.urlToPartInCourse( props.courseId, props.theme.Id, p.Id, "publish")}>
                {p.Name}
              </Link>
            </div>)}
          </div>
        </CardBody>
      </Card>



    </div>
  )
}
