import { LnCollapseGroup } from "../../components/LnCard/LnCollapseGroup";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from "../../components/DragHandle/DragHandle";
import classes from "./files.module.scss";
import { FileModel, FilesGroupModel } from "../domain/FilesGroupModel";
import FileCard from "./FileCard";
import { UniqueIdentifier } from "@dnd-kit/core";
import { useI18n } from "src/utils/lni18n";
import { setDialogCloseCallback, useDialog } from "src/components/Modalservice/Dialogservice";
import AddFileDialog from "./AddFileDialog";
import { AddFileModel } from "../domain/AddFileModel";
import FileSaverDialog from "src/components/FileUpload/FileSaverDialog";
import coursePartService from "../CoursePartService";
import { useAtom } from "jotai";
import { currentUser } from "src/services/user/userService";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import { InputDialog, InputOptions } from "src/components/Modalservice/InputDialog";
import LnIcon from "src/components/LnIcon";


export default function FileGroup(props: {
    group: FilesGroupModel,
    closed?: boolean, active?: UniqueIdentifier, dragged?: boolean, partId: string
}) {

    const { group, closed, active, dragged, partId } = props;
    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();
    const [user] = useAtom(currentUser);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: group.Id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: dragged ? "75%" : ""
    };

    const dummyFile: FileModel = {
        Id: "dummy_" + group.Id,
        FileName: "no name",
        Description: "",
        FileSize: 0,
        LastModified: ""

    }

    const uploadFile = () => {

        var p = dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <AddFileDialog onCancel={onCancel} onSubmit={onSubmit} />
            },
            size: "md"
        });

        p.then((data: AddFileModel) => {
            if (data) {
                const uploadData = coursePartService.addFile(data, user!.Id, partId, group.Id);
                if (uploadData) {
                    window.setTimeout(()=>{
                        dialogPortal({
                            factory: (onSubmit, onCancel) => {
                                return <FileSaverDialog onCancel={onCancel} />
                            },
                            size: "md"
                        }).catch(() => {
                            uploadData.killer && uploadData.killer();
                        });
                    }, 100);
                    
                }
            }
        });


    }

    const deleteFile = (fileId: string) => {

        var p = dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("delete"),
                    message: <div className="preserve-white" >{t.getText("file.delete.file.warning")}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        });

        p.then((res: boolean) => {
            if (res) {
                coursePartService.deleteFile(partId, fileId, group.Id);
            }
        })

    }

    const editFileDescription = (file: FileModel) => {

        var p = dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: InputOptions = {
                    title: t.getText("edit"),
                    message: <div>{t.getText("description")}</div>,
                    languageService: t,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                    type: "area",
                    text: file.Description
                }
                return <InputDialog {...dprops} />
            },
            size: "md"
        });

        p.then((res: string) => {
            if (res) {
                coursePartService.updateFileDescription(partId, file.Id, group.Id, res);
            }
        })

    }

    const deleteGroup = () => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("delete"),
                    message: <div className="preserve-white" >{t.getText("sure_to_delete")}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then((res: boolean) => {
            if (res) {
                coursePartService.deleteFileGroup({ partId, groupId: group.Id });
            }
        });
    }

    const editGroup = () => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: InputOptions = {
                    title: t.getText("edit"),
                    message: <div>{t.getText("name")}</div>,
                    languageService: t,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                    type: "input",
                    text: group.Name
                }
                return <InputDialog {...dprops} />
            },
            size: "md"
        }).then((name: string) => {
            if (name !== "") {
                coursePartService.renameFileGroup({ name, groupId: group.Id, partId: partId! })
            }
        });
    }

    const actionButtons = <>

        {group.IsDefault == false && <>
            <button className="btn btn-link mx-3" type="button" onClick={editGroup}>
                <LnIcon name="edit" />
            </button>
            <button className="btn btn-link mx-3" type="button" onClick={deleteGroup}>
                <LnIcon name="delete" />
            </button>
        </>
        }
    </>;


    const handle = group.IsDefault ? <div></div> : <div className={classes.dragHandle} {...attributes} {...listeners}><DragHandle /></div>;
    const panel = <LnCollapseGroup actionButton={actionButtons} className={active === group.Id ? classes.active : ""} groupName={group.Name} isOpen={!closed} handle={handle} >
        <SortableContext items={group.Files.map(f => f.Id)} id={group.Id}>
        <div className="text-right mb-3">
                <button onClick={() => uploadFile()} className={`btn btn-small btn-primary`}>{t.getText("add.file")}</button>
            </div>

            <div>
                {group.Files.map((item) => (
                    <FileCard edit={editFileDescription} deleteFile={deleteFile} partId={partId} active={active} key={item.Id} file={item} />
                ))}
                <FileCard edit={() => { }} deleteFile={() => { }} partId={partId} key={dummyFile.Id} file={dummyFile} dummy={true} />
            </div>
        </SortableContext >
    </LnCollapseGroup>;


    if (group.IsDefault) {
        return <div id={"g_" + group.Id} role="listitem" key={group.Id} className="mb-4" >
            {panel}
        </div>;
    } else {
        return <div ref={setNodeRef} style={style} id={"g_" + group.Id} role="listitem" key={group.Id} className="mb-4" >
            {panel}
        </div>
    }
}