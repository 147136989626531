import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { onePartatom } from "src/coursepart/CoursePartService";
import ReadOnlyLinkGroup from "./ReadOnlyLinkGroup";


const ReadOnlyLinks = () => {

  const { partId } = useParams();
  const partAtom = useMemo(() => onePartatom(partId!), [partId]);
  const [partData] = useAtom(partAtom);
  const part = partData.Data;


  if (!part || !part.LinkGroups) {
    return null;
  }

  return (
    <div className="max-col-width">
        <div role="list">
            {part.LinkGroups.map(item => <ReadOnlyLinkGroup partId={partId!}  closed={!item.IsDefault} key={item.Id} group={item} />)}
        </div >

       

      

    </div>
  )
}

export default ReadOnlyLinks;
