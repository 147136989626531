import * as React from "react";
import { Button,  ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LanguageService } from "../../utils/languageService";

export interface AlertOptions {
    title: string;
    message: string | React.ReactNode;
    className: string;
    languageService: LanguageService;
    onSubmit: (d:any) => void;
}

export const AlertDialog: React.FC<AlertOptions> = ({
    title,
    className,
    message,
    onSubmit,
    languageService,
}) => {

    const handleSubmit = () => {
        onSubmit(true);
    }
    return <>
    <span  className="pointer" data-dialog-draggable>
        <ModalHeader>{title}</ModalHeader>
        </span>
        <ModalBody>
            {message}
        </ModalBody>
        <ModalFooter>
            <Button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>{languageService.getText("ok")}</Button>&nbsp;
        </ModalFooter>
    </>
};
