import { useAtom } from 'jotai';
import EditTestForm from 'src/coursepart/Test/EditTestForm';
import { BaseTestDataDTO } from 'src/coursepart/Test/DomainModels';
import { currentTestAtom, testDataAtom } from 'src/coursepart/Test/TestService';
import { useEffect, useState } from 'react';


const CuePointEditTest = (props: { data: BaseTestDataDTO }) => {

    const [generalTestData] = useAtom(testDataAtom);
    const typeText = generalTestData.testTypes.find(t => t.TestType === props.data.Type);

    const [name, setName] = useState<any>("");

    useEffect(()=>{
        setName(props.data.Name);
    },[props.data.Name])

    return <>
        <div>
            <h3>{name}</h3>
            <div className="preserve-white">
                {typeText?.Description}
            </div>
        </div>
        <EditTestForm onNameChange={setName} data={props.data} />

    </>


}

export default CuePointEditTest;