import { useRouteError } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";

export const ErrorBoundary = () => {

    const { languageService: t } = useI18n();
    const error: any = useRouteError();
    let message;

    if (typeof error === "string") {
        message = <div className="preserve-white"> {error} </div>;
    }
    if (typeof error === "object") {
        if (error.message) {
            message = <div className="preserve-white"> { error.message } </div>;
        }
        else{
            message = <div className="preserve-white"> {error}</div>;
        }
    }

    return <div className={"scrollContent h-100"} aria-live="polite">
        <div className="card-box big-card-box">
            <div className="card-box-title card-title">
                <h3>{t.getText("error")}</h3>

            </div>
            <div className="card-box-content card-body scroller">
                <div className="mb-5">
                    {message}




                </div>

            </div>
        </div>
    </div>
}