export interface EvaluationEditDataDTO {
    CustomQuestions: EvaluationQuestionDTO[];
    StandardQuestions: EvaluationQuestionDTO[];
}

export interface EvaluationQuestionDTO {
    Id: number;
    Deleted: boolean;
    QuestionText: string;
    AnswerOptions: EvalEditAnswerOptionDTO[];
    Type: EvalQuestionType;
    HaveScores: boolean;
    Locked: boolean;
}

export interface EvalEditAnswerOptionDTO {
    Id: number;
    AnswerText: string;
    Deleted: boolean;
    Score: number | null;
    MaxScore: number | null;
}

export enum EvalQuestionType {
    MultipleChoice = 1,
    FreeText
}