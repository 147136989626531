import { DialogServiceProvider } from './components/Modalservice/Dialogservice';
import FileUploadPanel from './components/FileUploadPanel/FileUploadPanel';
import Pinger from './components/Pinger/Pinger';
import TopMenu from 'src/components/TopMenu/TopMenu';
import { Outlet } from 'react-router-dom';
import ErrorComponent from './components/Error/ErrorComponent';
import { useEffect, useState } from 'react';

import { unloadService } from './services/UnloadService';
import SideBar from './components/SideBar/SideBar';
import { currentUserAtom, userService } from './services/user/userService';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';


function App() {

  const [opensidebar, setOpenSideBar] = useState(false);

  const [user] = useAtom(currentUserAtom);

  useEffect(() => {
    // set contextify. HACK! css vars. I could not find out how to do this in in SCSS : root file
    let root = document.documentElement;
    root.style.setProperty('--contexify-activeItem-bgColor', "#5faa45");

    unloadService.setupOnUnload();
    const localtime = dayjs().format("YYYY-MM-DDTHH:mm:ssZ");
    userService.loginUser( { Federated: false, Identifier: "", LocalTime: localtime, Password: "", FederationText: "", FinalDomain: ""  } )

  }, [])

  return (

    <DialogServiceProvider >
      <Pinger />
      <ErrorComponent />
      <FileUploadPanel />

      

      <div className={`toggleBg ${opensidebar ? "open" : ""}`} onClick={() => setOpenSideBar(false)}>&nbsp;</div>

      <div className='rootPanel'>
        <div className={`sidebar d-print-none ${opensidebar ? "open" : ""}`}>
          <SideBar setOpenSideBar={setOpenSideBar} />
        </div>
        <div className='mainPanel'>
          <div className='menu d-print-none'>
            <TopMenu setOpenSideBar={setOpenSideBar} />
          </div>
          <div className='greenTop d-print-none'>&nbsp;</div>
          <div className='content'>

            <Outlet />
          </div>

        </div>

      </div>
      <div className="small mr-5 text-right">{ user?.FullName } { user?.Email}</div>

    </DialogServiceProvider>
  )
}
export default App;
