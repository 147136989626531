import { atom, getDefaultStore } from "jotai";
import { SplinePoint } from "../DomainModels";
import { DrawingState } from "./DrawingTestService";
import { produce } from "immer";

//@ts-check
export const POINTRADIUS = 7;
export const LINEWIDTH = 2;
export const SPLINETENSION = 0.4;

const intial = { Selected: undefined, CurrentColor: "#00ff00", AuthColor: "#00ff00", StudColor: "#ff0000", Closed: false, Points: [], DrawHandles: true };

const store = getDefaultStore();

export class CanvasService {

    constructor(state: DrawingState, updateCallback: (data: DrawingState) => void) {
        this.drawingStateAtom.debugLabel = "drawingStateAtom"; 
        store.set(this.drawingStateAtom, state);

        store.sub(this.drawingStateAtom, () => {
            updateCallback(store.get(this.drawingStateAtom));
        })

    }

    drawingStateAtom = atom<DrawingState>(intial);
    pressStartPos?: SplinePoint = undefined;
    dragging = false;
    newPointSelected = false;
    hovered?: SplinePoint = undefined;


    private get dataState(): DrawingState { return store.get(this.drawingStateAtom); };

    private MutateDrawingState(method: (state: DrawingState) => void) {
        const state = store.get(this.drawingStateAtom);
        const newstate = produce(state, (draft) => {
            method(draft);
        })
        store.set(this.drawingStateAtom, newstate);
        return newstate;
    }

    reset() {
        store.set(this.drawingStateAtom, intial);
        this.pressStartPos = undefined;
        this.dragging = false;
        this.newPointSelected = false;

    };


    getModel() { return this.dataState };


    erasePath() {
        this.dragging = false;
        this.newPointSelected = false;
        this.hovered = undefined;
        this.pressStartPos = undefined;

        return this.MutateDrawingState(draft => {
            draft.Points = [];
            draft.Closed = false;
        });
    }

    setHandlesState(state: boolean) {
        return this.MutateDrawingState(draft => {
            draft.DrawHandles = state;
        });
    }

    handleMousePress(pos: SplinePoint) {

        const newdata = this.MutateDrawingState(draft => {
            if (draft.DrawHandles === false && draft.Points.length == 0) {
                return;
            }
            this.pressStartPos = pos;
            this.dragging = false;
            let point = this.getPoint(pos);

            // Create a new point if there wasn't one there and the line isn't closed.
            if (!draft.Closed && !point) {

                point = {
                    X: pos.X,
                    Y: pos.Y
                };
                draft.Points.push(point);
                this.newPointSelected = true;
            } else {
                this.newPointSelected = false;
            }
            draft.Selected = point;


        });

        return newdata;

    }

    handleMouseMove = (pos: SplinePoint) => {

        let draw = false;

        const newState = this.MutateDrawingState(draft => {

            // Move the selected point.
            if (draft.Selected) {

                // Determine if the user is dragging or clicking the selected point.
                // If the cursor has moved 2 pixels away from its starting point,
                //   we're going to assume they're dragging.
                if (!this.dragging && this.distSqr(pos, this.pressStartPos!) > 4) {
                    this.dragging = true;
                }

                const point = draft.Points.find(p => p.X === draft.Selected!.X && p.Y === draft.Selected!.Y);
                if (point !== undefined) {
                    point.X = pos.X;
                    point.Y = pos.Y;
                }
              
                draft.Selected = pos;

                draw = true;

            } else {
                // Update the hovered point.
                const h = this.getPoint(pos);
                draw = this.hovered !== h;
                this.hovered = h;
            }
        });

        if (draw) console.log(pos, newState);

        return draw ? newState : null;
    }


    handleMouseUp = () => {

        const oldClosedState = this.dataState.Closed;
        let wasSelected = false;

        const newState = this.MutateDrawingState(draft => {

            if (draft.Selected) {
                wasSelected = true;
                let firstPoint = draft.Points[0];
                let lastPoint = draft.Points[draft.Points.length - 1];


                // Close the loop if the user clicked the first point.
                // Must be at least 3 points on the canvas.
                if (!this.dragging &&
                    draft.Points.length > 2 &&
                    draft.Selected.X === firstPoint.X && draft.Selected.Y === firstPoint.Y  &&
                    draft.Closed === false) {

                    draft.Closed = true;
                }

                // Close the loop if the last point and the first point are close enough (their edges are overlapping).  
                // Must be at least 4 points on the canvas, since the last point will be removed.
                if (this.dragging &&
                    draft.Points.length > 3 &&
                    this.distSqr(firstPoint, lastPoint) <= Math.pow(2 * POINTRADIUS, 2) &&
                    draft.Closed === false) {
                    draft.Points.pop();
                    draft.Closed = true;
                }
                // End dragging.
                draft.Selected = undefined;
                this.pressStartPos = undefined;
                this.dragging = false;
            }
        });

        return { model: newState, closedStateChanged: wasSelected || (newState.Closed !== oldClosedState) };
    }


    handleMouseOut = () => {


        if (this.dataState.Selected) {

            return this.MutateDrawingState(draft => {

                // Remove the selected point if the circle isn't closed.
                if (draft.Closed === false) {
                    for (let i = 0; i < draft.Points.length; i++) {
                        let point = draft.Points[i];
                        if (point === draft.Selected) {
                            draft.Points.splice(i, 1);
                            break;
                        }
                    }
                }


                // End dragging.
                draft.Selected = undefined;
                this.pressStartPos = undefined;
                this.dragging = false;
            });
        }

        return null;
    }

    handleDblClick = () => {
        if (this.dataState.Closed === false && this.dataState.Points.length > 2) {
            return this.MutateDrawingState(draft => {
                draft.Closed = true;
            });
        }

        return false;
    };

    getPoint = (pos: SplinePoint) => {

        for (let i = 0; i < this.dataState.Points.length; i++) {
            let point = this.dataState.Points[i];

            if (this.distSqr(point, pos) <= Math.pow(POINTRADIUS+8, 2)) {
                return point;
            }
        }

        return undefined;
    }

    distSqr = (a: SplinePoint, b: SplinePoint) => {
        return Math.pow(a.X - b.X, 2) + Math.pow(a.Y - b.Y, 2);
    }


    setAuthColor(val: string) {
        return this.MutateDrawingState(state => {
            state.AuthColor = val;
        });
    }

    setStudColor(val: string) {
        return this.MutateDrawingState(state => {
            state.StudColor = val;
        });
    }

    setCurrentColor(val: string) {
        return this.MutateDrawingState(state => {
            state.CurrentColor = val;
        });
    }

    setCurveClosed(closed: boolean) {
        return this.MutateDrawingState(state => {
            state.Closed = closed;
        });
    }



}
