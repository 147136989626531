import * as React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useI18n } from '../../utils/lni18n';
import FilePicker from 'src/components/FileUpload/FilePicker';
import { FileOptions } from '../domain/FileDialogsettings';
import { useState } from 'react';
import { AddFileModel } from "../domain/AddFileModel";


const AddFileDialog: React.FC<{ onSubmit: (data: AddFileModel) => void, onCancel: () => void }> = ({ onSubmit, onCancel }) => {

    const { languageService: t } = useI18n();
    const [pickedFile, setPickedFile] = useState<File | undefined>(undefined);
    const [text, setText] = useState<string>("");

    const fileOptions: FileOptions = {
        maxSize: "300mb",
        accepts: {}
    };

    const onFilePicked = (file: File) => {
        setPickedFile(file);
    }

    const handleSubmit = () => {
        if (pickedFile) {
            onSubmit({ file: pickedFile, description: text });
        }
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    }


    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("add.file")}</ModalHeader>
        </span>
        <ModalBody>

            <FilePicker className="mt-4" onFilePicked={onFilePicked} fileOptions={fileOptions} languageService={t} />

            <div className="form-group mt-4">
                <label>{t.getText("description")}</label>
                <textarea className="form-control" value={text} onChange={(e) => handleTextChange(e)}></textarea>
                {!text && <div className="text-danger small">{t.getText("required_field")}</div>}
            </div>
            {pickedFile && <div className="my-3">
                <h5>{t.getText("file")}</h5> {pickedFile.name}
            </div>}


        </ModalBody>
        <ModalFooter>

            <Button disabled={!(pickedFile && text)} className="btn btn-primary btn-small" onClick={() => handleSubmit()}>{t.getText("save.short")}</Button>&nbsp;
            <Button className="btn btn-inverse btn-small" onClick={onCancel}>{t.getText("cancel")}</Button>
        </ModalFooter>
    </>
}

export default AddFileDialog;
