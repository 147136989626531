
declare type multiplier = "k"|"m"|"g";

const muls:Record<multiplier , number> = {
    "k": 1024,
    "m": 1048576,
    "g": 1073741824
};

export const parseFileSize = function (text: string | number) {


    if (isNaN(text as number) === false) {
        return (text as number) *1;
    }

    const match = /^([0-9]+)([kmg]?)$/.exec(String(text).toLowerCase().replace(/[^0-9kmg]/g, ''));
    let size = +match![1];
    const mul: string = match![2];

    if (muls.hasOwnProperty(mul) ) {
        size *= muls[mul as multiplier];
    }

    return size;
}

const sizePrefixes = ["bytes", "kB", "MB", "GB", "PB"];

export const getFileSizeString = function (size: number) {

    if( size < 10) return size.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " bytes";

    let currentSize = size;
    let prevSize = size;
    let index = 0;

    for (index = 0; index < sizePrefixes.length; index++) {
        prevSize = prevSize / 1024;
        if( prevSize < 10 ) break;
        currentSize = prevSize;
    }

    return currentSize.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) + " " + sizePrefixes[index];


}
