
import { useI18n } from 'src/utils/lni18n';
import { EvalEditAnswerOptionDTO, EvalQuestionType } from '../domain/EvaluationEditDataDTO';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import coursePartService, { onePartatom } from '../CoursePartService';
import AnswerOption from './AnswerOption';
import { SortableContext } from '@dnd-kit/sortable';
import LnIcon from 'src/components/LnIcon';
import { useAtom } from 'jotai';

export default function EvalEditor(props: { locked: boolean, qId: number }) {

    const { languageService: t } = useI18n();
    const { qId, locked } = props;
    const { partId } = useParams();
    const [sortingUp, setSortingUp] = useState<boolean>(false);

    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData,] = useAtom(partAtom);
    const part = partData.Data;

    const question = part?.EvalQuestions.CustomQuestions.find(x => x.Id === qId);

    if (!partId || !question) return null;

    const q = question!;


    function toggleScores(qid: number, state: boolean): void {
        coursePartService.toggeEvalQuestionScore(qid, state, partId!);
    }

    function optionTextChanged(t: string, id: number): void {
        coursePartService.saveOptionText(partId!, t, id, qId);
    }

    function deleteOption(optionid: number): void {
        coursePartService.deleteEvalOption(partId!, optionid, qId);
    }

    function addOption() {
        coursePartService.addEvalOption(partId!, qId);
    }

    const renumber = useCallback( () => {
        coursePartService.enumerateOptions(partId!, qId, sortingUp);
        setSortingUp( s => !s );
    }, [ sortingUp ]);

    function setScore(o: EvalEditAnswerOptionDTO, score: number): void {
        coursePartService.setOptionScore( partId!, qId, o.Id, score  );
    }

    return <div>

        {q.Type === EvalQuestionType.FreeText && <div className='mb-5 p-3 border rounded' >{t.getText("openquestion")}</div>}

        {q.Type === EvalQuestionType.MultipleChoice && <div>
            <div className='d-flex mb-5 mt-4'>
                <div className='flex-fill'>
                    
                    <label >
                        <input disabled={locked} className='mr-3' type='checkbox' checked={q.HaveScores} value="true" onChange={(e) => toggleScores(q.Id, e.currentTarget.checked)} />
                        {t.getText("set.score")}
                    </label>
                    
                    {!locked && q.HaveScores &&
                        <button onClick={renumber} className='ml-3 btn btn-inverse btn-small'>
                            {t.getText("autoscore")}
                            { sortingUp ?  <LnIcon name="sorting-up" className='icon-small' /> : <LnIcon name="sorting-down" className='icon-small' />}
                            </button>
                    }

                </div>

                {!locked &&
                    <button onClick={addOption} className='btn btn-primary btn-small'>{t.getText("add")}</button>
                }

            </div>
            <SortableContext items={q.AnswerOptions.map(o => o.Id)} id={`options_${q.Id}`}>
                {q.AnswerOptions.map(o =>
                    <AnswerOption setScore={setScore} locked={locked} canDelete={q.AnswerOptions.length > 2} onDelete={deleteOption} onChange={optionTextChanged} haveScores={q.HaveScores} key={o.Id} option={o} numOptions={q.AnswerOptions.length} partId={partId} dragged={false} />
                )}
            </SortableContext>
        </div>}
    </div>


}