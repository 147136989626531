import {  useState, useMemo, useEffect } from "react";
import classes from '../lecture.module.scss';
import { LectureDtoWithOriginals } from '../../domain/LectureDtoWithOriginals';

import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu,
    ItemParams
} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import  { onePartatom } from '../../CoursePartService';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { useParams } from 'react-router-dom';
import scrapBookService from 'src/components/ScrapBook/ScrapBookService';
import ScrapBookDialog, { ScrapBookDialogResult, ScrapType } from 'src/components/ScrapBook/ScrapBookDialog';
import { useI18n } from "src/utils/lni18n";
import { useAtom } from "jotai";
import lectureService from "../LectureService";
import LectureDiffLink from "./LectureDiffLink";
import { Link } from "react-router-dom";

const MENU_ID = "lect-menu-id";

export type LetureEvent<T> = { value: T };
export const LectureEventnames = { newLecture: "newLecture" };

const LecturesDiff = () => {


    const { partId } = useParams();

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();

    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);
    const lects = partData.DiffLectures;


    const { show } = useContextMenu({
        id: MENU_ID
    });


    if (lects === undefined) {
        return null;
    }

  
    function displayContextMenu(e: React.MouseEvent, lect: LectureDtoWithOriginals) {
        show({
            event: e,
            props: lect
        });
    }

    async function handleItemClick(params: ItemParams<LectureDtoWithOriginals, any>) {
        const { id, props }: ItemParams<LectureDtoWithOriginals, any> = params;


        switch (id) {

          
            case "copy":
                if (props) {
                    await scrapBookService.addLecture(props!);
                }
                break;
        }
    }

    const showScrapBook = () => {

        scrapBookService.loadLectures();
        dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <ScrapBookDialog readOnly={true} contextId={partId!} type={ScrapType.lecture} onCancel={onCancel} onSubmit={onSubmit} />
            }
        });

      

    }

    return <>

        <div className='d-flex flex-wrap'>
            <button type="button" onClick={showScrapBook} className='mb-3 btn btn-primary btn-small'>{t.getText("scrapbook")}</button>
            <Link to={"../lectures"}  className='ml-auto btn btn-inverse btn-small' >{t.getText("close")} {t.getText("differences")}</Link>
        </div>

        <div className={"mt-5 flex-row flex-wrap " + classes.cardWrapper}>
            {lects?.map(le => <LectureDiffLink onContextMenu={e => displayContextMenu(e, le)} partId={partId!} lecture={le} key={le.Id} />)}
        </div>



        <Menu id={MENU_ID}>

            <Item id="copy" onClick={handleItemClick}>
                {t.getText("copy")}
            </Item>

        </Menu>


    </>

}

export default LecturesDiff;
