import { EditScreenDto, LectureState, ScreenCuePointDTO } from '../domain/LectureState';
import classes from './player.module.scss';
import CuePoint from '../Screen/CuePoint';


const PlayProgress = (props: { onClick: React.MouseEventHandler<HTMLElement>, lectureData: LectureState, currentScreen: EditScreenDto }) => {



    let progress = 0;

    const { currentScreen, onClick } = props;

    if (currentScreen) {
            progress = Math.max(0,  Math.min((props.lectureData.currentPlayed), 100));
    }


    return (<>

        {currentScreen.CuePoints && currentScreen.CuePoints.length > 0 && <div className={classes.cuePoints}>
            {currentScreen.CuePoints.map(cp =>
                <CuePoint key={cp.id.toString()} {...cp} />
            )}

        </div>}
        <div onClick={onClick} className={classes.progress + " progress-bar"} >
            <div className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} style={{ width: progress + "%" }}></div>

        </div>
    </>
    )
}

export default PlayProgress;