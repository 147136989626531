
import { useI18n } from '../utils/lni18n';
import { onePartatom } from './CoursePartService';
import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import WaitRipple from 'src/components/WaitRipple';
import CoursePartLoadError from './CoursePartLoadError';
import CoursePartComponent from './CoursePartComponent';
import ReadOnlyCoursePart from './ReadOnly/ReadOnlyCoursePart';
import { LockReason } from 'src/types';
import CoursePartNoRead from './CoursePartNoRead';

export default function CoursePartStart() {

  const { partId } = useParams();
  const [partData] = useAtom(
    useMemo(() => onePartatom(partId || ""), [partId]));

  if (!partData || partData.Loading) {
    return <WaitRipple />
  }

  if (partData.Error) {
    return <CoursePartLoadError />
  }


  if (partData.Data?.Locked) {
    if( partData.Data!.Locked.Reason === LockReason.Noread ){
      return <CoursePartNoRead />
    }
    return <ReadOnlyCoursePart />
  }
  else {
    return <CoursePartComponent />
  }



}