
import { S3UploadEvent, S3Uploader } from "src/components/FileUpload/S3Uploader";
import { AudioSequence } from "./AudioSequence";

export class WavfileService {

    public SaveWavToTemp( audioSequences: AudioSequence[],  userId:string, progressHandler: (d: S3UploadEvent) => void  ){
        var file = this.GetWaveFile(audioSequences);
        if (file == undefined) return;
        return S3Uploader.startUpload(userId, file, progressHandler );
    }

    public GetWaveFile( audioSequences: AudioSequence[] ){
        const data = this.encodeWaveFile( audioSequences );
        if (data == undefined) return;
        return new File([new Blob([data], { type: "audio/wav" })], "audio.wav");
    }

    private signedBorders = [0, 0xFF - 0x80, 0xFFFF - 0x8000, 0xFFFFFFFFF - 0x80000000];

    private encodeWaveFile = (audioSequences: AudioSequence[]) => {

        // prepare variables for encoding
        const waveChunkID = "RIFF";
        const waveFormat = "WAVE";
        const waveSubchunk1ID = "fmt ";
        const waveSubchunk1Size = 16;
        const waveAudioFormat = 1;
        const waveNumChannels = audioSequences.length;
        const waveSampleRate = audioSequences[0].sampleRate;
        const waveBitsPerSample = 16; // Attention! Order
        const waveByteRate = waveSampleRate * waveNumChannels * waveBitsPerSample / 8;
        const waveBlockAlign = waveNumChannels * waveBitsPerSample / 8;
        const waveSamplesPerChannel = audioSequences[0].data.length;
        const waveSubchunk2ID = "data";
        const waveSubchunk2Size = waveSamplesPerChannel * waveBlockAlign;
        const waveChunkSize = waveSubchunk2Size + 36; // 36 are the bytes from waveFormat till waveSubchunk2Size
        const totalSize = waveChunkSize + 8;

        // actual writing
        const writer = new BinaryWriter(totalSize);
        writer.writeString(waveChunkID);
        writer.writeUInt32(waveChunkSize);
        writer.writeString(waveFormat);

        writer.writeString(waveSubchunk1ID);
        writer.writeUInt32(waveSubchunk1Size);
        writer.writeUInt16(waveAudioFormat);
        writer.writeUInt16(waveNumChannels);
        writer.writeUInt32(waveSampleRate);
        writer.writeUInt32(waveByteRate);
        writer.writeUInt16(waveBlockAlign);
        writer.writeUInt16(waveBitsPerSample);

        writer.writeString(waveSubchunk2ID);
        writer.writeUInt32(waveSubchunk2Size);

        const signBorderId = waveBitsPerSample / 8;
        const signedBorder = this.signedBorders[signBorderId];

        for (let i = 0; i < waveSamplesPerChannel; ++i) {
            for (let channelId = 0; channelId < waveNumChannels; ++channelId) {
                writer.writeInt16(this.convertFloatToInt(audioSequences[channelId].data[i], waveBitsPerSample, signedBorder));
            }
        }
        return writer.data;
    };

    private convertFloatToInt = (value:number, waveBitsPerSample:number, signedBorder:number) => {
        return (waveBitsPerSample == 8) ? (value + 1.0) * signedBorder : value * signedBorder;
    };

    

}

class BinaryWriter {

    pos: number = 0;
    data!: Uint8Array;
    masks: number[];
    constructor(private estimatedSize: number){ 
        this.data = new Uint8Array(estimatedSize);
        this.masks = [0x0, 0xFF + 1, 0xFFFF + 1, 0xFFFFFF + 1, 0xFFFFFFFF + 1];
      }
    writeUInt8(value:number, bigEndian?:boolean) {
        return this.writeInteger(value, 1, bigEndian);
    };

    writeInt8(value:number, bigEndian?:boolean) {
        return this.writeInteger(value, 1, bigEndian);
    };

    writeUInt16(value:number, bigEndian?:boolean) {
        return this.writeInteger(value, 2, bigEndian);
    };

    writeInt16(value:number, bigEndian?:boolean) {
        return this.writeInteger(value, 2, bigEndian);
    };

    writeUInt32(value:number, bigEndian?:boolean) {
        return this.writeInteger(value, 4, bigEndian);
    };

    writeInt32(value:number, bigEndian?:boolean) {
        return this.writeInteger(value, 4, bigEndian);
    };

    writeString(value:string) {
        for (let i = 0; i < value.length; ++i) {
            this.data[this.pos++] = value.charCodeAt(i);
        }
    };

    /* value = the actual value which want to get stored
    * size = size in bytes of the value
    * bigEndian = flag to store the number in big endian style
    */
    writeInteger(value:number, size:number, bigEndian?:boolean) {
        let r = value;
        let i = 0;

        // convert to unsigned if value is negative
        if (value < 0) {
            r += this.masks[size];
        }

        // write the bytes
        for (i = 0; i < size; ++i) {
            if (bigEndian === true) {
                this.data[this.pos++] = (r >> ((size - i - 1) * 8)) & 0xFF;
            }
            else {
                this.data[this.pos++] = (r >> (i * 8)) & 0xFF;
            }
        }
    };
    

}

const wavfileService = new WavfileService();

export default wavfileService;