import { useMemo } from "react";
import coursePartService, { onePartatom } from "../../coursepart/CoursePartService";

import { useParams } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import { useAtom } from "jotai";

import PrintPartComponent from "src/Print/CoursePart/PrintPartComponent";
import SelectPartDetails from "src/Print/SelectPartDetails";



const PrintPart = () => {

  const { partId } = useParams();

  const partAtom = useMemo(() => onePartatom(partId!), [partId]);
  const [partData] = useAtom(partAtom);
  const { PrintData } = partData;
  const { languageService: t } = useI18n();


  function setItem() {
    coursePartService.clearPrintData(partId!);
  }

  const getPrint = (types: number[]) => {
    coursePartService.fetchPrintData(partId!, types, true);
  };

  return (
    <div className="mb-5">
      <div className="d-print-none">
        <h3 className="mb-3">{t.getText("print")}</h3>

        <SelectPartDetails onChange={setItem} getPrint={getPrint} />

      </div>

      {PrintData && <PrintPartComponent printData={PrintData} />}


    </div>
  )
}

export default PrintPart;
