import { useI18n } from "../../utils/lni18n";
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ErrorMessage } from "./ErrorService";

const ErrorDialog = (props: { error: ErrorMessage, onSubmit: (val: any) => void, onCancel: () => void }) => {

    const { languageService: t } = useI18n();
    const { error, onCancel } = props;

    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>
                {error.header}
            </ModalHeader>
        </span>
        <ModalBody>
            <div className="preserve-white" >{error.message}</div>
        </ModalBody>
        <ModalFooter >
            <button type='button' onClick={onCancel} value="cancel" className='btn btn-inverse btn-small' >{t.getText("close")}</button>
        </ModalFooter>
    </>
}

export default ErrorDialog; 