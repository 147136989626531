import React, { useState, useCallback, useRef, useEffect, useMemo, ChangeEventHandler, forwardRef, ChangeEvent } from "react";
import { Editor } from '@tinymce/tinymce-react';
import FloatBoxDialog from "./Plugins/FloatBoxDialog";
import { useI18n } from "../../utils/lni18n";
import { Editor as TinyMCEEditor } from 'tinymce';
import { PUBLIC_URL } from "../../utils/constants";
import imagePickerService from "../ImagePicker/ImagePickerService";
import { align } from "../ImagePicker/ImagePicker_types";
import ImagePickerDialog from "../ImagePicker/ImagePickerDialog";
import ImageAlignDialog from "../ImagePicker/ImageAlignDialog";
import { TextEditTypes } from "src/coursepart/domain/SavePartTextDto";
import { useDialog } from "../Modalservice/Dialogservice";
import { PickedImageData } from "../ImagePicker/ImagePicker";
import { read } from "@popperjs/core";

export type EditorProps = {
    className?: string,
    setValue?: (name: string, html: string, state?: boolean, text?: string) => void,
    initialValue?: string,
    onChange: ChangeEventHandler<HTMLTextAreaElement>,
    onClick?: React.MouseEventHandler<HTMLDivElement>,
    onBlur?: any,
    name: string | TextEditTypes,
    editorSettings?: any,
    onSetup?: Function,
    value?: string,
    invalid?: boolean,
    readOnly?: boolean
};

const HtmlTextEditor = function HtmlTextEditor(props: EditorProps) {

    const editorWrapper = useRef<any>();
    const fileOptions = {
        fileExtensions: "bmp,gif,jpg,png,tif,tiff,jpeg",
        maxsize: "10mb",
        mimetypes: "image/jpeg,image/png,image/gif,image/tiff,image/bmp"
    };

    const { readOnly, invalid, initialValue, name, editorSettings, onClick, onSetup, setValue, onBlur, value, onChange } = props;

    const dialogPortal = useDialog();

    const controlText = useRef<any>(undefined);

    const [boxState, setboxState] = useState<any | null>(null);
    const [dropFiles, setDropFiles] = useState(null);

    const [currentHtml, setCurrentHtml] = useState("");

    const [menuElementId] = useState("menu_" + Math.floor(Math.random() * 100000));
    const [debounceTimerId, setDebounceTimerId] = useState<number | null>(null);
    const { currentLanguage, languageService: t } = useI18n();

    // NOTE: to make this works with our language name I have changed file the sv_SE.js to sv.js
    //       and in that file 'tinymce.addI18n('sv_SE'...'  to 'tinymce.addI18n('sv' ...'
    let lang = currentLanguage.currentLang.Short;


    const [editor, setEditor] = useState<any | null>(null);

    useEffect(() => {
        window.clearTimeout(debounceTimerId || 0);
        setCurrentHtml(initialValue || "");

    }, [initialValue]);




    // unique id for each instance of editor
    const [editorId] = useState("id_" + (new Date()).getTime() + "_");

    const imageDialog = useCallback((ed: TinyMCEEditor) => {


        const imgXElm = ed.selection.getNode();
        const imgElm = imgXElm as HTMLImageElement;
        if (imgElm && imgElm.nodeName === "IMG") {

            let align: align = "none";
            if (imgElm.classList.contains("pull-left") === true) {
                align = "left";
            }
            if (imgElm.classList.contains("pull-right") === true) {
                align = "right";
            }
            const dataId = imgElm.attributes.getNamedItem("data-id");
            const id = dataId ? dataId.value : "";
            const src = imgElm.src;

            imagePickerService.setAlingData({
                modalOpen: true, imageAlign: align,
                imageId: id, url: src, changeOnly: true
            });


            var p = dialogPortal({
                factory: (onSubmit, onCancel) => {
                    return <ImageAlignDialog onCancel={onCancel} onSubmit={onSubmit} />
                },
                size: "md"
            });

            const editor = ed;
            p.then(res => {
                imagePickerService.setAlingData(undefined);
                if (res) {
                    imgElm.classList.remove('pull-right', 'pull-left');
                    if (res.imageAlign !== 'none') {
                        imgElm.classList.add('pull-' + res.imageAlign);
                    }
                }
                window.setTimeout(() => editor.focus(), 1000);
            });
        }
        else {
            imagePickerService.fetchImageData();

            var p = dialogPortal({
                factory: (onSubmit, onCancel) => {
                    return <ImagePickerDialog onCancel={onCancel} onSubmit={onSubmit} />
                },
                size: "xl"
            });

            const editor = ed;

            p.then((res: PickedImageData) => {
                imagePickerService.setModalState(false);
                imagePickerService.setAlingData(undefined);
                if (res) {
                    let className = "";
                    if (res.imageClasses !== "") {

                        className = `class="${res.imageClasses}"`;
                    }

                    ed.focus();
                    if (res.imageId) {
                        editor.insertContent(`<img ${className} src="${res.url}" data-id="${res.imageId}" />`)
                    }
                    else {
                        editor.insertContent(`<img ${className} src="${res.url}" />`)
                    }
                }
                window.setTimeout(() => editor.focus(), 1000);
            });


            imagePickerService.setSubmitBrooker((res) => {

            });
        }


    }, []);


    const floatBoxDialog = useCallback((ed: TinyMCEEditor) => {

        let floatdata = { align: "none", style: "Facts", width: 400, canDelete: false };

        var box = ed.dom.getParent(ed.selection.getNode(), "div.box");
        if (box != null) {
            floatdata.width = parseInt(ed.dom.getAttrib(box, "data-width"), 10);
            floatdata.align = ed.dom.getAttrib(box, "data-align");
            floatdata.style = ed.dom.getAttrib(box, "data-type");
            floatdata.canDelete = true;
        }

        setboxState(floatdata);


        var p = dialogPortal({
            factory: (onSubmit, onCancel) => {
                return <FloatBoxDialog floatCallback={onSubmit} boxState={floatdata} />
            },
            size: "md"
        });

        p.then(res => {
            setboxState(null);
            if (res) {
                const css = `box ${res.style} ${res.align}box w${res.width}`;
                if (box) {

                    if (res.delete) {
                        ed.dom.remove(box, false)
                    }
                    else {
                        ed.dom.setAttrib(box, "data-type", res.style);
                        ed.dom.setAttrib(box, "data-align", res.align);
                        ed.dom.setAttrib(box, "data-width", res.width);
                        ed.dom.setAttrib(box, "class", css);
                    }
                }
                else {
                    const html = `<div class='${css}' data-type='${res.style}' data-align='${res.align}'`
                        + ` data-width='${res.width}'><p>&#160;</p></div>`;
                    ed.insertContent(html);
                }
            }
            ed.focus();
        });


    }, [setboxState]);



    const handleEditorBlur = useCallback((e: TinyMCEEditor) => {
       
        const html = e.getContent();
        if (setValue) {
            setValue(name.toString(), html, true, e.getContent({ format: 'text' }));
        }

        if (controlText.current) {
            controlText.current.value = html || "";
            if (!controlText.current.onchange) {
                controlText.current.onchange = onChange;
            }
            controlText.current.dispatchEvent(new Event("change", { bubbles: true }));
        }


    }, [debounceTimerId, name, setValue, controlText.current]);


    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e);
    }


    const handleEditorChange = useCallback((c: any, e: TinyMCEEditor) => {

        setCurrentHtml(c);

        if (e.isDirty() === false) return;

        const html = c;

        window.clearTimeout(debounceTimerId || 0);

        const id = window.setTimeout(() => {

            if (setValue) {
                setValue(name.toString(), html, true, e.getContent({ format: 'text' }));
            }

            if (controlText.current) {
                controlText.current.value = html || "";
                if (!controlText.current.onchange) {
                    controlText.current.onchange = onChange;
                }
                controlText.current.dispatchEvent(new Event("change", { bubbles: true }));
            }

        }, 200);


        setDebounceTimerId(id);
    }, [debounceTimerId, name, setValue]);


    const onPaste = async (ev: any) => {

        // OLD ?
        // const paste = (ev.clipboardData || window["clipboardData"]);
        // const items = paste?.items;
        // if (items) {
        //     for (var i = 0; i < items.length; i++) {
        //         if (items[i].type.indexOf("image") !== -1) {
        //             // We need to represent the image as a file,
        //             var blob = items[i].getAsFile();
        //             setDropFiles([blob]);
        //             break;
        //         }
        //     }
        // }


        // Modern ??
        // try {
        //     const clipboardItems = await navigator.clipboard.read();
        //     for (const clipboardItem of clipboardItems) {

        //         const type = clipboardItem.types.find(t => t.indexOf("image") > -1)
        //         if (type) {
        //             const blob = await clipboardItem.getType(type);
        //             setDropFiles([blob]);
        //             return;
        //         }
        //     }
        // } catch (err) {
        //     console.error(err.name, err.message);
        // }
    }




    // const imageUploader = (ev) => {
    //     const files = ev.dataTransfer?.files;
    //     if (files) {
    //         setDropFiles(files);
    //     }
    // }

    // const handleDroppedFileReady = (id) => {
    //     setDropFiles(null);

    //     editor.focus();
    //     if (id) {
    //         const url = endPoint.GET_IMAGE_URL(id);
    //         editor.insertContent(`<img src="${url}" data-id="${id}" />`)
    //     }
    // }

    // const onCloseDropDialog = () => {
    //     setDropFiles(null);
    // }

    let settings: any = {
        icons: "lnauthoricons",
        language: lang,
        relative_urls: false,
        remove_script_host: false,
        // document_base_url: APP_URL,
        //theme: editorSettings && (editorSettings.theme || 'silver'),
        base_url: `${PUBLIC_URL}/tinymce`,
        contextmenu: "imagealign,floatbox",
        plugins: (editorSettings?.plugins) || "media,visualblocks,code,link,autolink,table,paste,charmap ",
        toolbar: (editorSettings?.toolbar) || "undo redo | styleselect | table | bold italic  | bullist numlist outdent indent | image floatbox media |  link code charmap ",
        menubar: false, //(editorSettings?.menubar) || "edit insert table format view tools",
        toolbar_items_size: "small",
        browser_spellcheck: true,
        fixed_toolbar_container: editorSettings?.toolbar === "" ? "nope" : "#" + menuElementId,
        skin_url: `${PUBLIC_URL}/tinymce/skins/ui/oxide`,
        paste_as_text: true,
        valid_elements: (editorSettings?.valid_elements) || "@[contenteditable|id|style|data*],-h1,-h2,-h3,-h4,-h5,-strong/b,-em/i,#p," +
            "-div[class],br,table[class],tr[class],td[class],thead[class],tbody[class],th[class],tfoot[class],-span" +
            ",source,video[controls|width|height],iframe[width|height|src|frameborder|allowfullscreen],-ol,-ul,-li,dl,dt,hr,del/strike,ins,rp,rt,ruby,-small,sub,sup,-code,-blockquote" +
            ",-a[href|target=_blank],img[class|src|alt|title|width|height|align|data-id]",

        media_live_embeds: true,

        object_resizing: "img",
        table_default_attributes: {
            'class': 'table'
        },
        table_class_list: [
            { title: 'none', value: '' },
            { title: 'default', value: 'table' },
            { title: 'striped', value: 'table table-striped' },
            { title: 'condensed', value: 'table table-small' },
            { title: 'condensed_striped', value: 'table table-condensed table-striped' },
            { title: 'border', value: 'table table-bordered' }
        ],

        table_row_class_list: [
            { title: 'none', value: '' },
            { title: 'notification.displaytype.success', value: 'table-success' },
            { title: 'active', value: 'table-active' },
            { title: 'notification.displaytype.danger', value: 'table-danger' },
            { title: 'notification.displaytype.warning', value: 'table-warning' }
        ],

        table_cell_class_list: [
            { title: 'none', value: '' },
            { title: 'notification.displaytype.success', value: 'table-success' },
            { title: 'active', value: 'table-active' },
            { title: 'notification.displaytype.danger', value: 'table-danger' },
            { title: 'notification.displaytype.warning', value: 'table-warning' }
        ],

        //table_cell_class_list: [{ title: 'None', value: '' } ],

        table_row_advtab: true,
        table_cell_advtab: false,
        table_advtab: false,

        setup: (ed: TinyMCEEditor) => {

            ed.on('blur', (e: any) => {
                handleEditorBlur(ed);
            });

            // ed.on('keydown ', (e: any) => {
            //     handleEditorChange( ed.getContent(), ed );
            // });



            ed.on('reset', (e: any) => {
                console.log("reset")
            });

            if (onSetup) {
                onSetup(ed);
            }
            //ed.on("drop", imageUploader);
            ed.on("paste", onPaste);

            ed.ui.registry.addButton('image', {
                icon: 'image',
                tooltip: 'Insert/edit image',
                onAction: () => imageDialog(ed)

                // stateSelector: 'img:not([data-mce-object],[data-mce-placeholder])'
            });


            ed.ui.registry.addButton('floatbox', {
                icon: 'floatbox',
                tooltip: 'Box',
                onAction: () => floatBoxDialog(ed),
            });



            ed.ui.registry.addContextMenu('imagealign', {

                update: (element: Element) => {
                    return !(element as HTMLImageElement).src ? ''
                        : [
                            {
                                icon: 'noalign', type: "item", text: "", onAction: () => setImageClass(element, null)
                            },
                            {
                                icon: "block", type: "item", text: "", onAction: () => setImageClass(element, "block")
                            },
                            {
                                icon: "leftalign", type: "item", text: "", onAction: () => setImageClass(element, "left")
                            },
                            {
                                icon: "rightalign", type: "item", text: "", onAction: () => setImageClass(element, "right")
                            }
                        ]
                }
            });

            setEditor(ed);

        }
    }

    function setImageClass(element: Element, className: string | null): void {
        const img = element as HTMLImageElement;
        switch (className) {
            case null:
                img.classList.remove("d-block", "float-left", "float-right");
                break;
            case "block":
                img.classList.remove("float-left", "float-right");
                img.classList.add("d-block");
                break;
            case "left":
                img.classList.remove("d-block", "float-left", "float-right");
                img.classList.add("float-left");
                break;

            case "right":
                img.classList.remove("d-block", "float-left", "float-right");
                img.classList.add("float-right");
                break;
        }
    }

    const tableSettings = ["table_class_list", "table_row_class_list", "table_cell_class_list"];

    tableSettings.forEach(key => {
        settings[key].forEach((e: any) => {
            e.title = t.getText(e.title);
        });
    })

    if (editorSettings) {
        settings = { ...settings, ...editorSettings }
    }

    // inline not supported on mobile
    settings.inline = true;

    if (readOnly) return <div className="" dangerouslySetInnerHTML={{ __html: initialValue || "<div>&nbsp;</div>" }}></div>

    return (
        <>
            <div onClick={onClick} ref={editorWrapper} className={`${invalid ? "invalid" : ""} ${props.className}  position-relative`}>
                <div className="outerMceMenuWrapper"><div className="innerMceMenuWrapper" id={menuElementId}></div></div>

                <Editor
                    onBlur={onBlur}
                    onEditorChange={handleEditorChange}
                    initialValue={initialValue}
                    value={currentHtml}
                    id={editorId + name}
                    tinymceScriptSrc={`${PUBLIC_URL}/tinymce/tinymce.min.js`}
                    init={settings}
                />

                <textarea className="off-screen" onChange={handleChange} name={name as string} value={currentHtml} ref={controlText}  ></textarea>
            </div>

            {/* <ImageDrop onCloseDialog={onCloseDropDialog} files={dropFiles} imagePreviewOptions={[{ name: "full", maxheight: 1200, maxwidth: 1200 }]}
                onImageReady={handleDroppedFileReady} accept={fileOptions.fileExtensions} languageService={t}
                maxsize={fileOptions.maxsize} /> */}


        </>
    )

}

export default HtmlTextEditor;


