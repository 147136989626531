

export class AudioSequence  {
    /// Name of the audio sequence (used for channel identification)
    public name: string = "unnamed";
    public sampleRate: number = 0;
    public data: Array<number> = [];

    // gain level of the signal data (maximum value) 
    public gain: number = 0.0;


    constructor(sampleRate: number, newData?: Float32Array) {
        this.sampleRate = sampleRate;
        
        this.data = [];
        if (newData !== undefined) {
            for (let i = 0; i < newData.length; i++) {
                this.data.push(newData[i]);
            }
        }
    }


    /**
     * This function merges another sequence from with the same sampling rate
     * into this.
     * @param mergePosition optional position where the new data should be merged (default is the end of the data block)
     * */
    public merge(otherAudioSequence: AudioSequence, mergePosition?: number) {
        // default parameters 
        if (mergePosition === undefined) mergePosition = this.data.length;
        // requirement check
        if (otherAudioSequence.sampleRate !== this.sampleRate) throw "Samplerate does not match.";
        if (mergePosition < 0 || mergePosition > this.data.length) throw "Merge position is invalid!";

        // create a new data block
        const newData = [];

        // iterate through the local data block
        for (let i = 0; i <= this.data.length; ++i) {
            // if the position is reached where the merge has to be filled in
            if (i == mergePosition) {
                for (let j = 0; j < otherAudioSequence.data.length; ++j) {
                    newData.push(otherAudioSequence.data[j]);
                }
            }
            // copy from the old to the new local data block
            if (i < this.data.length) {
                newData.push(this.data[i]);
            }
        }
        // set new references
        this.data = newData;

        // update gain value
        this.gain = this.getGain(undefined, undefined);
    }

    /**
     * Cuts off a part of the data sequence
     * @param start beginning of the trim
     * @param len optional len length of the trim (default is till the end of the data block)
     **/
    public trim(start: number, len?: number) {
        // default parameter
        if (len === undefined) len = this.data.length - start;

        if (start >= this.data.length || start < 0) throw "The start is invalid";
        if (start + len > this.data.length || len < 0) throw "The length is invalid.";

        this.data.splice(start, len);

        // update gain value
        this.gain = this.getGain(undefined, undefined);
    }

    /**
     * Create a clone of this sequence. Optionally the clone can be partial
     * @param start Optional beginning of the data block which will be cloned (default is 0)
     * @param len Optional len of the data block which will be cloned (default is till the end of the data block)
     */
    public clone(start: number, len: number): AudioSequence {
        // default parameter
        if (start === -1) start = 0;
        if (len === -1) len = this.data.length - start;

        // requirement check        
        if (start < 0 || start > this.data.length) throw "Invalid start parameter.";
        if (len < 0 || len + start > this.data.length) throw "Invalid len parameter.";

        // create new instance and copy array elements
        let clonedSequence = new AudioSequence(this.sampleRate, undefined);
        for (let i = start; i < start + len; ++i) {
            clonedSequence.data.push(this.data[i]);
        }

        // Update the gain for the cloned sequence
        clonedSequence.gain = clonedSequence.getGain(undefined, undefined);
        return clonedSequence;
    }

    /**
     * Creates a sequence with a specified length of data with value 0
     * @param len length of the 0 sequence
     * @param start optional insertion point for the 0 sequence (default is the end of the data block)
     */
    public createZeroData(len: number, start?: number) {
        let emptyData = new Float32Array(len);

        let tmpSequence: AudioSequence = new AudioSequence(this.sampleRate, emptyData);
        this.merge(tmpSequence, start);

        // update gain value
        this.gain = this.getGain(undefined, undefined);
    }

    /**
     * Copies the data into a complex array
     * @param start optional beginning of the data point (default is 0)
     * @param len optional length of the data sequence (default is till the end of the data block)
     */
    public toComplexSequence(start?: number, len?: number) {
        // default parameter
        if (start === undefined) start = 0;
        if (len === undefined) len = this.data.length - start;

        // requirement check
        if (start < 0 || start > this.data.length) throw "start parameter is invalid.";
        if (len < 0 || len + start > this.data.length) throw "end parameter is invalid.";

        let result = [];

        for (let i = start; i < start + len; ++i) {
            result.push(this.data[i]);
            result.push(0);
        }

        return result;
    }

    /**
     * Overwrites the data with the given complex array data
     * @param complexArray the complex array which gets real value gets copied
     * @param start optional beginning in the data point (default is 0)
     * @param len optional length of the data sequence (default is till the end of the data block)
     */
    public fromComplexSequence(complexArray:any[], start?: number, len?: number) {
        // default parameter
        if (start === undefined) start = 0;
        if (len === undefined) len = this.data.length - start;

        // requirement check
        if (complexArray.length / 2 !== len) throw "length of complex array does not match";
        if (complexArray.length % 2 !== 0) throw "the length of the complex array is totally wrong";
        if (start < 0 || start > this.data.length) throw "start parameter is invalid.";
        if (len < 0 || len + start > this.data.length) throw "end parameter is invalid.";

        let complexArrayIdx = 0;
        for (let i = start; i < start + len; ++i) {
            this.data[i] = complexArray[complexArrayIdx];
            complexArrayIdx += 2;
        }

        // update gain value
        this.gain = this.getGain(undefined, undefined);
    }

    /**
     * Returns the gain (maximum amplitude)
     * @param start optional beginning in the data point (default is 0)
     * @param len optional length of the data sequence (default is till the end of the data block)
     */
    public getGain(start?:number, len?:number) {
        // default parameter
        if (start === undefined) start = 0;
        if (len === undefined) len = this.data.length - start;

        // requirement check
        if (start < 0 || start > this.data.length) throw "start parameter is invalid.";
        if (len < 0 || len + start > this.data.length) throw "end parameter is invalid.";

        let result = 0.0;
        for (let i = start; i < start + len; ++i) {
            // the amplitude could be positive or negative
            let absValue = Math.abs(this.data[i]);
            result = Math.max(result, absValue);
        }
        return result;
    }

    /**
     * Returns the total length of this sequence in seconds
     **/
    public getLengthInSeconds() {
        return this.data.length / this.sampleRate;
    }

    /**
     * Apply a normalize on the data block, which changes the data value to use the optimal bandwidth
     * @param start optional beginning in the data point (default is 0)
     * @param len optional length of the data sequence (default is till the end of the data block)
     */
    public filterNormalize(start: number, len: number, correction?: number) {
        // default parameter
        if (start === -1) start = 0;
        if (len === -1) len = this.data.length - start;

        // requirement check
        if (start < 0 || start > this.data.length) throw "start parameter is invalid.";
        if (len < 0 || len + start > this.data.length) throw "end parameter is invalid.";

        let amplitudeCorrection = correction;
        if (!amplitudeCorrection) {
            // do a amplitude correction of the sequence
            let gainLevel = this.getGain(start, len);
            amplitudeCorrection = 1.0 / gainLevel;
        }
        for (let i = start; i < start + len; ++i) {
            this.data[i] = this.data[i] * amplitudeCorrection;
        }

        // update gain value
        this.gain = this.getGain(undefined, undefined);
        return amplitudeCorrection;
    }

    /**
     * Change the gain of the sequence. The result will give the sequence more or less amplitude
     * @param gainFactor the factor which will be applied to the sequence
     * @param start optional beginning in the data point (default is 0)
     * @param len optional length of the data sequence (default is till the end of the data block)
     */
    public filterGain(gainFactor:number, start?: number, len?: number) {
        // default parameter
        if (start === undefined) start = 0;
        if (len === undefined) len = this.data.length - start;

        // requirement check
        if (start < 0 || start > this.data.length) throw "start parameter is invalid.";
        if (len < 0 || len + start > this.data.length) throw "end parameter is invalid.";

        for (let i = start; i < start + len; ++i) {
            this.data[i] = this.data[i] * gainFactor;
        }

        // update gain value
        this.gain = this.getGain(undefined, undefined);
    }

    /**
     * Sets the data block to 0 (no amplitude = silence)
     * @param start optional beginning in the data point (default is 0)
     * @param len optional length of the data sequence (default is till the end of the data block)
     */
    public filterSilence(start?: number, len?: number) {
        this.filterGain(0.0, start, len);
    }

    /**
     * This function apply a fade effect on a given sequence range. The value of fadeStartGainFactor and fadeEndGainFactor
     * controls if the fade is an fadein or fadeout
     * @param fadeEndGainFactor The multiplier at the beginning of the fade
     * @param fadeEndGainFactor The multiplier at the end of the fade
     * @param start optional beginning in the data point (default is 0)
     * @param len optional length of the data sequence (default is till the end of the data block)
     */
    public filterLinearFade(fadeStartGainFactor:number, fadeEndGainFactor:number, start?: number, len?: number) {
        // default parameter
        if (start === undefined) start = 0;
        if (len === undefined) len = this.data.length - start;

        // requirement check
        if (start < 0 || start > this.data.length) throw "start parameter is invalid.";
        if (len < 0 || len + start > this.data.length) throw "end parameter is invalid.";

        let fadeGainMultiplier = 0.0;
        let fadePos = 0.0;
        for (let i = start; i < start + len; ++i) {
            fadePos = (i - start) / len;
            fadeGainMultiplier = this.lerp(fadeStartGainFactor, fadeEndGainFactor, fadePos);

            this.data[i] = this.data[i] * fadeGainMultiplier;

        }

        // update gain value
        this.gain = this.getGain(undefined, undefined);
    }

    private lerp = (start:number, end:number, percentage:number) => {
        if (start < end) {
            return start + (end - start) * percentage;
        }
        else {
            return end + (start - end) * (1.0 - percentage);
        }
    }

    /**
     * Process an reverse of the data block
     */
    public filterReverse() {
        this.data.reverse();
    }

    public createTestTone(frequency:number, sampleLength: number) {
        let data = [];
        let f = frequency / this.sampleRate;
        for (let i = 0; i < sampleLength; ++i) {
            data.push((Math.cos(2.0 * Math.PI * i * f) +
                Math.cos(2.0 * Math.PI * i * f * 1)) / 2);
        }

        this.data = data;
    }





}