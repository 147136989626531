//@ts-check
import { useCallback, useEffect, useState, useMemo } from "react";
import LinkGroup from "./LinkGroup";

import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, defaultDropAnimationSideEffects, useSensor, useSensors, } from "@dnd-kit/core";
import type { Active, DragEndEvent, DragOverEvent, DropAnimation, Over, UniqueIdentifier } from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import { Link } from "./Link";
import { LinkModel } from "../domain/LinksGroupModel";
import coursePartService, { onePartatom } from "../CoursePartService";
import { arrayMoveMutate } from "../../utils/ArrayMethods";

import { useImmerAtom } from 'jotai-immer'
import { useParams } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import { InputDialog, InputOptions } from "src/components/Modalservice/InputDialog";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import ReadOnlyLinks from "../ReadOnly/Link/ReadOnlyLinks";


const Links = () => {

  const { partId } = useParams();
  const dialogPortal = useDialog();
  const partAtom = useMemo(() => onePartatom(partId!), [partId]);
  const [partData, setPartDataFunction] = useImmerAtom(partAtom);
  const part = partData.Data;
  const { languageService: t } = useI18n();

  const groupsId = "thegroups";

  const [active, setActive] = useState<Active | null>(null);

  const groups = part?.LinkGroups || [];
  const [containers, setContainers] = useState<UniqueIdentifier[]>([]);

  const getLinkById = useCallback((id: UniqueIdentifier) => {
    let link: LinkModel | undefined;
    groups?.forEach(lg => lg.Links.forEach(li => {
      if (li.Id === id) link = li;
    }
    ));

    return link;
  }, [groups]);

  const getGroupById = useCallback((id: UniqueIdentifier) => {
    return groups?.find(g => g.Id === id)!;
  }, [groups]);

  useEffect(() => {
    if (groups) {
      setContainers(groups.map(lg => lg.Id as UniqueIdentifier));
    }
  }, groups)


  if (!part || !part.LinkGroups) {
    return null;
  }


  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const dragOver = (e: DragOverEvent) => {
    const { active, over } = e;
    if (!over) {
      return;
    }
    if (active.data.current?.sortable.containerId === groupsId
      && active.data.current?.sortable.index !== undefined
      && over.data.current?.sortable.index !== undefined) {
      setPartDataFunction(partData => {
        if (partData.Data?.FileGroups) {
          arrayMoveMutate(partData.Data.LinkGroups, active.data.current!.sortable.index, over.data.current!.sortable.index);
        }
      });

      return;
    }

    if (e.active.data.current?.sortable.containerId !== e.over?.data.current?.sortable.containerId) {

      setPartDataFunction(partData => {

        const gs = partData.Data?.LinkGroups;
        if (gs) {
          const newGroup = gs.find(g => g.Id === e.over?.data.current?.sortable.containerId);
          const oldGroup = gs.find(g => g.Id === e.active.data.current?.sortable.containerId);
          const link = oldGroup?.Links.find(l => l.Id === e.active.id);

          if (link && oldGroup && newGroup) {
            oldGroup.Links = oldGroup.Links.filter(l => l.Id !== e.active.id);
            newGroup.Links.splice(e.over?.data.current?.sortable.index, 0, link);
          }
        }

      });

    }
    else {  // same container
      setPartDataFunction(partData => {

        const gs = partData.Data?.LinkGroups;
        if (gs) {
          const group = gs.find(g => g.Id === e.active.data.current?.sortable.containerId);
          if (group) {
            arrayMoveMutate(group.Links, e.active.data.current?.sortable.index, e.over?.data.current?.sortable.index);
          }
        }
      });
    }

  }

  const dragEnd = async (ev: DragEndEvent) => {
    setActive(null);
    await coursePartService.saveLinkGroups(part.Id)
  };

  const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: '0.5',
        },
      },
    }),
  };

  const newGroup = () => {
    dialogPortal({
      factory: (onSubmit, onCancel) => {
        const dprops: InputOptions = {
          title: t.getText("add.file.group"),
          message: <div>{t.getText("name")}</div>,
          languageService: t,
          onCancel: onCancel,
          onSubmit: onSubmit,
          type: "input",
        }
        return <InputDialog {...dprops} />
      },
      size: "md"
    }).then((name: string) => {
      if (name !== "") {
        coursePartService.addLinkGroup({ name, partId: partId! })
      }
    });
  }

  if( part && part.Locked) return <ReadOnlyLinks />

  return (
    <div className="max-col-width">
      <DndContext
        sensors={sensors}

        onDragStart={({ active }) => {
          setActive(active);
        }}
        onDragOver={dragOver}
        onDragEnd={dragEnd}
        onDragCancel={() => {
          setActive(null);
        }}
      >

        <div role="list">
          <SortableContext items={groups.map(l => l.Id)} id={groupsId}>
            {groups.map(item => <LinkGroup partId={partId!} active={active?.id} closed={!item.IsDefault} key={item.Id} group={item} />)}
          </SortableContext>
        </div >

        {createPortal(
          <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>

            {active
              ? containers?.includes(active.id)
                ? <LinkGroup partId={partId!} dragged={true} closed={true} key={active.id} group={getGroupById(active.id)} />
                : <Link deleteLink={() => { }} edit={() => { }} dragged={true} link={getLinkById(active.id)!} />
              : null}
          </DragOverlay>,
          document.body
        )}

        <div className="mb-4 text-right">
          <button onClick={newGroup} className="btn btn-small btn-primary">{t.getText("add.more.group")}</button>
        </div>

      </DndContext>
    </div>
  )
}

export default Links;
