import { TinyAuthor } from "./coursepart/domain/TinyAuthor";


export interface Dictionary<T> {
    [key: string]: T;
}

export interface IdAndNameDto {
    Id: string;
    Name: string;
}

export interface IdAndNameAndSelectDto extends IdAndNameDto {
    Selected?: boolean;
    Dirty: boolean;
    OpenFromCourse?: boolean;
}





export interface IdAndNameWithNumDto extends IdAndNameDto {
    Num: number;
    type: "user" | "group";
}

export interface IdAndNameAndTypeDto extends IdAndNameDto {
    type: "user" | "group";
}




export interface KeyValuePair {
    Value: any;
    Key: string;
}


export interface Point {
    X: number;
    Y: number;
}


export interface DateSpan {
    Start: Date;
    End: Date;
}

// 


interface ValueDto<T> {
    Value: T;
}

export interface DateAndText {
    Text: string;
    Date: Date;
}

export enum Userstate {
    student = 0,
    teacher = 1,
    unitadmin = 2,
    unitroot = 3,
    root = 4
}

export enum ClientMode {
    notset = 0,
    patient = 1,
    student = 2,
    school = 4
}

export interface LockItemInfo {
    LockedBy: TinyAuthor;
    Reason: LockReason;
}

export enum LockReason {
    LockedByOther,
    LockedBySelf,
    ReadOnly,
    InEncoding,
    Noread
}