import { useNavigate, useParams } from 'react-router-dom';
import { useI18n } from 'src/utils/lni18n';
import { useAtom } from 'jotai';
import coursePartService, { partsListAtom } from 'src/coursepart/CoursePartService';
import { Card, CardBody, CardHeader } from 'reactstrap';
import LnIcon from '../LnIcon';
import { useDialog } from '../Modalservice/Dialogservice';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from '../Modalservice/ConfirmationDialog';
import classes from "./SideBar.module.scss";
import CoursesPanel from './CoursesPanel';
import { useEffect, useState } from 'react';
import { CoursePartWithConnectedDTO } from 'src/coursepart/domain/GetCoursepartsResponse';
import { urlService } from 'src/services/NavService';
import { Link } from 'react-router-dom';
import { currentUser, currentUserAtom } from 'src/services/user/userService';
import { Userstate } from 'src/types';


export default function SideBar(props: { setOpenSideBar: (state: boolean) => void }) {
    const { languageService: t } = useI18n();
    const navigate = useNavigate();
    const dialogPortal = useDialog();

    const { partId } = useParams();

    const [partList] = useAtom(partsListAtom);
    const [user] = useAtom(currentUserAtom);

    const [filteredList, setFilteredList] = useState<CoursePartWithConnectedDTO[]>([]);

    useEffect(() => {
        setFilteredList(partList.filter(p => !p.OpenFromCourse));
    }, [partList]);

    const navigateToPart = (id: string) => {

        if (id === partId) return;

        const url = coursePartService.getLastUrl(id);
        if (url) navigate(url);
    }

    const closePart = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {

        e.preventDefault();
        e.stopPropagation();

        const navigateTo = (nextPart?: string) => {
            if (nextPart) {
                navigateToPart(nextPart);
            }
            else {
                navigate(`/`);
            }
        }

        let result = await coursePartService.closePart(id);
        if (!result.confirm) {
            navigateTo(result.returnId);
        }
        else {
            dialogPortal({
                factory: (onSubmit, onCancel) => {
                    const dprops: ConfirmationOptions = {
                        className: "",
                        title: t.getText("close.editor"),
                        message: <div className="preserve-white" >{t.getText("publish.before.close")}</div>,
                        languageService: t,
                        show: true,
                        onClose: onCancel,
                        onSubmit: onSubmit,
                        buttons: ConfirmationButtons.YesNo
                    }
                    return <ConfirmationDialog {...dprops} />
                },
                size: "md"
            }).then(async (res: boolean) => {
                if (!res) {
                    result = await coursePartService.closePart(id, true);
                    navigateTo(result.returnId);
                }
                else {
                    navigate(urlService.urlToPart(id));
                }
            });

        }
    }

    return (
        <div className={`m-4 `}>

            <Link to="/" className={`${classes.topIcon}`} >
                <img src="/imgs/logo-author.svg" />
                {user?.UserState === Userstate.root &&
                    <button onClick={() => { throw new Error("ops"); }} type="button" className='btn btn-link'>
                        <LnIcon name="alert" className='icon-small' />
                    </button>
                }
            </Link>

            <div className='toggle'>
                <button className='btn btn-link p-3' onClick={() => props.setOpenSideBar(false)}>
                    <LnIcon name="previous-2" className='icon-small filter-green' />
                </button>
            </div>

            {filteredList && filteredList.length > 0 &&
                <Card className={`border border-primary ${classes.theme}`}>
                    <CardHeader className={classes.header}>
                        <strong>{t.getText("single.parts.in.edit")}</strong>
                    </CardHeader>
                    <CardBody className={classes.parts}>

                        {filteredList.map(p =>
                            <div role="link" onClick={() => navigateToPart(p.Id)} key={p.Id} className={`pointer ${classes.part} ${p.Selected ? "selected" : ""}`}  >

                                <div className='d-flex align-content-center w-100'>
                                    <div>
                                        {p.ConnectedWithCourse === false && <LnIcon name="no-course" className={`icon-small ${classes.noCourseIcon} ${p.Selected ? "filter-white" : ""} `} />}

                                        {p.Name}</div>
                                    <div className="ml-auto d-flex">
                                        {p.Dirty && <LnIcon name="alert" className='icon-small mb-2 mr-2' />}
                                        <button onClick={(e) => closePart(e, p.Id)} className="btn btn-link btn-small-icon" type="button">
                                            <LnIcon name="close" className='icon-small' />
                                        </button>
                                    </div>
                                </div>




                            </div>
                        )}

                    </CardBody>
                </Card >
            }


            <CoursesPanel />


        </div>
    )
}
