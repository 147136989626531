import * as React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LanguageService } from "../../utils/languageService";

export interface InputOptions {
    title: string;
    message: string | React.ReactNode;
    languageService: LanguageService;
    onSubmit: (d: any) => void;
    onCancel: () => void;
    text?: string,
    type: "input" | "area"
}

export const InputDialog: React.FC<InputOptions> = ({
    title,
    message,
    onSubmit,
    onCancel,
    languageService,
    text,
    type
}) => {

    const inp = React.useRef<HTMLInputElement | HTMLTextAreaElement | null>();

    const handleSubmit = () => {

        if (!inp.current) return;
        const el = inp.current as HTMLInputElement;
        if (el.value === "") return;
        onSubmit(el.value);
    }

    const handleCancel = () => {
        onCancel();
    }
    const setRef = React.useCallback((node: HTMLInputElement | HTMLTextAreaElement | null) => {
        if (node) {
            inp.current = node;
            if (text) {
                node.value = text;
            }
        }
    }, []);

    return <>
        <span  className="pointer" data-dialog-draggable>
            <ModalHeader>
                {title}
            </ModalHeader>
        </span>
        <ModalBody>
            {message}
            <form className="mt-2 mb-5" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                {
                    type === "area"
                        ? <textarea ref={setRef} className="form-control" />
                        : <input ref={setRef} type="text" className="form-control" />
                }
            </form>
        </ModalBody>
        <ModalFooter>
            <Button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>{languageService.getText("ok")}</Button>&nbsp;
            <Button type="button" className="btn btn-warning" onClick={() => handleCancel()}>{languageService.getText("cancel")}</Button>
        </ModalFooter>
    </>
};
